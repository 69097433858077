import React, { ComponentType, FunctionComponent, ImgHTMLAttributes } from 'react';
import { global } from 'data';
import { styled, css } from 'mymoria-ui';
import { Text } from 'mymoria-ui/components';
import boisLogo from 'assets/initiative-logo.svg';
import trustedShopsLogo from 'assets/trusted-shops-logo.png';

const {
  certificate: {
    header1,
    logos: { bois, trustedShops },
  },
} = global;

interface LogoProps {
  Icon: ComponentType<ImgHTMLAttributes<HTMLImageElement>>;
  logo: string;
  alt: string;
  link: string;
  title: string;
}

const InitiativeLogo = styled.img`
  width: 4rem;
  height: auto;
`;

const TrustedShopLogo = styled.img`
  width: 4rem;
  height: auto;
`;

const Logo: FunctionComponent<LogoProps> = ({ logo, alt, link, title, Icon }) => (
  <a href={link} target="_blank" title={title} rel="nofollow noopener noreferrer">
    <Icon src={logo} alt={alt} />
  </a>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled(Text)(
  ({ theme: { colors, font } }) => css`
    color: ${colors.lighterBlack};
    font-size: ${font.size.tiny}; // todo: how to override style defined in mymoria-ui?
  `,
);

const Certificates = () => (
  <>
    <Container>
      <Title>{header1}</Title>
    </Container>
    <Container>
      <Logo logo={boisLogo} Icon={InitiativeLogo} {...bois} />
      <Logo logo={trustedShopsLogo} Icon={TrustedShopLogo} {...trustedShops} />
    </Container>
  </>
);

export default Certificates;
