import { useSelector } from 'hooks';
import { merge } from 'lodash';
import { UIProvider } from 'mymoria-ui';
import React, { FunctionComponent } from 'react';
import { isWundw } from 'reducers/insurance';
import { apiConfig, defaultTheme } from 'styles/themes';

const WundWTheme = {
  colors: {
    primary: '#eb3c07',
    gray2: '#eb3c07',
    lightBlack: '#111111',
    lighterBlack: '#555555',
    black: '#000000',
  },
  font: {
    family: {
      glosa: 'Arial',
      proximaNova: 'Arial',
    },
  },
  components: {
    headings: {
      h1: {
        fontFamily: 'Arial',
        color: '#111111',
      },
      h2: {
        fontFamily: 'Arial',
        color: '#111111',
      },
      h3: {
        fontFamily: 'Arial',
        color: '#111111',
      },
      h4: {
        fontFamily: 'Arial',
        color: '#111111',
      },
      h5: {
        fontFamily: 'Arial',
        color: '#111111',
      },
      h6: {
        fontFamily: 'Arial',
        color: '#111111',
      },
    },
  },
};

const CustomUIProvider: FunctionComponent = ({ children }) => {
  const isWundW = useSelector(isWundw);

  return (
    <UIProvider theme={isWundW ? merge(apiConfig, WundWTheme) : defaultTheme}>
      {children}
    </UIProvider>
  );
};

export default CustomUIProvider;
