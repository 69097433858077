import { OverviewCard, Page, Section } from 'components';
import { global as t } from 'data';
import { useFunnel, useSelector } from 'hooks';
import { Col, Row, Text, Grid } from 'mymoria-ui/components';
import React, { FunctionComponent } from 'react';
import { shallowEqual } from 'react-redux';
import { pick } from 'lodash';
import { useHistory } from 'mymoria-ui/hooks';

const WishesView: FunctionComponent = () => {
  const { handlePush } = useHistory();
  const { openEditFunnel } = useFunnel();
  const { music, quote, personalNotification } = useSelector(
    ({ provision }) => pick(provision, ['music', 'quote', 'personalNotification']),
    shallowEqual,
  );
  const editModeOptions = { headerTitle: t.wishes.editMode.headerTitle };

  return (
    <Page>
      <Section
        title={t.wishes.sectionTitle}
        description={t.wishes.sectionDescription}
        onGoBack={handlePush('/overview', { restoreScrollPosition: true })}
        goBackLabel={t.overview.name}
      >
        <Grid>
          <Row flexWrap="wrap" justifyContent="center">
            <Col width={[1, 10 / 12, 2 / 3]} px={[0, 1]}>
              <OverviewCard
                title={t.wishes.music.title}
                btnOnClick={() => openEditFunnel('WISHES_MUSIC', editModeOptions)}
                status={music ? 'completed' : 'incompleted'}
              >
                {music && <Text>{music}</Text>}
              </OverviewCard>
            </Col>
            <Col width={[1, 10 / 12, 2 / 3]}>
              <OverviewCard
                title={t.wishes.quote.title}
                btnOnClick={() => openEditFunnel('WISHES_QUOTE', editModeOptions)}
                status={quote ? 'completed' : 'incompleted'}
              >
                {quote && <Text>{quote}</Text>}
              </OverviewCard>
            </Col>
            <Col width={[1, 10 / 12, 2 / 3]}>
              <OverviewCard
                title={t.wishes.personalNotification.title}
                btnOnClick={() => openEditFunnel('WISHES_PERSONAL_NOTIFICATION', editModeOptions)}
                status={personalNotification ? 'completed' : 'incompleted'}
              >
                {personalNotification && <Text>{personalNotification}</Text>}
              </OverviewCard>
            </Col>
          </Row>
        </Grid>
      </Section>
    </Page>
  );
};

export default WishesView;
