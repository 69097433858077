import actions from 'actions';
import { GraveTypesState } from 'types';
import { createReducer } from 'typesafe-actions';

const initialState: GraveTypesState = { burial: [], cremation: [], sea: [], tree: [] };

const { fetchGraveTypes } = actions.graveTypes;

export default createReducer(initialState).handleAction(fetchGraveTypes, (state, { payload }) => ({
  ...state,
  ...payload.result,
}));
