import { Button, Markdown, WizardCard, Row, Loader } from 'mymoria-ui/components';
import { financial, global } from 'data';
import React, { FunctionComponent, useLayoutEffect, useState } from 'react';
import { useHistory, useToast } from 'mymoria-ui/hooks';
import { api } from 'utils';
import { WizardComponentProps } from 'react-albus';
import { useSelector } from 'hooks';
import { useHDHState } from './HDHState';

const DocumentStep: FunctionComponent<WizardComponentProps> = ({
  wizard: { push, step, steps },
}) => {
  const [isLoading, setLoading] = useState(false);
  const [documentURL, setDocumentURL] = useState();
  const [{ paymentType, contactDetails, totalFuturePrice }] = useHDHState();
  const { addToast } = useToast();
  const { handlePush } = useHistory();
  const provisionId = useSelector(({ provision }) => provision.id);
  const provisionBirth = useSelector(({ provision }) => provision.provisionBirth);

  useLayoutEffect(() => {
    if (!paymentType) {
      push('paymentType');
    }
  }, [paymentType, push]);

  const downloadDocument = async () => {
    try {
      setLoading(true);
      const { data } = await api.post(`/provisions/insurances/hdh/document`, {
        paymentType,
        provisionBirth,
        provisionId: provisionId.substring(0, 8),
        costsRounded: totalFuturePrice,
        ...contactDetails,
      });

      setLoading(false);
      setDocumentURL(data.url);

      window.open(data.url);
    } catch (err) {
      addToast(global.apiErrors.general);
    }
  };

  return (
    <WizardCard
      title={documentURL ? financial.hdh.download.downloadedTitle : financial.hdh.download.title}
      labelPrev={global.labels.back}
      labelNext={global.labels.continue}
      onClickPrev={() => push('contact')}
      steps={steps.length - 2}
      step={steps.indexOf(step)}
    >
      {isLoading && <Loader overlay />}
      <Markdown>
        {documentURL
          ? financial.hdh.download.downloadedDescription
          : financial.hdh.download.description}
      </Markdown>
      <Row justifyContent="center" mt={6}>
        <Button onClick={documentURL ? () => window.open(documentURL) : downloadDocument} mx={2}>
          {documentURL
            ? financial.hdh.download.downloadedText
            : financial.hdh.download.downloadButton}
        </Button>
        <Button ghost onClick={handlePush('/overview/finance')} mx={2}>
          {financial.hdh.download.fundingButton}
        </Button>
      </Row>
    </WizardCard>
  );
};

export default DocumentStep;
