import React, { FunctionComponent, useState } from 'react';
import { createLetter } from 'actions/letters';
import { useDispatch } from 'hooks';
import { global, letter as t } from 'data';
import { withLocationTrack } from 'hocs';
import { Button, Card, Col, Loader, Markdown, Row, Grid } from 'mymoria-ui/components';
import { css, mq, styled } from 'mymoria-ui';
import { Section } from 'components';
import { usePush, useToast } from 'mymoria-ui/hooks';
import letter1 from 'assets/letter1.jpg';
import letter2 from 'assets/letter2.jpg';
import letter3 from 'assets/letter3.jpg';
import { PortalSection } from 'types';

interface LetterIntroProps {
  translations?: PortalSection;
}

const StyledCard = styled(Card)`
  background: #fff url('/img/background.svg') no-repeat right top;
  position: relative;
  margin-top: 4rem;
`;

const PriceCircle = styled.div`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-color: #f2b134;
  padding: 1.5rem;
  line-height: 1.3;
  font-weight: 700;
  color: white;
  font-size: 26px;
  text-align: center;
  display: flex;
  align-items: center;
  position: absolute;
  top: -60px;
  right: 50px;
`;

const GhostButton = styled(Button)(
  mq({
    paddingLeft: ['1rem', '1rem', '2rem'],
  }),
);

const Thumbnail = styled.div<{ background: string }>(
  ({ background }) => css`
    background: url(${background});
    background-size: cover;
    border-radius: 50%;
    border: 1px solid #ccc;

    ${mq({
      minWidth: ['100px', '100px', '120px'],
      minHeight: ['100px', '100px', '120px'],
      marginRight: ['0.5rem', '0.5rem', '1rem'],
    })}
  `,
);

const StyledMarkdown = styled(Markdown)`
  margin-top: 2rem;
  ul {
    margin: 2rem 0 0;
    list-style-position: inside;
    li {
      &:last-child {
        line-height: 1;
        padding-bottom: 0;
      }
    }
  }
`;

const LetterIntro: FunctionComponent<LetterIntroProps> = ({
  translations: { Headline, IntroDescription, IntroButton } = {},
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { handlePush, push } = usePush();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleClick = () => {
    setSubmitting(true);
    dispatch(createLetter())
      .then(({ payload }) => {
        setSubmitting(false);
        push('/overview/letters/create', payload.result);
      })
      .catch(() => {
        setSubmitting(false);
        addToast(global.apiErrors.general);
      });
  };

  return (
    <Section
      title={Headline}
      goBackLabel={global.labels.back}
      onGoBack={handlePush('/overview/letters')}
    >
      <Grid>
        <Row justifyContent="center">
          <Col width={[1, 3 / 4]}>
            {isSubmitting && <Loader overlay />}
            <StyledCard>
              <PriceCircle>{t.create.steps.intro.priceTag}</PriceCircle>
              <StyledMarkdown>{IntroDescription}</StyledMarkdown>
              <Col display="flex" py="3rem" px="0">
                {[letter1, letter2, letter3].map((letter, index) => (
                  <Thumbnail key={`letter${index}`} background={letter} />
                ))}
              </Col>
              <Col display="flex">
                <Button onClick={handleClick}>{IntroButton}</Button>
                <GhostButton ghost onClick={handlePush('/overview/letters')}>
                  {global.labels.later}
                </GhostButton>
              </Col>
            </StyledCard>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

export default withLocationTrack(t.create.steps.intro.title)(LetterIntro);
