import { createAction } from 'typesafe-actions';
import { AppParams } from 'types';

const setAppParamsAction = createAction('SET_APP_PARAMS')<Partial<AppParams>>();

export const setAppParams = (params: Partial<AppParams>) => setAppParamsAction(params);

export default {
  setAppParams: setAppParamsAction,
};
