import React, { FunctionComponent } from 'react';
import { Page, Section } from 'components';
import { financial as t } from 'data';
import { RouteComponentProps } from 'react-router';
import { Step, Steps, Wizard } from 'react-albus';
import { WizardGrid } from 'mymoria-ui/components';
import IntroStep from './IntroStep';
import HDHStateProvider from './HDHState';
import PaymentTypeStep from './PaymentTypeStep';
import TariffStep from './TariffStep';
import ContactDetailsStep from './ContactDetailsStep';
import DocumentStep from './DocumentStep';
import TreuhandStep from './TreuhandStep';

const HDHWizard: FunctionComponent<RouteComponentProps> = ({ history, match }) => (
  <Page footer="none">
    <Wizard
      history={history}
      basename={match.url}
      render={({ step }) => (
        <Section
          title={t.wizard[step.id]?.title}
          onGoBack={step.id === 'intro' ? () => history.push('/overview/finance') : undefined}
        >
          <WizardGrid>
            <HDHStateProvider>
              <Steps>
                <Step id="intro" render={wizard => <IntroStep wizard={wizard} />} />
                <Step id="paymentType" render={wizard => <PaymentTypeStep wizard={wizard} />} />
                <Step id="tariff" render={wizard => <TariffStep wizard={wizard} />} />
                <Step id="contact" render={wizard => <ContactDetailsStep wizard={wizard} />} />
                <Step id="document" render={wizard => <DocumentStep wizard={wizard} />} />
                <Step id="treuhand" render={wizard => <TreuhandStep wizard={wizard} />} />
              </Steps>
            </HDHStateProvider>
          </WizardGrid>
        </Section>
      )}
    />
  </Page>
);

export default HDHWizard;
