import React, { createContext, FunctionComponent, useCallback, useState } from 'react';

export type FunnelMode = 'edit' | 'create' | null;
export type FunnelSection =
  | 'TRUSTED_PERSON'
  | 'WISHES'
  | 'WISHES_MUSIC'
  | 'WISHES_QUOTE'
  | 'WISHES_PERSONAL_NOTIFICATION'
  | 'FUNERAL_SITE'
  | 'MEALS'
  | 'GRAVE_STONE'
  | 'WOHUNG'
  | 'GRAVE_MAINTENANCE'
  | 'FINANCIAL_PROVISION'
  | 'FINANCIAL_QUESTION'
  | 'PROFILE'
  | 'HDH'
  | null;

interface FunnelOptions {
  [field: string]: any;
}

interface FunnelData {
  mode: FunnelMode;
  section: FunnelSection;
  options: FunnelOptions;
  openCreateFunnel: (section: FunnelSection, options?: FunnelOptions) => void;
  openEditFunnel: (section: FunnelSection, options?: FunnelOptions) => void;
  closeFunnel: () => void;
}

export const FunnelContext = createContext<FunnelData>({} as FunnelData);

export const FunnelProvider: FunctionComponent = ({ children }) => {
  const [mode, setMode] = useState<FunnelMode>(null);
  const [section, setSection] = useState<FunnelSection>(null);
  const [options, setOptions] = useState({});

  const openFunnel = (mode: FunnelMode) => (section: FunnelSection, options?: FunnelOptions) => {
    setMode(mode);
    setSection(section);

    if (options) {
      setOptions(prevOptions => ({ ...prevOptions, ...options }));
    }
  };

  const openCreateFunnel = useCallback(
    (section, options) => openFunnel('create')(section, options),
    [],
  );

  const openEditFunnel = useCallback(
    (section, options) => openFunnel('edit')(section, options),
    [],
  );

  const closeFunnel = useCallback(() => {
    setMode(null);
    setSection(null);
    setOptions({});
  }, []);

  const contextValue: FunnelData = {
    mode,
    section,
    options,
    openCreateFunnel,
    openEditFunnel,
    closeFunnel,
  };

  return <FunnelContext.Provider value={contextValue}>{children}</FunnelContext.Provider>;
};
