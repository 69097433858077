import React, { FunctionComponent, useCallback } from 'react';
import * as t from 'data';
import { Col, Legend, Row, Text, WizardCard } from 'mymoria-ui/components';
import { funeralTypes } from 'forms/options';
import { useSelector } from 'hooks';
import { setRegistration } from 'actions/registration';
import { WizardComponentProps } from 'react-albus';
import config from 'config';
import { styled } from 'mymoria-ui';
import { FuneralType } from 'mymoria-types';
import FunnelButton from './FunnelButton';
import { withLocationTrack } from 'hocs';
import { useDispatch } from 'hooks';
import { trackProvisionPortalData } from 'actions/tracking';

const StyledButton = styled(FunnelButton)`
  display: flex;
  position: relative;
  justify-content: center;
  padding: 1.5rem 1rem;
  height: 100%;
  line-height: normal;
  flex-direction: column;
  overflow: initial;

  &:hover {
    p {
      color: white;
    }
  }
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  height: 100%;
  white-space: normal;

  & > img {
    height: 30px;
    width: auto;
    margin: auto 1rem auto 0;
  }
`;

const StyledLegend = styled(Legend)`
  position: absolute;
  top: -10px;
  align-self: center;
`;

const StyledDescription = styled(Text)`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const FuneralTypeStep: FunctionComponent<WizardComponentProps> = ({
  wizard: { next, steps, step },
}) => {
  const funeralType = useSelector(({ registration }) => registration.funeralType);

  const dispatch = useDispatch();

  const goToFunnel = useCallback(() => {
    const funnelUrl = new URL('/create/concern-type', config.funnel.endpoint);
    window.open(funnelUrl.toString());
  }, []);

  const handleSubmit = (funeralType: FuneralType) => {
    dispatch(setRegistration({ funeralType }));
    dispatch(trackProvisionPortalData({ funeralType }));
    next();
  };

  return (
    <WizardCard
      title={t.signUp.wizards.funeralType.title}
      onClickPrev={goToFunnel}
      onClickNext={next}
      labelPrev={t.global.labels.back}
      labelNext={t.global.labels.continue}
      disabledNext={!funeralType}
      icon="/img/icons/funeralType.svg"
      steps={steps.length - 1}
      step={steps.indexOf(step)}
    >
      <Text mb="2rem">{t.signUp.wizards.funeralType.description}</Text>
      <Row flexWrap="wrap" mx={-1}>
        {funeralTypes.map(({ value, label, description, legend, bg }) => (
          <Col key={value} width={[1, 1, 1 / 2]} my={1}>
            <StyledButton
              selected={value === funeralType}
              onClick={() => handleSubmit(value as FuneralType)}
            >
              {legend && <StyledLegend bgColor={bg}>{legend}</StyledLegend>}
              <StyledWrapper>
                <img src={`/img/icons/${value}.svg`} alt={`${value}-icon`} />
                <div>
                  <Text fontSize={2} margin={0}>
                    {label}
                  </Text>
                  <StyledDescription fontSize={1} margin={0} lineHeight={1.2} color="#555">
                    {description}
                  </StyledDescription>
                </div>
              </StyledWrapper>
            </StyledButton>
          </Col>
        ))}
      </Row>
    </WizardCard>
  );
};

export default withLocationTrack(t.signUp.wizards.funeralType.title)(FuneralTypeStep);
