import { Button, Col, Heading, Loader, Row, Text, Icon } from 'mymoria-ui/components';
import config from 'config';
import { AxiosError } from 'axios';
import { useErrorMessage, useSelector } from 'hooks';
import moment from 'moment';
import { styled, css } from 'mymoria-ui/utils';
import { trackOfferRefresh } from 'actions/tracking';
import { global } from 'data';
import React, { FunctionComponent, useState } from 'react';
import { RefreshProps } from 'types';
import { proposalAPI } from 'api';
import RefreshOfferResult from './RefreshOfferResult';
import { useDispatch } from 'react-redux';

const { dateFormat } = config;

interface Props {
  onClose: () => void;
}

const t = {
  title: 'Hinweis',
  submit: 'Angebot aktualisieren',
  cancel: 'Abbrechen',
  description: (createdAt: string) =>
    `Dieses Angebot wurde am ${createdAt} zuletzt bearbeitet. Seit Erstellung des Angebots könnten sich die Preise und Verfügbarkeiten der angebotenen Leistung verändert haben. Um aktuelle Preise zu berechnen, klicken Sie bitte hier.`,
};

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  svg {
    width: 50px;
    height: 50px;
    margin-right: 1rem;
  }
`;

const StyledButton = styled(Button)(
  ({
    theme: {
      colors,
      font: { weight },
    },
  }) => css`
    color: ${colors.white};
    font-weight: ${weight.bold};

    svg {
      width: 25px;
      height: 40px;
      vertical-align: middle;
      margin-right: 0.5rem;
    }
  `,
);

const RefreshOfferModal: FunctionComponent<Props> = ({ onClose }) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [refreshResult, setRefreshResult] = useState<RefreshProps>();
  const { error, setError } = useErrorMessage({ messages: global.apiErrors.general });
  const createdAt = useSelector(({ provision }) => provision.createdAt);
  const createdAtDate = moment(createdAt).format(dateFormat.view);
  const proposalId = useSelector(({ provision }) => provision.proposalId);

  const handleSubmit = () => {
    setLoading(true);
    if (error) {
      setError(null);
    }

    dispatch(
      trackOfferRefresh(global.tracking.refreshOffer.eAct, global.tracking.refreshOffer.eLab),
    );

    return proposalAPI
      .refreshOffer(proposalId)
      .then(({ data }) => {
        setRefreshResult(data.message ? null : data);
        setLoading(false);
      })
      .catch((err: AxiosError) => {
        setLoading(false);
        setError(err);
        onClose();
      });
  };

  return refreshResult || refreshResult === null ? (
    <RefreshOfferResult result={refreshResult} />
  ) : (
    <>
      {loading && <Loader overlay />}
      <Row flexDirection="column">
        <Col width={1}>
          <Title>
            <Icon.Refresh color="#111111" />
            <Heading.Heading2>{t.title}</Heading.Heading2>
          </Title>
          <br />
          <Text>{t.description(createdAtDate)}</Text>
          <br />
        </Col>
      </Row>
      <Row flexDirection="column" alignItems="center">
        <Col width={[1, 3 / 4]} py={3}>
          <StyledButton block onClick={handleSubmit}>
            <Icon.Reload color="white" />
            {t.submit}
          </StyledButton>
        </Col>
        <Col width={[1, 3 / 4]}>
          <Button outline block onClick={onClose}>
            {t.cancel}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default RefreshOfferModal;
