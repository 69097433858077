import config from 'config';
import { IconSection, ProvisionPlan, Section } from 'components';
import { global as t } from 'data';
import { withLocationTrack } from 'hocs';
import { useSelector } from 'hooks';
import moment from 'moment';
import { Col, Grid, Loader, Message, Row } from 'mymoria-ui/components';
import { getDiscount } from 'reducers/provision';
import { useAPI, useHistory } from 'mymoria-ui/hooks';
import { sprintf } from 'utils';
import React, { FunctionComponent } from 'react';
import { ProvisionPlan as ProvisionPlanType } from 'mymoria-types';
import { useFormikContext } from 'formik';
import { PricingValues } from '../types';

const PriceGuarantee: FunctionComponent = () => {
  const { handlePush } = useHistory();
  const discount = useSelector(state => getDiscount(state));
  const formikBag = useFormikContext<PricingValues>();
  const { paymentAcceptedAt, paymentAcceptedPlan, paymentPriceGuarantee } = useSelector(
    ({ provision }) => provision,
  );
  const [{ data: plan, loading }] = useAPI<ProvisionPlanType>('/provisions/plans/2');

  const handleClick = () => {
    formikBag.setFieldValue('plan', plan);
    formikBag.submitForm();
  };

  return (
    <Section
      title={t.priceGuarantee.sectionTitle}
      description={t.priceGuarantee.description}
      onGoBack={handlePush('/overview', { restoreScrollPosition: true })}
      goBackLabel={t.overview.name}
    >
      <Grid>
        <Row flexWrap="wrap" justifyContent="center">
          <Col width={1}>
            {paymentPriceGuarantee && (
              <Message>
                {sprintf(t.priceGuarantee.message, {
                  date: moment(paymentAcceptedAt).format(config.dateFormat.view),
                })}
              </Message>
            )}
            {discount && !paymentPriceGuarantee && (
              <Message status="warning">{t.priceGuarantee.discountMessage}</Message>
            )}
          </Col>
          {loading ? (
            <Loader />
          ) : (
            <Col width={[1, 10 / 12, 6 / 9]} px={[0, 1]}>
              <ProvisionPlan
                plan={plan}
                onClick={handleClick}
                isActive={paymentAcceptedPlan === plan?.id}
              />
              {!paymentPriceGuarantee && <IconSection />}
            </Col>
          )}
        </Row>
      </Grid>
    </Section>
  );
};

export default withLocationTrack(t.priceGuarantee.sectionTitle)(PriceGuarantee);
