import { ConfirmationBox, SectionPublic, Page } from 'components';
import { global as t, global } from 'data';
import { Form, Formik, FormikHelpers } from 'formik';
import { EmailField } from 'forms/fields';
import { withLocationTrack } from 'hocs';
import { styled } from 'mymoria-ui';
import { Button, Loader, Text } from 'mymoria-ui/components';
import React from 'react';
import { api } from 'utils';
import * as yup from 'yup';

interface FormikValues {
  username: string;
}

const StyledLoader = styled(Loader)`
  padding: 0 1rem;
`;

const ForgotPassword = () => {
  const handleSubmit = (values: FormikValues, bag: FormikHelpers<FormikValues>) => {
    bag.setStatus({ error: false });

    api
      .post('/provisions/users/forgot-password/email', values)
      .then(() => {
        bag.setSubmitting(false);
        bag.setStatus({ submitted: true });
      })
      .catch(err => {
        console.log(err.response);
        // todo: replace it with toast notification
        bag.setSubmitting(false);
        bag.setStatus({
          error: err.response?.status === 404 ? t.validators.userNotExists : t.apiErrors.general,
          submitted: false,
        });
      });
  };

  return (
    <Page footer="none">
      <Formik
        validationSchema={yup.object().shape({
          // todo: check if email exists in our database
          username: yup.string().email(t.validators.email).required(t.validators.required),
        })}
        initialValues={{ username: '' }}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, status = {}, values }) => (
          <SectionPublic
            title={t.forgotPassword.email.title}
            description={
              status.submitted
                ? t.forgotPassword.email.successDescription
                : t.forgotPassword.email.description
            }
            footerLink="/login"
            footerLinkLabel={t.labels.signIn}
            footerTitle={t.forgotPassword.email.footerTitle}
          >
            {status.submitted ? (
              <ConfirmationBox>
                <img src="/img/fancy-check-mark.svg" alt="success" />
                <Text align="center">
                  {t.forgotPassword.email.success}
                  <br />
                  <strong>{values.username}</strong>
                </Text>
              </ConfirmationBox>
            ) : (
              <Form noValidate>
                <EmailField type="email" name="username" label="E-mail" />
                <Button type="submit" block disabled={isSubmitting}>
                  {t.forgotPassword.email.send}
                  {isSubmitting && <StyledLoader tiny />}
                </Button>
                {/* todo: add toast notification and remove it */}
                {status.error && <Text>{status.error}</Text>}
              </Form>
            )}
          </SectionPublic>
        )}
      </Formik>
    </Page>
  );
};

export default withLocationTrack(global.tracking.forgotPassword)(ForgotPassword);
