import React, { FunctionComponent } from 'react';
import { styled, mq } from 'mymoria-ui/utils';
import { transparentize } from 'polished';

interface ToolBarContainerProps {
  onClick?: () => void;
}

const StyledContainer = styled.div`
  background: ${transparentize(0.05, '#222222')};
  display: flex;
  justify-content: space-between;
  padding: 2rem 1rem;
  border-radius: 4px;
  ${mq({
    padding: ['1rem', '2rem 1rem'],
  })}
`;

const ToolBarContainer: FunctionComponent<ToolBarContainerProps> = ({ children, ...props }) => (
  <StyledContainer {...props}>{children}</StyledContainer>
);

export default ToolBarContainer;
