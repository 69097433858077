import * as t from 'data';
import { CallbackTime, FuneralPlan, FuneralType, Salutation } from 'mymoria-types';
import { RadioGroupOptions } from 'mymoria-ui/components/Form/RadioGroup';
import { SelectOption } from 'mymoria-ui/components/Form/SelectLight';

export const salutationOptions: RadioGroupOptions<Salutation>[] = [
  { value: 'sir', label: t.global.forms.salutation.sir },
  { value: 'madame', label: t.global.forms.salutation.madame },
];

export const callbackTimeOptions: RadioGroupOptions<CallbackTime>[] = [
  { value: 'asap', label: t.global.forms.callbackTime.asap },
  { value: 'morning', label: t.global.forms.callbackTime.morning },
  { value: 'lunchtime', label: t.global.forms.callbackTime.lunchtime },
  { value: 'afternoon', label: t.global.forms.callbackTime.afternoon },
  { value: 'evening', label: t.global.forms.callbackTime.evening },
];

type InsurancePaymentType = 'ALREADY_PAID' | 'PAY_BY_MYSELF' | 'PAY_BY_FAMILY';

export const insurancePaymentStatus: RadioGroupOptions<InsurancePaymentType>[] = [
  { value: 'ALREADY_PAID', label: t.financial.wizard.insuranceType.status.ALREADY_PAID },
  { value: 'PAY_BY_MYSELF', label: t.financial.wizard.insuranceType.status.PAY_BY_MYSELF },
  { value: 'PAY_BY_FAMILY', label: t.financial.wizard.insuranceType.status.PAY_BY_FAMILY },
];

export const existingInsuranceTypes: SelectOption[] = [
  { value: 'Sterbegeldversicherung', label: 'Sterbegeldversicherung' },
  { value: 'Treuhandkonto', label: 'Treuhandkonto' },
  { value: 'Sterbekasse', label: 'Sterbekasse' },
  { value: 'Lebensversicherung', label: 'Lebensversicherung' },
  { value: 'Sonstiges', label: 'Sonstiges' },
];

export const funeralPlans: RadioGroupOptions<FuneralPlan>[] = [
  { value: 'basic', label: t.global.funeralPlans.basic },
  { value: 'high', label: t.global.funeralPlans.high },
];

export const seaFuneralPlans: RadioGroupOptions<FuneralPlan>[] = [
  { value: 'basic', label: t.global.funeralPlans.seaBasic },
  { value: 'high', label: t.global.funeralPlans.seaHigh },
];

interface ExtendedFuneralType {
  value: FuneralType;
  label: string;
  description: string;
  legend?: string;
  bg?: string;
}

export const funeralTypes: ExtendedFuneralType[] = [
  {
    value: 'burial',
    label: 'Erdbestattung',
    description: 'Beisetzung des Sarges in einem Erdgrab',
    legend: 'hohe klimaneutralität',
    bg: '#96C8BE',
  },
  {
    value: 'cremation',
    label: 'Feuerbestattung',
    description: 'Beisetzung der Urne in einem Grabplatz',
  },
  {
    value: 'sea',
    label: 'Seebestattung',
    description: 'Beisetzung der Urne im Meer oder Fluss',
    legend: 'günstigste variante',
    bg: '#96C8BE',
  },
  {
    value: 'tree',
    label: 'Baumbestattung',
    description: 'Beisetzung der Urne im Wurzelbereich eines Baumes',
  },
];
