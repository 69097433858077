import React from 'react';
import { Section } from 'components';
import { global as t } from 'data';
import { RadioGroupField } from 'forms/fields';
import { Field, Form, useFormikContext } from 'formik';
import { withLocationTrack } from 'hocs';
import { Card, Col, Grid, Row } from 'mymoria-ui/components';
import { useHistory } from 'mymoria-ui/hooks';
import { RadioGroupOptions } from 'mymoria-ui/components/Form/RadioGroup';
import { Account } from '../Steps';
import Cart from '../Cart';
import { PricingValues } from '../types';

const Payment = () => {
  const { handlePush } = useHistory();
  const {
    values: { paymentType },
  } = useFormikContext<PricingValues>();

  const paymentMethods: RadioGroupOptions[] = [
    {
      value: t.forms.payment.sepa,
      label: t.forms.payment.sepaLabel,
      suffix: <img style={{ width: 80, height: 40 }} src="/img/sepa.svg" alt="sepa" />,
    },
    {
      value: t.forms.payment.paypal,
      label: t.forms.payment.paypalLabel,
      suffix: <img style={{ width: 80, height: 40 }} src="/img/paypal.svg" alt="paypal" />,
    },
  ];

  return (
    <Section
      title={t.pricing.payment.sectionTitle}
      description={t.pricing.payment.sectionDescription}
      onGoBack={handlePush('/overview/pricing/address', { restoreScrollPosition: true })}
      goBackLabel={t.labels.back}
    >
      <Grid>
        <Row flexWrap="wrap" justifyContent="center">
          <Col width={[1, 1, 3 / 5]}>
            <Card variant="large" title={t.pricing.payment.formTitle}>
              <Form noValidate>
                <RadioGroupField name="paymentType" values={paymentMethods} layout="vertical" />
                {paymentType === t.forms.payment.paypal && (
                  <>
                    <Field name="paypalOrderID" type="hidden" />
                    <Field name="paypalSubscriptionID" type="hidden" />
                  </>
                )}
              </Form>
            </Card>
            {paymentType === 'sepa' && <Account />}
          </Col>
          <Col width={[1, 1, 2 / 5]}>
            <Cart />
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

export default withLocationTrack(t.pricing.payment.sectionTitle)(Payment);
