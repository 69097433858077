import { global as t } from 'data';
import { useDispatch, useSelector } from 'hooks';
import { Card, Col, Row } from 'mymoria-ui/components';
import { mq, styled } from 'mymoria-ui/utils';
import React, { FunctionComponent, useCallback } from 'react';
import { EditFunnel } from 'components';
import { updateProvision } from 'actions/provision';
import { Provision } from 'types';
import { shallowEqual } from 'react-redux';
import { pick } from 'lodash';
import { TextareaField } from 'forms/fields';
import { FunnelSection } from 'context/Funnel';

interface Props {
  section: FunnelSection;
}

const StyledCard = styled(Card)(
  mq({
    minHeight: ['auto', '450px', '450px', '480px'],
  }),
);

const WishesEdit: FunctionComponent<Props> = ({ section }) => {
  const dispatch = useDispatch();
  const { music, quote, personalNotification } = useSelector(
    ({ provision }) => pick(provision, ['music', 'quote', 'personalNotification']),
    shallowEqual,
  );
  const handleSubmit = useCallback(
    (values: Partial<Provision>) => dispatch(updateProvision(values)),
    [dispatch],
  );

  switch (section) {
    case 'WISHES_MUSIC':
      return (
        <EditFunnel
          headerTitle={t.wishes.sectionTitle}
          initialValues={{ music: music || '' }}
          onSubmit={handleSubmit}
          title={t.wishes.editMode.sectionTitle}
        >
          <StyledCard title={t.wishes.music.title}>
            <Row flexWrap="wrap">
              <Col width={1}>
                <TextareaField cols={60} rows={8} name="music" label={t.forms.note} />
              </Col>
            </Row>
          </StyledCard>
        </EditFunnel>
      );

    case 'WISHES_QUOTE':
      return (
        <EditFunnel
          headerTitle={t.wishes.sectionTitle}
          initialValues={{ quote: quote || '' }}
          onSubmit={handleSubmit}
          title={t.wishes.editMode.sectionTitle}
        >
          <Card title={t.wishes.quote.title}>
            <Row flexWrap="wrap">
              <Col width={1}>
                <TextareaField cols={60} rows={8} name="quote" label={t.forms.note} />
              </Col>
            </Row>
          </Card>
        </EditFunnel>
      );

    case 'WISHES_PERSONAL_NOTIFICATION':
      return (
        <EditFunnel
          headerTitle={t.wishes.sectionTitle}
          initialValues={{ personalNotification: personalNotification || '' }}
          onSubmit={handleSubmit}
          title={t.wishes.editMode.sectionTitle}
        >
          <Card
            title={t.wishes.personalNotification.title}
            hint={t.wishes.personalNotification.hint}
          >
            <Row flexWrap="wrap">
              <Col width={1}>
                <TextareaField
                  cols={60}
                  rows={8}
                  name="personalNotification"
                  label={t.forms.note}
                />
              </Col>
            </Row>
          </Card>
        </EditFunnel>
      );

    default:
      return null;
  }
};

export default WishesEdit;
