import { find, head, pick } from 'lodash';
import config from 'config';

const { api } = config;

export const apiConfig = {
  config: {
    api,
  },
};

const colors = {
  primary: '#153255',
  red: '#e02020',
  green: '#96C8BE',
  lightBlack: '#153255',
  lighterBlack: '#153255',
  black: '#153255',
  link: '#00b1af',
  primaryLight: '#dff5f3',
  primaryDark: '#4d837f',
  primaryHover: '#007e75',
  white: '#fff',
  light: '#c9c9c9',
  lightDark: '#c0c0c0',
  darkLight: '#606060',
  dark: '#353535',
  success: '#28a745',
  warning: '#d68227',
  error: '#ff3823',
};

export const defaultTheme = {
  colors,
  apiConfig,
  components: {
    headings: {
      h1: {
        color: colors.lightBlack,
      },
      h2: {
        color: colors.lightBlack,
      },
      h3: {
        color: colors.lightBlack,
      },
      h4: {
        color: colors.lightBlack,
      },
      h5: {
        color: colors.lightBlack,
      },
      h6: {
        color: colors.lightBlack,
      },
    },
  },
  disabled: {
    button: {
      color: colors.darkLight,
      backgroundColor: colors.light,
    },
  },
  primary: {
    button: {
      color: colors.white,
      backgroundColor: colors.primary,
    },
  },
  secondary: {
    button: {
      color: colors.white,
      backgroundColor: colors.lightDark,
    },
  },
  warning: {
    button: {
      color: colors.white,
      backgroundColor: colors.warning,
    },
  },
};

const themeVariants = ['primary', 'secondary', 'warning'];
export const getThemeVariant = props =>
  find(themeVariants, name => pick(props, themeVariants)[name]) || head(themeVariants);
