import { api } from 'utils';
import { Letter } from 'mymoria-types';

const fetch = (provisionId: string) =>
  api
    .get<Letter[]>('/letters', { params: { provisionId } })
    .then(({ data }) => data);

const create = (provisionId: string) =>
  api
    .post<Letter>('/letters', { provisionId })
    .then(({ data }) => data);

// todo: fix any
const update = (letterId: string, data: any) =>
  api.post<Letter>(`/letters/${letterId}`, data).then(({ data }) => data);

export default {
  fetch,
  create,
  update,
};
