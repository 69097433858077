import { Dispatch } from 'redux';
import { createAction } from 'typesafe-actions';
import { ProvisionInsurance } from 'mymoria-types';
import { InsuranceTariff } from 'types';
import { api } from 'utils';

const fetchTariffAction = createAction('GRAVES_TARIFF')<InsuranceTariff>();

export default {
  fetch: fetchTariffAction,
};

export const generateDocument = (payload: Partial<ProvisionInsurance>) => () =>
  api
    .post(`/provisions/insurances/document`, payload)
    .then(response => response.data)
    .then(response => window.open(response.url));

export const fetchTariff = (payload: Partial<ProvisionInsurance>) => (dispatch: Dispatch) =>
  api
    .post(`/provisions/insurances/tariff`, payload)
    .then(response => response.data)
    .then(response => dispatch(fetchTariffAction(response)));
