import React, { FunctionComponent, useState } from 'react';
import * as t from 'data';
import { Markdown, Text, WizardCard, SelectButton, Hint, Loader } from 'mymoria-ui/components';
import { WizardComponentProps } from 'react-albus';
import { useWundWState, WundWPaymentType, WundWTariffResponse } from './WundWState';
import { styled } from 'mymoria-ui';
import { useEffect } from 'react';
import { api } from 'utils';
import moment from 'moment';
import config from 'config';

const { dateFormat } = config;

const {
  global: { labels },
  financial: { wundw },
} = t;

const StyledSelectButton = styled(SelectButton)`
  margin: 1rem 0;
  display: flex;
  align-items: center;
`;

const StyledHint = styled(Hint)`
  &:parent {
    width: 40px;
    height: 40px;
    background: red;
  }
  margin: 0;
`;

const StyledIcon = styled.img`
  display: block;
  width: 40px;
  height: 40px;
  margin: auto 1rem auto 0;
`;

const PaymentTypeStep: FunctionComponent<WizardComponentProps> = ({
  wizard: { next, push, step, steps },
}) => {
  const [
    { paymentType, totalFuturePrice, birthDate, startDate, tariff },
    setState,
  ] = useWundWState();
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    api
      .get<WundWTariffResponse>('provisions/tariff', {
        params: {
          birthdate: moment(birthDate).format(dateFormat.view),
          insuranceSum: totalFuturePrice,
          startDate: moment(startDate).format(dateFormat.view),
        },
      })
      .then(({ data }) => {
        setLoading(false);
        if (!data.oneTime) {
          setErrorMessage(wundw.paymentType.errors.customerTooOld);
        }
        setState({ tariff: data });
      })
      .catch(({ response: { data } }) => {
        setLoading(false);
        setErrorMessage(wundw.paymentType.errors[data.message]);
      });
  }, [birthDate, totalFuturePrice, startDate, setState]);

  const handleSubmit = (paymentType: WundWPaymentType) => {
    setState({ paymentType });
    if (paymentType === 'consulting') {
      push('consulting');
    } else {
      next();
    }
  };

  const oneTimeValidation = !tariff?.oneTime;
  const monthlyValidation = !tariff?.monthly;

  return (
    <WizardCard
      title={wundw.paymentType.title}
      onClickPrev={() => push('intro')}
      onClickNext={next}
      labelPrev={labels.back}
      labelNext={labels.continue}
      disabledNext={!paymentType}
      steps={steps.length - 1}
      step={steps.indexOf(step)}
    >
      {loading ? (
        <Loader delay={500} />
      ) : (
        <>
          <Markdown>{wundw.paymentType.description}</Markdown>
          <StyledSelectButton
            selected={paymentType === 'oneTime'}
            onClick={() => handleSubmit('oneTime')}
            disabled={oneTimeValidation}
          >
            <StyledIcon src="/img/partners/wundw/onetime.png" alt="onetime-icon" />
            <Text fontSize={2} margin={0}>
              {wundw.paymentType.oneTime}
            </Text>
            {oneTimeValidation && <StyledHint>{errorMessage}</StyledHint>}
          </StyledSelectButton>
          <StyledSelectButton
            selected={paymentType === 'monthly'}
            onClick={() => handleSubmit('monthly')}
            disabled={monthlyValidation}
          >
            <StyledIcon src="/img/partners/wundw/monthly.png" alt="monthly-icon" />
            <Text fontSize={2} margin={0}>
              {wundw.paymentType.monthly}
            </Text>
            {monthlyValidation && <StyledHint>{errorMessage}</StyledHint>}
          </StyledSelectButton>
          <StyledSelectButton
            selected={paymentType === 'consulting'}
            onClick={() => handleSubmit('consulting')}
          >
            <StyledIcon src="/img/partners/wundw/consulting.png" alt="treuhand-icon" />
            <Text fontSize={2} margin={0}>
              {wundw.paymentType.consulting}
            </Text>
          </StyledSelectButton>
        </>
      )}
    </WizardCard>
  );
};

export default PaymentTypeStep;
