import { Page, Section } from 'components';
import { Button, Col, Row, Grid } from 'mymoria-ui/components';
import { global as t } from 'data';
import { Form, Formik, FormikHelpers, FormikValues } from 'formik';
import { useFunnel } from 'hooks';
import { css, mq, styled } from 'mymoria-ui';
import { transparentize } from 'polished';
import React, { FunctionComponent, useEffect } from 'react';

interface Props {
  cancelLabel?: string;
  initialValues: object;
  onSubmit: (v: any) => Promise<any>;
  submitLabel?: string;
  title: string;
  validationSchema?: any | (() => any);
  headerTitle?: string;
}

/* todo: move common styled components to separate file */
const Footer = styled.div(({ theme: { colors } }) =>
  mq({
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    height: ['56px', '100px'],
    display: 'flex',
    justifyContent: ['space-evenly', 'flex-end'],
    alignItems: 'center',
    padding: [0, '0 4.5rem'],
    background: colors.primary,
  }),
);

const StyledButton = styled(Button)(
  ({ theme: { colors, font } }) => css`
    margin: 0 0.5rem;
    padding-right: 1rem;
    background: transparent;
    color: ${colors.white};
    border-color: ${colors.white};
    text-transform: uppercase;
    font-weight: ${font.weight.semiBold};
    font-size: ${font.size.normal};
    letter-spacing: 0.1rem;
    &[disabled] {
      opacity: 0.4;
      color: ${colors.white};
      border: 1px solid ${colors.white};

      ${mq({
        borderWidth: [0, 1],
      })};
    }

    &:not([disabled]) {
      &:hover {
        color: ${transparentize(0.1, colors.white)} !important;
        border-color: ${transparentize(0.1, colors.white)} !important;
        background: ${transparentize(0.9, colors.black)} !important;
      }
    }

    ${mq({
      padding: [0, '0 1rem'],
      borderWidth: [0, 1],
    })};
  `,
);

const GhostButton = styled(StyledButton)`
  &:not([disabled]) {
    &:hover {
      padding: 1rem;
    }
  }
`;

const VerticalSep = styled.div`
  width: 2px;
  height: 20px;
  background: #fff;

  ${mq({
    display: ['block', 'none'],
  })};
`;

const ContentCol = styled(Col)`
  margin-bottom: 7rem;
`;

const EditFunnel: FunctionComponent<Props> = ({
  cancelLabel = t.labels.cancel,
  children,
  initialValues,
  onSubmit,
  submitLabel = t.labels.submit,
  title,
  validationSchema,
  headerTitle,
}) => {
  const { closeFunnel } = useFunnel();

  useEffect(() => closeFunnel, [closeFunnel]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (values: FormikValues, helpers: FormikHelpers<any>) =>
    onSubmit(values).then(() => {
      helpers.setSubmitting(false);
      closeFunnel();
    });

  return (
    <Page withNavigation={false} footer="none" headerTitle={headerTitle}>
      <Formik
        initialValues={initialValues}
        enableReinitialize={false}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, dirty }) => (
          <Form noValidate>
            <Section title={title} onGoBack={closeFunnel} goBackLabel={t.overview.name}>
              <Grid>
                <Row justifyContent="center">
                  <ContentCol width={[1, 10 / 12, 2 / 3]}>{children}</ContentCol>
                </Row>
              </Grid>
            </Section>
            <Footer>
              <GhostButton type="button" ghost disabled={isSubmitting} onClick={closeFunnel}>
                {cancelLabel}
              </GhostButton>
              <VerticalSep />
              <StyledButton type="submit" outline disabled={isSubmitting || !dirty}>
                {submitLabel}
              </StyledButton>
            </Footer>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default EditFunnel;
