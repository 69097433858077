import { trackPaymentProcess } from 'actions/tracking';
import { Section } from 'components';
import { CheckMark } from 'components/Icons';
import { global as t } from 'data';
import { withLocationTrack } from 'hocs';
import { useDispatch, useSelector } from 'hooks';
import { mq, styled } from 'mymoria-ui';
import { Button, Card, Col, Heading, Row, Text, Grid } from 'mymoria-ui/components';
import { usePush } from 'mymoria-ui/hooks';
import React, { useEffect } from 'react';

const StyledCheckMark = styled(CheckMark)`
  padding-bottom: 3rem;
  margin: 0 auto;
`;

const StyledHeading = styled(Heading.Heading2)`
  margin-bottom: 2rem;
`;

const StyledDescription = styled(Text)`
  margin-bottom: 3rem;
`;

const StyledButton = styled(Button)(
  mq({
    minWidth: ['auto', 'auto', '300px'],
  }),
);

const Confirmation = () => {
  const { handlePush } = usePush();
  const dispatch = useDispatch();
  const username = useSelector(({ provision }) => provision.userEmail);

  useEffect(() => {
    dispatch(trackPaymentProcess(t.tracking.packagePaymentConfirmed, username));
  }, [dispatch, username]);

  return (
    <Section>
      <Grid>
        <Row justifyContent="center">
          <Col width={[1, 1, 2 / 3]}>
            <Card variant="large">
              <StyledCheckMark />
              <StyledHeading>{t.pricing.confirmation.sectionTitle}</StyledHeading>
              <StyledDescription>{t.pricing.confirmation.sectionDescription}</StyledDescription>
              <StyledButton type="button" onClick={handlePush('/overview')}>
                {t.pricing.confirmation.label}
              </StyledButton>
            </Card>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

export default withLocationTrack(t.pricing.confirmation.sectionTitle)(Confirmation);
