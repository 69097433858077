import { FuneralPlan, FuneralType, Product, ProductCategory, Customizations } from 'mymoria-types';
import { includes, map, partition, reject, toString, concat, sortBy } from 'lodash';
import { api, customize, getAxiosData } from 'utils';
import config from 'config';

const { mandatoryProductCategories, productsOrder } = config;

interface DefaultProductsAPI {
  products: Product[];
  productsDeselected: Product[];
}

const sort = (products: Product[]) => sortBy(products, ({ category }) => productsOrder[category]);

const split = (products: Product[]) =>
  partition<Product>(products, ({ category }) => includes(mandatoryProductCategories, category));

const parse = (selectedProducts: Product[], allProducts: Product[]) => {
  const [selectedBasicProducts, selectedOptionalProducts] = split(selectedProducts);
  const [allBasicProducts, allOptionalProducts] = split(allProducts);

  return {
    selected: {
      basicProducts: selectedBasicProducts,
      optionalProducts: selectedOptionalProducts,
    },
    all: {
      basic: allBasicProducts,
      optional: sort(allOptionalProducts),
      defaults: {
        basic: allBasicProducts,
        optional: sort(allOptionalProducts),
      },
    },
  };
};

const fetchDefault = (code: number, plan: FuneralPlan, type: FuneralType) =>
  api
    .get<DefaultProductsAPI>('/products/default', { params: { code, plan, type } })
    .then(({ data: { products, productsDeselected } }) =>
      parse(products, [...products, ...productsDeselected]),
    );

const fetchSelected = (
  code: number,
  plan: FuneralPlan,
  type: FuneralType,
  ids: string[],
  customizations?: Customizations,
) =>
  Promise.all([
    ids.length > 0
      ? api
          .get<Product[]>('/products', { params: { ids: toString(ids) } })
          .then(getAxiosData)
      : [],
    api
      .get<DefaultProductsAPI>('/products/default', { params: { code, plan, type } })
      .then(getAxiosData),
  ]).then(([selectedProducts, defaultProducts]) => {
    const [selectedBasicProducts, selectedOptionalProducts] = split(
      customize(selectedProducts, customizations?.products),
    );

    const [defaultsBasic, defaultsOptional] = split(
      customize(
        concat(defaultProducts.products, defaultProducts.productsDeselected),
        customizations?.products,
      ),
    );

    // reject suggested product if user already selected one for given category
    const productsDeselected = reject(defaultsOptional, ({ category }) =>
      includes(map(selectedOptionalProducts, 'category'), category),
    );

    return {
      selected: {
        basicProducts: selectedBasicProducts,
        optionalProducts: selectedOptionalProducts,
      },
      all: {
        basic: selectedBasicProducts,
        optional: sort(concat(productsDeselected, selectedOptionalProducts)),
        defaults: {
          basic: defaultsBasic,
          optional: sort(defaultsOptional),
        },
      },
    };
  });

const fetch = (id: string) =>
  api
    .get<Product>(`/products/${id}`, { params: { withLocale: true } })
    .then(({ data }) => data);

interface ProductParams {
  category: ProductCategory;
  type?: FuneralType;
  code?: number;
}

const fetchAll = (params: ProductParams) =>
  api
    .get<Product[]>('/products/find', { params })
    .then(({ data }) => data);

export default {
  split,
  parse,
  fetchDefault,
  fetchSelected,
  fetch,
  fetchAll,
};
