import React, { FunctionComponent } from 'react';
import { css, styled } from 'mymoria-ui';
import { transparentize } from 'polished';
import { Row } from 'mymoria-ui/components';

interface ColorPickerProps {
  colors: string[];
  color: string;
  onChange: (color: string) => void;
}

const Color = styled.div<{ selected: boolean; color: string }>(
  ({ selected, color, theme: { colors } }) => css`
    background-color: ${color};
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid ${selected ? colors.primary : transparentize(0.5, colors.white)};
    cursor: ${selected ? 'auto' : 'pointer'};
    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
      display: ${selected ? 'block' : 'none'};
    }
  `,
);

const Mark = styled.div(
  ({ theme: { colors } }) => css`
    width: 20px;
    height: 8px;
    border-left: 3px solid ${colors.primary};
    border-bottom: 3px solid ${colors.primary};
    transform: rotate(-45deg);
    margin-top: -5px;
  `,
);

const ColorPicker: FunctionComponent<ColorPickerProps> = ({
  color: selected,
  colors,
  onChange,
}) => (
  <Row justifyContent="space-between">
    {colors.map(color => (
      <Color
        key={color}
        color={color}
        selected={color === selected}
        onClick={() => onChange(color)}
      >
        <Mark />
      </Color>
    ))}
  </Row>
);

export default ColorPicker;
