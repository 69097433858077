import React, { FunctionComponent } from 'react';
import { css, styled } from 'mymoria-ui';
import { darken, transparentize } from 'polished';
import Button, { ButtonProps } from 'mymoria-ui/components/Button/Button';

interface FunnelButtonProps extends ButtonProps {
  selected?: boolean;
}

const StyledButton = styled(Button)<FunnelButtonProps>(
  ({ theme: { colors, font }, selected = false }) => css`
    width: 100%;
    height: 56px;
    color: ${colors.primary};
    font-weight: ${selected ? font.weight.semiBold : font.weight.normal};
    background-color: ${selected ? transparentize(0.85, colors.primary) : colors.white};
    border: ${selected ? 'none' : '2px solid'};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: none;

    p {
      font-weight: ${selected ? font.weight.semiBold : font.weight.normal};
    }

    &&:hover {
      color: ${colors.white};
      font-weight: 500;
      background-color: ${darken(0.05, colors.primary)};
    }
  `,
);

const FunnelButton: FunctionComponent<FunnelButtonProps> = ({
  children,
  selected = false,
  ...props
}) => (
  <StyledButton outline selected={selected} {...props}>
    {children}
  </StyledButton>
);

export default FunnelButton;
