import { FunctionComponent } from 'react';
import { Markdown, Message, MessageProps } from 'mymoria-ui/components';
import React from 'react';

interface AlertMessageProps {
  message: string;
  status?: MessageProps['status'];
}

const AlertMessage: FunctionComponent<AlertMessageProps> = ({ message, status }) => (
  <Message status={status}>
    <Markdown>{message}</Markdown>
  </Message>
);

export default AlertMessage;
