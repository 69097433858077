import axios, { AxiosError } from 'axios';
import config from 'config';
import { global as t } from 'data';
import { identity } from 'lodash';

const api = axios.create({
  baseURL: config.api.endpoint,
});

api.interceptors.request.use(request => {
  request.headers = {
    ...request.headers,
    'Accept-Language': 'de',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  };
  return request;
});

api.interceptors.response.use(identity, (error: AxiosError) => {
  let specialMessage;
  if (error.response?.data.message) {
    const arr = error.response?.data.message.split(' ');
    if (t.apiErrors[arr[0]]) {
      specialMessage = `${t.apiErrors[arr[0]]} (${arr.slice(1).join(' ')})`;
    }
  }
  error.response && error.response.status
    ? (error.message = specialMessage || t.apiErrors[error.response.status])
    : (error.message = t.apiErrors.general);
  return Promise.reject(error);
});

export default api;
