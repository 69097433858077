import { mq, styled } from 'mymoria-ui';
import { Button, Col, Heading, Markdown, Row, StatusTag } from 'mymoria-ui/components';
import React, { FunctionComponent } from 'react';
import { StatusTagProps } from 'mymoria-ui/components/StatusTag';

interface Props extends StatusTagProps {
  /**
   * Tile title.
   */
  title?: string;

  /**
   * Tile Description.
   */
  description?: string;

  /**
   * Status Text.
   */
  statusText?: string;

  /**
   * Button Label.
   */
  label: string;

  /**
   * Submit handler.
   */
  onSubmit: () => void;
}

const StyledRow = styled(Row)`
  z-index: 8;
`;

const StyledCol = styled(Col)(
  mq({
    margin: ['0 0 0.5rem', '0 0 2.5rem'],
  }),
);

const DescriptionCol = styled(Col)`
  max-height: 500px;
  overflow: hidden;

  ${mq({
    margin: ['0 0 0.5rem', '0 0 2.5rem'],
  })};
`;

const InfoModal: FunctionComponent<Props> = ({
  description,
  title,
  label,
  status,
  onSubmit,
  statusText,
}) => (
  <StyledRow flexDirection="column">
    <StyledCol width={9 / 10}>
      <StatusTag className="tile_name" status={status}>
        {statusText}
      </StatusTag>
    </StyledCol>
    <StyledCol width={1}>
      <Heading.Heading2 className="modal_title">{title}</Heading.Heading2>
    </StyledCol>
    <DescriptionCol width={1}>
      <Markdown className="modal_description" source={description} />
    </DescriptionCol>
    {status !== 'comingSoon' && (
      <Col width={1}>
        <Button onClick={onSubmit} className="create_btn" variant="primary">
          {label}
        </Button>
      </Col>
    )}
  </StyledRow>
);

export default InfoModal;
