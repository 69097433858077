import Auth from '@aws-amplify/auth';
import Amplify from '@aws-amplify/core';
import { CognitoUser, ISignUpResult } from 'amazon-cognito-identity-js';
import { AxiosError, AxiosResponse } from 'axios';
import config from 'config';
import { intersection, isEmpty } from 'lodash';
import React, { createContext, FunctionComponent, useEffect, useState } from 'react';
import { api } from 'utils';
import { global } from 'data';
import { removeProvisionAction } from 'actions/provision';
import { useDispatch } from 'hooks';

const { cognitoRolesProperty, privilegedRoles, awsCognito } = config;

Amplify.configure(awsCognito);

interface SignInPayload {
  username: string;
  password: string;
  skipPostAuthentication?: boolean;
}

interface SignUpPayload extends SignInPayload {
  name: string;
}

interface AuthData {
  signIn: (data: SignInPayload) => Promise<CognitoUser | any>;
  signUp: (data: SignUpPayload) => Promise<ISignUpResult>;
  signOut: () => Promise<any>;
  changePassword: (
    user: CognitoUser | any,
    oldPassword: string,
    newPassword: string,
  ) => Promise<'SUCCESS'>;
  forgotPassword: (username: string) => Promise<any>;
  forgotPasswordSubmit: (username: string, code: string, newPassword: string) => Promise<void>;
  sendConfirmationEmail: (username: string, encrypted?: boolean) => Promise<{ username: string }>;
  checkUser: (username: string) => Promise<AxiosResponse>;
  isAdmin: boolean;
  user: CognitoUser | any;
  isAuthenticated: boolean;
  isAuthenticatePending: boolean;
  introModal: boolean;
}

export const AuthContext = createContext<AuthData>({
  user: null,
  isAuthenticatePending: false,
} as AuthData);

export const AuthProvider: FunctionComponent = ({ children }) => {
  const [user, setUser] = useState<CognitoUser | any>(null);
  const [showIntro, setShowIntro] = useState(false);
  const [isAuthenticatePending, setAuthenticatePending] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const dispatch = useDispatch();

  const mapCognitoErrorMessage = (err: AxiosError) =>
    err.code ? new Error(global.apiErrors[err.code] || global.apiErrors.general) : err;

  const signIn = ({ username, password, skipPostAuthentication = false }: SignInPayload) =>
    Auth.signIn(username.toLowerCase(), password)
      .then(user => {
        localStorage.setItem('accessToken', user.getSignInUserSession().getIdToken().getJwtToken());

        if (skipPostAuthentication) {
          setUser(user);
          setShowIntro(true);
          return user;
        }

        return api
          .post('/provisions/postAuthentication', { username: user.username })
          .then(() => {
            setUser(user);
            setShowIntro(true);
            return user;
          })
          .catch(err => {
            signOut();
            throw err;
          });
      })
      .catch(err => {
        throw mapCognitoErrorMessage(err);
      });

  const signUp = ({ username, password, name }: SignUpPayload) =>
    Auth.signUp({
      password,
      username: username.toLowerCase(),
      attributes: { email: username.toLowerCase(), name },
    });

  const signOut = () =>
    Auth.signOut()
      .then(() => {
        setUser(null);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('provisionId');
        sessionStorage.removeItem('form-step-pricing');
        sessionStorage.removeItem('form-values-pricing');
      })
      .then(() => {
        dispatch(removeProvisionAction());
      })
      .catch(err => {
        throw mapCognitoErrorMessage(err);
      });

  const changePassword = (user: CognitoUser | any, oldPassword: string, newPassword: string) =>
    Auth.changePassword(user, oldPassword, newPassword);

  const forgotPassword = (username: string) => Auth.forgotPassword(username.toLowerCase());

  const forgotPasswordSubmit = (username: string, code: string, newPassword: string) =>
    Auth.forgotPasswordSubmit(username.toLowerCase(), code, newPassword);

  const sendConfirmationEmail = (username: string, encrypted?: boolean) =>
    api
      .post<{ username: string }>('/provisions/users/sendConfirmationEmail', {
        username: username.toLowerCase(),
        encrypted,
      })
      .then(({ data: { username } }) => ({
        username,
      }));

  const checkUser = (username: string) =>
    api.post('/provisions/usersCheck', { username: username ? username.toLowerCase() : '' });

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(user => {
        localStorage.setItem('accessToken', user.getSignInUserSession().getIdToken().getJwtToken());
        setUser(user);
      })
      .catch(() => setUser(null))
      .finally(() => setAuthenticatePending(false));
  }, []);

  useEffect(() => {
    if (!user) {
      setIsAdmin(false);
      return;
    }
    const accessToken = user.getSignInUserSession().getIdToken().decodePayload();

    setIsAdmin(!isEmpty(intersection(accessToken[cognitoRolesProperty], privilegedRoles)));
  }, [user, dispatch]);

  const contextValue: AuthData = {
    signIn,
    signUp,
    signOut,
    changePassword,
    forgotPassword,
    forgotPasswordSubmit,
    sendConfirmationEmail,
    checkUser,
    isAdmin,
    introModal: showIntro,
    user,
    isAuthenticated: !!user,
    isAuthenticatePending,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
