import React, { FunctionComponent } from 'react';
import { styled, mq, css } from 'mymoria-ui';
import { Icon } from 'mymoria-ui/components';
import { letter as t } from 'data';
import ToolBarContainer from './ToolBarContainer';

interface ToolBarNavigationProps {
  initialZoom?: number;
  initialPage?: number;
  onZoomChange?: () => void;
  onPageChange?: () => void;
  onToolBarChange: () => void;
}

const StyledToolBarContainer = styled(ToolBarContainer)(
  ({ theme: { colors } }) => css`
    align-items: center;
    min-width: 170px;
    cursor: pointer;

    ${mq({
      minWidth: ['auto', '170px'],
    })};

    svg {
      path {
        fill: ${colors.white};
      }
    }

    span {
      color: ${colors.white};
      font-weight: bold;
      text-transform: uppercase;

      ${mq({
        display: ['none', 'block'],
      })};
    }
  `,
);

const ToolBarNavigation: FunctionComponent<ToolBarNavigationProps> = ({ onToolBarChange }) => (
  <StyledToolBarContainer onClick={onToolBarChange}>
    <Icon.Edit />
    <span>{t.editor.edit}</span>
  </StyledToolBarContainer>
);

export default ToolBarNavigation;
