import React, { ElementType } from 'react';
import { cdn, css, styled } from 'mymoria-ui';
import { Button, Heading, Loader, Text } from 'mymoria-ui/components';
import { useCMS } from 'mymoria-ui/hooks';
import { LoginPromo } from 'mymoria-types';
import { first } from 'lodash';

interface ButtonProps {
  text?: string;
  url?: string;
}

const ShopButton = ({ text, url }: ButtonProps) => (
  <Button onClick={() => window.open(url)}>{text}</Button>
);

interface HeaderProps {
  content: string;
}

const Header = ({ content }: HeaderProps) => <Text>{content}</Text>;

interface MappingProps {
  [key: string]: ElementType;
}

const componentsMapping: MappingProps = {
  markdown: Header,
  ctabutton: ShopButton,
};

const PromoSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: white;
  text-align: center;
  height: 100%;
  box-sizing: border-box;
  padding: 2rem 0;
  p {
    margin: 1.125rem 0;
    width: 80%;
    text-align: center;
  }

  button {
    width: 13.5rem;
  }
`;

const PromoImage = styled.img`
  width: 350px;
  height: auto;
`;

interface HeadlineProps {
  HeadlineColor: string;
}

const StyledHeadline = styled(Heading.Heading2)<HeadlineProps>(
  ({ HeadlineColor }) => css`
    color: ${HeadlineColor};
  `,
);

interface CircleProps {
  price: string;
}

const PriceCircle = styled.div<CircleProps>(
  ({ theme: { colors }, price }) => css`
    display: ${price ? 'flex' : 'none'};
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    border-radius: 50%;
    position: absolute;
    bottom: 9rem;
    right: -2.5rem;
    background: ${colors.primary};

    h2 {
      color: ${colors.white};
      line-height: 0;
    }
  `,
);

const AssetWrapper = styled.div`
  position: relative;
  padding: 0;
`;

const SigninPromoSection = () => {
  const [{ data, loading }] = useCMS<LoginPromo[]>(
    '/PortalLoginPromo?filter[_id]=5dc40f952a077b7a3303eb72&simple=1&filter[Status]=live',
  );

  const { Headline, HeadlineColor = 'black', Price, Content = [], Asset: { path = '' } = {} } =
    first(data) || ({} as LoginPromo);

  return (
    <PromoSection>
      {loading ? (
        <Loader />
      ) : (
        <>
          <AssetWrapper>
            <PromoImage src={cdn('cms', path)} alt={Headline} />
            <PriceCircle price={Price}>
              <Heading.Heading2>{Price}</Heading.Heading2>
            </PriceCircle>
          </AssetWrapper>
          <StyledHeadline HeadlineColor={HeadlineColor} paddingTop="12px">
            {Headline}
          </StyledHeadline>
          {Content.map(({ settings, component }, index) => {
            const Component = componentsMapping[component.toLowerCase()];
            return <Component key={index} {...settings} />;
          })}
        </>
      )}
    </PromoSection>
  );
};

export default SigninPromoSection;
