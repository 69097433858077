import { trackUserInteraction } from 'actions/tracking';
import { Page, ResendConfirmationEmail } from 'components';
import { IntroModal } from 'components/Modals';
import { StyledCard } from 'containers/Auth/ActivateAccount';
import { global as t, global } from 'data';
import { isEmpty } from 'lodash';
import { useAuth, useDispatch, useSelector } from 'hooks';
import { tilesDetails } from 'helpers';
import { Loader } from 'mymoria-ui/components';
import { usePush } from 'mymoria-ui/hooks';
import React, { FunctionComponent, ReactNode, useCallback, useEffect, useState } from 'react';
import { getProvision } from 'reducers/provision';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router-dom';
import { PortalSection } from 'types';
import { sprintf } from 'utils';

interface PrivateRouteProps extends RouteProps {
  component?: Exclude<RouteProps['component'], undefined>;
  render?: (props: RouteComponentProps<any>) => ReactNode;
  tiles?: PortalSection[];
}

const PrivateRoute: FunctionComponent<PrivateRouteProps> = ({
  tiles,
  component: Component,
  render,
  ...routeProps
}) => {
  const { isAuthenticated, isAuthenticatePending, isAdmin, introModal } = useAuth();
  const [tilesDetailsList, setTilesDetailsList] = useState([] as PortalSection[]);
  const { push } = usePush();
  const dispatch = useDispatch();

  const provision = useSelector(getProvision);
  const isActivated = !!provision.paymentAcceptedAt;

  useEffect(() => {
    setTilesDetailsList(tilesDetails(tiles, provision));
  }, [tiles, provision]);

  const [showIntroModal, setShowIntroModal] = useState(introModal);

  const handleIntro = useCallback(() => {
    dispatch(trackUserInteraction('Welcome Intro', provision.lastname));
    setShowIntroModal(false);
  }, [dispatch, provision.lastname]);

  const handleDocumentIntro = useCallback(() => {
    dispatch(trackUserInteraction('Document Intro', provision.lastname));
    setShowIntroModal(false);
    push('/profile');
  }, [dispatch, push, provision.lastname]);

  const handleTilesIntro = (tile: string, link?: string) => {
    dispatch(trackUserInteraction('Tiles Intro', tile));

    if (link) push(`overview${link}`);
    setShowIntroModal(false);
  };

  const renderTilesIntro = () => {
    for (let tile in tilesDetailsList) {
      if (!tilesDetailsList[tile].Completed) {
        return (
          <IntroModal
            key={tilesDetailsList[tile].Tile}
            title={tilesDetailsList[tile].IntroHeadline}
            description={tilesDetailsList[tile].IntroDescription}
            label={tilesDetailsList[tile].IntroButton}
            ghostLabel={global.tileIntro.ghostLabel}
            isTile
            onSubmit={() =>
              handleTilesIntro(tilesDetailsList[tile].Tile, tilesDetailsList[tile].ButtonLink)
            }
            ghostButton={() => handleTilesIntro(tilesDetailsList[tile].Tile)}
          />
        );
      }
    }
  };

  return (
    <Route
      {...routeProps}
      render={(props: any) => {
        if (isAuthenticatePending) {
          return <Loader />;
        }

        if (isAuthenticated) {
          if (showIntroModal && provision.loginCounter === 1) {
            return (
              <Page footer="none">
                <StyledCard
                  justifyContent={['unset']}
                  padding={['3.5rem 2rem', '8.5rem 5rem', '8.5rem 7rem', '9.5rem 15rem']}
                  height={['auto']}
                >
                  <IntroModal
                    title={sprintf(global.introModal.title, {
                      salutation: provision.salutation === 'sir' ? t.male : t.female,
                      lastname: provision.lastname,
                    })}
                    description={global.introModal.description}
                    label={global.introModal.buttonLabel}
                    onSubmit={handleIntro}
                    ghostButton={handleIntro}
                  />
                </StyledCard>
              </Page>
            );
          }

          if (provision.status === 'NOT_CONFIRMED' && !isAdmin) {
            return (
              <Page footer="none">
                {' '}
                <StyledCard>
                  <ResendConfirmationEmail
                    message={global.activateAccount.secondLoginActivation}
                    username={provision.userEmail}
                  />
                </StyledCard>
              </Page>
            );
          }

          if (isActivated && showIntroModal && !provision.requestedDocumentsAt) {
            return (
              <Page footer="none">
                <StyledCard
                  justifyContent={['unset']}
                  padding={['3.5rem 2rem', '8.5rem 5rem', '8.5rem 7rem', '9.5rem 15rem']}
                  height={['auto']}
                >
                  <IntroModal
                    title={global.documentIntro.title}
                    description={global.documentIntro.description}
                    label={global.documentIntro.buttonLabel}
                    onSubmit={handleDocumentIntro}
                    ghostLabel={global.documentIntro.ghostLabel}
                    ghostButton={handleDocumentIntro}
                  />
                </StyledCard>
              </Page>
            );
          }

          if (showIntroModal && !isEmpty(tilesDetailsList) && provision.loginCounter > 1) {
            return (
              <Page footer="none">
                <StyledCard
                  justifyContent={['unset']}
                  padding={['6.5rem 2rem', '8.5rem 5rem', '8.5rem 7rem', '9.5rem 15rem']}
                  height={['auto']}
                >
                  {renderTilesIntro()}
                </StyledCard>
              </Page>
            );
          }
          if (Component) {
            return <Component {...props} />;
          } else if (render) {
            return render(props);
          } else {
            console.error('PrivateRoute requires component or render prop');
            return null;
          }
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: routeProps.location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
