import { css, mq, styled } from 'mymoria-ui';
import { Button, Col, Heading, Markdown, Row, Text } from 'mymoria-ui/components';
import React, { FunctionComponent, useEffect } from 'react';
import { global as t } from 'data';
import Confetti from 'components/Confetti';
import { useDispatch } from 'react-redux';
import { trackActivationSuccess } from 'actions/tracking';

interface Props {
  /**
   * Tile title.
   */
  title?: string;

  /**
   * Tile Description.
   */
  description?: string;

  /**
   * modal headline.
   */
  headline?: string;

  /**
   * Button Label.
   */
  label: string;

  /**
   * Ghost Button Label.
   */
  ghostLabel: string;

  /**
   * Submit handler.
   */
  onSubmit: () => void;
  /**
   * Close modal handler.
   */
  onClose?: () => void;
}

const StyledRow = styled(Row)`
  z-index: 8;
  position: relative;
  align-items: left;

  ${mq({
    alignItems: ['center', 'center', 'unset'],
  })};
`;

const StyledCol = styled(Col)(
  mq({
    margin: ['0 0 0.5rem', '0 0 0.5rem', '0 0 1rem'],
  }),
);

const DescriptionCol = styled(Col)`
  max-height: 500px;
  overflow: hidden;

  ${mq({
    margin: ['0 0 0.5rem', '0 0 1.5rem', '0 0 2.5rem'],
  })};
`;
const StyledButton = styled(Button)(
  mq({
    margin: ['1rem 0.5rem 1rem 0', '1rem 0.5rem 0 0', '1rem 1rem 0 0'],
  }),
);

const ButtonsCol = styled(Col)`
  display: flex;

  button {
    ${mq({
      width: ['100%', 'auto'],
    })};
  }

  ${mq({
    flexWrap: ['wrap', 'wrap', 'nowrap'],
    flexDirection: ['column', 'column', 'row'],
    justifyContent: ['center', 'center', 'left'],
  })};
`;

const StyledText = styled(Text)(
  ({ theme: { font } }) => css`
    font-weight: ${font.weight.bold};
  `,
);

const ActiveAccountModal: FunctionComponent<Props> = ({
  description,
  headline,
  title,
  label,
  ghostLabel,
  onClose,
  onSubmit,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(trackActivationSuccess(t.tracking.activation.eAct, t.tracking.activation.eLab));
  }, [dispatch]);

  return (
    <StyledRow flexDirection="column">
      <Confetti />
      <StyledCol width={[1, 1, 5 / 6]}>
        <Heading.Heading2 className="modal_title">{title}</Heading.Heading2>
      </StyledCol>

      <StyledCol width={[1, 1, 5 / 6]}>
        <StyledText>{headline}</StyledText>
      </StyledCol>

      <DescriptionCol width={[1, 1, 5 / 6]}>
        <Markdown className="modal_description" source={description} />
      </DescriptionCol>

      <ButtonsCol width={1}>
        <StyledButton onClick={onSubmit} className="create_btn" variant="primary">
          {label}
        </StyledButton>
        <StyledButton ghost onClick={onClose}>
          {ghostLabel}
        </StyledButton>
      </ButtonsCol>
    </StyledRow>
  );
};

export default ActiveAccountModal;
