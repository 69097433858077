import { mq, styled, css } from 'mymoria-ui';
import { Button, Col, Heading, Markdown, Row } from 'mymoria-ui/components';
import React, { FunctionComponent } from 'react';

interface Props {
  /**
   * Tile title.
   */
  title?: string;

  /**
   * Tile Description.
   */
  description?: string;

  /**
   * Button Label.
   */
  label: string;

  /**
   * Ghost Button Label.
   */
  ghostLabel?: string;

  /**
   * Submit handler.
   */
  onSubmit: () => void;

  /**
   * Submit handler ghost button.
   */
  ghostButton?: () => void;

  /**
   * Intro used for tiles
   */
  isTile?: boolean;
}

const StyledRow = styled(Row)`
  z-index: 8;
`;

const StyledCol = styled(Col)(
  mq({
    margin: ['0 0 0.5rem', '0 0 2.5rem'],
  }),
);

interface DescriptionProp {
  isTile: boolean;
}
const DescriptionCol = styled(Col)<DescriptionProp>(
  ({ theme: { font }, isTile }) => css`
    max-height: 500px;
    .modal_description {
      p {
        font-weight: ${isTile ? font.weight.bold : font.weight.semiBold};

        ${mq({
          margin: ['1rem 0', '2rem 0'],
        })};
      }
    }

    button {
      display: inline-block;
    }

    li {
      padding: 0;
      font-weight: ${font.weight.semiBold};
    }

    ${mq({
      margin: ['0 0 1.5rem', '0 0 2.5rem'],
    })};
  `,
);

const StyledButton = styled(Button)(
  mq({
    margin: ['1rem 0.5rem 1rem 0', '0 0 0 1rem', '0 0 0 1rem'],
  }),
);

const IntroModal: FunctionComponent<Props> = ({
  description,
  title,
  label,
  ghostLabel,
  onSubmit,
  ghostButton,
  isTile = false,
}) => (
  <StyledRow flexDirection="column">
    <StyledCol width={1}>
      <Heading.Heading1 className="modal_title">{title}</Heading.Heading1>
    </StyledCol>
    <DescriptionCol width={1} isTile={isTile}>
      <Markdown className="modal_description" source={description} />
      <Button onClick={onSubmit} className="create_btn" variant="primary">
        {label}
      </Button>
      {ghostLabel && (
        <StyledButton ghost onClick={ghostButton}>
          {ghostLabel}
        </StyledButton>
      )}
    </DescriptionCol>
  </StyledRow>
);

export default IntroModal;
