import icon from 'assets/fancy-check-mark.svg';
import { styled } from 'mymoria-ui';
import { FC } from 'react';

const CheckMark: FC = styled.div(`
  width: 120px;
  height: 120px;
  background: url(${icon}) no-repeat;
`);

export default CheckMark;
