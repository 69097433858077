import { useDispatch, useSelector } from 'hooks';
import React, { FunctionComponent } from 'react';
import { formatPrice } from 'helpers';
import { CustomOfferItemPreview } from 'mymoria-ui/components';
import { useHistory } from 'mymoria-ui/hooks';
import { addProduct, removeProduct } from 'actions/provision';
import { useRouteMatch } from 'react-router-dom';
import { trackUserInteraction } from 'actions/tracking';
import { isOfferEditable } from 'reducers/provision';
import * as t from 'data';
import { find, pick } from 'lodash';

interface ProductPreviewProps {
  id: string;
}

const OptionalProductPreview: FunctionComponent<ProductPreviewProps> = ({ id }) => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const funeralType = useSelector(({ provision }) => provision.funeralType);
  const isEditable = useSelector(isOfferEditable());

  const selectedProductsIds = useSelector(({ provision }) => provision.optionalProducts);
  const allProducts = useSelector(({ entities }) => entities.products);
  const selectedProducts = pick(allProducts, selectedProductsIds);
  const mostRecentProduct = useSelector(({ entities }) => entities.products[id]);

  // check if any of selected provision product match with one of the defaults
  const foundSelectedOptionalProduct = find(
    selectedProducts,
    entity => entity.articleNumber === mostRecentProduct.articleNumber,
  );

  const item = foundSelectedOptionalProduct || mostRecentProduct;
  const selected = !!foundSelectedOptionalProduct;

  const handleEdit = () => {
    dispatch(trackUserInteraction('Edit Product', item.translations.shortname));
    push(`${url}/products/${item.category}`, { restoreScrollPosition: false });
  };

  const handleSelect = () =>
    dispatch<Promise<any>>(selected ? removeProduct(item.id) : addProduct(item.id));

  if (!item) {
    return null;
  }

  return (
    <CustomOfferItemPreview
      funeralType={funeralType}
      selected={selected}
      name={item.translations?.shortname}
      price={formatPrice(item.price, { gross: true })}
      image={item.pictures[0]?.url}
      onSelect={isEditable ? handleSelect : undefined}
      onClick={isEditable ? handleEdit : undefined}
      outdated={!!item.deletedAt}
      description={item.translations.description}
      shortDescription={item.translations.name}
      buttonLabel={t.offer.editProduct[item.category]}
    />
  );
};

export default OptionalProductPreview;
