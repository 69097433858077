import { Dispatch } from 'redux';
import { createAction } from 'typesafe-actions';
import { SalesforceUserAccountsData } from 'types';
import { api } from 'utils';

const fetchSalesforceUserAccountsDataAction = createAction('FETCH_SALESFORCE_USER_ACCOUNTS_DATA')<
  SalesforceUserAccountsData[]
>();

export default {
  fetchSalesfroceUserData: fetchSalesforceUserAccountsDataAction,
};

export const fetchSalesforceUserAccountsData = (email: string) => (dispatch: Dispatch) =>
  api
    .post<SalesforceUserAccountsData[]>('/salesforce/user-accounts', { email })
    .then(({ data }) => dispatch(fetchSalesforceUserAccountsDataAction(data)));
