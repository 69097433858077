/* I will handle it later */
/* eslint-disable react-hooks/exhaustive-deps */
import { usePush } from 'mymoria-ui/hooks';
import { useSessionStorage } from './index';
import { FormikHelpers, FormikValues } from 'formik';
import * as Yup from 'yup';
import { ComponentType, useCallback, useEffect } from 'react';
import { times } from 'lodash';
import { RouteComponentProps, useLocation } from 'react-router';

interface Step {
  path: string;
  component: ComponentType<RouteComponentProps<any>>;
  validationSchema?: any | (() => any);
  title?: string;
  description?: string;
}

export interface StepperConfig {
  name: string;
  basepath: string;
  onGoBack?: string;
  initialValues: FormikValues;
  steps: Step[];
  onSubmit: (values: any, bag: FormikHelpers<any>) => void;
  headerTitle?: string;
}

export default ({ name, basepath, initialValues, steps, onSubmit }: StepperConfig) => {
  const { push } = usePush();
  const { pathname } = useLocation();

  const [step, setStep] = useSessionStorage<number>(`form-step-${name}`, 0);
  const [values, setValues] = useSessionStorage<FormikValues>(`form-values-${name}`, initialValues);
  const [validationMap, setValidationMap] = useSessionStorage<boolean[]>(
    `form-validation-map-${name}`,
    times(steps.length, () => false),
  );

  useEffect(() => {
    const nextStep = steps.findIndex(({ path: stepPath }) => pathname === `${basepath}${stepPath}`);

    if (nextStep === -1 || validationMap[nextStep - 1] === false) {
      push(`${basepath}${steps[0].path}`);
      setValues(initialValues);
      setValidationMap(times(steps.length, () => false));
      setStep(0);
    } else if (nextStep !== step) {
      setStep(nextStep);
    }
  }, [pathname]);

  const handleSubmit = useCallback(
    (values, bag) => {
      if (step === steps.length - 1) {
        bag.setStatus(null);
        return new Promise(resolve => resolve(onSubmit(values, bag)))
          .then(() => {
            sessionStorage.removeItem(`form-step-${name}`);
            sessionStorage.removeItem(`form-values-${name}`);
          })
          .catch(({ response }) => {
            bag.setSubmitting(false);
            bag.setStatus({ error: response?.data });
          });
      }

      bag.setTouched({});
      bag.setSubmitting(false);
      setStep((step: number) => {
        const nextStep = Math.min(step + 1, steps.length - 1);
        setValidationMap(validationMap.map((v: boolean, k: number) => v || k === step));
        push(`${basepath}${steps[nextStep].path}`);
        return nextStep;
      });
      setValues(values);
    },
    [step, steps],
  );

  const goToPrevStep = useCallback(() => {
    const prevStep = Math.max(step - 1, 0);

    if (steps[prevStep].path !== pathname) {
      push(`${basepath}${steps[prevStep].path}`);
    }
  }, [basepath, pathname, push, step, steps]);

  return {
    goToPrevStep,
    currentStep: step,
    formikProps: {
      onSubmit: handleSubmit,
      initialValues: values,
      validationSchema: steps[step].validationSchema || Yup.object(),
    },
  };
};
