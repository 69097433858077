import { merge, get } from 'lodash';
import config from 'config';
import { AxiosResponse, AxiosError } from 'axios';
import moment from 'moment';
import { OfferItem } from 'mymoria-types';
import uuid from 'uuid/v4';
import { CyAttribute } from 'types';

export { default as media } from './media';
export { default as api } from './api';

const {
  letter: { fonts },
} = config;

export const colors = {
  primary: '#00aea0',
  primaryHover: '#007e75',
  black: '#000',
  white: '#fff',
  gray: '#c9c9c9',
  hint: '#929292',
  details: '#f5f5f5',
  red: '#ff3823',
  lighterGray: '#c0c0c0',
  lightGray: '#f7f6f6',
  headerGray: '#6f6f6f',
  darkGray: '#353535',
  fontGray: '#606060',
  progressBarBase: '#d6d6d6',
  footerColor: '#6e6e6e',
  linkButton: '#00b1af',
  signatureBackground: 'rgba(255, 255, 255, .9)',
  warning: '#d68227',
  success: '#28a745',
  placeholder: '#aaa',
};

export const styles = {
  h1: {
    fontSize: '2rem',
    color: colors.headerGray,
    fontWeight: 'lighter',
  },
  h2: {
    fontSize: '1.6rem',
    color: colors.headerGray,
    fontWeight: 'lighter',
  },
  h3: {
    fontSize: '1.17rem',
    color: colors.headerGray,
    fontWeight: 'lighter',
  },
  h4: {
    fontSize: '1rem',
    color: colors.headerGray,
    fontWeight: 'lighter',
  },
  ul: {
    margin: '1rem 0 2rem',
    listStyleType: 'none',
    textIndent: '-2rem',
    textAlign: 'left',

    '& li:before': {
      content: 'close-quote',
      display: 'inline-block',
      width: '2rem',
      height: '1rem',
      padding: '.5rem 0',
    },
  },
  starList: {
    '& li:before': {},
  },
};

export const sprintf = (str: string, data: object) =>
  str.replace(/%\([A-z]+\)/g, r => get(data, r.slice(2, -1).toString(), 'NOT_FOUND'));

// @ts-ignore
export const onEnterSubmit = (e, cb) => {
  if (e.key === 'Enter' && e.shiftKey === false) {
    e.preventDefault();
    cb();
  }
};

export const loadLetterFonts = () =>
  Promise.all(fonts.map(({ value }) => document.fonts.load(`24px "${value}"`)));

export const getAxiosData = <T>({ data }: AxiosResponse<T>) => data;

export const customize = <T extends OfferItem>(entities: T[], customizations?: Partial<T>[]) =>
  !customizations
    ? entities
    : entities.map(entity => {
        const custom = customizations.find(({ id: customId }) => entity.id === customId);
        return custom ? merge(entity, custom) : entity;
      });

export const postalCodeValidator = (input: string) => /^\d{5}\d*$/.test(input);

export const isAxiosError = (e: any): e is AxiosError => {
  return e.isAxiosError === true && e.response;
};

export const parseDataLayerError = ({ response }: AxiosError) => ({
  endpoint: `${response?.config?.method?.toUpperCase()} ${response?.config?.url}`,
  params: Object.entries(response?.config?.params || {}).reduce(
    (acc, [key, value]) => acc.concat(`${key}: ${value};`),
    '',
  ),
  response: `${response?.data?.statusCode} ${response?.data?.code} ${response?.data?.message}`,
});

export const generateCookiesSettings = () => ({
  id: uuid(),
  options: {
    domain: window.location.hostname === 'localhost' ? '' : '.mymoria.de',
    path: '/',
    expires: moment().add(6, 'month').toDate(),
  },
});

export const applyCyAttribute = (cyAttribute: string, specific?: string): CyAttribute => ({
  'data-cy': cyAttribute.concat(specific ? `-${specific}` : ''),
});
