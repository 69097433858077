import React, { FunctionComponent } from 'react';
import { usePush } from 'mymoria-ui/hooks';
import { StepperFormFooter, Loader } from 'mymoria-ui/components';
import { LetterContent } from 'mymoria-types';
import { useDispatch, useSelector } from 'hooks';
import { updateLetter } from 'actions/letters';
import { global, letter as t } from 'data';
import { isEmpty } from 'lodash';
import { Step as StepProps } from './types';
import LetterEditor from '../LetterEditor';

const ContentStep: FunctionComponent<StepProps> = ({ letterId, onClickNext, ...props }) => {
  const { handlePush } = usePush();
  const dispatch = useDispatch();
  const letter = useSelector(({ entities }) => entities.letters[letterId]);

  return (
    <LetterEditor
      initialValues={letter.content}
      onSubmit={(content: LetterContent) =>
        dispatch(updateLetter(letterId, { content: content.text.length ? content : null }))
      }
    >
      {({ submitForm, isValid, dirty, isSubmitting, values }) => (
        <>
          {isSubmitting && <Loader overlay />}
          <StepperFormFooter
            labelPrev={global.labels.back}
            labelNext={isEmpty(values.text) ? global.labels.skip : t.create.steps.content.submit}
            onClickNext={() => (dirty ? submitForm().then(onClickNext) : onClickNext())}
            disabledNext={!isValid}
            {...props}
            onClickPrev={handlePush('/overview/letters')}
          />
        </>
      )}
    </LetterEditor>
  );
};

export default ContentStep;
