import React from 'react';
import { useSelector } from 'hooks';
import OfferCustomizedPage from './OfferCustomizedPage';
import OfferEditPage from './OfferEditPage';

const OfferPage = () => {
  const state = useSelector(({ provision }) => provision.state);

  return state === 'customized' ? <OfferCustomizedPage /> : <OfferEditPage />;
};

export default OfferPage;
