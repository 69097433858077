import actions from 'actions';
import { SignupValues } from 'types';
import { createReducer } from 'typesafe-actions';

const initialState: Partial<SignupValues> = {};

const { setRegistration, removeRegistrationData } = actions.registration;

export default createReducer(initialState)
  .handleAction([setRegistration], (state, { payload }) => ({
    ...state,
    ...payload,
  }))
  .handleAction([removeRegistrationData], () => initialState);
