import { Markdown, WizardCard } from 'mymoria-ui/components';
import { global, financial } from 'data';
import React, { FunctionComponent, useLayoutEffect } from 'react';
import { WizardComponentProps } from 'react-albus';
import { useHistory } from 'mymoria-ui/hooks';
import { useWundWState } from './WundWState';

const ConsultingStep: FunctionComponent<WizardComponentProps> = ({
  wizard: { push, step, steps },
}) => {
  const { handlePush } = useHistory();
  const [{ paymentType }] = useWundWState();

  useLayoutEffect(() => {
    if (!paymentType) {
      push('paymentType');
    }
  }, [paymentType, push]);

  return (
    <WizardCard
      title={financial.wundw.consulting.title}
      labelPrev={global.labels.back}
      labelNext={global.labels.continue}
      onClickPrev={() => push('paymentType')}
      onClickNext={handlePush('consent')}
      steps={steps.length - 1}
      step={steps.indexOf(step)}
    >
      <Markdown>{financial.wundw.consulting.description}</Markdown>
    </WizardCard>
  );
};

export default ConsultingStep;
