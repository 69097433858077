import React from 'react';
import { Section } from 'components';
import { Col, FormFooter, Grid, Loader, Row } from 'mymoria-ui/components';
import { LetterContent } from 'mymoria-types';
import { useDispatch, useSelector } from 'hooks';
import { updateLetter } from 'actions/letters';
import { global, letter as t } from 'data';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'mymoria-ui/hooks';
import { isMobile } from 'react-device-detect';
import LetterEditor from '../LetterEditor';

const Editor = () => {
  const { handlePush } = useHistory();
  const dispatch = useDispatch();
  const { state: letterId } = useLocation<string>();
  const letter = useSelector(({ entities }) => entities.letters[letterId]);
  return (
    <LetterEditor
      initialValues={letter.content}
      onSubmit={(content: LetterContent) =>
        dispatch(updateLetter(letterId, { content: content.text.length ? content : null })).then(
          handlePush('/overview/letters/edit', letterId),
        )
      }
    >
      {({ submitForm, dirty, isValid, isSubmitting }) => (
        <>
          {isSubmitting && <Loader overlay />}
          <FormFooter
            labelCancel={global.labels.cancel}
            labelSubmit={global.labels.submit}
            onCancel={handlePush('/overview/letters/edit', letterId)}
            onSubmit={submitForm}
            disabledSubmit={!dirty || !isValid}
          />
        </>
      )}
    </LetterEditor>
  );
};

const EditContent = () => {
  const { handlePush } = useHistory();
  const { state: letterId } = useLocation<string>();

  return isMobile ? (
    <Editor />
  ) : (
    <Section
      title={t.edit.content.sectionTitle}
      onGoBack={handlePush('/overview/letters/edit', letterId)}
      goBackLabel={t.edit.content.back}
    >
      <Grid>
        <Row justifyContent="center">
          <Col width={[1, 10 / 12, 2 / 3]} marginBottom="7rem">
            <Editor />
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

export default EditContent;
