import { useSelector } from 'hooks';
import { isEmpty, omit, some } from 'lodash';
import { useLayoutEffect } from 'react';
import { StepObject } from 'react-albus';

export const useStepValidation = (
  steps: StepObject[],
  step: StepObject,
  replace: (step: string) => void,
) => {
  const { funeralType, funeralPlan, place, provisionBirth } = useSelector(
    ({ registration }) => registration,
  );
  const stepIndex = steps.indexOf(step);
  const nextSteps = steps.filter((_, index) => index >= stepIndex).map(({ id }) => id);

  const stepToStore = {
    type: funeralType,
    place: place,
    character: funeralPlan,
    birthdate: provisionBirth,
  };

  useLayoutEffect(() => {
    if (some(omit(stepToStore, nextSteps), isEmpty)) {
      replace('type');
    }
  }, [nextSteps, replace, stepToStore]);
};
