import React, { FunctionComponent } from 'react';
import { css, styled } from 'mymoria-ui';
import { Button, Caption, Card, Loader } from 'mymoria-ui/components';
import { global as t } from 'data';
import { isEmpty } from 'lodash';
import CouponCode from './CouponCode';
import { useSelector } from 'hooks';
import { getDiscount } from 'reducers/provision';
import { useFormikContext } from 'formik';
import { Route } from 'react-router-dom';
import CartItem from './CartItem';
import { PricingValues } from './types';
import PayPalButton from './PayPalButton';

interface Props {
  buttonLabel?: string;
}

const StickyCard = styled(Card)`
  position: sticky;
  top: 6.25rem;
`;

const Separator = styled.hr(
  ({ theme: { colors } }) => css`
    margin: 1.5rem 0;
    background: ${colors.gray};
    border: 0;
    height: 1px;
  `,
);

const Pricing = styled.div`
  margin: 2rem 0;
`;

const StyledButton = styled(Button)`
  margin-top: 2rem;
`;

const TotalPrice = styled(CartItem)`
  margin-bottom: 0.5rem;

  p {
    font-weight: bold;
  }
`;

const Cart: FunctionComponent<Props> = ({ buttonLabel }) => {
  const formikBag = useFormikContext<PricingValues>();
  const plan = formikBag.values.plan;
  const discount = useSelector(state => getDiscount(state));
  const isPaypal = formikBag.values.paymentType === 'paypal';

  if (isEmpty(plan)) {
    return <Loader overlay />;
  }

  const {
    price: { value },
  } = plan;

  return (
    <StickyCard variant="small" title={t.pricing.cart.total}>
      <Pricing>
        <CartItem
          description={t.pricing.cart.priceGuaranteeText}
          value={value}
          period={plan.price.period}
        />

        {discount && (
          <>
            <CartItem
              description={t.pricing.cart.discountTotal}
              value={-(value * discount) / 100}
              discount={discount}
            />
            <Separator />
            <TotalPrice
              description={t.pricing.cart.totalText}
              value={discount ? value - (value * discount) / 100 : value}
            />
          </>
        )}

        <Caption light>{t.pricing.cart.includeTax}</Caption>
      </Pricing>
      <Route path={'/overview/pricing/address'} component={CouponCode} />
      <Route
        path="/overview/pricing/summary"
        render={() =>
          isPaypal ? (
            <PayPalButton />
          ) : (
            <StyledButton block type="button" onClick={formikBag.submitForm}>
              {buttonLabel}
            </StyledButton>
          )
        }
      />
      <Route
        exact8
        path={['/overview/pricing/address', '/overview/pricing/payment']}
        component={() => (
          <StyledButton block type="button" onClick={formikBag.submitForm}>
            {buttonLabel}
          </StyledButton>
        )}
      />
    </StickyCard>
  );
};

Cart.defaultProps = {
  buttonLabel: t.pricing.cart.buttonNext,
};

export default Cart;
