import React, { FunctionComponent } from 'react';
import { StepperFormFooter } from 'mymoria-ui/components';
import { letter as t, global } from 'data';
import { Form, Formik } from 'formik';
import { LetterRecipient } from 'mymoria-types';
import { useDispatch, useSelector } from 'hooks';
import { updateLetter } from 'actions/letters';
import { merge, isEmpty } from 'lodash';
import { Step as StepProps } from './types';
import { RecipientForm, recipientSchema } from '../forms';

const RecipientStep: FunctionComponent<StepProps> = ({ letterId, onClickNext, ...props }) => {
  const dispatch = useDispatch();
  const letter = useSelector(({ entities }) => entities.letters[letterId]);

  const handleSubmit = (recipient: LetterRecipient) =>
    dispatch(updateLetter(letterId, { recipient }));

  return (
    <Formik
      initialValues={merge({}, letter.recipient)}
      validationSchema={recipientSchema}
      onSubmit={handleSubmit}
    >
      {({ dirty, isValid, submitForm, values }) => (
        <Form noValidate>
          <RecipientForm />
          <StepperFormFooter
            labelPrev={global.labels.back}
            labelNext={isEmpty(values) ? global.labels.skip : t.create.steps.recipient.submit}
            onClickNext={() => (dirty ? submitForm().then(onClickNext) : onClickNext())}
            disabledNext={!isValid}
            {...props}
          />
        </Form>
      )}
    </Formik>
  );
};

export default RecipientStep;
