import { global } from 'data';
import { Caption, Col, Row } from 'mymoria-ui/components';
import { mq, styled } from 'mymoria-ui/utils';
import React, { FunctionComponent } from 'react';

const PaymentIcon = styled.img`
  height: 22px;

  ${mq({
    marginRight: ['1rem'],
  })};
`;

const PartnerIcon = styled.img`
  height: 50px;

  &:last-child {
    margin: 0;
  }

  ${mq({
    marginRight: ['2rem'],
  })};
`;

const IconSection: FunctionComponent = () => {
  const partnersIcons = ['trustedShops'];
  const paymentIcons = ['paypal', 'sepa', 'visa', 'master'];

  return (
    <Row mb={2} flexDirection={['column', 'row']}>
      <Col width={[1, 1, 1 / 2]} textAlign={['center', 'left']}>
        <Caption my={2} textAlign={['center', 'left']}>
          {global.iconSection.paymentTitle}
        </Caption>
        {paymentIcons.map(icon => (
          <PaymentIcon src={`/img/${icon}.svg`} key={`icon-${icon}`} />
        ))}
      </Col>
      <Col width={[1, 1, 1 / 2]} textAlign={['center', 'right']}>
        <Caption textAlign={['center', 'right']} my={2}>
          {global.iconSection.partnerTitle}
        </Caption>
        {partnersIcons.map(icon => (
          <PartnerIcon src={`/img/${icon}.png`} key={`icon-${icon}`} />
        ))}
      </Col>
    </Row>
  );
};

export default IconSection;
