import { activatePlan } from 'actions/provision';
import { Stepper, Page } from 'components';
import config from 'config';
import { global } from 'data';
import { useDispatch } from 'hooks';
import { Loader } from 'mymoria-ui/components';
import { useToast } from 'mymoria-ui/hooks';
import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as Yup from 'yup';
import { Address, Confirmation, Plans, Summary, Payment } from './Steps';
import { PricingValues } from './types';

const { regex } = config;

const steps = [
  {
    path: '/plans',
    component: Plans,
  },
  {
    path: '/address',
    component: Address,
    validationSchema: Yup.object().shape({
      firstname: Yup.string().required(global.validators.required),
      lastname: Yup.string().required(global.validators.required),
      address: Yup.string().required(global.validators.required),
      postalCode: Yup.number()
        .required(global.validators.required)
        .typeError(global.validators.wrong),
      city: Yup.string()
        .required(global.validators.required)
        .matches(regex.alphaGerman, global.validators.wrong),
    }),
  },
  {
    path: '/payment',
    component: Payment,
    validationSchema: Yup.object().shape({
      paymentType: Yup.string().required(global.validators.required),
    }),
  },
  {
    path: '/summary',
    component: Summary,
  },
];

const initialValues = {
  plan: '',
  paymentType: '',
  firstname: '',
  lastname: '',
  address: '',
  postalCode: '',
  city: '',
  iban: '',
  bank: '',
  bic: '',
};

const PricingPage = () => {
  const { addToast } = useToast();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const handleSubmit = ({ plan, iban, ...values }: PricingValues) => {
    setIsSubmitting(true);
    dispatch(
      activatePlan({
        paymentAcceptedPlan: plan.id,
        iban: iban.replace(/\s+/g, ''),
        ...values,
      }),
    )
      .then(() => {
        setIsSubmitted(true);
        setIsSubmitting(false);
      })
      .catch(({ message }) => {
        addToast(message);
        setIsSubmitting(false);
      });
  };

  return (
    <Page footer="simple" headerTitle={global.priceGuarantee.headerTitle}>
      {isSubmitting ? (
        <Loader overlay />
      ) : isSubmitted ? (
        <Confirmation />
      ) : (
        <Stepper
          name="pricing"
          basepath={match.path}
          steps={steps}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      )}
    </Page>
  );
};

export default PricingPage;
