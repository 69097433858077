import actions from 'actions';
import { FuneralSitesState } from 'types';
import { createReducer } from 'typesafe-actions';
import { uniq } from 'lodash';

const initialState: FuneralSitesState = [];

const { fetchFuneralSite } = actions.funeralSites;

export default createReducer(initialState).handleAction(fetchFuneralSite, (state, { payload }) =>
  uniq([...state, payload.id]),
);
