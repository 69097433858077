import { Button, Markdown, WizardCard, Row, Loader } from 'mymoria-ui/components';
import { financial, global } from 'data';
import React, { FunctionComponent, useLayoutEffect } from 'react';
import { WizardComponentProps } from 'react-albus';
import { useHistory, useToast } from 'mymoria-ui/hooks';
import { CheckboxField } from 'forms/fields';
import { formatPrice } from 'helpers';
import { Form, Formik } from 'formik';
import { styled } from 'mymoria-ui';
import * as yup from 'yup';
import { updateProvision } from 'actions/provision';
import moment from 'moment';
import { AlertMessage } from 'components';
import { useSelector, useDispatch } from 'hooks';
import config from 'config';
import { useWundWState } from './WundWState';
import { sprintf } from 'utils';

const { dateFormat } = config;

const StyledLoader = styled(Loader)`
  & > div {
    width: 40px;
    height: 40px;
  }
`;

const ConsentStep: FunctionComponent<WizardComponentProps> = ({
  wizard: { push, step, steps },
}) => {
  const { handlePush } = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [{ paymentType = '', tariff, totalFuturePrice }] = useWundWState();
  const notConfirmed = useSelector(({ provision }) => provision.status !== 'CONFIRMED');
  const provision = useSelector(({ provision }) => provision);
  const userEmail = useSelector(({ provision }) => provision.userEmail);
  const { sumStatus = '', monthly, oneTime } = tariff || {};

  useLayoutEffect(() => {
    if (!paymentType) {
      push('paymentType');
    }
  }, [paymentType, push]);

  const handleSubmit = () => {
    const tariffSumStatus =
      sumStatus === 'normal' ? '-' : financial.wundw.tariff.sumStatus[sumStatus];
    const tariffPaymentValue =
      paymentType === 'monthly' ? formatPrice(monthly) : formatPrice(oneTime);
    const paymentTypeValue = financial.wundw.tariff.paymentType[paymentType].title;

    dispatch(
      updateProvision({
        wundw: {
          permissionGrantedAt: new Date(),
          emailData: {
            salesPersonId: provision.salesPersonId || '-',
            provisionBirth: moment(provision.provisionBirth).format(dateFormat.view),
            salutation: global.forms.salutation[provision.salutation],
            firstname: provision.firstname,
            lastname: provision.lastname,
            status: provision.status,
            email: provision.email,
            phone: provision.phone,
            city: provision.city,
            number: provision.number,
            tariffPaymentValue: tariffPaymentValue || '-',
            tariffSum: formatPrice(tariff?.sum) || '-',
            tariffSumStatus: tariffSumStatus || '-',
            totalFutureCost: formatPrice(totalFuturePrice),
            paymentType: paymentTypeValue || '-',
          },
        },
      }),
    )
      .then(() => addToast(financial.wundw.tariff.message, 'success'))
      .then(handlePush('summary'))
      .catch(({ message }) => addToast(message));
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ terms: false }}
      validationSchema={yup.object({
        terms: yup.boolean().oneOf([true], global.validators.terms),
      })}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <WizardCard
          title={financial.wundw.consent.title}
          labelPrev={global.labels.back}
          onClickPrev={() => push('paymentType')}
          steps={steps.length - 1}
          step={steps.indexOf(step)}
        >
          <Form noValidate>
            <Markdown>{financial.wundw.consent.description}</Markdown>
            <Row justifyContent="center" my="2rem">
              <CheckboxField name="terms" label={financial.wundw.consent.terms} />
            </Row>
            {notConfirmed && (
              <Row justifyContent="center">
                <AlertMessage
                  message={sprintf(global.activateAccount.ActivateAccount, { email: userEmail })}
                  status="warning"
                />
              </Row>
            )}
            <Row justifyContent="center">
              <Button type="submit" disabled={!isValid || !dirty || notConfirmed} mx={2}>
                {isSubmitting ? (
                  <StyledLoader tiny color="#ffffff" />
                ) : (
                  financial.wundw.consent.emailButton
                )}
              </Button>
              <Button ghost onClick={handlePush('/overview/finance')} mx={2}>
                {financial.wundw.consent.continueButton}
              </Button>
            </Row>
          </Form>
        </WizardCard>
      )}
    </Formik>
  );
};

export default ConsentStep;
