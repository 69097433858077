import { global, letter as t } from 'data';
import { styled } from 'mymoria-ui';
import { Button, Card, Heading, ProgressBar, StatusTag, Text } from 'mymoria-ui/components';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'hooks';
import { sprintf } from 'utils';
import { usePush } from 'mymoria-ui/hooks';
import { useRouteMatch } from 'react-router-dom';
import { Canvas } from './index';

const {
  labels: { show = '', proceed = '' },
  status: statusLabel,
} = global;

interface LetterTileProps {
  id: string;
}

const StyledTile = styled(Card)`
  min-height: unset;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 !important;
  overflow: hidden;
`;

const Container = styled.div`
  padding: 1.5rem;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid #ccc;
`;

const Heading2 = styled(Heading.Heading2)`
  padding: 1rem 0;
`;

const InsideWrapper = styled.div`
  flex: 1;
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 1rem;
`;

const LetterTile: FunctionComponent<LetterTileProps> = ({ id }) => {
  const { handlePush } = usePush();
  const { url } = useRouteMatch();
  const letter = useSelector(({ entities }) => entities.letters[id]);

  if (!letter) {
    return null;
  }

  const { content, recipient, shippingDate } = letter;
  const requiredSteps = [content, recipient, shippingDate];
  const completedSteps = requiredSteps.reduce((acc, item) => (item ? acc + 1 : acc), 0);
  const status = completedSteps === requiredSteps.length ? 'completed' : 'inProgress';
  const isCompleted = status === 'completed';

  return (
    <StyledTile variant="small">
      <Canvas content={letter.content} />
      <Container>
        <TopWrapper>
          <StatusTag status={status}>{statusLabel[status]}</StatusTag>
          {!isCompleted && (
            <ProgressBar width="100px" percentage={`${(100 / 3) * completedSteps}%`} />
          )}
        </TopWrapper>
        <InsideWrapper>
          <Heading2>
            {recipient
              ? sprintf(t.list.tile.title, { name: `${recipient.firstname} ${recipient.lastname}` })
              : t.list.tile.inProgress}
          </Heading2>
          <Text>{t.list.tile[isCompleted ? 'shippingDate' : 'updatedAt']}</Text>
          <Text fontWeight="bold">
            {shippingDate &&
              (shippingDate.generic === 'EXACT_DATE' && shippingDate.exact
                ? `${shippingDate.exact.day}${
                    shippingDate.exact.year === 'EXACT_DATE'
                      ? shippingDate.exact.exactYear
                      : ` ${t.list.dates[shippingDate.exact.year]}`
                  }`
                : t.list.dates[shippingDate.generic])}
          </Text>
        </InsideWrapper>
        <div>
          <Button outline={isCompleted} onClick={handlePush(`${url}/edit`, letter.id)}>
            {isCompleted ? show : proceed}
          </Button>
        </div>
      </Container>
    </StyledTile>
  );
};

export default LetterTile;
