import actions from 'actions';
import { ProductCategory } from 'mymoria-types';
import { getValidPhoneFormat } from 'mymoria-ui/utils';
import { ApplicationState, ProvisionState } from 'types';
import { createReducer } from 'typesafe-actions';
import { filter, map } from 'lodash';

const {
  activate,
  create,
  discount,
  fetch,
  remove,
  update,
  changeBasicInformation,
  addProduct,
  removeProduct,
  changeProduct,
  addService,
  removeService,
  changeService,
  changeFuneralSite,
  lockProduct,
  switchSeaBasicService,
  setFormSubmissionTime,
} = actions.provision;
const initialState: ProvisionState = {} as ProvisionState;

export default createReducer(initialState)
  .handleAction(create, (state, { payload }) => payload)
  .handleAction(fetch, (state, { payload }) => payload.result.provision)
  .handleAction(
    [activate, discount, update, changeFuneralSite, lockProduct],
    (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  )
  .handleAction([remove], () => initialState)
  .handleAction(changeBasicInformation, (state, { payload }) => ({
    ...state,
    ...payload.result.provision,
  }))
  .handleAction(addProduct, (state, { payload: { id, price } }) => ({
    ...state,
    price,
    optionalProducts: [...state.optionalProducts, id],
  }))
  .handleAction(removeProduct, (state, { payload: { id, price } }) => ({
    ...state,
    price,
    optionalProducts: filter(state.optionalProducts, itemId => itemId !== id),
  }))
  .handleAction(changeProduct, (state, { payload: { addedId, removedId, price } }) => ({
    ...state,
    price,
    basicProducts: map(state.basicProducts, id => (id === removedId ? addedId : id)),
    optionalProducts: map(state.optionalProducts, id => (id === removedId ? addedId : id)),
  }))
  .handleAction(setFormSubmissionTime, (state, { payload: { time } }) => ({
    ...state,
    formSubmissionTime: time,
  }))
  .handleAction(addService, (state, { payload: { id, price } }) => ({
    ...state,
    price,
    optionalServices: [...state.optionalServices, id],
  }))
  .handleAction(removeService, (state, { payload: { id, price } }) => ({
    ...state,
    price,
    optionalServices: filter(state.optionalServices, itemId => itemId !== id),
  }))
  .handleAction(changeService, (state, { payload: { addedId, removedId, price } }) => ({
    ...state,
    price,
    basicServices: map(state.basicServices, id => (id === removedId ? addedId : id)),
  }))
  .handleAction(
    switchSeaBasicService,
    (
      state,
      {
        payload: {
          addedBasicService,
          removedBasicService,
          addedServices,
          removedServices,
          price,
          funeralPlan,
          graveType,
          funeralSite,
        },
      },
    ) => ({
      ...state,
      basicServices: map(state.basicServices, id =>
        id === removedBasicService ? addedBasicService : id,
      ),
      optionalServices: [
        ...state.optionalServices.filter(id => !removedServices.includes(id)),
        ...addedServices.filter(id => !state.optionalServices.includes(id)),
      ],
      price,
      funeralPlan,
      graveType,
      ...(funeralSite ? { funeralSite } : {}),
    }),
  );

export const getProvision = (state: ApplicationState) => state.provision;

export const getDiscount = (state: ApplicationState) => state.provision.couponDiscount;

export const getPlanActivationStatus = (state: ApplicationState) =>
  typeof state.provision.paymentAcceptedPlan !== 'undefined';

export const getCustomerPhone = ({ provision: { phone } }: ApplicationState) =>
  getValidPhoneFormat({ phone, formatType: 'national', withSlash: true });

export const getTrustedPersonPhone = ({ provision: { trustedPerson } }: ApplicationState) => {
  if (trustedPerson?.phone) {
    return getValidPhoneFormat({
      phone: trustedPerson?.phone,
      formatType: 'national',
      withSlash: true,
    });
  }
};

export const isOfferEditable = () => ({ provision: { state } }: ApplicationState) =>
  state !== 'customized';

export const isOptionalProductSelected = (id: string) => ({
  provision: { optionalProducts },
}: ApplicationState) => optionalProducts.includes(id);

export const isOptionalServiceSelected = (id: string) => ({
  provision: { optionalServices },
}: ApplicationState) => optionalServices.includes(id);

export const isProductSelected = (id: string) => ({
  provision: { basicProducts, optionalProducts },
}: ApplicationState) => [...basicProducts, ...optionalProducts].includes(id);

export const getSelectedProductByCategory = (category: ProductCategory) => ({
  provision: { basicProducts, optionalProducts },
  entities,
}: ApplicationState) =>
  [...basicProducts, ...optionalProducts]
    .map(id => entities.products[id])
    .find(product => product?.category === category);
