import React from 'react';
import { sprintf } from 'utils';
import { offer as t } from 'data';
import { useSelector } from 'hooks';
import { Card } from 'mymoria-ui/components';
import BasicServicePreview from './BasicServicePreview';
import BasicProductPreview from './BasicProductPreview';

const BasicItemsCard = () => {
  const { number, basicServices, basicProducts } = useSelector(({ provision }) => provision);
  const items = [
    ...basicServices.map(id => <BasicServicePreview key={id} id={id} />),
    ...basicProducts.map(id => <BasicProductPreview key={id} id={id} />),
  ].flatMap((node, index, array) =>
    index === array.length - 1 ? node : [node, <hr key={`separator-${index}`} />],
  );

  if (items.length === 0) {
    return null;
  }

  return <Card title={sprintf(t.offerDetails.servicesTitle, { number })}>{items}</Card>;
};

export default BasicItemsCard;
