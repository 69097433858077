import React, { FunctionComponent } from 'react';
import * as t from 'data';
import { Text, WizardCard } from 'mymoria-ui/components';
import { DateInputField } from 'forms/fields';
import { Formik } from 'formik';
import { WizardComponentProps } from 'react-albus';
import { Provision } from 'types';
import * as yup from 'yup';
import moment from 'moment';
import config from 'config';
import { withLocationTrack } from 'hocs';
import { useDispatch } from 'hooks';
import { trackProvisionPortalData } from 'actions/tracking';
import { useSelector } from 'hooks';
import { setRegistration } from 'actions/registration';
import { useStepValidation } from './utils';

type FormValues = Pick<Provision, 'provisionBirth'>;

const BirthDateStep: FunctionComponent<WizardComponentProps> = ({
  wizard: { next, previous, steps, step, replace },
}) => {
  useStepValidation(steps, step, replace);
  const provisionBirth = useSelector(({ registration }) => registration.provisionBirth);
  const dispatch = useDispatch();

  const handleSubmit = ({ provisionBirth }: FormValues) => {
    dispatch(setRegistration({ provisionBirth }));
    dispatch(trackProvisionPortalData({ provisionBirth }));
    next();
  };

  return (
    <Formik
      validationSchema={yup.object({
        provisionBirth: yup
          .string()
          .test('checkDateRange', t.global.validators.date, (value: string) =>
            moment(value, config.dateFormat.view).isBetween(
              '1920-01-01',
              moment().subtract(18, 'years'),
            ),
          )
          .required(t.global.validators.required),
      })}
      onSubmit={handleSubmit}
      initialValues={{ provisionBirth } as FormValues}
    >
      {({ values: { provisionBirth }, isSubmitting, errors, submitForm }) => (
        <WizardCard
          title={t.signUp.wizards.birthDate.title}
          onClickPrev={previous}
          onClickNext={() => handleSubmit({ provisionBirth })}
          isLoading={isSubmitting}
          labelPrev={t.global.labels.back}
          labelNext={t.global.labels.continue}
          disabledNext={!provisionBirth || errors.provisionBirth}
          icon="/img/icons/summary.svg"
          hint={t.signUp.wizards.birthDate.hint}
          steps={steps.length - 1}
          step={steps.indexOf(step)}
        >
          <Text mb="2rem">{t.signUp.wizards.birthDate.description}</Text>
          <DateInputField
            name="provisionBirth"
            label={t.global.forms.provisionBirth}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                submitForm();
              }
            }}
          />
        </WizardCard>
      )}
    </Formik>
  );
};

export default withLocationTrack(t.signUp.wizards.birthDate.title)(BirthDateStep);
