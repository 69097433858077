import { EditButton, Section } from 'components';
import config from 'config';
import { global as t } from 'data';
import { useFormikContext } from 'formik';
import { withLocationTrack } from 'hocs';
import { useSelector } from 'hooks';
import { pick } from 'lodash';
import moment from 'moment';
import { styled } from 'mymoria-ui';
import { Caption, Card, Col, Grid, Loader, Markdown, Row, Text } from 'mymoria-ui/components';
import { useHistory } from 'mymoria-ui/hooks';
import React from 'react';
import { sprintf } from 'utils';
import Cart from '../Cart';
import { PricingValues } from '../types';

const { dateFormat } = config;

const addressData = [
  'firstname',
  'lastname',
  'address',
  'postalCode',
  'city',
  'iban',
  'bank',
  'bic',
];

const SummaryMarkdown = styled(Markdown)`
  p {
    margin-bottom: 2rem;
  }
`;

const AccountMarkdown = styled(Markdown)`
  p {
    margin: 0;
  }
`;

const StyledCaption = styled(Caption)`
  font-style: italic;
  margin-bottom: 2rem;
`;

const Summary = () => {
  const { handlePush } = useHistory();
  const { values, isSubmitting } = useFormikContext<PricingValues>();
  const { number, provisionBirth, firstname, lastname } = useSelector(({ provision }) => provision);

  return (
    <Section
      title={t.pricing.summary.sectionTitle}
      description={t.pricing.summary.sectionDescription}
      onGoBack={handlePush('/overview/pricing/payment', { restoreScrollPosition: true })}
      goBackLabel={t.labels.back}
    >
      {isSubmitting && <Loader overlay />}
      <Grid>
        <Row flexDirection="row" flexWrap="wrap">
          <Col width={[1, 1, 3 / 5]}>
            {values.paymentType === 'paypal' && (
              <Card
                variant="large"
                title={t.pricing.summary.paymentMethod}
                icon={<img src="/img/paypal.svg" alt="paypal" />}
              >
                <SummaryMarkdown source={t.pricing.summary.paymentMethodText} />
                <EditButton onClick={handlePush('/overview/pricing/payment')} />
              </Card>
            )}
            {values.paymentType === 'sepa' && (
              <>
                <Card variant="large" title={t.pricing.summary.payment.title}>
                  <SummaryMarkdown
                    source={sprintf(t.pricing.summary.payment.content, { proposalNumber: number })}
                  />
                  <StyledCaption light>{t.pricing.summary.payment.remark}</StyledCaption>
                  <Text>
                    {sprintf(t.pricing.summary.payment.date, {
                      date: moment().format(dateFormat.view),
                    })}
                  </Text>
                </Card>
                <Card variant="large" title={t.pricing.summary.account.title}>
                  <AccountMarkdown
                    source={sprintf(t.pricing.summary.account.content, pick(values, addressData))}
                  />
                  <EditButton onClick={handlePush('/overview/pricing/payment')} />
                </Card>
              </>
            )}

            <Card variant="large" title={t.pricing.summary.address.title}>
              <AccountMarkdown
                source={sprintf(t.pricing.summary.address.content, pick(values, addressData))}
              />
              <EditButton onClick={handlePush('/overview/pricing/address')} />
            </Card>
          </Col>
          <Col width={[1, 1, 2 / 5]}>
            <Cart buttonLabel={t.pricing.cart.buttonSubmit} />
            <Card variant="small" title={t.pricing.summary.statement.title}>
              <Markdown
                source={sprintf(t.pricing.summary.statement.content, {
                  firstname,
                  lastname,
                  provisionBirth: moment(provisionBirth).format(config.dateFormat.view),
                })}
              />
            </Card>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

export default withLocationTrack(t.pricing.summary.sectionTitle)(Summary);
