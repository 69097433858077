import React, { FunctionComponent } from 'react';
import { Text } from 'mymoria-ui/components';
import { global as t } from 'data';
import { sprintf } from 'utils';
import { styled, css } from 'mymoria-ui';
import Price, { PriceProps } from 'components/Price';

interface PriceItemProps extends PriceProps {
  description: string;
  discount?: number;
}

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
`;

const StyledText = styled(Text)`
  margin: 0;
`;

const DiscountValue = styled.span(
  ({ theme: { colors, font } }) => css`
    color: ${colors.primary};
    font-weight: ${font.weight.bold};
    font-size: ${font.size.large};
    margin-left: 0.5rem;
  `,
);

const CartItem: FunctionComponent<PriceItemProps> = ({ description, discount, ...props }) => (
  <Item {...props}>
    <StyledText>
      {description}
      {discount && (
        <DiscountValue>{sprintf(t.pricing.cart.discountText, { discount })}</DiscountValue>
      )}
    </StyledText>
    <Price {...props} />
  </Item>
);

export default CartItem;
