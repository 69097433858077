import React from 'react';
import { Form, Formik } from 'formik';
import { Card, Col, FormFooter, Grid, Loader, Row, Text } from 'mymoria-ui/components';
import { TextareaField } from 'forms/fields';
import { global as t } from 'data';
import useErrorMessage, { Error } from 'hooks/useErrorMessage';
import { updateProvision } from 'actions/provision';
import { useHistory, useToast } from 'mymoria-ui/hooks';
import { Page, Section } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { styled } from 'mymoria-ui';
import * as yup from 'yup';

interface FormState {
  graveTypeNote: string;
}

const StyledText = styled(Text)`
  width: 50%;
`;

const GraveTypeNotePage = () => {
  const dispatch = useDispatch();
  const { handlePush, push } = useHistory();
  const { addToast } = useToast();
  const { graveTypeNote } = useSelector(({ provision }) => provision);
  const { error, setError } = useErrorMessage({ messages: t.apiErrors.general });

  const handleSubmit = ({ graveTypeNote }: FormState) => {
    if (error) {
      setError(null);
    }

    return dispatch(updateProvision({ graveTypeNote }))
      .then(() => {
        addToast(t.graveTypeNote.successMsg, 'success', { autoDismiss: false });
        push('/overview/offer', { restoreScrollPosition: true });
      })
      .catch((err: Error) => {
        setError(err);
      });
  };

  return (
    <Page footer="none" headerTitle={t.offer.headerTitle}>
      <Formik
        onSubmit={handleSubmit}
        initialValues={{ graveTypeNote: graveTypeNote || '' }}
        validationSchema={yup.object().shape({
          graveTypeNote: yup.string().max(500, t.validators.max),
        })}
      >
        {({ isSubmitting, submitForm, dirty, isValid }) => (
          <>
            <Section
              title={t.graveTypeNote.header}
              onGoBack={handlePush('/overview/offer', { restoreScrollPosition: true })}
              goBackLabel={t.labels.offer}
            >
              <Grid>
                <Row justifyContent="center" pb="5rem">
                  <Col width={[1, 10 / 12, 2 / 3]} px={0}>
                    <Card title={t.graveTypeNote.title}>
                      <StyledText marginBottom="3rem">{t.graveTypeNote.text}</StyledText>
                      <Form noValidate>
                        {isSubmitting && <Loader overlay />}
                        <TextareaField
                          name="graveTypeNote"
                          label={t.graveTypeNote.placeholder}
                          extra={t.graveTypeNote.upTo500}
                        />
                      </Form>
                    </Card>
                  </Col>
                </Row>
              </Grid>
            </Section>
            <FormFooter
              labelCancel={t.labels.cancel}
              labelSubmit={t.labels.submit}
              onSubmit={submitForm}
              onCancel={handlePush('/overview/offer', { restoreScrollPosition: true })}
              disabledSubmit={!dirty || !isValid}
            />
          </>
        )}
      </Formik>
    </Page>
  );
};

export default GraveTypeNotePage;
