import React, { FunctionComponent } from 'react';
import * as t from 'data';
import { WizardComponentProps } from 'react-albus';
import { Markdown, WizardCard, SelectButton, Hint } from 'mymoria-ui/components';
import { styled } from 'mymoria-ui/utils';
import { HDHPaymentType, useHDHState } from './HDHState';

type Button<T> = {
  value: T;
  label: string;
  icon: string;
  hint?: string;
  validate?: (age: number) => boolean;
};

const paymentTypeButtons: Button<HDHPaymentType>[] = [
  {
    value: 'treuhand',
    label: t.financial.insurances.treuhand.title,
    icon: '/img/partners/treuhand.png',
  },
  // we will need this later
  // {
  //   value: 'onetime',
  //   label: t.financial.hdh.payment.onetime,
  //   icon: '/img/partners/hdh.png',
  //   hint: t.financial.insurances.hdh.oneTime,
  //   validate: (age: number) => age > 18 && age < 75,
  // },
  // {
  //   value: 'monthly',
  //   label: t.financial.hdh.payment.monthly,
  //   icon: '/img/partners/hdh.png',
  //   hint: t.financial.insurances.hdh.monthly,
  //   validate: (age: number) => age > 18 && age < 65,
  // },
];

const StyledSelectButton = styled(SelectButton)`
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
    background-color: #ccc;
    border-radius: 50%;
    margin: auto 1rem auto 0;
    object-fit: contain;
  }
`;

const StyledHint = styled(Hint)`
  display: flex;
  margin-left: 1rem;
`;

const PaymentTypeStep: FunctionComponent<WizardComponentProps> = ({
  wizard: { push, step, steps },
}) => {
  const [{ paymentType, age }, setState] = useHDHState();

  const handleSubmit = (paymentType: HDHPaymentType) => {
    setState({ paymentType });
    push(paymentType === 'treuhand' ? 'treuhand' : 'tariff');
  };

  return (
    <WizardCard
      title={t.financial.hdh.payment.title}
      onClickPrev={() => push('intro')}
      onClickNext={() => push(paymentType === 'treuhand' ? 'treuhand' : 'tariff')}
      labelPrev={t.global.labels.back}
      labelNext={t.global.labels.continue}
      disabledNext={!paymentType}
      steps={steps.length - 2}
      step={steps.indexOf(step)}
    >
      <Markdown>{t.financial.hdh.payment.description}</Markdown>
      {paymentTypeButtons.map(({ value, label, icon, hint, validate }) => {
        const isValid = validate ? validate(age) : true;

        return (
          <StyledSelectButton
            key={value}
            selected={value === paymentType}
            onClick={() => handleSubmit(value)}
            disabled={!isValid}
          >
            <img src={icon} alt={`${value}-icon`} />
            {label}
            {!isValid && hint && <StyledHint>{hint}</StyledHint>}
          </StyledSelectButton>
        );
      })}
    </WizardCard>
  );
};

export default PaymentTypeStep;
