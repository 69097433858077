import React, { FunctionComponent, useEffect, useLayoutEffect, useState } from 'react';
import { WizardComponentProps } from 'react-albus';
import { Button, Markdown, Text, WizardCard, Loader } from 'mymoria-ui/components';
import { financial, global } from 'data';
import { api, sprintf } from 'utils';
import { formatPrice } from 'helpers';
import { css, styled } from 'mymoria-ui/utils';
import { useSelector } from 'hooks';
import { useHDHState } from './HDHState';

type HDHTariff = {
  sum: string;
  payout: string;
  fee: string;
  name: string;
};

const Widget = styled.div(
  ({ theme: { colors } }) => css`
    background-color: ${colors.primary};
    min-height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    margin: 0 0 1.5rem 0;

    p {
      color: white;
    }
  `,
);

const Total = styled(Text)`
  margin: 0;
`;

const Price = styled.span(
  ({
    theme: {
      components: { headings },
    },
  }) => css`
    ${headings.h1};
    color: white;
    display: inline-block;
    margin-right: 0.5rem;
  `,
);

const TariffStep: FunctionComponent<WizardComponentProps> = ({ wizard: { push, steps, step } }) => {
  const [{ age, totalFuturePrice, paymentType }] = useHDHState();
  const [isLoading, setLoading] = useState(false);
  const [tariff, setTariff] = useState<HDHTariff>({
    fee: '0',
    sum: '0',
    payout: '0',
    name: '',
  });
  const provisionBirth = useSelector(({ provision }) => provision.provisionBirth);

  useLayoutEffect(() => {
    if (!paymentType) {
      push('paymentType');
    }
  }, [paymentType, push]);

  useEffect(() => {
    if (totalFuturePrice && paymentType && provisionBirth) {
      setLoading(true);
      api
        .get<HDHTariff>('/provisions/insurances/hdh/tariff', {
          params: {
            paymentType,
            provisionBirth,
            costsRounded: totalFuturePrice,
          },
        })
        .then(({ data }) => {
          setTariff(data);
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  }, [totalFuturePrice, paymentType, provisionBirth]);

  return (
    <WizardCard
      title={financial.hdh.results.title}
      onClickPrev={() => push('paymentType')}
      onClickNext={() => push('contact')}
      labelPrev={global.labels.back}
      labelNext={global.labels.continue}
      steps={steps.length - 2}
      step={steps.indexOf(step)}
    >
      <Markdown>
        {sprintf(
          paymentType === 'onetime'
            ? financial.hdh.results.onetimeDescription
            : financial.hdh.results.monthlyDescription,
          {
            age,
            totalFuturePrice: formatPrice(totalFuturePrice),
          },
        )}
      </Markdown>
      <Widget>
        {isLoading ? (
          <Loader delay={500} />
        ) : (
          <>
            <Text>
              <Price>{tariff.fee} €</Price>
              {paymentType === 'onetime'
                ? financial.hdh.results.onetime
                : financial.hdh.results.perMonth}
            </Text>
            <Total>
              {sprintf(financial.hdh.results.expectedPayout, {
                expectedPayout: tariff.payout,
              })}
            </Total>
          </>
        )}
      </Widget>
      <Button style={{ margin: 'auto' }} outline onClick={() => push('contact')}>
        {financial.hdh.results.button}
      </Button>
    </WizardCard>
  );
};

export default TariffStep;
