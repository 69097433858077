import { offer } from 'data';
import { formatPrice } from 'helpers';
import { useSelector } from 'hooks';
import { Card, CustomOfferItemPreview } from 'mymoria-ui/components';
import { styled } from 'mymoria-ui/utils';
import React from 'react';
import OptionalProductPreview from './OptionalProductPreview';
import OptionalServicePreview from './OptionalServicePreview';

const StyledCard = styled(Card)`
  // select card title
  & > div:first-of-type {
    & + hr {
      display: none;
    }
  }

  & > hr {
    & + hr {
      display: none;
    }
    &:last-child {
      display: none;
    }
  }
`;

const SelectedOptionalItemsCard = () => {
  const products = useSelector(({ provision }) => provision.optionalProducts);
  const services = useSelector(({ provision }) => provision.optionalServices);
  const funeralType = useSelector(({ provision }) => provision.funeralType);
  const extras = useSelector(({ provision }) => provision.extras);
  const items = [
    ...services.map(id => <OptionalServicePreview key={id} id={id} />),
    ...products.map(id => <OptionalProductPreview key={id} id={id} />),
    ...extras.map(({ name, price, description, picture }) => (
      <CustomOfferItemPreview
        name={name}
        funeralType={funeralType}
        price={formatPrice(price, { gross: true })}
        image={picture?.url}
        shortDescription={description}
      />
    )),
  ].flatMap((node, index, array) =>
    index === array.length - 1 ? node : [node, <hr key={`separator-${index}`} />],
  );

  if (items.length === 0) {
    return null;
  }

  return <StyledCard title={offer.offerDetails.othersTitle}>{items}</StyledCard>;
};

export default SelectedOptionalItemsCard;
