import { Page, Section } from 'components';
import { Col, Row, Grid } from 'mymoria-ui/components';
import React, { useEffect, useState } from 'react';
import { ProductCategory } from 'mymoria-types';
import { NotFoundPage } from 'containers/index';
import { useDispatch, useSelector } from 'hooks';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { fetchAlternativeProducts } from 'actions/products';
import { useHistory, useToast } from 'mymoria-ui/hooks';
import * as t from 'data';
import Product from './Product';

interface UrlParams {
  category: ProductCategory;
}

const ProductsPage = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { handlePush } = useHistory();
  const { category } = useParams<UrlParams>();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const products = useSelector(({ products }) => products.alternatives[category]);

  useEffect(() => {
    if (isEmpty(products)) {
      setIsLoading(true);
      dispatch(fetchAlternativeProducts(category))
        .catch(error => {
          addToast(error.message);
          setError(true);
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, setIsLoading, products, category, addToast]);

  if (error) {
    return <NotFoundPage />;
  }

  return (
    <Page
      withNavigation={false}
      footer="none"
      isLoading={isLoading}
      headerTitle={t.offer.headerTitle}
    >
      <Section
        title={t.offer.productTitle[category]}
        meta={`${products?.length} Artikel`}
        onGoBack={handlePush('/overview/offer', { restoreScrollPosition: true })}
        goBackLabel={t.global.labels.offer}
      >
        <Grid>
          <Row flexWrap="wrap">
            {products?.map(id => (
              <Col key={id} width={[1 / 2, 1 / 3, 1 / 3, 1 / 4]} p={1}>
                <Product id={id} />
              </Col>
            ))}
          </Row>
        </Grid>
      </Section>
    </Page>
  );
};

export default ProductsPage;
