import React, { FunctionComponent } from 'react';
import { financial } from 'data';
import { Button, Icon, Markdown, Row, Text, WizardIntroCard } from 'mymoria-ui/components';
import { WizardComponentProps } from 'react-albus';
import { sprintf } from 'utils';
import { formatPrice } from 'helpers';
import { useWundWState } from './WundWState';

const IntroStep: FunctionComponent<WizardComponentProps> = ({ wizard: { next } }) => {
  const [{ totalFuturePrice }] = useWundWState();

  return (
    <WizardIntroCard>
      <Markdown>
        {sprintf(financial.wundw.intro.description, {
          totalFuturePrice: formatPrice(totalFuturePrice),
        })}
      </Markdown>
      <Row justifyContent="center" alignItems="center" p={4}>
        <Icon.Duration />
        <Text ml={1} fontWeight="bold">
          {financial.wundw.intro.estimatedTime}
        </Text>
      </Row>
      <Button onClick={next}>{financial.wundw.intro.next}</Button>
    </WizardIntroCard>
  );
};

export default IntroStep;
