import { Dispatch } from 'redux';
import { createAction } from 'typesafe-actions';
import { EntitiesState, GetState } from 'types';
import { lettersAPI } from 'api';
import { normalize, schema } from 'normalizr';

const letter = new schema.Entity('letters');

interface FetchAction {
  entities: Pick<EntitiesState, 'letters'>;
  result: string[];
}

interface CreateAction {
  entities: Pick<EntitiesState, 'letters'>;
  result: string;
}

const fetchLettersAction = createAction('FETCH_LETTERS')<FetchAction>();
const createLetterAction = createAction('CREATE_LETTER')<CreateAction>();
const updateLetterAction = createAction('UPDATE_LETTER')<CreateAction>();

export default {
  fetchLetters: fetchLettersAction,
  createLetter: createLetterAction,
  updateLetter: updateLetterAction,
};

export const fetchLetters = () => (dispatch: Dispatch, getState: GetState) => {
  const { provision } = getState();

  return lettersAPI
    .fetch(provision.id)
    .then(data => dispatch(fetchLettersAction(normalize(data, [letter]))));
};

export const createLetter = () => (dispatch: Dispatch, getState: GetState) => {
  const { provision } = getState();

  return lettersAPI
    .create(provision.id)
    .then(data => dispatch(createLetterAction(normalize(data, letter))));
};

// todo: fix any
export const updateLetter = (letterId: string, data: any) => (dispatch: Dispatch) =>
  lettersAPI
    .update(letterId, data)
    .then(data => dispatch(updateLetterAction(normalize(data, letter))));
