import { useState } from 'react';

type StorageType = 'localStorage' | 'sessionStorage';

export default (storage: StorageType) => <T>(
  key: string,
  initialState: T | (() => T),
): [T, (value: T | ((prevValue: T) => T)) => void] => {
  const getStateFromStore = () => {
    try {
      const item = window[storage].getItem(key);

      if (item) {
        return JSON.parse(item);
      }
    } catch (err) {
      console.log(err.message);
    }
  };

  const storeState = (item: any) => {
    try {
      window[storage].setItem(key, JSON.stringify(item));
    } catch (err) {
      console.log(err.message);
    }
  };

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    const storedState = getStateFromStore();

    if (storedState) {
      return storedState;
    }

    const state = initialState instanceof Function ? initialState() : initialState;
    storeState(state);

    return state;
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | ((prevValue: T) => T)) => {
    const valueToStore = value instanceof Function ? value(storedValue) : value;
    // Save state
    setStoredValue(valueToStore);
    // Save to local storage
    storeState(valueToStore);
  };

  return [storedValue, setValue];
};
