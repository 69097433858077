import React, { FunctionComponent, useState, useCallback } from 'react';
import ToolBarNavigation from './ToolBarNavigation';
import ToolBarStyles from './ToolBarStyles';
import { LetterStyles } from 'mymoria-types';
import { mq, styled } from 'mymoria-ui/utils';

interface ToolBarProps {
  styles: LetterStyles;
  onStylesChange: (styles: LetterStyles) => void;
  onZoomChange?: () => void;
  onPageChange?: () => void;
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  left: 0;

  ${mq({
    bottom: ['60px', '120px'],
  })}
`;

const ToolBar: FunctionComponent<ToolBarProps> = ({ styles, onStylesChange }) => {
  const [mode, setMode] = useState(true);
  const setModeWrapper = useCallback(() => setMode(prevState => !prevState), []);

  return (
    <Wrapper>
      {mode ? (
        <ToolBarNavigation onToolBarChange={setModeWrapper} />
      ) : (
        <ToolBarStyles styles={styles} onChange={onStylesChange} onClose={setModeWrapper} />
      )}
    </Wrapper>
  );
};

export default ToolBar;
