import React, { useState, useEffect, useRef } from 'react';
import { Letter } from 'mymoria-types';
import { styled } from 'mymoria-ui';
import { loadLetterFonts } from 'utils';

const StyledCanvas = styled.canvas`
  width: 100%;
`;

const Convas = ({ content }: Pick<Letter, 'content'>) => {
  const [[width, height]] = useState<[number, number]>([400, 120]);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvasObj = canvasRef.current;
    if (canvasObj) {
      const ctx = canvasObj.getContext('2d');
      if (ctx) {
        loadLetterFonts().then(() => {
          ctx.clearRect(0, 0, width, height);
          ctx.fillStyle = content?.styles.color || '#333';
          ctx.font = `${content?.styles.fontSize}px ${content?.styles.fontFamily}`;
          const lines = content?.text.split('\n') || [];
          lines.map((line, index) =>
            ctx.fillText(line, 0, (content?.styles.fontSize || 0 * 10) * (index + 1)),
          );
          ctx.save();
          ctx.restore();
        });
      }
    }
  });

  return <StyledCanvas ref={canvasRef} width={width} height={height} />;
};

export default Convas;
