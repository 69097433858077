import { global } from 'data';
import { Dispatch } from 'redux';
import { BasicInformation, EntitiesState, GetState, Provision, SignupValues } from 'types';
import {
  Discount,
  FuneralPlan,
  PaymentInfo,
  Provision as ProvisionRaw,
  Proposal,
} from 'mymoria-types';
import { createAction } from 'typesafe-actions';
import { api } from 'utils';
import { productAPI, proposalAPI, serviceAPI } from 'api';
import { find, head, map, pick } from 'lodash';
import { normalize, schema } from 'normalizr';
import { findAnonymousBasicService, findBasicService, getOptionalDefaultProductId } from 'helpers';
import moment from 'moment';
import config from 'config';

const {
  api: { timeout },
} = config;

const product = new schema.Entity('products');
const service = new schema.Entity('services');
const funeralSite = new schema.Entity('funeralSites');
const provisionSchema = {
  provision: {
    funeralSite,
    basicServices: [service],
    optionalServices: [service],
    basicProducts: [product],
    optionalProducts: [product],
  },
  products: {
    basic: [product],
    optional: [product],
    defaults: {
      basic: [product],
      optional: [product],
    },
  },
  services: {
    basic: [service],
    optional: [service],
  },
};

type EmptyProvision = Pick<
  Provision,
  'userEmail' | 'proposalId' | 'referrer' | 'couponCode' | 'financePartner' | 'salesPersonId'
>;

interface ProvisionAction {
  entities: Pick<EntitiesState, 'products' | 'services' | 'funeralSites'>;
  result: {
    provision: Provision;
    products: {
      basic: string[];
      optional: string[];
      defaults: {
        basic: string[];
        optional: string[];
      };
    };
    services: {
      basic: string[];
      optional: string[];
    };
  };
}

interface AddOfferItem {
  id: string;
  price: number;
  defaultProductId?: string;
}

interface ChangeOfferItem {
  addedId: string;
  removedId: string;
  price: number;
}

interface LockProductAction {
  isOfferLocked: boolean;
}

interface SwitchSeaBasicService {
  addedServices: string[];
  removedServices: string[];
  addedBasicService: string;
  removedBasicService: string;
  price: number;
  funeralPlan: FuneralPlan;
  graveType: string;
  funeralSite?: string;
}

export const activateProvisionAction = createAction('PROVISION_ACTIVATE')<PaymentInfo>();
const fetchProvisionAction = createAction('PROVISION_FETCH')<ProvisionAction>();
const discountProvisionAction = createAction('PROVISION_DISCOUNT')<Discount>();
const createProvisionAction = createAction('PROVISION_CREATE')<Provision>();
export const removeProvisionAction = createAction('PROVISION_REMOVE')<undefined>();
const updateProvisionAction = createAction('PROVISION_UPDATE')<Partial<Provision>>();
const changeBasicInformationAction = createAction('CHANGE_BASIC_INFORMATION')<ProvisionAction>();
const lockProductAction = createAction('LOCK_OFFER_ITEM')<LockProductAction>();
const addProductAction = createAction('ADD_PRODUCT')<AddOfferItem>();
const removeProductAction = createAction('REMOVE_PRODUCT')<AddOfferItem>();
const changeProductAction = createAction('CHANGE_PRODUCT')<ChangeOfferItem>();
const addServiceAction = createAction('ADD_SERVICE')<AddOfferItem>();
const removeServiceAction = createAction('REMOVE_SERVICE')<AddOfferItem>();
const changeServiceAction = createAction('CHANGE_SERVICE')<ChangeOfferItem>();
const changeFuneralSiteAction = createAction('CHANGE_FUNERAL_SITE')<
  Pick<Provision, 'funeralSite' | 'graveType' | 'price'>
>();
const switchSeaBasicServiceAction = createAction('SWITCH_BASIC_SERVICE')<SwitchSeaBasicService>();
const setFormSubmissionTimeAction = createAction('SET_FORM_SUBMISSION_TIME')<{ time?: string }>();

export default {
  activate: activateProvisionAction,
  create: createProvisionAction,
  discount: discountProvisionAction,
  fetch: fetchProvisionAction,
  remove: removeProvisionAction,
  update: updateProvisionAction,
  changeBasicInformation: changeBasicInformationAction,
  lockProduct: lockProductAction,
  addProduct: addProductAction,
  removeProduct: removeProductAction,
  changeProduct: changeProductAction,
  addService: addServiceAction,
  removeService: removeServiceAction,
  changeService: changeServiceAction,
  changeFuneralSite: changeFuneralSiteAction,
  switchSeaBasicService: switchSeaBasicServiceAction,
  setFormSubmissionTime: setFormSubmissionTimeAction,
};

// export const activateProvision = (payload: PaymentInfo) => (
//   dispatch: Dispatch,
//   getState: GetState,
// ) => {
//   const {
//     provision: { id },
//   } = getState();
//
//   return api.put<PaymentInfo>(`/provisions/${id}/activate`, payload).then(({ data }) => {
//     dispatch(activateProvisionAction(data));
//     return data;
//   });
// };
export const createProposal = ({
  email,
  firstname,
  lastname,
  place,
  ...contactData
}: Omit<SignupValues, 'password'>) => async (dispatch: Dispatch, getState: GetState) => {
  const {
    tracking: {
      userID,
      sessionID,
      utmCampaign,
      utmMedium,
      utmSource,
      clientidGclid,
      sourceCookie,
      clientId,
    },
    provision: { formSubmissionTime },
    registration,
  } = getState();

  // Delay another request in case the previous one haven't finished yet
  const now = new Date().getTime();
  if (formSubmissionTime) {
    const timeDifference = now - new Date(formSubmissionTime).getTime();
    if (timeDifference < timeout) {
      await new Promise(resolve => setTimeout(resolve, timeout - timeDifference));
    }
  }
  dispatch(setFormSubmissionTimeAction({ time: new Date().toISOString() }));

  const data = await api.post<Proposal & { lastVersionCreatedAt?: number }>('/proposals', {
    proposal: {
      ...registration,
      ...registration.place,
      ...contactData,
      utmCampaign,
      utmMedium,
      utmSource,
      clientidGclid,
      sourceCookie,
      clientId,
      ...place,
      firstname,
      lastname,
      relationship: 'myself',
      concernType: 'provision',
      email: email.toLowerCase(),
      provisionBirth: moment(contactData.provisionBirth, config.dateFormat.view)
        .set({ hour: 12 })
        .toISOString(),
    },
    meta: { userID, sessionID },
  });

  dispatch(setFormSubmissionTimeAction({ time: undefined }));

  return data;
};

export const createProvision = (provision: EmptyProvision) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const {
    provision: { formSubmissionTime },
  } = getState();

  // Delay another request in case the previous one haven't finished yet
  const now = new Date().getTime();
  if (formSubmissionTime) {
    const timeDifference = now - new Date(formSubmissionTime).getTime();
    if (timeDifference < timeout) {
      await new Promise(resolve => setTimeout(resolve, timeout - timeDifference));
    }
  }
  dispatch(setFormSubmissionTimeAction({ time: new Date().toISOString() }));

  return api
    .post<Provision & { lastVersionCreatedAt?: number }>(`/provisions`, {
      ...provision,
      userEmail: provision.userEmail.toLowerCase(),
    })
    .then(({ data }) => {
      dispatch(createProvisionAction(data));
      dispatch(setFormSubmissionTimeAction({ time: undefined }));

      return data;
    });
};

export const discountProvision = (data: Pick<Discount, 'couponCode'>) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const {
    provision: { id },
  } = getState();

  return api
    .put<Discount>(`/provisions/${id}/coupon`, data)
    .then(({ data }) => dispatch(discountProvisionAction(data)));
};

export const fetchProvision = (id: string = 'me') => (dispatch: Dispatch) =>
  api
    .get<ProvisionRaw>(`/provisions/${id}`)
    .then(({ data: { products, services, ...provision } }) => {
      const { code, funeralPlan, funeralType, customizations } = provision;

      return Promise.all([
        productAPI.fetchSelected(
          code,
          funeralPlan,
          funeralType,
          map(products, 'id'),
          customizations,
        ),
        serviceAPI.fetchSelected(code, funeralType, map(services, 'id'), customizations),
      ]).then(([products, services]) =>
        dispatch(
          fetchProvisionAction(
            normalize(
              {
                products: products.all,
                services: services.all,
                provision: { ...provision, ...products.selected, ...services.selected },
              },
              provisionSchema,
            ),
          ),
        ),
      );
    });

export const removeProvision = () => (dispatch: Dispatch, getState: GetState) => {
  const {
    provision: { id },
  } = getState();

  return api.delete(`/provisions/${id}`).then(() => dispatch(removeProvisionAction()));
};

export const updateProvision = (payload: Partial<Provision>) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const {
    provision: { id },
  } = getState();

  return api.put<Provision>(`/provisions/${id}`, payload).then(({ data }) => {
    dispatch(updateProvisionAction({ ...payload, ...data }));
    return { ...payload, ...data };
  });
};

// @ts-ignore
// export const submitFuneralCosts = ({ additionalCosts, ...rest }) => (dispatch, getState) => {
//   const {
//     provision: { id },
//   } = getState();
//
//   return dispatch({
//     type: 'UPDATE_FUNERAL_COSTS',
//     payload: api
//       .put(`/provisions/${id}`, {
//         payload: {
//           ...rest,
//           additionalCosts: Object.values(additionalCosts),
//         },
//         addToken: true,
//       })
//       // @ts-ignore
//       .then(({ updatedAt }) => {
//         // When estimatedPrice prop is not defined - add it with empty string value.
//         // This is because DynamoDB does not support empty strings, but we need to
//         // overwrite the existing estimatedPrice value with '' during state update (mergeDeep)
//         Object.keys(additionalCosts).forEach(key => {
//           if (!additionalCosts[key].estimatedPrice) {
//             additionalCosts[key].estimatedPrice = '';
//           }
//         });
//
//         return Map({ updatedAt, additionalCosts, ...rest });
//       })
//       .catch(() => Promise.reject(global.apiErrors.general)),
//   });
// };

export const activatePlan = (payload: PaymentInfo & Pick<Provision, 'paymentAcceptedPlan'>) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const {
    provision: { id },
  } = getState();

  return api.put<PaymentInfo>(`/provisions/${id}/activate`, payload).then(({ data }) => {
    dispatch(activateProvisionAction(data));
    return data;
  });
};

// dispatch({
//   type: 'ACTIVATE_PLAN',
//   payload: api
//     .put(`/provisions/${id}/activate`, {
//       payload: { paymentAcceptedPlan },
//       addToken: true,
//     })
//     // @ts-ignore
//     .then(({ paymentAcceptedAt, paymentContractMode, paymentPriceGuarantee }) => ({
//       paymentAcceptedPlan,
//       paymentAcceptedAt,
//       paymentContractMode,
//       paymentPriceGuarantee,
//     }))
//     .catch(() => Promise.reject(global.apiErrors.general)),
// });

// @ts-ignore
export const deactivatePlan = () => (dispatch, getState) => {
  const {
    provision: { id },
  } = getState();

  dispatch({
    type: 'DEACTIVATE_PLAN',
    payload: api
      .put(`/provisions/${id}/deactivate`)
      // .then(() => ({
      //   paymentAcceptedAt: null,
      //   paymentAcceptedPlan: '',
      //   paymentContractMode: '',
      //   paymentPriceGuarantee: false,
      // }))
      .catch(() => Promise.reject(global.apiErrors.general)),
  });
};

export const changeBasicInformation = (data: BasicInformation) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const {
    provision: { proposalId },
  } = getState();

  return proposalAPI
    .changeBasicInformation(proposalId, data)
    .then(
      ({
        proposal: { products: selectedProducts, services: selectedServices, ...proposal },
        products: allProducts,
        services: allServices,
      }) => {
        const products = productAPI.parse(selectedProducts, allProducts);
        const services = serviceAPI.parse(selectedServices, allServices);

        return dispatch(
          changeBasicInformationAction(
            normalize(
              {
                products: products.all,
                services: services.all,
                provision: {
                  ...proposal,
                  ...products.selected,
                  ...services.selected,
                },
              },
              provisionSchema,
            ),
          ),
        );
      },
    );
};

export const addProduct = (id: string) => async (dispatch: Dispatch, getState: GetState) => {
  const {
    provision: { proposalId, isOfferLocked },
  } = getState();

  if (isOfferLocked) return;

  await Promise.resolve(dispatch(lockProductAction({ isOfferLocked: true })));

  try {
    const {
      data: { price },
    } = await api.put(`/proposals/${proposalId}/product`, { id });
    await Promise.resolve(dispatch(addProductAction({ id, price })));
    return dispatch(lockProductAction({ isOfferLocked: false }));
  } catch (e) {
    return dispatch(lockProductAction({ isOfferLocked: false }));
  }
};

export const removeProduct = (id: string) => async (dispatch: Dispatch, getState: GetState) => {
  const {
    provision: { proposalId, isOfferLocked },
    products,
  } = getState();

  if (isOfferLocked) return;

  await Promise.resolve(dispatch(lockProductAction({ isOfferLocked: true })));

  const defaultProductId = getOptionalDefaultProductId(products, id);

  try {
    const {
      data: { price },
    } = await api.delete(`/proposals/${proposalId}/product`, { data: { id } });
    await Promise.resolve(
      dispatch(
        removeProductAction({
          id,
          price,
          ...(defaultProductId !== id ? { defaultProductId } : undefined),
        }),
      ),
    );
    return dispatch(lockProductAction({ isOfferLocked: false }));
  } catch (e) {
    return dispatch(lockProductAction({ isOfferLocked: false }));
  }
};

export const changeProduct = (addedId: string, removedId: string) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const {
    provision: { proposalId },
  } = getState();

  return api
    .post(`/proposals/${proposalId}/product`, { addedId, removedId })
    .then(({ data: { price } }) => dispatch(changeProductAction({ addedId, removedId, price })));
};

export const addService = (id: string) => async (dispatch: Dispatch, getState: GetState) => {
  const {
    provision: { proposalId, isOfferLocked },
  } = getState();
  if (isOfferLocked) return;

  await Promise.resolve(dispatch(lockProductAction({ isOfferLocked: true })));

  try {
    const {
      data: { price },
    } = await api.put(`/proposals/${proposalId}/service`, { id });

    await Promise.resolve(dispatch(addServiceAction({ id, price })));
    return dispatch(lockProductAction({ isOfferLocked: false }));
  } catch (e) {
    return dispatch(lockProductAction({ isOfferLocked: false }));
  }
};

export const removeService = (id: string) => async (dispatch: Dispatch, getState: GetState) => {
  const {
    provision: { proposalId, isOfferLocked },
  } = getState();

  if (isOfferLocked) return;

  await Promise.resolve(dispatch(lockProductAction({ isOfferLocked: true })));

  try {
    const {
      data: { price },
    } = await api.delete(`/proposals/${proposalId}/service`, { data: { id } });

    await Promise.resolve(dispatch(removeServiceAction({ id, price })));
    return dispatch(lockProductAction({ isOfferLocked: false }));
  } catch (e) {
    return dispatch(lockProductAction({ isOfferLocked: false }));
  }
};

export const changeService = (addedId: string, removedId: string) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const {
    provision: { proposalId, isOfferLocked },
  } = getState();

  if (isOfferLocked) return;

  await Promise.resolve(dispatch(lockProductAction({ isOfferLocked: true })));

  try {
    const {
      data: { price },
    } = await api.post(`/proposals/${proposalId}/service`, { addedId, removedId });

    await Promise.resolve(dispatch(changeServiceAction({ addedId, removedId, price })));
    return dispatch(lockProductAction({ isOfferLocked: false }));
  } catch (e) {
    return dispatch(lockProductAction({ isOfferLocked: false }));
  }
};

export const switchSeaBasicService = (funeralSite?: string, graveType?: string) => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const { provision, entities, services } = getState();
  if (provision.isOfferLocked) return;
  await Promise.resolve(dispatch(lockProductAction({ isOfferLocked: true })));

  const basicServices = pick(entities.services, services.basic);
  const basicService = findBasicService(basicServices);
  const basicAnonymousService = findAnonymousBasicService(basicServices);
  const addonId =
    find(entities.services, ({ identifier }) => identifier === 'sea-addon-1')?.id || '';

  if (!basicService || !basicAnonymousService) {
    return Promise.reject();
  }

  const anonymous = graveType
    ? graveType === 'SU'
    : head(provision.basicServices) === basicService?.id;

  const addedBasicService = anonymous ? basicAnonymousService.id : basicService.id;
  const removedBasicService = anonymous ? basicService.id : basicAnonymousService.id;
  const addedOptionalServices = anonymous ? [] : [addonId];
  const removedOptionalServices = anonymous ? [addonId] : [];

  try {
    const {
      data: { price },
    } = await api.post(`/proposals/${provision.proposalId}/switchBasicService`, {
      removeIds: [removedBasicService, ...removedOptionalServices],
      addIds: [addedBasicService, ...addedOptionalServices],
      funeralSite,
      anonymous,
    });

    await Promise.resolve(
      dispatch(
        switchSeaBasicServiceAction({
          addedBasicService: addedBasicService || '',
          removedBasicService: removedBasicService || '',
          addedServices: addedOptionalServices,
          removedServices: removedOptionalServices,
          price,
          funeralPlan: anonymous ? 'basic' : 'high',
          graveType: graveType || (anonymous ? 'SU' : 'SB'),
          funeralSite,
        }),
      ),
    );
    return dispatch(lockProductAction({ isOfferLocked: false }));
  } catch (e) {
    return dispatch(lockProductAction({ isOfferLocked: false }));
  }
};

export const changeFuneralSite = (funeralSite: string, graveType: string) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const {
    provision: { proposalId },
  } = getState();

  return api
    .put(`/proposals/${proposalId}/funeralSite`, { funeralSiteId: funeralSite, graveType })
    .then(({ data: { price } }) =>
      dispatch(
        changeFuneralSiteAction({
          price,
          graveType,
          funeralSite,
        }),
      ),
    );
};
