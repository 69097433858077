import React, { FunctionComponent, useLayoutEffect, useState, useEffect } from 'react';
import { mq, styled, css } from 'mymoria-ui';
import { Caption, Card, Col, Heading, Icon, Loader, Row, Text } from 'mymoria-ui/components';
import PlaceSearch, { Place } from 'mymoria-ui/components/Form/PlaceSearch';
import * as t from 'data';
import { fetchFuneralSite } from 'actions/funeralSites';
import { useDispatch, useSelector } from 'hooks';
import { getFuneralSiteById } from 'reducers/entities';
import { FuneralPlan, FuneralType } from 'mymoria-types';
import { sprintf } from 'utils';
import FuneralSiteGraves from './FuneralSiteGraves';
import FuneralSiteDetails from './FuneralSiteDetails';

type InfoBoxMode = 'search' | 'graves' | 'details';

interface FuneralSiteInfoBoxProps {
  funeralType: FuneralType;
  funeralPlan: FuneralPlan;
  onClose: () => void;
  onPlaceChange: (place: Place) => void;
  origined: boolean;
  onGraveTypeChange: (value: string) => void;
  selectedGraveType?: string;
  onFuneralSiteDelete: () => void;
  selectedFuneralSite?: string;
}

const StyledCard = styled(Card)<{ mode: InfoBoxMode }>(
  ({ mode, theme: { components } }) => css`
    && {
      margin: 0;
      padding: 12px;
      position: absolute;
      box-sizing: border-box;
      overflow-y: ${mode === 'search' ? 'initial' : 'auto'};
    }

    #icon-close {
      cursor: pointer;
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    ${mq({
      width: ['100%', 300, 400, 500],
      top: [0, '2.5rem'],
      left: [0, '2.5rem'],
      right: [0, 'unset'],
      bottom: [mode === 'search' ? 'unset' : 0, 'unset'],
      borderRadius: [0, components.cards.borderRadius],
      maxHeight: ['none', '85%'],
      boxShadow: ['none', components.cards.boxShadow],
      backgroundColor: [
        mode === 'search' ? 'transparent' : components.cards.backgroundColor,
        components.cards.backgroundColor,
      ],
    })}
  `,
);

const StyledPlaceSearch = styled(PlaceSearch)`
  margin: 0;
`;

const FuneralSiteInfoBox: FunctionComponent<FuneralSiteInfoBoxProps> = ({
  funeralType,
  funeralPlan,
  onClose,
  onPlaceChange,
  origined,
  onGraveTypeChange,
  selectedGraveType,
  selectedFuneralSite,
  onFuneralSiteDelete,
}) => {
  const dispatch = useDispatch();
  const funeralSite = useSelector(getFuneralSiteById(selectedFuneralSite));
  const [mode, setMode] = useState<InfoBoxMode>('search');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!selectedFuneralSite) {
      setMode('search');
    } else if (origined) {
      setMode('details');
    } else {
      setMode('graves');
    }
  }, [origined, selectedFuneralSite]);

  useLayoutEffect(() => {
    if (selectedFuneralSite && !funeralSite) {
      setLoading(true);
      dispatch(fetchFuneralSite(selectedFuneralSite))
        .catch(err => setError(err.message))
        .finally(() => setLoading(false));
    }
  }, [selectedFuneralSite, funeralSite, dispatch]);

  const handleGraveTypeEdit = () => {
    setMode('graves');
  };

  const handleFuneralSiteDelete = () => {
    onFuneralSiteDelete();
    setMode('search');
  };

  return (
    <StyledCard mode={mode}>
      {mode === 'search' ? (
        <StyledPlaceSearch label={t.offer.funeralSites.placeSearch} onChange={onPlaceChange} />
      ) : (
        <Row flexDirection="column" justifyContent="space-between" minHeight={450}>
          <Icon.Close id="icon-close" onClick={onClose} />
          {loading && <Loader delay={750} />}
          {error && <Text>{error}</Text>}
          {!loading && !error && funeralSite && (
            <>
              <Col width={1}>
                <Heading.Heading3>{funeralSite.title}</Heading.Heading3>
                <Caption
                  light
                >{`${funeralSite.address.street}, ${funeralSite.address.plz} ${funeralSite.address.city}`}</Caption>
              </Col>
              {mode === 'graves' && (
                <FuneralSiteGraves
                  funeralSite={funeralSite}
                  funeralType={funeralType}
                  funeralPlan={funeralPlan}
                  graveType={selectedGraveType}
                  onChange={onGraveTypeChange}
                />
              )}
              {mode === 'details' && (
                <FuneralSiteDetails
                  funeralSite={funeralSite}
                  funeralType={funeralType}
                  graveType={selectedGraveType}
                  onEdit={handleGraveTypeEdit}
                  onDelete={handleFuneralSiteDelete}
                />
              )}
              <Col width={1}>
                {funeralSite.organisation?.name && (
                  <Caption mt={2} light>
                    {sprintf(t.offer.funeralSites.details.organisationName, {
                      source: funeralSite.organisation?.name,
                    })}
                  </Caption>
                )}
              </Col>
            </>
          )}
        </Row>
      )}
    </StyledCard>
  );
};

export default FuneralSiteInfoBox;
