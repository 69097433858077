import { trackUserInteraction } from 'actions/tracking';
import * as t from 'data';
import { useDispatch, useSelector } from 'hooks';
import { formatPrice, getFuneralSitePrice } from 'helpers';
import { isEmpty } from 'lodash';
import { Caption, Card, Markdown, Message, CustomOfferItemPreview } from 'mymoria-ui/components';
import { cdn } from 'mymoria-ui';
import { useHistory } from 'mymoria-ui/hooks';
import React from 'react';
import { isOfferEditable } from 'reducers/provision';
import { getFuneralSiteById } from 'reducers/entities';
import { useRouteMatch } from 'react-router-dom';

const FuneralSiteCard = () => {
  const funeralType = useSelector(({ provision }) => provision.funeralType);
  const graveType = useSelector(({ provision }) => provision.graveType) || '';
  const funeralSiteId = useSelector(({ provision }) => provision.funeralSite);
  const funeralSite = useSelector(getFuneralSiteById(funeralSiteId));
  const graveTypeEntity = useSelector(({ entities }) => entities.graveTypes[graveType]);
  const isEditable = useSelector(isOfferEditable());
  const { push } = useHistory();
  const dispatch = useDispatch();
  const match = useRouteMatch();

  const handleEdit = () => {
    dispatch(trackUserInteraction('Edit Funeral site', funeralType));
    push(`${match.url}/funeral-site`);
  };

  if (isEmpty(funeralSite)) {
    return (
      <Card
        title={t.offer.funeralSitesCard.noFuneralTitle}
        status="incompleted"
        statusLabel={t.global.status['open']}
        onStatusClick={handleEdit}
      >
        <CustomOfferItemPreview
          funeralType={funeralType}
          selected
          image="/img/map.png"
          price=""
          icon="/img/ic_pp-zoom.svg"
          onClick={isEditable ? handleEdit : undefined}
          shortDescription={t.offer.funeralSitesCard.noFuneralDescription}
        />
      </Card>
    );
  }

  const funeralSitePrice = getFuneralSitePrice(funeralSite, graveType);

  return (
    <Card
      title={t.offer.funeralSitesCard.funeralSiteTitle[funeralType]}
      status={funeralSitePrice > 0 ? 'completed' : 'optional'}
      statusLabel={t.global.status[funeralSitePrice > 0 ? 'selected' : 'notIncluded']}
    >
      <CustomOfferItemPreview
        funeralType={funeralType}
        selected
        price={funeralSitePrice > 0 ? formatPrice(funeralSitePrice) : ''}
        extraText={graveTypeEntity?.translations.name}
        name={funeralSite.title}
        image={cdn('www', `/img/funeralSite/${funeralType}.png`)}
        onClick={isEditable ? handleEdit : undefined}
      >
        {funeralSitePrice > 0 ? (
          <>
            {graveTypeEntity && <Markdown source={graveTypeEntity.translations.description} />}
            <Caption light pt={2}>
              {t.offer.funeralSitesCard.funeralSiteCaption}
            </Caption>
          </>
        ) : (
          <Message status="warning">{t.offer.funeralSitesCard.noFuneralSitePrice}</Message>
        )}
      </CustomOfferItemPreview>
    </Card>
  );
};

export default FuneralSiteCard;
