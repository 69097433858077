import React, { FunctionComponent, ReactNode, useCallback, useState } from 'react';
import { LetterContent } from 'mymoria-types';
import { isEqual } from 'lodash';
import { letter as t } from 'data';
import ToolBar from './ToolBar';
import LetterPaper from './LetterPaper';
import LetterInput from './LetterInput';

/**
 * LetterEditor helpers.
 */
interface LetterEditorHelpers {
  /** Submit the form imperatively */
  submitForm(): Promise<void>;
}

/**
 * LetterEditor computed properties. These are read-only.
 */
interface LetterEditorComputedProps {
  /** True if any input has been touched. False otherwise. */
  readonly dirty: boolean;
  /** True if state.errors is empty */
  readonly isValid: boolean;
}

interface LetterEditorState {
  /** whether the form is currently submitting */
  isSubmitting: boolean;
  /** LetterEditor values */
  values: LetterContent;
}

interface LetterEditorProps {
  initialValues?: LetterContent;
  children: (
    props: LetterEditorState & LetterEditorHelpers & LetterEditorComputedProps,
  ) => ReactNode;
  onSubmit: (values: LetterContent) => void | Promise<any>;
}

const LetterEditor: FunctionComponent<LetterEditorProps> = ({
  onSubmit,
  children,
  initialValues: { text: initialText, styles: initialStyles } = {
    text: '',
    styles: { color: '#000', fontSize: 24, fontFamily: 'Kalam' },
  },
}) => {
  const [text, setText] = useState(initialText);
  const [styles, setStyles] = useState(initialStyles);
  const [isSubmitting, setSubmitting] = useState(false);
  const wrappedSetStyles = useCallback(setStyles, []);
  const letterEditorBag = {
    isSubmitting,
    isValid: true,
    dirty: !isEqual(styles, initialStyles) || text !== initialText,
    values: { text, styles },
    submitForm: () => {
      setSubmitting(true);
      return Promise.resolve(onSubmit({ text, styles })).finally(() => setSubmitting(false));
    },
  };

  return (
    <>
      <LetterPaper>
        <LetterInput
          placeholder={t.editor.textAreaPlaceHolder}
          value={text}
          styles={styles}
          onChange={setText}
        />
      </LetterPaper>
      <ToolBar styles={styles} onStylesChange={wrappedSetStyles} />
      {children(letterEditorBag)}
    </>
  );
};

export default LetterEditor;
