import { FieldConfig, useField } from 'formik';
import Textarea, { TextareaProps } from 'mymoria-ui/components/Form/Textarea';
import React, { FunctionComponent, ChangeEvent } from 'react';

const TextareaField: FunctionComponent<TextareaProps & FieldConfig> = props => {
  const [field] = useField(props);

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    field.onChange(e);
    props.onChange && props.onChange(e);
  };

  return <Textarea {...field} {...props} onChange={onChange} />;
};

export default TextareaField;
