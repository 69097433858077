import React, { FunctionComponent } from 'react';
import { Page, Section } from 'components';
import { financial as t } from 'data';
import { RouteComponentProps } from 'react-router';
import { Step, Steps, Wizard } from 'react-albus';
import { WizardGrid } from 'mymoria-ui/components';
import IntroStep from './IntroStep';
import WundwStateProvider from './WundWState';
import PaymentTypeStep from './PaymentTypeStep';
import TariffStep from './TariffStep';
import ConsentStep from './ConsentStep';
import ConsultingStep from './ConsultingStep';
import SummaryStep from './SummaryStep';

const WundwWizard: FunctionComponent<RouteComponentProps> = ({ history, match }) => (
  <Page footer="none">
    <Wizard
      history={history}
      basename={match.url}
      render={({ step }) => (
        <Section
          title={t.wizard[step.id]?.title}
          onGoBack={step.id === 'intro' ? () => history.push('/overview/finance') : undefined}
        >
          <WizardGrid>
            <WundwStateProvider>
              <Steps>
                <Step id="intro" render={wizard => <IntroStep wizard={wizard} />} />
                <Step id="paymentType" render={wizard => <PaymentTypeStep wizard={wizard} />} />
                <Step id="tariff" render={wizard => <TariffStep wizard={wizard} />} />
                <Step id="consent" render={wizard => <ConsentStep wizard={wizard} />} />
                <Step id="consulting" render={wizard => <ConsultingStep wizard={wizard} />} />
                <Step id="summary" render={wizard => <SummaryStep wizard={wizard} />} />
              </Steps>
            </WundwStateProvider>
          </WizardGrid>
        </Section>
      )}
    />
  </Page>
);

export default WundwWizard;
