import React from 'react';
import { global } from 'data';
import { Text } from 'mymoria-ui/components';
import { TextareaField } from 'forms/fields';
import { FormCard } from 'components';

const t = global.wishes.createMode.steps.music;

const WishesStepMusic = () => (
  <FormCard variant="large" title={t.formTitle}>
    <Text>{t.formDescription}</Text>
    <TextareaField cols={60} rows={8} name="music" label={t.placeholder} />
  </FormCard>
);

export default WishesStepMusic;
