import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Card } from 'mymoria-ui/components';
import { useHistory } from 'mymoria-ui/hooks';
import { financial as t } from 'data';
import { Button, Icon, Text } from 'mymoria-ui/components';
import { styled } from 'mymoria-ui';
import { useSelector } from 'hooks';
import { sprintf } from 'utils';
import { formatPrice } from 'helpers';
import { calculateCostEstimation } from 'helpers/index';
import { Wuerttembergische } from 'components/Icons';
import { isWundw } from 'reducers/insurance';

const EstimatedTime = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 160px;

  svg {
    margin-right: 0.5rem;
  }
`;

const StyledWundWWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    display: block;
    width: 200px;
    margin: 0 auto 24px auto;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
`;

const PartnerCard = () => {
  const { handlePush } = useHistory();
  const { url } = useRouteMatch();
  const isWundW = useSelector(isWundw);
  const { price: basePrice, funeralSiteEstimatedPrice, provisionBirth } = useSelector(
    ({ provision }) => provision,
  );

  const price = basePrice + parseInt(funeralSiteEstimatedPrice || '0');
  const { timeToLive, interestRate } = calculateCostEstimation(provisionBirth);
  const priceIncrease = timeToLive * price * interestRate;
  const totalFuturePrice = price + priceIncrease;

  return (
    <Card
      title={t.view.tariff.title}
      icon={
        <EstimatedTime>
          <Icon.Duration />
          <Text fontWeight="bold">
            {isWundW ? t.wundw.intro.estimatedTime : t.hdh.intro.estimatedTime}
          </Text>
        </EstimatedTime>
      }
    >
      <Text textAlign="center" mb={3}>
        {sprintf(t.view.tariff.description, {
          totalFuturePrice: formatPrice(totalFuturePrice),
        })}
      </Text>
      {isWundW && (
        <StyledWundWWrapper>
          <Text>{t.wundw.logoCaption}</Text>
          <Wuerttembergische />
        </StyledWundWWrapper>
      )}
      <StyledButton onClick={handlePush(`${url}/partner`)}>
        {t.wizard.intro.buttonLabel}
      </StyledButton>
    </Card>
  );
};

export default PartnerCard;
