import { useSelector } from 'hooks';
import React from 'react';
import * as t from 'data';
import { Card } from 'mymoria-ui/components';
import OptionalProductPreview from './OptionalProductPreview';
import OptionalServicePreview from './OptionalServicePreview';

const OptionalItemsCard = () => {
  const services = useSelector(({ services: { optional } }) => optional);
  const products = useSelector(({ products: { optional } }) => optional);
  const items = [
    ...products.slice(0, 1).map(id => <OptionalProductPreview key={id} id={id} />),
    ...services.slice(0, 3).map(id => <OptionalServicePreview key={id} id={id} />),
    ...products.slice(1).map(id => <OptionalProductPreview key={id} id={id} />),
    ...services.slice(3).map(id => <OptionalServicePreview key={id} id={id} />),
  ].flatMap((node, index, array) =>
    index === array.length - 1 ? node : [node, <hr key={`separator-${index}`} />],
  );

  if (items.length === 0) {
    return null;
  }

  return (
    <Card title={t.offer.offerDetails.othersTitle} margin={0}>
      {items}
    </Card>
  );
};

export default OptionalItemsCard;
