import { Page, Section } from 'components';
import * as t from 'data';
import { Card, Col, FormFooter, Grid, Heading, Loader, Row, Text } from 'mymoria-ui/components';
import { useHistory, useToast } from 'mymoria-ui/hooks';
import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'hooks';
import { updateProvision } from 'actions/provision';
import { Provision } from 'types';
import { existingInsuranceTypes, insurancePaymentStatus } from 'forms/options';
import { InputField, RadioGroupField, TextareaField } from 'forms/fields';
import SelectLightField from 'forms/fields/SelectLightField';

type FormValues = Pick<
  Provision,
  | 'existingInsuranceCompany'
  | 'existingInsuranceContractNumber'
  | 'existingInsuranceType'
  | 'financialProvisionNote'
  | 'financialProvisionType'
>;

const InsuranceEditPage = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { handlePush } = useHistory();
  const {
    financialProvisionType,
    existingInsuranceCompany,
    existingInsuranceContractNumber,
    existingInsuranceType,
    financialProvisionNote,
  } = useSelector(({ provision }) => provision);

  const initialValues: FormValues = {
    financialProvisionType,
    existingInsuranceCompany,
    existingInsuranceContractNumber,
    existingInsuranceType,
    financialProvisionNote,
  };

  const handleSubmit = (values: FormValues) =>
    dispatch(updateProvision(values))
      .then(() => addToast(t.financial.form.success, 'info'))
      .then(handlePush('/overview/finance'))
      .catch(() => addToast(t.global.apiErrors.general, 'error'));

  return (
    <Page footer="none">
      <Formik
        initialValues={initialValues}
        validationSchema={yup.object().shape({
          financialProvisionType: yup.string().required(),
        })}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, dirty }) => (
          <>
            <Section onGoBack={handlePush('/overview/finance')} title={t.financial.edit.title}>
              <Grid>
                <Row justifyContent="center" pb="5rem">
                  <Col width={[1, 10 / 12, 2 / 3]} px={0}>
                    <Card>
                      {isSubmitting && <Loader overlay />}
                      <Heading.Heading3 mb={2}>{t.financial.edit.subTitle}</Heading.Heading3>
                      <RadioGroupField
                        name="financialProvisionType"
                        values={insurancePaymentStatus}
                        layout="vertical"
                      />
                      <Text>{t.financial.edit.description}</Text>
                      <SelectLightField
                        name="existingInsuranceType"
                        options={existingInsuranceTypes}
                        label={t.financial.form.existingInsuranceType.label}
                        hint={t.financial.form.existingInsuranceType.hint}
                      />
                      <InputField
                        name="existingInsuranceCompany"
                        label={t.financial.form.existingInsuranceCompany.label}
                        hint={t.financial.form.existingInsuranceCompany.hint}
                      />
                      <Text py={1}>{t.financial.edit.optionalNote}</Text>
                      <InputField
                        name="existingInsuranceContractNumber"
                        label={t.financial.form.existingInsuranceContractNumber.label}
                        hint={t.financial.form.existingInsuranceContractNumber.hint}
                      />
                      <TextareaField
                        name="financialProvisionNote"
                        rows={8}
                        label={t.financial.form.financialProvisionNote.label}
                      />
                    </Card>
                  </Col>
                </Row>
              </Grid>
            </Section>
            <FormFooter
              labelCancel={t.global.labels.cancel}
              labelSubmit={t.global.labels.submit}
              onSubmit={submitForm}
              onCancel={handlePush('/overview/finance')}
              disabledSubmit={isSubmitting || !dirty}
            />
          </>
        )}
      </Formik>
    </Page>
  );
};

export default InsuranceEditPage;
