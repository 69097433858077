import { Config } from 'types/config';

const {
  REACT_APP_ENVIRONMENT = 'staging',
  REACT_APP_GATEWAY = 'https://api.mymoria.de',
  REACT_APP_PAYPAL_CLIENT_ID,
  REACT_APP_IDENTITY_POOL_ID,
  REACT_APP_REGION,
  REACT_APP_USER_POOL_ID,
  REACT_APP_USER_POOL_WEB_CLIENT_ID,
  REACT_APP_CREATE_OFFER_URL,
  REACT_APP_GTM_PROPERTY_ID,
  REACT_APP_GOOGLE_MAPS_API_KEY = '',
  REACT_APP_FUNNEL = 'https://angebot.mymoria.de',
} = process.env;

const paypalPlans =
  REACT_APP_ENVIRONMENT === 'staging'
    ? {
        plans: {
          0: 'P-0YT892061R682361HL2YBOOA',
          10: 'P-392695855A113501RL2YBS7A',
          20: 'P-16X458175U186533UL2YBTVA',
          25: 'P-6UD99532LX671600CL2YBWWI',
          50: 'P-2WW299602D961373UL2YBW5Y',
        },
        defaultPlanId: 'P-0YT892061R682361HL2YBOOA',
      }
    : {
        plans: {
          0: 'P-3CS744375N857410HL252ARQ',
          10: 'P-5HB06836XN5225741L252AUY',
          20: 'P-51U06181AF822024HL252AXQ',
          25: 'P-0CX78299HV9143630L252A2I',
          50: 'P-25F762654K247045SL252A4Q',
        },
        defaultPlanId: 'P-3CS744375N857410HL252ARQ',
      };

const config: Config = {
  api: {
    endpoint: REACT_APP_GATEWAY,
    provisionPdfName: 'Bestattungsvorsorge_%(firstname)_%(lastname)_%(date).pdf',
    timeout: 60000,
  },
  funnel: {
    endpoint: REACT_APP_FUNNEL,
  },
  cms: {
    collectionEndpoint: 'https://cms.mymoria.de/api/collections/get',
    origin: process.env.REACT_APP_ORIGIN || 'vorsorge.mymoria.de',
    cookieOrigin: process.env.REACT_APP_COOKIE_ORIGIN || 'vorsorge.mymoria.de',
  },
  animation: {
    opacity: [0, 1],
    duration: 1500,
    delay: 700,
  },
  app: {
    head: {
      defaultTitle: 'Mymoria Bestattungsvorsorge',
      titleTemplate: '%s - Mymoria Bestattungsvorsorge',
    },
  },
  dateFormat: {
    view: 'DD.MM.YYYY',
    api: 'YYYY.MM.DD',
    pdf: 'DD-MM-YY',
  },
  paypal: {
    options: {
      clientId: REACT_APP_PAYPAL_CLIENT_ID || '',
      currency: 'EUR',
      vault: true,
      locale: 'de_DE',
    },
    style: {
      color: 'gold',
    },
    ...paypalPlans,
  },
  phonePattern: /^(0|\+?4)[+\d\s-/()]{3,14}$/,
  awsCognito: {
    identityPoolId: REACT_APP_IDENTITY_POOL_ID,
    region: REACT_APP_REGION,
    userPoolId: REACT_APP_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
  hdhCostLimit: 8000,
  hdhPdfName: ({ firstname, lastname }) => `HDH-Antrag-${firstname}-${lastname}.pdf`,
  mymoriaAboutUsUrl: 'https://www.mymoria.de/de/ueber-uns/mymoria-datenschutzerklaerung',
  mymoriaOfferCreationUrl: REACT_APP_CREATE_OFFER_URL,
  inflation: 2, // percentage per year
  timeToLiveRound: 5, // number of remaining life years, rounded to next 5
  country: 'de',
  currency: 'EUR',
  regex: {
    iban: /^[A-Z]{2}[0-9]{2}\s?[a-zA-Z0-9]{4}\s?[0-9]{4}\s?[0-9]{3}([a-zA-Z0-9]\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,3})?$/,
    bic: /^(?=.{1,11}$)[a-zA-Z]{4}[a-zA-Z]{2}\w{2}(\w{3})?$/,
    alphanumericGerman: /^[a-zA-Z0-9äöüßÄÖÜẞ.\s]*$/i,
    alphaGerman: /^[a-zA-ZäöüßÄÖÜẞ\s]*$/i,
    nameRule: /^[A-Za-zªµºÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿĀāĂăĄąĆćĊċČčĎďĐđĒēĔĕĖėĘęĚěĞğĠġĢģĦħĨĩĪīĬĭĮįİıĴĵĶķĸĹĺĻļĽľĿŀŁłŃńŅņŇňŉŊŋŌōŎŏŐőŒœŔŕŖŗŘřŚśŞşŠšŢţŤťŦŧŨũŪūŮůŰűŲųŴŵŶŷŸŹźŻżŽžƏƠơƯưƷǍǎǏǐǑǒǓǔǞǟǤǥǦǧǨǩǪǫǬǭǮǯǰǴǵǺǻǼǽǾǿȘșȚțȞȟȪȫȮȯȰȱȲȳəʒḂḃḊḋḐḑḞḟḠḡḤḥḦḧḰḱṀṁṄṅṖṗṠṡṢṣṪṫẀẁẂẃẄẅẌẍẎẏẐẑẒẓẞẠạẢảẤấẦầẪẫẬẮắẰằẲẳẴẵẶặẸẹẺẻẼẽẾếỀềỄễỆệỈỉỊịỌọỎỏỐốỒồỖỗỘộỚớỜờỤụỦủỨứỪừỬửỮữỰựỲỳỴỵỶỷỸỹ .-]+$/,
    noLatinChar: /^[A-Za-z-0-9+@._]+$/,
    vorsorgeEmailRule: /^([^@\w]|^)vorsorge(?![\w])+(\+\w+|\w[\w.]*)[\w.]*@mymoria\.de$/,
    confidantPersonEmailRule: /^[^+]+@mymoria\.de$/,
    ccEmailRule: /^[\w]*(\.\w[\w.]*\+cc(?!\w))(?:\.\w+)*@mymoria\.de$/,
    mymoriaDomain: /@mymoria\.de$/,
  },
  gtmPropertyId: REACT_APP_GTM_PROPERTY_ID || '',
  paymentPhoneNumber: '0800 / 803 80000',
  privilegedRoles: ['admin', 'customercare'],
  cognitoRolesProperty: 'cognito:groups',
  paymentContractModes: ['CLASSIC', 'NORMAL', 'FREE'],
  imageDimension: 'preview', // pictures in products, services and extras
  germanTax: 1.19,
  flowerTax: 1.07,
  localStorage: {
    stepper: {
      keys: {
        step: 'form-step',
        values: 'form-values',
      },
      forms: {
        pricing: 'pricing',
        signup: 'signup',
        // add another stepper form here
      },
    },
  },
  dipat: {
    url: (email, firstName, lastName) =>
      `https://mein.dipat.de/extern-registrieren?email=${email}&firstName=${firstName}&lastName=${lastName}&coupon=mymoria19&redirectUrl=auto`,
  },
  tilesOptions: {
    wishes: ['music', 'quote', 'personalNotification'],
    financials: ['financialProvisionNote', 'financialProvisionType', 'additionalCosts'],
    vertrauensperson: ['trustedPerson'],
    offer: ['proposalId'],
    price_guarantee: ['paymentPriceGuarantee'],
  },
  mandatoryProductCategories: ['coffin', 'urn'],
  // @ts-ignore
  planDefaultProductCategories: {
    basic: ['coffin', 'urn'],
    high: ['coffin', 'urn', 'flower'],
    premium: ['coffin', 'urn', 'flower', 'card', 'notice'],
  },
  productsOrder: {
    flower: 1,
    grave_goods: 2,
    card: 3,
    notice: 4,
    urn: 5,
    coffin: 6,
  },
  salesforce: {
    affiliateSourceMaxLength: 255,
  },
  letter: {
    fonts: [
      { value: 'Kalam', label: 'mymoria handwriting 01' },
      { value: 'DancingScript', label: 'mymoria handwriting 02' },
      { value: 'Pangolin', label: 'mymoria handwriting 03' },
      { value: 'ShadowsIntoLightTwo', label: 'mymoria handwriting 04' },
    ],
    fontSizes: [
      { value: 18, label: '18px' },
      { value: 24, label: '24px' },
      { value: 32, label: '32px' },
    ],
    colors: ['#000', '#555', '#241C75', '#3D700B', '#C4006', '#D6003D', '#FD9100'],
    dateFormats: {
      exactDayMonth: { de: 'TT.MM.', en: 'DD.MM.' },
      exactYear: { de: 'JJJJ', en: 'YYYY' },
    },
  },
  trackingCookies: [
    { cookieName: 'sessionID', fieldName: 'sessionID' },
    { cookieName: 'userID', fieldName: 'userID' },
  ],
  trackingParams: [
    'utmMedium',
    'utm_medium',
    'utmSource',
    'utm_source',
    'utmCampaign',
    'utm_campaign',
    'clientidGclid',
    'gclid',
    'sourceCookie',
  ],
  appParams: ['firstname', 'lastname', 'email', 'referrer', 'couponcode', 'fp', 'vermittler'],
  businessPartners: [
    {
      name: 'Trusted Shops',
      img: '/img/partners/trustedShops.png',
      url: 'https://www.trustedshops.de/bewertung/info_X80F99922883DD5D04258CDF59E117845.html',
    },
  ],
  paymentPartners: [
    { name: 'paypal', img: '/img/paypal.svg' },
    { name: 'sepa', img: '/img/sepa.svg' },
    { name: 'visa', img: '/img/visa.svg' },
    { name: 'master', img: '/img/master.svg' },
  ],
  mediaPartners: [
    { name: 'das-erste', img: '/img/media-partners/das-erste.png' },
    { name: 'bild', img: '/img/media-partners/bild.png' },
    { name: 'wiwo', img: '/img/media-partners/wiwo.png' },
    { name: 'sueddeutsche', img: '/img/media-partners/sueddeutsche.png' },
    { name: 'mdr', img: '/img/media-partners/mdr.png' },
    { name: 'inforadio', img: '/img/media-partners/inforadio.png' },
    { name: 'swr', img: '/img/media-partners/swr.png' },
  ],
  googleMaps: {
    key: REACT_APP_GOOGLE_MAPS_API_KEY,
    url: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${REACT_APP_GOOGLE_MAPS_API_KEY}`,
    center: { lat: 52.085366, lng: 9.42968 },
    zoom: 13,
    mapType: 'ROADMAP',
    balticSea: { lat: 54.4839153, lng: 11.2543088 },
    northSea: { lat: 54.2, lng: 6.9533109 },
    mapOptions: {
      scrollwheel: false,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
    },
  },
  // todo: remove "simple" from FuneralPlan type (mymoria-types)
  defaultGraveTypes: {
    // @ts-ignore
    burial: {
      basic: '',
      high: '',
      premium: '',
    },
    // @ts-ignore
    cremation: {
      basic: 'NW',
      high: 'NW',
      premium: 'NW',
    },
    // @ts-ignore
    sea: {
      basic: 'SU',
      high: 'SB',
      premium: 'SB',
    },
    // @ts-ignore
    tree: {
      basic: 'BBG',
      high: 'BBG',
      premium: 'BBG',
    },
  },
  treuhand: {
    pdfUrl: 'https://mymoria-pdf.s3.eu-west-1.amazonaws.com/treuhand/Vertrag_mymoria_Treuhand.pdf',
  },
  ratedBusinessPartners: [
    {
      name: 'Trusted Shops',
      img: '/img/partners/trustedShops.png',
      url: 'https://www.trustedshops.de/bewertung/info_X80F99922883DD5D04258CDF59E117845.html',
      rating: 4.7,
      caption: 'sehr gut (4.77 / 5.00)',
    },
    {
      name: 'Google',
      img: '/img/partners/google.svg',
      url: 'https://www.mymoria.de/ueber-uns/bewertungen-das-sagen-unsere-kunden-ueber-uns/',
      rating: 4.9,
      caption: 'sehr gut (223)',
    },
  ],
  wundwPartner: {
    name: 'WUNDW',
    img: '/img/partners/wundw/wundw.svg',
    wundwCaption: 'In Kooperation mit:',
  },
  cookieSiteSlug: 'gatsby_',
};

export default config;
