import React, { FunctionComponent } from 'react';
import * as t from 'data';
import { Button, Icon, Markdown, Row, Text, WizardIntroCard } from 'mymoria-ui/components';
import { WizardComponentProps } from 'react-albus';

const IntroStep: FunctionComponent<WizardComponentProps> = ({ wizard }) => (
  <WizardIntroCard>
    <Markdown>{t.financial.wizard.intro.description}</Markdown>
    <Row justifyContent="center" alignItems="center" p={4}>
      <Icon.Duration />
      <Text ml={1} fontWeight="bold">
        {t.financial.wizard.intro.estimatedTime}
      </Text>
    </Row>
    <Button onClick={wizard.next}>{t.financial.wizard.intro.buttonLabel}</Button>
  </WizardIntroCard>
);

export default IntroStep;
