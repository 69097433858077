import React, { useCallback, useRef, useState } from 'react';
import { Page, Section } from 'components';
import { useDispatch, useSelector } from 'hooks';
import { FuneralSiteMarker as IFuneralSiteMarker } from 'types';
import { debounce } from 'lodash';
import { funeralSiteAPI } from 'api';
import FuneralSiteMarker from './FuneralSiteMarker';
import { FormFooter, Loader } from 'mymoria-ui/components';
import config from 'config';
import { GoogleMap } from '@react-google-maps/api';
import * as t from 'data';
import { mq, styled } from 'mymoria-ui';
import { useHistory, useToast } from 'mymoria-ui/hooks';
import { Place } from 'mymoria-ui/components/Form/PlaceSearch';
import { getFuneralSiteById } from 'reducers/entities';
import { changeFuneralSite, switchSeaBasicService } from 'actions/provision';
import FuneralSiteInfoBox from './FuneralSiteInfoBox';
import styles from './FuneralSiteStyles';

const {
  googleMaps: { center: defaultCenter, mapOptions },
} = config;

const StyledMapContainer = styled.div`
  position: absolute;
  right: 0;
  left: 0;

  ${mq({
    top: ['11rem', '13rem'],
    bottom: [60, '5rem'],
  })}

  button.gm-ui-hover-effect {
    visibility: hidden;
  }
`;

const FuneralSitesPage = () => {
  const dispatch = useDispatch();
  const { handlePush } = useHistory();
  const { funeralPlan, funeralType, lat, lon, funeralSite = '', graveType = '' } = useSelector(
    ({ provision }) => provision,
  );
  const funeralSiteEntity = useSelector(getFuneralSiteById(funeralSite));
  const mapRef = useRef<any>(null);
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [center, setCenter] = useState(() => {
    if (funeralSiteEntity) {
      return funeralSiteEntity.address.gps;
    } else if (lat && lon) {
      return { lat, lng: lon };
    } else {
      return defaultCenter;
    }
  });
  const [markers, setMarkers] = useState<IFuneralSiteMarker[]>([]);
  const [selectedFuneralSite, setSelectedFuneralSite] = useState(funeralSite);
  const [selectedGraveType, setSelectedGraveType] = useState(graveType);
  const [selectedMarker, setSelectedMarker] = useState(funeralSite);
  const [zoom, setZoom] = useState(config.googleMaps.zoom);

  const fetchMarkers = useCallback(
    debounce(() => {
      if (mapRef.current) {
        return funeralSiteAPI
          .fetchMarkers(funeralType, funeralPlan, mapRef.current.getBounds())
          .then(markers => {
            setMarkers(markers);
            if (markers.length < 3) {
              setZoom(zoom - 1);
            }
          })
          .catch(() => {
            setZoom(zoom - 1);
          });
      }
    }, 250),
    [funeralType, funeralPlan, zoom],
  );

  const selectMarker = (id: string) => {
    setSelectedMarker(id);
    setSelectedFuneralSite(id);

    if (id === funeralSite) {
      setSelectedGraveType(graveType);
    } else {
      setSelectedGraveType('');
    }
  };

  const deselectMarker = () => {
    setSelectedMarker('');
    setSelectedGraveType(graveType);
    setSelectedFuneralSite(funeralSite);
  };

  const deleteFuneralSite = () => {
    setSelectedMarker('');
    setSelectedGraveType('');
    setSelectedFuneralSite('');
  };

  const handlePlaceChange = useCallback(
    (place: Place) => place && setCenter({ lat: place.lat, lng: place.lon }),
    [],
  );

  const handleFuneralSiteChange = useCallback(() => {
    setIsLoading(true);

    if (funeralType === 'sea') {
      return dispatch(switchSeaBasicService(selectedFuneralSite, selectedGraveType))
        .then(handlePush('/overview/offer', { restoreScrollPosition: true }))
        .catch(() => addToast(t.global.apiErrors.general))
        .finally(() => setIsLoading(false));
    } else {
      return dispatch(changeFuneralSite(selectedFuneralSite, selectedGraveType))
        .then(handlePush('/overview/offer', { restoreScrollPosition: true }))
        .catch(() => addToast(t.global.apiErrors.general))
        .finally(() => setIsLoading(false));
    }
  }, [addToast, dispatch, funeralType, handlePush, selectedFuneralSite, selectedGraveType]);

  return (
    <Page footer="none" headerTitle={t.offer.headerTitle}>
      <Section
        title={t.offer.funeralSites.sectionTitle}
        onGoBack={handlePush('/overview/offer', { restoreScrollPosition: true })}
        goBackLabel={t.global.labels.offer}
      >
        <StyledMapContainer>
          <GoogleMap
            center={center}
            zoom={zoom}
            mapContainerStyle={{ height: '100%' }}
            onZoomChanged={() => {
              if (mapRef.current) {
                setZoom(mapRef.current.getZoom());
              }
            }}
            onLoad={ref => {
              mapRef.current = ref;
            }}
            onIdle={fetchMarkers}
            options={{
              ...mapOptions,
              styles,
              mapTypeId: google.maps.MapTypeId[config.googleMaps.mapType],
              zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_BOTTOM },
            }}
          >
            {isLoading && <Loader overlay />}
            {markers.map(({ id, city, name, latitude, longitude }) => (
              <FuneralSiteMarker
                key={`${id}-${city}`}
                id={id}
                name={name}
                lat={latitude}
                lng={longitude}
                funeralType={funeralType}
                selected={id === selectedMarker}
                origined={id === funeralSite}
                onClick={selectMarker}
                imageExt="svg"
              />
            ))}
            <FuneralSiteInfoBox
              selectedFuneralSite={selectedMarker}
              funeralType={funeralType}
              funeralPlan={funeralPlan}
              origined={funeralSite === selectedFuneralSite}
              selectedGraveType={selectedGraveType}
              onClose={deselectMarker}
              onPlaceChange={handlePlaceChange}
              onFuneralSiteDelete={deleteFuneralSite}
              onGraveTypeChange={setSelectedGraveType}
            />
          </GoogleMap>
        </StyledMapContainer>
      </Section>
      <FormFooter
        labelCancel={t.global.labels.cancel}
        labelSubmit={t.global.labels.submit}
        onCancel={handlePush('/overview/offer', { restoreScrollPosition: true })}
        onSubmit={handleFuneralSiteChange}
        disabledSubmit={
          isLoading ||
          (selectedMarker === funeralSite && selectedGraveType === graveType) ||
          (funeralType === 'sea' && !selectedMarker)
        }
      />
    </Page>
  );
};

export default FuneralSitesPage;
