import config from 'config';
import axios from 'axios';
import { Dispatch } from 'redux';
import { Flags } from 'types';
import { PaymentInfo, Provision } from 'mymoria-types';
import { createAction } from 'typesafe-actions';
import { api } from 'utils';

const {
  api: { endpoint },
  cms: { collectionEndpoint },
} = config;

const setProvisionActiveFlagAction = createAction('PROVISION_ACTIVE_FLAG')<Flags>();

export default {
  set: setProvisionActiveFlagAction,
};

export const setActivateFlag = (activeModal: boolean) => (dispatch: Dispatch) =>
  dispatch(setProvisionActiveFlagAction({ activeModal }));

export const fetchTiles = () =>
  axios
    .get(`${collectionEndpoint}/PortalSections?simple=1&filter[Status]=live`)
    .then(({ data }) => data)
    .catch(console.error);

export const activateAccount = (provision: Provision) => {
  const values: PaymentInfo = {
    firstname: provision.firstname,
    lastname: provision.lastname,
    paymentType: 'free',
    bank: '-',
    iban: '-',
    bic: '-',
    address: '-',
    postalCode: provision.code.toString(),
    city: provision.city,
  };

  return api
    .put<PaymentInfo>(`${endpoint}/provisions/${provision.id}/activate`, {
      paymentAcceptedPlan: '1',
      ...values,
    })
    .then(({ data }) => data)
    .catch(console.error);
};
