import actions from 'actions';
import { SalesforceUserAccountsData } from 'types';
import { createReducer } from 'typesafe-actions';

const initialState: { accounts: SalesforceUserAccountsData[] } = { accounts: [] };

const { fetchSalesfroceUserData } = actions.salesforce;

export default createReducer(initialState).handleAction(
  fetchSalesfroceUserData,
  (state, { payload }) => ({
    ...state,
    accounts: payload,
  }),
);
