import { FuneralPlan, FuneralType, Service, Customizations } from 'mymoria-types';
import { partition, sortBy, toString } from 'lodash';
import { api, customize, getAxiosData } from 'utils';

const split = (services: Service[]) =>
  partition<Service>(sortBy(services, 'identifier'), ({ optional }) => !optional);

const parse = (selectedServices: Service[], allServices: Service[]) => {
  const [selectedBasicServices, selectedOptionalServices] = split(selectedServices);
  const [allBasicServices, allOptionalServices] = split(allServices);

  return {
    selected: {
      basicServices: selectedBasicServices,
      optionalServices: selectedOptionalServices,
    },
    all: {
      basic: allBasicServices,
      optional: allOptionalServices,
    },
  };
};

const fetchDefault = (code: number, plan: FuneralPlan, type: FuneralType) =>
  Promise.all([
    api.get<Service[]>('/services/find', { params: { code, plan, type } }),
    api.get<Service[]>('/services/find', { params: { code, type } }),
  ]).then(([{ data: selectedServices }, { data: allServices }]) =>
    parse(selectedServices, allServices),
  );

const fetchSelected = (
  code: number,
  type: FuneralType,
  ids: string[],
  customizations?: Customizations,
) =>
  Promise.all([
    ids.length > 0
      ? api
          .get<Service[]>('/services', { params: { ids: toString(ids), code } })
          .then(getAxiosData)
      : [],
    api
      .get<Service[]>('/services/find', { params: { code, type } })
      .then(getAxiosData),
  ]).then(([selectedServices, allServices]) =>
    parse(selectedServices, customize(allServices, customizations?.services)),
  );

export default {
  split,
  parse,
  fetchDefault,
  fetchSelected,
};
