import React, { FunctionComponent } from 'react';
import { Offer } from 'components/Icons';
import { css, styled, Theme, withTheme } from 'mymoria-ui';

interface ProgressBarProps {
  progress: number;
  theme: Theme;
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const Bar = styled.div(
  ({ theme: { colors } }) => css`
    width: 100%;
    height: 4px;
    position: relative;
    background: ${colors.gray};
    transition: width 0.8s ease-in-out;
  `,
);

const Progress = styled.div<Pick<ProgressBarProps, 'progress'>>(
  ({ theme: { colors }, progress }) => css`
    position: absolute;
    display: block;
    height: 4px;
    width: ${progress}%;
    background: ${colors.primary};
    transition: width 0.8s ease-in-out;
    z-index: 1;
    left: 0;
    top: 0;
  `,
);

const Icon = styled(Offer)`
  height: 4rem;
  width: 4rem;
  z-index: 1;
`;

const ProgressBar: FunctionComponent<ProgressBarProps> = ({ progress, theme: { colors } }) => (
  <Container>
    <Bar>
      <Progress progress={progress} />
    </Bar>
    <Icon fill={progress < 100 ? colors.gray : colors.primary} />
  </Container>
);

export default withTheme(ProgressBar);
