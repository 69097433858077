import { AdminEditingMessage, AlertMessage, Page, Section, Tile } from 'components';
import { ActiveAccountModal } from 'components/Modals';
import { global as t } from 'data';
import { isTileCompleted } from 'helpers';
import { withLocationTrack } from 'hocs';
import { useDispatch, useSelector } from 'hooks';
import { isEmpty, partition, sortBy } from 'lodash';
import { isWundw } from 'reducers/insurance';
import { applyCyAttribute, cdn, withTheme } from 'mymoria-ui';
import { Col, Grid, Row } from 'mymoria-ui/components';
import { useModal, usePush } from 'mymoria-ui/hooks';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { getProvision } from 'reducers/provision';
import { Wuerttembergische } from 'components/Icons';
import { compose } from 'redux';
import { PortalSection } from 'types';
import { sprintf } from 'utils';
import { setActivateFlag } from 'actions/flags';

interface Props extends RouteComponentProps {
  tiles: PortalSection[];
}

const OverviewPage: FunctionComponent<Props> = ({ match: { path }, tiles }) => {
  const { handlePush } = usePush();
  const isWundW = useSelector(isWundw);
  const provision = useSelector(getProvision);
  const provisionBirth = useSelector(({ provision }) => provision.provisionBirth);
  const paymentAcceptedPlan = useSelector(({ provision }) => provision.paymentAcceptedPlan);
  const status = useSelector(({ provision }) => provision.status);
  const userEmail = useSelector(({ provision }) => provision.userEmail);
  const dispatch = useDispatch();
  const { showModal, hideModal, Modal: HookModal } = useModal();

  const activeModal = useSelector(({ flags }) => flags.activeModal);

  useEffect(() => {
    // show account activation modal if activeModal flag is true
    activeModal && showModal();
  }, [activeModal, showModal]);

  const createBox = (portalSection: PortalSection) => {
    const status = isTileCompleted(portalSection.Tile, provision)
      ? 'completed'
      : portalSection.Level;

    const Icon =
      portalSection.Tile === 'financials' && isWundW ? (
        <Wuerttembergische />
      ) : (
        <img src={cdn('cms', portalSection.Icon.path)} alt={portalSection.Headline} />
      );

    return (
      <Col display="flex" key={portalSection._id} width={[1, 1 / 2, 1 / 3]}>
        <Tile
          onClick={handlePush(`${path}${portalSection.ButtonLink}`)}
          title={portalSection.Headline}
          sectionName={portalSection.Tile}
          description={portalSection.ShortDescription}
          modalDescription={portalSection.LongDescription}
          status={status}
          icon={Icon}
        />
      </Col>
    );
  };

  const sortTiles = ({ Sort }: PortalSection) => +Sort;
  const [upperTiles, lowerTiles] = partition(
    tiles,
    ({ Section }: PortalSection) => Section === 'upper',
  );

  const completedUpperTiles = upperTiles.reduce(
    (acc, { Tile: tileName }: PortalSection) =>
      isTileCompleted(tileName, provision) ? acc + 1 : acc,
    0,
  );

  const handleCloseModal = useCallback(() => {
    dispatch(setActivateFlag(false));
    hideModal();
  }, [dispatch, hideModal]);

  return (
    <Page>
      <Section
        title={t.overview.primarySection.title}
        description={t.overview.primarySection.description}
        meta={sprintf(t.overview.primarySection.meta, {
          completedTiles: completedUpperTiles,
          tilesCount: upperTiles.length,
        })}
        {...applyCyAttribute('OverviewPageSection', t.overview.primarySection.title)}
      >
        <Grid>
          <Col width={1}>
            {status !== 'CONFIRMED' && (
              <AlertMessage
                message={sprintf(t.activateAccount.ActivateAccount, { email: userEmail })}
                status="warning"
              />
            )}
            {!paymentAcceptedPlan ? (
              <AlertMessage message={t.overview.activeBanner} status="warning" />
            ) : null}
            <AdminEditingMessage />
            {!provisionBirth ? (
              <AlertMessage message={t.missingBirthDate.message} status="error" />
            ) : null}
          </Col>
          <Row flexWrap="wrap">{sortBy(upperTiles, sortTiles).map(createBox)}</Row>
        </Grid>
      </Section>
      {!isEmpty(lowerTiles) && (
        <Section
          title={t.overview.secondarySection.title}
          description={t.overview.secondarySection.description}
          {...applyCyAttribute('OverviewPageSection', t.overview.secondarySection.title)}
        >
          <Grid>
            <Row flexWrap="wrap">{sortBy(lowerTiles, sortTiles).map(createBox)}</Row>
          </Grid>
        </Section>
      )}

      <HookModal
        withFlowers
        flexDirection={['row', 'column']}
        height={['100%', 'auto']}
        onClose={handleCloseModal}
      >
        <ActiveAccountModal
          onClose={handleCloseModal}
          title={sprintf(t.activeAccountModal.title, {
            salutation:
              provision.salutation === 'sir'
                ? t.activeAccountModal.greetingMale
                : t.activeAccountModal.greetingFemale,
            lastname: provision.lastname,
          })}
          description={t.activeAccountModal.description}
          headline={t.activeAccountModal.headline}
          label={t.activeAccountModal.buttonLabel}
          ghostLabel={t.activeAccountModal.ghostLabel}
          onSubmit={handlePush('/profile')}
        />
      </HookModal>
    </Page>
  );
};

export default compose<FunctionComponent<Props>>(
  withTheme,
  withLocationTrack(t.overview.primarySection.title),
)(OverviewPage);
