import { Page, Section } from 'components';
import { global as t } from 'data';
import { withLocationTrack } from 'hocs';
import { Button, Card, Col, Grid, LinkButton, Markdown, Row } from 'mymoria-ui/components';
import { useHistory } from 'mymoria-ui/hooks';
import { mq, styled } from 'mymoria-ui/utils';
import React from 'react';

const StyledImg = styled.img`
  position: absolute;
  top: -70px;
  width: 202px;
  height: 154px;
  transform: rotate(180deg);
`;

const StyledCard = styled(Card)`
  position: relative;
  margin-top: 4rem;
`;

const StyledLinkButton = styled(LinkButton)`
  ${mq({
    width: ['100%', 'auto'],
  })};
`;

const StyledGhost = styled(Button)(
  mq({
    marginLeft: ['0', '2rem'],
  }),
);

const StyledMarkdown = styled(Markdown)`
  margin-top: 4rem;
  margin-bottom: 1rem;
`;

const DigitalHeritage = () => {
  const history = useHistory();
  return (
    <Page>
      <Section
        title={t.digitalHeritage.title}
        description={t.digitalHeritage.description}
        onGoBack={() => history.push('/overview')}
        goBackLabel={t.overview.name}
      >
        <Grid>
          <Row justifyContent="center">
            <Col width={[1, 10 / 12, 2 / 3]}>
              <StyledCard>
                <Row flexDirection="column" alignItems="center">
                  <Col
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width={[1, 1, 7 / 10]}
                  >
                    <StyledImg src="/img/flower_04.png" alt="flower" />
                    <StyledMarkdown>{t.digitalHeritage.cardDescriptionOne}</StyledMarkdown>
                    <Markdown>{t.digitalHeritage.cardDescriptionTwo}</Markdown>
                  </Col>

                  <Col
                    display="flex"
                    alignItems="center"
                    margin="4rem 0"
                    flexWrap={['wrap', 'nowrap']}
                    flexDirection={['column', 'row']}
                    width={[1, 1, 7 / 10]}
                  >
                    <StyledLinkButton
                      href={t.digitalHeritage.memoresaUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t.digitalHeritage.cardConfirmButton}
                    </StyledLinkButton>

                    <StyledGhost ghost onClick={() => history.push('/overview')}>
                      {t.digitalHeritage.cardCancelButton}
                    </StyledGhost>
                  </Col>
                </Row>
              </StyledCard>
            </Col>
          </Row>
        </Grid>
      </Section>
    </Page>
  );
};

export default withLocationTrack(t.digitalHeritage.title)(DigitalHeritage);
