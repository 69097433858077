import { Page, Section } from 'components';
import { global as t } from 'data';
import { useDispatch, useSelector } from 'hooks';
import { Col, FormFooter, Grid, Loader, Row } from 'mymoria-ui/components';
import { useHistory, useToast } from 'mymoria-ui/hooks';
import { getValidPhoneFormat } from 'mymoria-ui/utils';
import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import config from 'config';
import { updateProvision } from 'actions/provision';
import TrustedPersonForm, { TrustedPersonValues } from './TrustedPersonForm';

const {
  regex: { noLatinChar, confidantPersonEmailRule, mymoriaDomain },
} = config;

const validateConfidantPersonEmailRule = (value: string) => confidantPersonEmailRule.test(value);
const validateMymoriaDomain = (value: string) => mymoriaDomain.test(value);

const initialValues: TrustedPersonValues = {
  salutation: '',
  firstname: '',
  lastname: '',
  relationship: '',
  street: '',
  city: '',
  postalCode: '',
  phone: '',
  email: '',
  note: '',
};

const validateEmail = (value: string) =>
  yup
    .string()
    .required(t.validators.required)
    .email()
    .isValid(value)
    .then(
      isValid =>
        isValid &&
        (validateMymoriaDomain(value) ? !validateConfidantPersonEmailRule(value) : !!value),
    );

const TrustedPersonEditPage = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { handlePush } = useHistory();
  const trustedPerson = useSelector(({ provision: { trustedPerson } }) => trustedPerson);
  const permissionGrantedAt = trustedPerson?.permissionGrantedAt;

  const handleSubmit = ({ permissionGranted, phone, ...values }: TrustedPersonValues) =>
    dispatch(
      updateProvision({
        trustedPerson: {
          ...values,
          phone: getValidPhoneFormat({ phone, formatType: 'e164' }),
          permissionGrantedAt: permissionGranted === 'true' ? new Date() : undefined,
        },
      }),
    )
      .then(() => {
        if (
          (!permissionGrantedAt || values.email !== trustedPerson?.email) &&
          permissionGranted === 'true'
        ) {
          addToast(t.trustedPerson.permission.sucess, 'success');
        }
      })
      .then(handlePush('/overview/trusted-person'))
      .catch(({ message }) => addToast(message));

  return (
    <Page footer="none" headerTitle={t.trustedPerson.sectionTitle}>
      <Formik
        initialValues={{
          ...initialValues,
          ...trustedPerson,
          permissionGranted: permissionGrantedAt ? 'true' : 'false',
        }}
        validationSchema={yup.object().shape({
          email: yup
            .string()
            .required(t.validators.required)
            .email(t.validators.email)
            .matches(noLatinChar, t.validators.noLatinChar)
            .test('is-valid-confidant-email', t.validators.notValidConfidantEmail, validateEmail),
          firstname: yup.string().required(t.validators.required),
          lastname: yup.string().required(t.validators.required),
          relationship: yup.string(),
          city: yup.string(),
          postalCode: yup.string().max(20, t.validators.max),
          phone: yup.number().required(t.validators.required),
          trustedPersonPermissionGranted: yup.boolean(),
        })}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting }) => (
          <>
            <Section
              title={t.trustedPerson.editMode.sectionTitle}
              onGoBack={handlePush('/overview/trusted-person')}
            >
              <Grid>
                <Row justifyContent="center" pb="5rem">
                  <Col width={[1, 10 / 12, 2 / 3]} px={0}>
                    {isSubmitting && <Loader overlay />}
                    <TrustedPersonForm permissionGrantedAt={permissionGrantedAt} />
                  </Col>
                </Row>
              </Grid>
            </Section>
            <FormFooter
              labelCancel={t.labels.cancel}
              labelSubmit={t.labels.submit}
              onSubmit={submitForm}
              onCancel={handlePush('/overview/trusted-person')}
              disabledSubmit={isSubmitting}
            />
          </>
        )}
      </Formik>
    </Page>
  );
};

export default TrustedPersonEditPage;
