import { useDispatch, useSelector } from 'hooks';
import React, { FunctionComponent } from 'react';
import { formatPrice } from 'helpers';
import { CustomOfferItemPreview } from 'mymoria-ui/components';
import { addService, removeService, switchSeaBasicService } from 'actions/provision';
import { isOfferEditable } from 'reducers/provision';
import * as t from 'data';
import { find, pick } from 'lodash';

interface ServicePreviewProps {
  id: string;
}

const OptionalServicePreview: FunctionComponent<ServicePreviewProps> = ({ id }) => {
  const dispatch = useDispatch();
  const isEditable = useSelector(isOfferEditable());
  const funeralType = useSelector(({ provision }) => provision.funeralType);

  const selectedServicesIds = useSelector(({ provision }) => provision.optionalServices);
  const allServices = useSelector(({ entities }) => entities.services);
  const selectedServices = pick(allServices, selectedServicesIds);
  const mostRecentService = useSelector(({ entities }) => entities.services[id]);

  // check if any of selected provision service match with one of the defaults
  const foundSelectedOptionalService = find(
    selectedServices,
    entity => entity.identifier === mostRecentService.identifier,
  );

  const item = foundSelectedOptionalService || mostRecentService;
  const selected = !!foundSelectedOptionalService;

  const handleSelect = () => {
    if (item.identifier === 'sea-addon-1') {
      return dispatch(switchSeaBasicService());
    }
    return dispatch(selected ? removeService(item.id) : addService(item.id));
  };

  if (!item) {
    return null;
  }

  return (
    <CustomOfferItemPreview
      selected={selected}
      funeralType={funeralType}
      name={item.translations.shortname}
      price={formatPrice(item.price, { gross: true })}
      image={item.pictures[0]?.url}
      onSelect={isEditable ? handleSelect : undefined}
      outdated={!!item.deletedAt}
      description={item.translations.description}
      shortDescription={item.translations.shortDescription}
      footNote={item.translations.footnote}
      buttonLabel={t.global.labels.edit}
    />
  );
};

export default OptionalServicePreview;
