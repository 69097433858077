import { styled, mq } from 'mymoria-ui';
import { Card } from 'mymoria-ui/components';

export default styled(Card)`
  p {
    margin: 2rem 0;
  }

  ${mq({
    minHeight: ['auto', '450px', '450px', '480px'],
  })};
`;
