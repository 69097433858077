import React, { FunctionComponent } from 'react';
import { useHistory } from 'mymoria-ui/hooks';
import { ProductListItem } from 'mymoria-ui/components';
import { useDispatch, useSelector } from 'hooks';
import { formatPrice } from 'helpers';
import { useRouteMatch } from 'react-router-dom';
import { trackUserInteraction } from 'actions/tracking';
import { isProductSelected } from 'reducers/provision';

interface OfferProductProps {
  id: string;
}

const Product: FunctionComponent<OfferProductProps> = ({ id }) => {
  const product = useSelector(({ entities }) => entities.products[id]);
  const selected = useSelector(isProductSelected(id));
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { url } = useRouteMatch();

  if (!product) {
    return null;
  }

  const handleClick = () => {
    dispatch(trackUserInteraction('Product Details', product.translations.shortname));
    push(`${url}/${id}`, { restoreScrollPosition: false });
  };

  return (
    <ProductListItem
      name={product.translations.shortname}
      image={product.pictures[0]?.url}
      price={formatPrice(product.price, { gross: true })}
      onClick={handleClick}
      selected={selected}
    />
  );
};

export default Product;
