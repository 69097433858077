import React, { FunctionComponent, useLayoutEffect } from 'react';
import { WizardComponentProps } from 'react-albus';
import { Col, Row, WizardCard } from 'mymoria-ui/components';
import { getValidPhoneFormat } from 'mymoria-ui/utils';
import { Formik } from 'formik';
import { financial, global } from 'data';
import { salutationOptions } from 'forms/options';
import { InputField, RadioGroupField, EmailField } from 'forms/fields';
import * as Yup from 'yup';
import config from 'config';
import { HDHContactDetails, useHDHState } from './HDHState';

const {
  regex: { noLatinChar, nameRule },
} = config;

const ContactDetailsStep: FunctionComponent<WizardComponentProps> = ({
  wizard: { push, step, steps },
}) => {
  const [{ contactDetails, paymentType }, setState] = useHDHState();

  useLayoutEffect(() => {
    if (!paymentType) {
      push('paymentType');
    }
  }, [paymentType, push]);

  const handleSubmit = (contactDetails: HDHContactDetails) => {
    setState({
      contactDetails: {
        ...contactDetails,
        phone: getValidPhoneFormat({ phone: contactDetails.phone, formatType: 'e164' }),
      },
    });
    push('document');
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={contactDetails}
      validationSchema={Yup.object({
        salutation: Yup.string().required(global.validators.required),
        firstname: Yup.string()
          .required(global.validators.required)
          .matches(nameRule, global.validators.wrongName),
        lastname: Yup.string()
          .required(global.validators.required)
          .matches(nameRule, global.validators.wrongName),
        street: Yup.string().required(global.validators.required),
        postalCode: Yup.string().required(global.validators.required),
        city: Yup.string().required(global.validators.required),
        email: Yup.string()
          .required(global.validators.required)
          .email(global.validators.email)
          .matches(noLatinChar, global.validators.noLatinChar),
        phone: Yup.number().required(global.validators.required),
      })}
    >
      {({ isValid, submitForm }) => (
        <WizardCard
          title={financial.hdh.details.title}
          description={financial.hdh.details.subHeadline}
          onClickPrev={() => push('tariff')}
          onClickNext={() => push('document')}
          labelPrev={global.labels.back}
          labelNext={global.labels.continue}
          disabledNext={!isValid}
          steps={steps.length - 2}
          step={steps.indexOf(step)}
        >
          <Row flexWrap="wrap">
            <Col width={1}>
              <RadioGroupField name="salutation" values={salutationOptions} />
            </Col>
            <Col width={[1, 1, 1 / 2]}>
              <InputField type="text" name="firstname" label={global.forms.firstname} />
            </Col>
            <Col width={[1, 1, 1 / 2]}>
              <InputField type="text" name="lastname" label={global.forms.lastname} />
            </Col>
            <Col width={[1, 1, 1 / 2]}>
              <InputField type="text" name="street" label={global.forms.hdhStreet} />
            </Col>
            <Col width={[1, 1, 1 / 2]}>
              <InputField type="text" name="houseNumber" label={global.forms.house} />
            </Col>
            <Col width={[1, 1, 1 / 2]}>
              <InputField type="text" name="postalCode" label={global.forms.postalCode} />
            </Col>
            <Col width={[1, 1, 1 / 2]}>
              <InputField type="text" name="city" label={global.forms.city} />
            </Col>
            <Col width={[1, 1, 1 / 2]}>
              <EmailField type="email" name="email" label={global.forms.email} />
            </Col>
            <Col width={[1, 1, 1 / 2]}>
              <InputField type="text" name="phone" label={global.forms.phone} />
            </Col>
          </Row>
        </WizardCard>
      )}
    </Formik>
  );
};

export default ContactDetailsStep;
