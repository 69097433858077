import pdfPic from 'assets/pdf-download-image.jpg';
import { Page, Section } from 'components';
import config from 'config';
import { documents, global as t } from 'data';
import { withLocationTrack } from 'hocs';
import { useSelector } from 'hooks';
import moment from 'moment';
import { css, mq, styled } from 'mymoria-ui';
import { Button, Caption, Card, Col, Grid, Heading, Icon, Row, Text } from 'mymoria-ui/components';
import React, { FunctionComponent, useCallback } from 'react';
import { sprintf } from 'utils';
import { useHistory } from 'mymoria-ui/hooks';

const StyledCard = styled(Card)`
  img {
    max-width: 100%;
    border-radius: 4px 4px 0 0;
  }

  ${mq({
    padding: [0, 0, 0, 0],
  })};
`;

const Container = styled.div`
  padding: 2.5rem 1.5rem;
`;

const CustomText = styled(Text)`
  margin: 0;
`;

const TextContainer = styled.div`
  padding: 2rem 0 2.5rem;
`;

const CustomCaption = styled(Caption)(
  ({ theme: { font, colors } }) => css`
    font-size: ${font.size.normal};
    color: ${colors.darkGray};
    padding-top: 2rem;
  `,
);

const DocumentsPage: FunctionComponent = () => {
  const { handlePush } = useHistory();
  const id = useSelector(({ provision }) => provision.id);
  const updatedAt = useSelector(({ provision }) => provision.updatedAt);

  const handleDownloadPdf = useCallback(() => {
    const pdfURL = new URL(`/pdf/provision/${id}`, config.api.endpoint);
    window.open(pdfURL.toString());
  }, [id]);

  return (
    <Page>
      <Section
        title={documents.header}
        onGoBack={handlePush('/overview', { restoreScrollPosition: true })}
        goBackLabel={t.overview.name}
      >
        <Grid>
          <Row justifyContent="center">
            <Col width={[1, 1 / 2, 1 / 3]}>
              <StyledCard>
                <img src={pdfPic} alt="download-pdf" />
                <Container>
                  <Heading.Heading2>{documents.header}</Heading.Heading2>
                  <TextContainer>
                    <CustomText>{documents.subheader}</CustomText>
                    <CustomCaption light>
                      {sprintf(documents.lastUpdate, {
                        date: moment(updatedAt).format(config.dateFormat.view),
                      })}
                    </CustomCaption>
                  </TextContainer>
                  <Button block onClick={handleDownloadPdf}>
                    <Icon.Download />
                    {documents.buttonTitle}
                  </Button>
                </Container>
              </StyledCard>
            </Col>
          </Row>
        </Grid>
      </Section>
    </Page>
  );
};

export default withLocationTrack(documents.header)(DocumentsPage);
