import { updateProvision } from 'actions/provision';
import { useDispatch } from 'hooks';
import { styled } from 'mymoria-ui';
import { Button, Col, Form, Heading, Loader, Row, Text } from 'mymoria-ui/components';
import { useToast } from 'mymoria-ui/hooks';
import React, { ChangeEvent, FunctionComponent, useCallback, useState } from 'react';

interface Props {
  /**
   * Tile title.
   */
  title?: string;

  /**
   * Tile Description.
   */
  description?: string;

  /**
   * confirm Button Label.
   */
  confirmButtonText: string;

  /**
   * cancel Button Label.
   */
  cancelButtonText: string;

  /**
   * textarea Label.
   */
  textareaLabel: string;

  /**
   * hide Modal
   */
  hideModal: () => void;

  /**
   * Note Value
   */
  graveTypeNote?: string;

  /**
   * Note example text
   */
  noteExample?: string;
}

const StyledRow = styled(Row)`
  z-index: 8;
`;

const StyledCol = styled(Col)`
  margin: 0 0 2.5rem;
`;

const FlexCol = styled(Col)`
  display: flex !important;
  justify-content: flex-end;
`;

const CancelButton = styled(Button)`
  margin-right: 2rem;
`;

const NoteModal: FunctionComponent<Props> = ({
  title,
  confirmButtonText,
  cancelButtonText,
  textareaLabel,
  hideModal,
  graveTypeNote,
  noteExample,
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();

  // below code is always false , not working
  // const loading = useSelector(isLoading('provision'));
  const [loading, setLoading] = useState(false);

  const [graveNote, setGraveNote] = useState(graveTypeNote);

  const handleGraveNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) =>
    setGraveNote(event.target.value);

  const handleGraveNoteUpdate = useCallback(() => {
    setLoading(true);
    dispatch(updateProvision({ graveTypeNote: graveNote }))
      .then(() => {
        setLoading(false);
        hideModal();
      })
      .catch(({ message }) => addToast(message));
  }, [dispatch, graveNote, hideModal, addToast]);

  return (
    <StyledRow flexDirection="column">
      {loading && <Loader overlay />}
      <StyledCol width={[1, 1, 1 / 2]}>
        <Heading.Heading1 className="modal_title">{title}</Heading.Heading1>
        <Text>{noteExample}</Text>
      </StyledCol>
      <StyledCol width={1}>
        <Form.Textarea
          rows={8}
          onChange={handleGraveNoteChange}
          value={graveNote}
          label={textareaLabel}
        />
      </StyledCol>
      <FlexCol width={1}>
        <CancelButton className="cancel_btn" onClick={hideModal} ghost>
          {cancelButtonText}
        </CancelButton>
        <Button className="create_btn" variant="primary" onClick={handleGraveNoteUpdate}>
          {confirmButtonText}
        </Button>
      </FlexCol>
    </StyledRow>
  );
};

export default NoteModal;
