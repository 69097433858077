import { fetchProvision } from 'actions/provision';
import { useDispatch, useLocalStorage, useSelector } from 'hooks';
import { Loader } from 'mymoria-ui/components';
import { useToast } from 'mymoria-ui/hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Redirect, RouteComponentProps } from 'react-router';

interface RouteParams {
  provisionId: string;
}

const ProvisionLoader: FunctionComponent<RouteComponentProps<RouteParams>> = ({
  match: {
    params: { provisionId },
  },
}) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const storedProvisionId = useSelector(({ provision: { id } }) => id);
  const [isLoading, setIsLoading] = useState(true);
  const [, setProvisionId] = useLocalStorage<string>('provisionId', '');

  useEffect(() => {
    if (storedProvisionId !== provisionId) {
      dispatch(fetchProvision(provisionId))
        .then(() => setProvisionId(provisionId))
        .catch(({ message }) => addToast(message))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [storedProvisionId, provisionId, setProvisionId, dispatch, addToast]);

  return isLoading ? <Loader /> : <Redirect to="/overview" />;
};

export default ProvisionLoader;
