import { Text, EditButton } from 'mymoria-ui/components';
import Card, { CardProps } from 'mymoria-ui/components/Card';
import { global as t } from 'data';
import { mq, styled } from 'mymoria-ui';
import React, { FunctionComponent } from 'react';

export interface OverviewCardProps extends CardProps {
  btnLabel?: string;
  btnOnClick?: () => void;
}

const { status: statusLabel } = t;

const StyledCard = styled(Card)`
  min-height: unset;
  margin: 0;

  button {
    margin: 2rem 0 0;
  }

  ${mq({
    margin: ['0 0 0.8rem', '0 0 1.5rem'],
  })};
`;

const OverviewCard: FunctionComponent<OverviewCardProps> = ({
  btnLabel,
  btnOnClick,
  children,
  status,
  ...props
}) => (
  <StyledCard statusLabel={status && statusLabel[status]} status={status} {...props}>
    {children || (
      <Text color="#777" fontStyle="italic">
        {t.labels.noInformation}
      </Text>
    )}
    {btnOnClick && <EditButton onClick={btnOnClick} label={btnLabel} />}
  </StyledCard>
);

export default OverviewCard;
