import Button, { ButtonProps } from 'mymoria-ui/components/Button/Button';
import { styled, css } from 'mymoria-ui';
import React, { FunctionComponent } from 'react';
import { Icon } from 'mymoria-ui/components';
import { global as t } from 'data';

interface Props extends ButtonProps {
  label?: string;
}

const StyledButton = styled(Button)(
  ({ theme: { colors } }) => css`
    display: flex;
    svg {
      margin-right: 0.5rem;
      path {
        stroke: ${colors.primary};
      }
    }
  `,
);

const EditButton: FunctionComponent<Props> = ({ label, ...props }) => (
  <StyledButton ghost {...props}>
    <Icon.Edit />
    {label}
  </StyledButton>
);

EditButton.defaultProps = {
  label: t.labels.edit,
};

export default EditButton;
