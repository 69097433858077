import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState } from 'types';
import { combineReducers } from 'redux';
import provision from './provision';
import graveTypes from './graveTypes';
import insurance from './insurance';
import plans from './plans';
import flags from './flags';
import letters from './letters';
import entities from './entities';
import tracking from './tracking';
import params from './params';
import funeralSites from './funeralSites';
import products from './products';
import services from './services';
import registration from './registration';
import salesforce from './salesforce';

export default (history: History) =>
  combineReducers<ApplicationState>({
    router: connectRouter(history),
    provision,
    graveTypes,
    insurance,
    plans,
    flags,
    letters,
    entities,
    tracking,
    params,
    funeralSites,
    products,
    services,
    registration,
    salesforce,
  });
