import { global } from 'data';
import { mq, styled } from 'mymoria-ui';
import { Button, Col, Heading, Markdown, Row } from 'mymoria-ui/components';
import React, { FunctionComponent } from 'react';
interface Props {
  /**
   * title.
   */
  title: string;
  /**
   * text.
   */
  text: string;

  /**
   * submit handler.
   */
  submitModal: () => void;

  /**
   * hide modal handler.
   */
  hideModal: () => void;
}

export const StyledMarkdown = styled(Markdown)`
  text-align: 'left';
`;

const Footer = styled('div')`
  display: 'flex';
  align-items: 'center';
`;

const BaseButton = styled(Button)`
  display: inline-block;
`;

const StyledButton = styled(BaseButton)`
  margin-right: 1rem;
`;

const DesktopText = styled.div(
  mq({
    display: ['none', 'block'],
  }),
);

const MobileText = styled.div(
  mq({
    display: ['block', 'none'],
  }),
);

const StyledRow = styled(Row)`
  z-index: 8;
`;

const StyledCol = styled(Col)`
  margin-bottom: 2.5rem;
`;

const ConfirmModal: FunctionComponent<Props> = ({ text, title, hideModal, submitModal }) => (
  <StyledRow flexDirection="column">
    <StyledCol width={[1 / 2]}>
      <Heading.Heading2 className="modal_title">{title}</Heading.Heading2>
    </StyledCol>
    <StyledCol width={[1, 5 / 6]}>
      <StyledMarkdown className="modal_description" source={text} />
    </StyledCol>
    <Col width={1}>
      <Footer>
        <StyledButton className="confirm_btn" variant="primary" onClick={submitModal}>
          <DesktopText>{global.confirmModal.confirm}</DesktopText>
          <MobileText>{global.confirmModal.confirmMobile}</MobileText>
        </StyledButton>
        <BaseButton ghost onClick={hideModal} className="cancel_btn">
          {global.confirmModal.cancel}
        </BaseButton>
      </Footer>
    </Col>
  </StyledRow>
);

export default ConfirmModal;
