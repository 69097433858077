import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { Card, Col, FormFooter, Grid, Loader, RadioHint, Row, Text } from 'mymoria-ui/components';
import { RadioGroupField } from 'forms/fields';
import * as t from 'data';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'hooks';
import useErrorMessage, { Error } from 'hooks/useErrorMessage';
import { FuneralType } from 'mymoria-types';
import { changeBasicInformation } from 'actions/provision';
import useToast from 'mymoria-ui/hooks/useToast';
import { useHistory } from 'mymoria-ui/hooks';
import { Page, Section } from 'components';

interface FormValues {
  funeralType: FuneralType;
}

const BasicInformationPage = () => {
  const dispatch = useDispatch();
  const { handlePush, push } = useHistory();
  const { addToast } = useToast();
  const { error, setError } = useErrorMessage({ messages: t.global.apiErrors.general });
  const funeralType = useSelector(({ provision }) => provision.funeralType);

  const handleSubmit = (
    { funeralType }: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) => {
    if (error) {
      setError(null);
    }

    return dispatch(changeBasicInformation({ funeralType }))
      .then(() => {
        setSubmitting(false);
        addToast(t.offer.basicInformationDetails.successMsg, 'success', { autoDismiss: false });
        push('/overview/offer', { restoreScrollPosition: true });
      })
      .catch((err: Error) => {
        setSubmitting(false);
        setError(err);
      });
  };

  return (
    <Page footer="none" headerTitle={t.offer.headerTitle}>
      <Formik
        initialValues={{
          funeralType,
        }}
        validationSchema={yup.object({
          funeralType: yup.string().required(t.global.validators.required),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, submitForm, dirty, isValid }) => (
          <>
            <Section
              title={t.offer.basicInformationDetails.header}
              onGoBack={handlePush('/overview/offer', { restoreScrollPosition: true })}
              goBackLabel={t.global.labels.offer}
            >
              <Grid>
                <Row justifyContent="center" pb="5rem">
                  <Col width={[1, 10 / 12, 2 / 3]} px={0}>
                    <Card title={t.offer.basicInformationDetails.formTitle}>
                      <Form noValidate>
                        {isSubmitting && <Loader overlay />}
                        <Text my={3}>{t.offer.basicInformationDetails.funeralType}</Text>
                        <RadioGroupField
                          name="funeralType"
                          values={[
                            {
                              value: 'burial',
                              label: t.global.forms.funeralType.burial,
                              suffix: (
                                <RadioHint>{t.global.forms.funeralType.burialHint}</RadioHint>
                              ),
                            },
                            {
                              value: 'cremation',
                              label: t.global.forms.funeralType.cremation,
                              suffix: (
                                <RadioHint>{t.global.forms.funeralType.cremationHint}</RadioHint>
                              ),
                            },
                            {
                              value: 'sea',
                              label: t.global.forms.funeralType.sea,
                              suffix: <RadioHint>{t.global.forms.funeralType.seaHint}</RadioHint>,
                            },
                            {
                              value: 'tree',
                              label: t.global.forms.funeralType.tree,
                              suffix: <RadioHint>{t.global.forms.funeralType.treeHint}</RadioHint>,
                            },
                          ]}
                        />
                      </Form>
                    </Card>
                  </Col>
                </Row>
              </Grid>
            </Section>
            <FormFooter
              labelCancel={t.global.labels.cancel}
              labelSubmit={t.global.labels.submit}
              onSubmit={submitForm}
              onCancel={handlePush('/overview/offer', { restoreScrollPosition: true })}
              disabledSubmit={!dirty || !isValid}
            />
          </>
        )}
      </Formik>
    </Page>
  );
};

export default BasicInformationPage;
