import React, { FunctionComponent } from 'react';
import { styled } from 'mymoria-ui';

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    margin-top: 2rem;
  }

  img {
    height: 10rem;
    text-align: center;
    margin-bottom: 1rem;
  }
`;

const ConfirmationBox: FunctionComponent = ({ children }) => <StyledBox>{children}</StyledBox>;

export default ConfirmationBox;
