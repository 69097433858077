import { Button, Col, Heading, Row, Markdown, Icon } from 'mymoria-ui/components';
import { global } from 'data';
import { refreshTranslation } from 'helpers';
import { styled, css } from 'mymoria-ui/utils';
import React from 'react';
import { RefreshProps } from 'types';

const Title = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;

  svg {
    width: 50px;
    height: 50px;
    margin-right: 1rem;
  }
`;

const StyledButton = styled(Button)(
  ({
    theme: {
      colors,
      font: { weight },
    },
  }) => css`
    color: ${colors.white};
    font-weight: ${weight.bold};

    svg {
      width: 25px;
      height: 40px;
      vertical-align: middle;
      margin-right: 0.5rem;
    }
  `,
);

const RefreshOfferResult = ({ result }: { result: RefreshProps }) => (
  <>
    <Row flexDirection="column">
      <Col width={1}>
        <Title>
          <Icon.Refresh color="#111111" />
          <Heading.Heading2>{refreshTranslation.title}</Heading.Heading2>
        </Title>
        <br />
        <Markdown
          source={
            result
              ? refreshTranslation.responseFeedback(result)
              : global.refreshOffer.result.uptoDate
          }
        />
        <br />
      </Col>
    </Row>
    <Row flexDirection="column" alignItems="center">
      <Col width={[1, 3 / 4]} py={3}>
        <StyledButton block onClick={() => window.location.reload()}>
          <Icon.Reload color="white" />
          {refreshTranslation.submit}
        </StyledButton>
      </Col>
    </Row>
  </>
);

export default RefreshOfferResult;
