import { InfoWindow, Marker as BaseMarker } from '@react-google-maps/api';
import { Text } from 'mymoria-ui/components';
import React, { FunctionComponent, memo, useState } from 'react';
import { cdn, css, styled } from 'mymoria-ui/utils';
import { FuneralType } from 'mymoria-types';
import { FuneralSiteMarker as IFuneralSiteMarker, ImageExt } from 'types';

const StyledMarkerWrapper = styled.div(
  ({ theme: { colors } }) => css`
    background: ${colors.white};
    border-radius: 5px;
    white-space: nowrap;
  `,
);

interface FuneralSiteMarkerProps extends Pick<IFuneralSiteMarker, 'id' | 'name'> {
  lat: number;
  lng: number;
  funeralType: FuneralType;
  onClick: (id: string) => void;
  origined: boolean;
  selected: boolean;
  imageExt: ImageExt;
}

const getIconURL = (
  funeralType: FuneralType,
  isSelected: boolean,
  isOrigined: boolean,
  ext: ImageExt = 'svg',
) => {
  const keys: string[] = [funeralType === 'cremation' ? 'burial' : funeralType];

  if (isOrigined) keys.push('origined');
  if (isSelected) keys.push('selected');

  return cdn('www', `/img/markers/${keys.join('-')}.${ext}`);
};

const FuneralSiteMarker: FunctionComponent<FuneralSiteMarkerProps> = ({
  id,
  funeralType,
  onClick,
  lat,
  lng,
  name,
  origined,
  selected,
  imageExt,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <BaseMarker
      cursor="pointer"
      icon={{ url: getIconURL(funeralType, selected || hovered, origined, imageExt) }}
      position={{ lat, lng }}
      onClick={() => onClick(id)}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      {hovered && (
        <InfoWindow
          options={{ pixelOffset: new google.maps.Size(0, -2, 'px', 'px') }}
          position={{ lat, lng }}
        >
          <StyledMarkerWrapper onClick={() => onClick(id)}>
            <Text>{name}</Text>
          </StyledMarkerWrapper>
        </InfoWindow>
      )}
    </BaseMarker>
  );
};

export default memo(FuneralSiteMarker);
