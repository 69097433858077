import { RadioGroupOptions } from 'mymoria-ui/components/Form/RadioGroup';
import { global as t } from 'data';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { TrustedPerson } from 'mymoria-types';
import { Card, Col, Heading, Row, Text } from 'mymoria-ui/components';
import { sprintf } from 'utils';
import moment from 'moment';
import { InputField, RadioGroupField, TextareaField, EmailField } from 'forms/fields';
import config from 'config';
import { useToast } from 'mymoria-ui/hooks';

interface TrustedPersonFormProps {
  permissionGrantedAt?: Date;
}

export interface TrustedPersonValues extends Omit<TrustedPerson, 'permissionGrantedAt'> {
  permissionGranted?: 'true' | 'false';
}

const saluteTypes: RadioGroupOptions[] = [
  { value: 'sir', label: t.forms.salutation.sir },
  { value: 'madame', label: t.forms.salutation.madame },
];

const permissionTypes: RadioGroupOptions[] = [
  {
    value: 'true',
    label: t.forms.permission.granted,
  },
  { value: 'false', label: t.forms.permission.declined },
];

const TrustedPersonForm: FunctionComponent<TrustedPersonFormProps> = ({ permissionGrantedAt }) => {
  const { addToast } = useToast();
  const [emailDirty, setEmailDirty] = useState(false);
  const { values, initialValues, setFieldValue } = useFormikContext<TrustedPersonValues>();

  useEffect(() => {
    if (values.email !== initialValues.email) {
      setEmailDirty(true);
      setFieldValue('permissionGranted', undefined);
    } else {
      setEmailDirty(false);
      setFieldValue('permissionGranted', initialValues.permissionGranted);
    }
  }, [values.email, initialValues.email, initialValues.permissionGranted, setFieldValue, addToast]);

  useEffect(() => {
    if (emailDirty && initialValues.email) {
      addToast(t.trustedPerson.permission.warning, 'warning');
    }
  }, [addToast, emailDirty, initialValues.email]);

  return (
    <Card
      title={t.trustedPerson.personalInfo.title}
      caption={t.trustedPerson.personalInfo.caption}
      hint={t.trustedPerson.personalInfo.hint}
    >
      <Row flexWrap="wrap" mx={-1}>
        {permissionGrantedAt && !emailDirty && (
          <Col mb="1rem">
            <Text>
              {sprintf(t.trustedPerson.permission.grantedDate, {
                date: moment(permissionGrantedAt).format(config.dateFormat.view),
              })}
            </Text>
          </Col>
        )}
        <Col width={1}>
          <RadioGroupField name="salutation" values={saluteTypes} />
        </Col>
        <Col width={[1, 1, 1 / 2]}>
          <InputField name="firstname" label={t.forms.firstname} />
        </Col>
        <Col width={[1, 1, 1 / 2]}>
          <InputField name="lastname" label={t.forms.lastname} />
        </Col>
        <Col width={[1, 1, 1 / 2]}>
          <InputField name="relationship" label={t.forms.relationship} />
        </Col>
        <Col width={[1, 1, 1 / 2]}>
          <InputField name="street" label={t.forms.street} />
        </Col>
        <Col width={[1, 1, 1 / 2]}>
          <InputField name="postalCode" label={t.forms.postalCode} />
        </Col>
        <Col width={[1, 1, 1 / 2]}>
          <InputField name="city" label={t.forms.city} />
        </Col>
        <Col width={[1, 1, 1 / 2]}>
          <EmailField name="email" label={t.forms.email} />
        </Col>
        <Col width={[1, 1, 1 / 2]}>
          <InputField name="phone" label={t.forms.phone} />
        </Col>
        <Col width={1}>
          <TextareaField name="note" label={t.forms.note} />
        </Col>
        {(!permissionGrantedAt || emailDirty) && (
          <Col width={1} mt="2rem">
            <Heading.Heading2>{t.trustedPerson.permission.title}</Heading.Heading2>
            <Text>{t.trustedPerson.permission.caption}</Text>
            <RadioGroupField name="permissionGranted" values={permissionTypes} />
          </Col>
        )}
      </Row>
    </Card>
  );
};

export default TrustedPersonForm;
