import { global as t } from 'data';
import { Form } from 'formik';
import { InputField } from 'forms/fields';
import { withLocationTrack } from 'hocs';
import { Section, Card, Col, Row, Text, Grid } from 'mymoria-ui/components';
import { useHistory } from 'mymoria-ui/hooks';
import React from 'react';
import Cart from '../Cart';

const Address = () => {
  const { handlePush } = useHistory();

  return (
    <Section
      title={t.pricing.address.sectionTitle}
      description={t.pricing.address.sectionDescription}
      onGoBack={handlePush('/overview', { restoreScrollPosition: true })}
      goBackLabel={t.labels.back}
    >
      <Grid>
        <Row flexWrap="wrap" justifyContent="center">
          <Col width={[1, 1, 3 / 5]}>
            <Card variant="large" title={t.pricing.address.formTitle}>
              <Form noValidate>
                <Row flexWrap="wrap">
                  <Col width={1}>
                    <Text>{t.pricing.address.formDescription}</Text>
                  </Col>
                  <Col width={[1, 1, 1 / 2]}>
                    <InputField name="firstname" label="Vorname" />
                  </Col>
                  <Col width={[1, 1, 1 / 2]}>
                    <InputField name="lastname" label="Nachname" />
                  </Col>
                  <Col width={1}>
                    <InputField name="address" label="Straße und Hausnummer" />
                  </Col>
                  <Col width={[1, 1, 1 / 2]}>
                    <InputField name="postalCode" label="PLZ" />
                  </Col>
                  <Col width={[1, 1, 1 / 2]}>
                    <InputField name="city" label="Ort" />
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
          <Col width={[1, 1, 2 / 5]}>
            <Cart />
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

export default withLocationTrack(t.pricing.address.sectionTitle)(Address);
