import React from 'react';
import { global, letter as t } from 'data';
import { Caption, Card, Col, Loader, Row, Text } from 'mymoria-ui/components';
import { DateInputField, SelectField } from 'forms/fields';
import { map, pick, entries } from 'lodash';
import { useFormikContext } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import config from 'config';
import { LetterShippingDate } from 'mymoria-types';

const {
  letter: { dateFormats },
} = config;

export const shippingDateSchema = yup.object().shape({
  generic: yup.string().required(global.validators.required),
  exact: yup.object().when('generic', {
    is: 'EXACT_DATE',
    then: yup
      .object()
      .shape({
        day: yup
          .string()
          .test('is-exact-day', global.validators.invalidDate, value =>
            moment(value, dateFormats.exactDayMonth.en, true).isValid(),
          ),
        year: yup.string().required(global.validators.required),
        exactYear: yup.string().when('year', {
          is: 'EXACT_DATE',
          then: yup
            .string()
            .test('is-exact-year', global.validators.invalidDate, value =>
              moment(value, dateFormats.exactYear.en, true).isAfter(),
            ),
        }),
      })
      .required(global.validators.required),
  }),
});

const ShippingDateForm = () => {
  const { isSubmitting, values } = useFormikContext<LetterShippingDate>();

  return (
    <Card title={t.create.steps.shippingDate.title}>
      {isSubmitting && <Loader overlay />}
      <Row flexWrap="wrap">
        <Col width={1} mb={4}>
          <Text mb={6}>{t.create.steps.shippingDate.description}</Text>
          <Text my={3}>{t.create.steps.shippingDate.deliveryTime}</Text>
          <SelectField
            name="generic"
            placeholder={t.create.steps.shippingDate.genericDatePlaceholder}
            options={entries(t.list.dates).map(([value, label]) => ({ value, label }))}
          />
        </Col>
        {values.generic === 'EXACT_DATE' && (
          <>
            <Col width={[1, 1 / 2]} mb={4}>
              <Text my={3}>{t.create.steps.shippingDate.day}</Text>
              <DateInputField
                name="exact.day"
                format={dateFormats.exactDayMonth.de}
                label={t.create.steps.shippingDate.day}
              />
            </Col>
            <Col width={[1, 1 / 2]} mb={4}>
              <Text my={3}>{t.create.steps.shippingDate.year}</Text>
              <SelectField
                name="exact.year"
                placeholder="Jahr"
                options={entries(
                  pick(t.list.dates, ['EXACT_DATE', 'YEAR_AFTER_FUNERAL']),
                ).map(([value, label]) => ({ value, label }))}
              />
            </Col>
            {values.exact?.year === 'EXACT_DATE' && (
              <Col width={[1, 1 / 2]} mb={4}>
                <Text my={3}>{t.create.steps.shippingDate.exactYear}</Text>
                <DateInputField
                  name="exact.exactYear"
                  format={dateFormats.exactYear.de}
                  label={t.create.steps.shippingDate.exactYear}
                />
              </Col>
            )}
          </>
        )}

        <Col width={1} my={4}>
          <Text>{t.create.steps.shippingDate.priceDescription}</Text>
          <Text bold>{t.create.steps.shippingDate.price}</Text>
        </Col>
        <Col width={1}>
          {map(t.create.steps.shippingDate.remarks, (remark: string) => (
            <Caption light key={remark} fontStyle="italic">
              {remark}
            </Caption>
          ))}
        </Col>
      </Row>
    </Card>
  );
};

export default ShippingDateForm;
