import { styled } from 'mymoria-ui';
import React, { FunctionComponent } from 'react';
import './Confetti.css';
import { times } from 'lodash';

const Wrapper = styled.div`
  z-index: -1;
  position: fixed;
  height: 100%;
  width: 50%;
`;

const Confetti: FunctionComponent = () => (
  <Wrapper>
    {times(80, i => (
      <div key={i} className={`confetti-${i}`} />
    ))}
  </Wrapper>
);

export default Confetti;
