import { useRouteMatch } from 'react-router-dom';
import { Card, EditButton, PriceRow, Text } from 'mymoria-ui/components';
import { useHistory } from 'mymoria-ui/hooks';
import React from 'react';
import * as t from 'data';
import { useSelector } from 'hooks';
import { sprintf } from 'utils';
import { formatPrice } from 'helpers';
import { css, styled } from 'mymoria-ui';
import { calculateCostEstimation } from 'helpers/index';
import OfferTotalPriceSummary from './OfferTotalPriceSummary';

const StyledLine = styled.hr(
  ({ theme: { colors } }) => css`
    border-color: ${colors.primary};
    margin: 1rem 0;
  `,
);

const CostsCard = () => {
  const { handlePush } = useHistory();
  const { url } = useRouteMatch();
  const { price: basePrice, provisionBirth, funeralSiteEstimatedPrice } = useSelector(
    ({ provision }) => provision,
  );
  const bufferCost = parseInt(funeralSiteEstimatedPrice || '0');
  const price = basePrice + bufferCost;
  const { timeToLive, interestRate, ageAtDeath } = calculateCostEstimation(provisionBirth);
  const priceIncrease = timeToLive * price * interestRate;
  const totalFuturePrice = price + priceIncrease;

  return (
    <Card
      title={t.financial.view.costs.title}
      status={funeralSiteEstimatedPrice ? 'completed' : 'incompleted'}
      statusLabel={
        funeralSiteEstimatedPrice ? t.global.status.completed : t.global.status.incompleted
      }
    >
      <OfferTotalPriceSummary />
      <PriceRow
        label={t.financial.wizard.costCalculator.funeralSiteEstimatedPrice}
        value={formatPrice(bufferCost)}
      />
      <PriceRow
        label={sprintf(t.financial.wizard.futureEstimation.futureCost, {
          timeToLive,
        })}
        value={formatPrice(priceIncrease)}
      />
      <StyledLine />
      <PriceRow
        label={sprintf(t.financial.wizard.futureEstimation.totalFutureCost, {
          timeToLive,
          ageAtDeath,
        })}
        value={formatPrice(totalFuturePrice)}
      />
      <StyledLine />
      <Text>
        {sprintf(t.financial.wizard.futureEstimation.footNote, {
          totalFuturePrice: formatPrice(totalFuturePrice),
        })}
      </Text>
      <EditButton
        onClick={handlePush(`${url}${funeralSiteEstimatedPrice ? '/costs' : '/costs/wizard'}`)}
      />
    </Card>
  );
};

export default CostsCard;
