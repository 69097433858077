import { ConnectedRouter } from 'connected-react-router';
import { App } from 'containers';
import { AuthProvider, FunnelProvider } from 'context';
import { Loader, GoogleAndCookies, GoogleMapsWrapper } from 'mymoria-ui/components';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom';
import { Provider as StoreProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import createStore, { history } from 'store';
import * as serviceWorker from './serviceWorker';
import CustomUIProvider from './CustomUIProvider';
import 'core-js/stable';
import 'url-search-params-polyfill';
import 'regenerator-runtime/runtime';
import 'mymoria-ui/styles/theme';
import config from 'config';

const { store, persistor } = createStore();

ReactDOM.render(
  <FunnelProvider>
    <StoreProvider store={store}>
      <AuthProvider>
        <CustomUIProvider>
          <CookiesProvider>
            <PersistGate loading={<Loader />} persistor={persistor}>
              <ConnectedRouter history={history}>
                <GoogleMapsWrapper apiKey={config.googleMaps.key}>
                  <GoogleAndCookies />
                  <App />
                </GoogleMapsWrapper>
              </ConnectedRouter>
            </PersistGate>
          </CookiesProvider>
        </CustomUIProvider>
      </AuthProvider>
    </StoreProvider>
  </FunnelProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
