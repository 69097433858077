import React, { FunctionComponent } from 'react';
import { mq, styled } from 'mymoria-ui';
import { usePush } from 'mymoria-ui/hooks';
import { Button, Col, Row, Card, Text, StepperFormFooter } from 'mymoria-ui/components';
import { letter as t } from 'data';
import { Step as StepProps } from './types';

const StyledButton = styled(Button)(
  mq({
    margin: ['1rem 0.5rem 1rem 0', '1rem 0.5rem 0 0', '1rem 1rem 0 0'],
  }),
);

const ButtonsCol = styled(Col)`
  display: flex;

  button {
    ${mq({
      width: ['100%', 'auto'],
    })};
  }

  ${mq({
    flexWrap: ['wrap', 'wrap', 'nowrap'],
    flexDirection: ['column', 'column', 'row'],
    justifyContent: ['center', 'center', 'left'],
  })};
`;

const StyledCard = styled(Card)`
  position: relative;
`;

const Flower = styled(Col)`
  margin: 0;
  min-width: 0;
  position: absolute;
  z-index: 10;
  width: 100%;
  padding: 0;
  left: 0;
  display: flex;
  justify-content: center;

  ${mq({
    top: ['-68px', '-100px'],
  })};

  img {
    ${mq({
      width: ['100px', '160px'],
    })};
  }
`;

const ConfirmationStep: FunctionComponent<StepProps> = ({ letterId, ...props }) => {
  const { handlePush } = usePush();

  return (
    <Row alignItems="center" flexDirection="column" justifyContent="center">
      <Col my="5rem" width={[1, 5 / 6]}>
        <StyledCard title={t.create.steps.confirmation.title}>
          <Flower>
            <img src="/img/img_pp_flowers_01" alt="flower" />
          </Flower>
          <Text py="2rem">{t.create.steps.confirmation.description}</Text>
          <ButtonsCol width={1}>
            <StyledButton
              onClick={handlePush('/overview/letters/edit', letterId)}
              className="create_btn"
              variant="primary"
            >
              {t.create.steps.confirmation.labelSubmitBtn}
            </StyledButton>
            <StyledButton ghost onClick={handlePush('/overview/letters')}>
              {t.create.steps.confirmation.labelCancelBtn}
            </StyledButton>
          </ButtonsCol>
        </StyledCard>
      </Col>

      <StepperFormFooter
        labelPrev={t.create.steps.confirmation.labelCancel}
        labelNext={t.create.steps.confirmation.labelSubmit}
        {...props}
        onClickNext={handlePush('/overview/letters')}
      />
    </Row>
  );
};

export default ConfirmationStep;
