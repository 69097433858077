import React, { FunctionComponent, useLayoutEffect } from 'react';
import { Route, Redirect, RouteComponentProps, Switch } from 'react-router-dom';
import { useSelector } from 'hooks';
import { isEmpty } from 'lodash';
import { Page, Section } from 'components';
import { Col, Grid, Row } from 'mymoria-ui/components';
import { useHistory } from 'mymoria-ui/hooks';
import { withLocationTrack } from 'hocs';
import { financial, global as t } from 'data';
import PartnerPage from './PartnerPage';
import CostsCard from './CostsCard';
import PartnerCard from './PartnerCard';
import InsuranceCard from './InsuranceCard';
import InsuranceWizard from './InsuranceWizard';
import InsuranceEditPage from './InsuranceEditPage';
import CostsWizard from './CostsWizard';
import CostsEditPage from './CostsEditPage';

const FinancePage: FunctionComponent<RouteComponentProps> = ({ match, history }) => {
  const { handlePush } = useHistory();
  const financialProvisionType = useSelector(({ provision }) => provision.financialProvisionType);

  useLayoutEffect(() => {
    if (isEmpty(financialProvisionType)) {
      history.replace(`${match.url}/insurance/wizard`);
    }
  }, [financialProvisionType, history, match.url]);

  return (
    <Switch>
      <Route path={`${match.path}/partner`} component={PartnerPage} />
      <Route path={`${match.path}/costs/wizard`} component={CostsWizard} />
      <Route path={`${match.path}/costs`} component={CostsEditPage} />
      <Route path={`${match.path}/insurance/wizard`} component={InsuranceWizard} />
      <Route path={`${match.path}/insurance`} component={InsuranceEditPage} />
      <Route exact path={match.path}>
        <Page>
          <Section
            title={financial.title}
            description={financial.description}
            onGoBack={handlePush('/overview', { restoreScrollPosition: true })}
            goBackLabel={t.overview.name}
          >
            <Grid>
              <Row justifyContent="center">
                <Col width={[1, 10 / 12, 2 / 3]}>
                  <CostsCard />
                  <PartnerCard />
                  <InsuranceCard />
                </Col>
              </Row>
            </Grid>
          </Section>
        </Page>
      </Route>
      <Redirect to={match.path} />
    </Switch>
  );
};

export default withLocationTrack(financial.title)(FinancePage);
