import React from 'react';
import { Col, FormFooter, Grid, Row } from 'mymoria-ui/components';
import { Form, Formik } from 'formik';
import { LetterRecipient } from 'mymoria-types';
import { useDispatch, useSelector } from 'hooks';
import { updateLetter } from 'actions/letters';
import { global, letter as t } from 'data';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'mymoria-ui/hooks';
import { merge } from 'lodash';
import { Section } from 'components';
import { RecipientForm, recipientSchema } from '../forms';

const EditRecipient = () => {
  const { handlePush } = useHistory();
  const dispatch = useDispatch();
  const { state: letterId } = useLocation<string>();
  const letter = useSelector(({ entities }) => entities.letters[letterId]);

  const handleSubmit = (recipient: LetterRecipient) =>
    dispatch(updateLetter(letterId, { recipient })).then(
      handlePush('/overview/letters/edit', letterId),
    );

  return (
    <Section
      title={t.edit.recipient.sectionTitle}
      onGoBack={handlePush('/overview/letters/edit', letterId)}
      goBackLabel={t.edit.content.back}
    >
      <Grid>
        <Row justifyContent="center">
          <Col width={[1, 10 / 12, 2 / 3]} marginBottom="7rem">
            <Formik
              initialValues={merge({}, letter.recipient)}
              validationSchema={recipientSchema}
              onSubmit={handleSubmit}
            >
              {({ dirty, isValid, submitForm }) => (
                <Form noValidate>
                  <RecipientForm />
                  <FormFooter
                    labelCancel={global.labels.cancel}
                    labelSubmit={global.labels.submit}
                    onCancel={handlePush('/overview/letters/edit', letterId)}
                    onSubmit={submitForm}
                    disabledSubmit={!dirty || !isValid}
                  />
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Grid>
    </Section>
  );
};

export default EditRecipient;
