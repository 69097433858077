import React, { FunctionComponent } from 'react';
import * as t from 'data';
import { PriceRow, Row, Text, WizardCard } from 'mymoria-ui/components';
import { InputField } from 'forms/fields';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'hooks';
import { WizardComponentProps } from 'react-albus';
import { useHistory, useToast } from 'mymoria-ui/hooks';
import { formatPrice } from 'helpers';
import { css, styled } from 'mymoria-ui';
import { isEmpty } from 'lodash';
import { Provision } from 'mymoria-types';
import * as yup from 'yup';
import { updateProvision } from 'actions/provision';
import OfferTotalPriceSummary from '../OfferTotalPriceSummary';

type FormValues = Pick<Provision, 'funeralSiteEstimatedPrice'>;

const StyledPriceRow = styled(PriceRow)(
  ({ theme: { colors, fontSizes } }) => css`
    border-top: 2px solid ${colors.primary};
    border-bottom: 2px solid ${colors.primary};
    padding: 1rem 0;
    font-size: ${fontSizes[4]};
  `,
);

const CostsCalculatorStep: FunctionComponent<WizardComponentProps> = ({ wizard }) => {
  const { handlePush } = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { price, funeralSiteEstimatedPrice } = useSelector(({ provision }) => provision);

  const handleSubmit = async ({ funeralSiteEstimatedPrice }: FormValues) => {
    try {
      await dispatch(updateProvision({ funeralSiteEstimatedPrice }));
      wizard.push('futureEstimationStep');
    } catch (error) {
      addToast(t.global.apiErrors.general);
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ funeralSiteEstimatedPrice }}
      validationSchema={yup.object().shape({
        funeralSiteEstimatedPrice: yup
          .string()
          .matches(/^(0|[1-9][0-9]+)$/, t.financial.calculator.priceValidateMsg),
      })}
    >
      {({ submitForm, isSubmitting, values: { funeralSiteEstimatedPrice }, errors }) => (
        <WizardCard
          title={t.financial.wizard.costCalculator.title}
          onClickPrev={handlePush('/overview/finance')}
          onClickNext={submitForm}
          isLoading={isSubmitting}
          labelPrev={t.global.labels.back}
          labelNext={t.global.labels.continue}
          disabledNext={!isEmpty(errors)}
        >
          <OfferTotalPriceSummary />
          <Row mt={3}>
            <Text my={0} mr={2}>
              {t.financial.wizard.costCalculator.funeralSiteEstimatedPrice}
            </Text>
            <InputField name="funeralSiteEstimatedPrice" />
          </Row>
          <StyledPriceRow
            label={t.financial.wizard.costCalculator.total}
            value={formatPrice(price + parseInt(funeralSiteEstimatedPrice || '0'))}
          />
        </WizardCard>
      )}
    </Formik>
  );
};

export default CostsCalculatorStep;
