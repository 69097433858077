import { routerActions as router } from 'connected-react-router';
import { default as graveTypes } from './graveTypes';
import { default as insurance } from './insurance';
import { default as provision } from './provision';
import { default as flags } from './flags';
import { default as letters } from './letters';
import { default as tracking } from './tracking';
import { default as params } from './params';
import { default as funeralSites } from './funeralSites';
import { default as products } from './products';
import { default as registration } from './registration';
import { default as salesforce } from './salesforce';

export default {
  router,
  provision,
  graveTypes,
  insurance,
  flags,
  letters,
  tracking,
  params,
  funeralSites,
  products,
  registration,
  salesforce,
};
