import React, { FunctionComponent } from 'react';
import { styled } from 'mymoria-ui/utils';
import OverviewCard, { OverviewCardProps } from 'components/OverviewCard';

const Paper = styled(OverviewCard)`
  position: relative;
  border-radius: 0;

  &:before,
  &:after {
    position: absolute;
    width: 40%;
    height: 10px;
    content: ' ';
    left: 12px;
    bottom: 12px;
    background: transparent;
    transform: skew(-5deg) rotate(-5deg);
    box-shadow: 0 12px 15px rgba(0, 0, 0, 0.5);
    z-index: -1;
  }
  &:after {
    left: auto;
    right: 12px;
    transform: skew(5deg) rotate(5deg);
  }
`;

const LetterPaper: FunctionComponent<OverviewCardProps> = props => <Paper {...props} />;

export default LetterPaper;
