import { trackSigninProcess } from 'actions/tracking';
import { global as t } from 'data';
import { Formik } from 'formik';
import SignInForm, { SignInFormValues } from 'forms/SignInForm';
import { useAuth, useDispatch } from 'hooks';
import { Col, Row } from 'mymoria-ui/components';
import { usePush, useToast } from 'mymoria-ui/hooks';
import React, { useCallback, FunctionComponent } from 'react';
import * as Yup from 'yup';
import { Page, SectionPublic } from 'components';
import SigninPromoSection from './SigninPromoSection';
import { get } from 'lodash';
import { RouteComponentProps } from 'react-router';
import { withLocationTrack } from 'hocs';

const SignIn: FunctionComponent<RouteComponentProps> = ({ location }) => {
  const dispatch = useDispatch();
  const { signIn, signOut } = useAuth();
  const { push } = usePush();
  const { addToast } = useToast();

  const handleSubmit = useCallback(
    (values: SignInFormValues) =>
      signIn(values)
        .then(({ username }) => {
          dispatch(trackSigninProcess('sign in', username));
          push(get(location, 'state.from.pathname', '/'));
        })
        .catch(err => {
          addToast(err.message, 'error');
          signOut();
        }),
    [dispatch, push, signIn, signOut, addToast, location],
  );

  return (
    <Page footer="none">
      <Row justifyContent="center" flex={1}>
        <Col px={0} width={[1, 1, 1 / 2]}>
          <SectionPublic
            title={t.signIn.header}
            description={t.signIn.subheader}
            footerLink="/register"
            footerLinkLabel={t.signIn.register}
          >
            <Formik
              component={SignInForm}
              validationSchema={Yup.object().shape({
                username: Yup.string().email(t.validators.email).required(t.validators.required),
                password: Yup.string().required(t.validators.required).min(6, t.validators.length),
              })}
              initialValues={{
                password: '',
                username: '',
              }}
              onSubmit={handleSubmit}
            />
          </SectionPublic>
        </Col>
        <Col px={0} width={1 / 2} display={['none', 'none', 'flex']}>
          <SigninPromoSection />
        </Col>
      </Row>
    </Page>
  );
};

export default withLocationTrack(t.signIn.header)(SignIn);
