import React, { FunctionComponent } from 'react';
import * as t from 'data';
import { financial } from 'data';
import { Text, WizardCard } from 'mymoria-ui/components';
import { WizardComponentProps } from 'react-albus';
import { Formik } from 'formik';
import { useHistory, useToast } from 'mymoria-ui/hooks';
import { useDispatch, useSelector } from 'hooks';
import { updateProvision } from 'actions/provision';
import SelectLightField from 'forms/fields/SelectLightField';
import { existingInsuranceTypes } from 'forms/options';
import { InputField, TextareaField } from 'forms/fields';
import { Provision } from 'types';

type FormValues = Pick<
  Provision,
  | 'existingInsuranceCompany'
  | 'existingInsuranceContractNumber'
  | 'existingInsuranceType'
  | 'financialProvisionNote'
>;

const InsuranceDetailsStep: FunctionComponent<WizardComponentProps> = ({ wizard }) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { handlePush, push } = useHistory();
  const {
    existingInsuranceCompany,
    existingInsuranceContractNumber,
    existingInsuranceType,
    financialProvisionNote,
  } = useSelector(({ provision }) => provision);

  const initialValues: FormValues = {
    existingInsuranceCompany,
    existingInsuranceContractNumber,
    existingInsuranceType,
    financialProvisionNote,
  };

  const handleSubmit = (values: FormValues) =>
    dispatch(updateProvision(values))
      .then(() => addToast(t.financial.form.success, 'info'))
      .then(handlePush('/overview/finance'))
      .catch(() => addToast(t.global.apiErrors.general, 'error'));

  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      {({ submitForm, dirty, isSubmitting }) => (
        <WizardCard
          title={t.financial.wizard.insuranceType.title}
          onClickPrev={() => wizard.push('type')}
          onClickNext={() => (dirty ? submitForm() : push('/overview/finance'))}
          isLoading={isSubmitting}
          labelPrev={t.global.labels.back}
          labelNext={t.global.labels.continue}
        >
          <Text>{financial.edit.description}</Text>
          <SelectLightField
            name="existingInsuranceType"
            options={existingInsuranceTypes}
            label={financial.form.existingInsuranceType.label}
            hint={financial.form.existingInsuranceType.hint}
          />
          <InputField
            name="existingInsuranceCompany"
            label={financial.form.existingInsuranceCompany.label}
            hint={financial.form.existingInsuranceCompany.hint}
          />
          <Text py={1}>{financial.edit.optionalNote}</Text>
          <InputField
            name="existingInsuranceContractNumber"
            label={financial.form.existingInsuranceContractNumber.label}
            hint={financial.form.existingInsuranceContractNumber.hint}
          />
          <TextareaField
            name="financialProvisionNote"
            rows={8}
            label={financial.form.financialProvisionNote.label}
          />
        </WizardCard>
      )}
    </Formik>
  );
};

export default InsuranceDetailsStep;
