import { fetchTiles, activateAccount } from 'actions/flags';
import { canActivate } from 'helpers';
import { takeEvery, all, fork, select, call, put } from 'redux-saga/effects';
import { ApplicationState } from 'types';

/**
 * // worker Saga: will be fired on PROVISION_UPDATE actions
 */

function* handleActiveFlag(): Generator {
  try {
    const getProvision = (state: ApplicationState) => state.provision;

    // fetch provision from store
    const provision: any = yield select(getProvision);

    if (!provision.paymentAcceptedPlan) {
      // fetch provision tiles from cms
      const tiles: any = yield call(fetchTiles);

      // check if user is eligible to active the account
      const canActivatePlan = canActivate(
        tiles.filter((item: any) => item.Level === 'important'),
        provision,
      );

      if (canActivatePlan) {
        // trigger account activation api end point
        const data = yield call(() => activateAccount(provision));

        if (data) {
          // dispatch provision activate flag action
          yield put({ type: 'PROVISION_ACTIVE_FLAG', payload: { activeModal: true } });
          // dispatch provision activate action
          yield put({ type: 'PROVISION_ACTIVATE', payload: data });
        }
      }
    }
  } catch (e) {
    console.error('update provision watcher failed');
  }
}

/**
 * Watches provision update action every time it trigger
 */
function* watchUpdateProvisionRequest(): Generator {
  yield takeEvery('PROVISION_UPDATE', handleActiveFlag);
}

/**
 * saga init, forks in effects, other sagas
 */
export default function* flagsSaga() {
  yield all([fork(watchUpdateProvisionRequest)]);
}
