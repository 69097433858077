import React, { FunctionComponent } from 'react';
import { Formik } from 'formik';
import { Redirect, Route, Switch } from 'react-router-dom';
import { StepperConfig } from 'hooks/useStepper';
import { useStepper } from 'hooks';

const Stepper: FunctionComponent<StepperConfig> = ({ basepath, steps, ...props }) => {
  const { formikProps } = useStepper({ basepath, steps, ...props });

  return (
    <Formik enableReinitialize {...formikProps}>
      <Switch>
        {steps.map(({ path, component }) => (
          <Route key={path} path={`${basepath}${path}`} component={component} />
        ))}
        <Redirect to={`${basepath}${steps[0].path}`} />
      </Switch>
    </Formik>
  );
};

export default Stepper;
