import { trackUserInteraction } from 'actions/tracking';
import { CheckMark } from 'components/Icons';
import { global } from 'data';
import { useAuth, useDispatch } from 'hooks';
import { styled, css } from 'mymoria-ui';
import { Button, Heading, Loader, Text } from 'mymoria-ui/components';
import { useToast } from 'mymoria-ui/hooks';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { sprintf } from 'utils';

interface Props {
  message: string;
  username: string;
  onSend?: (value: boolean) => void;
  isUserNameEncrypted?: boolean;
}

const StyledHeading = styled(Heading.Heading1)`
  margin: 4rem 0 3rem;
  text-align: center;
`;

const StyledText = styled(Text)(
  ({ theme: { font } }) => css`
    font-weight: ${font.weight.bold};
    max-width: 650px;
    text-align: center;
  `,
);

const StyledMessage = styled(Text)(
  ({ theme: { font } }) => css`
    font-weight: ${font.weight.bold};
    margin: 3rem 0 5rem;
    text-align: center;
  `,
);

const ResendConfirmationEmail: FunctionComponent<Props> = ({
  message,
  username,
  onSend,
  isUserNameEncrypted = false,
}) => {
  const { sendConfirmationEmail } = useAuth();
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const [sent, setSent] = useState(false);
  const { addToast } = useToast();
  const [usernameDecoded, setUsernameDecoded] = useState<string>();

  const handleClick = useCallback(() => {
    setPending(true);
    return sendConfirmationEmail(username, isUserNameEncrypted)
      .then(({ username: usernameDecoded }) => {
        setUsernameDecoded(usernameDecoded);
        dispatch(trackUserInteraction('Resend Confirmation', usernameDecoded));
        setPending(false);
        setSent(true);
        if (onSend) {
          onSend(false);
        }
      })
      .catch(({ message }) => {
        setPending(false);
        addToast(message);
      });
  }, [sendConfirmationEmail, username, isUserNameEncrypted, dispatch, onSend, addToast]);

  return (
    <>
      {sent ? (
        <>
          <CheckMark />
          <StyledHeading>{global.activateAccount.emailSent.title}</StyledHeading>
          <StyledText>
            {sprintf(global.activateAccount.emailSent.desc, { email: usernameDecoded })}
          </StyledText>
        </>
      ) : (
        <>
          <Heading.Heading1>{global.activateAccount.hello}</Heading.Heading1>
          <StyledMessage>{message}</StyledMessage>
          <Button disabled={pending || !username} onClick={handleClick}>
            {global.labels.resendConfirmationEmail}
          </Button>
          {pending && <Loader overlay />}
        </>
      )}
    </>
  );
};

export default ResendConfirmationEmail;
