import config from 'config';
import { remainingYearsToLive, global } from 'data';
import { endsWith, find, get, isEmpty, partition, round, some } from 'lodash';
import moment from 'moment';
import { FuneralSite, Service } from 'mymoria-types';
import { sprintf } from 'utils';
import { PortalSection, ProductsState, Provision, Tile, RefreshProps } from 'types';

export * from './proposal';
export * from './validationSchema';

const { germanTax, currency, tilesOptions } = config;
const { refreshOffer } = global;

export const formatPrice = (price: number = 0, { gross = false, fractionDigit = 0 } = {}) =>
  round(gross ? +price * germanTax : +price).toLocaleString('de-DE', {
    style: 'currency',
    currency,
    minimumFractionDigits: fractionDigit,
    maximumFractionDigits: 2,
  });

export const getUserAge = (provisionBirth: string) =>
  moment().diff(moment(provisionBirth), 'years');
export const getUserYearsToLive = (age: number) => Math.round(remainingYearsToLive.ttl[age]);
export const getUserFutureYearsToLive = (timeToLive: number) =>
  Math.round(timeToLive + moment().year());
export const getInflationCosts = (price: number) =>
  Math.round(price * remainingYearsToLive.interestRate);
export const getInsuranceStartDate = () =>
  moment().date() > 1 ? moment().set('date', 1).add(1, 'months').valueOf() : moment().valueOf();

export const calculateCostEstimation = (provisionBirth: string) => {
  const { ttl, interestRate, defaultYear } = remainingYearsToLive;
  const age = getUserAge(provisionBirth);
  const timeToLive = round(ttl[provisionBirth ? age : defaultYear]);
  const futureYears = getUserFutureYearsToLive(timeToLive);

  return {
    age,
    timeToLive,
    futureYears,
    interestRate,
    futureEstimatedPrice: timeToLive * interestRate,
    ageAtDeath: age + timeToLive,
  };
};

export const calculateTotalFuturePrice = (provision: Provision) => {
  const price = provision.price + parseInt(provision.funeralSiteEstimatedPrice || '0');

  const { timeToLive, interestRate, age } = calculateCostEstimation(provision.provisionBirth);

  const priceIncrease = timeToLive * price * interestRate;

  return { totalFuturePrice: price + priceIncrease, age };
};

// todo: refactor!
export const financialCalculator = ({
  funeralSiteEstimatedPrice,
  price,
  additionalCosts = [],
  funeralSite,
  provisionBirth,
}: Provision) => {
  const todayPrice = round(price);
  const { age, timeToLive, interestRate } = calculateCostEstimation(provisionBirth);
  const inflationCost = round(todayPrice * interestRate);

  const funeralPrice = !funeralSite ? parseInt(funeralSiteEstimatedPrice || '0') : 0;

  let additionalCost = additionalCosts.reduce((acc: any, item) => {
    acc['total'] = (parseInt(acc['total']) || 0) + parseInt(item.estimatedPrice || '0');
    return acc;
  }, []);

  // total without inflation
  const subTotal = round(additionalCost.total || 0) + todayPrice + funeralPrice;

  return { age, todayPrice, subTotal, timeToLive, inflationCost, funeralPrice };
};

export const parseQueryParams = (query: string, params: string[]) => {
  const queryParams = new URLSearchParams(query);
  const parsedParams: { [key: string]: string } = {};
  params.forEach(param =>
    queryParams.get(param) ? (parsedParams[param] = queryParams.get(param) || '') : {},
  );

  return parsedParams;
};

export const isTileCompleted = (tile: Tile, provision: Partial<Provision>) =>
  some(tilesOptions[tile], field =>
    field === 'additionalCosts'
      ? provision[field]?.some(({ estimatedPrice = '' }) => parseInt(estimatedPrice) > 0)
      : field === 'paymentPriceGuarantee'
      ? provision[field]
      : !isEmpty(provision[field]),
  );

// map tile completed status with tile details
export const tilesDetails = (
  portalSection: PortalSection[] = [],
  provision: Partial<Provision>,
) => {
  const array = [] as PortalSection[];

  portalSection.map(item => {
    const obj = {
      Tile: item.Tile,
      Sort: item.Sort,
      Section: item.Section,
      ButtonLink: item.ButtonLink,
      IntroButton: item.IntroButton,
      IntroDescription: item.IntroDescription,
      IntroHeadline: item.IntroHeadline,
      Completed: isTileCompleted(item.Tile, provision),
    } as PortalSection;

    item.Section === 'upper' && item.Level === 'important' && !obj.Completed && array.push(obj);
    return array;
  });

  return array.sort((a, b) => (a.Sort < b.Sort ? -1 : 1));
};

export const canActivate = (portalSections: any, provision: Provision) => {
  const [upperTiles = []] = partition(
    portalSections,
    ({ Section }: PortalSection) => Section === 'upper',
  );

  const completedUpperTiles = upperTiles.reduce(
    (acc, { Tile: tileName, Level: defaultStatus }: PortalSection) =>
      isTileCompleted(tileName, provision) && defaultStatus === 'important' ? acc + 1 : acc,
    0,
  );

  return upperTiles.length === completedUpperTiles;
};

export const getFuneralSitePrice = (funeralSite: FuneralSite, graveType: string = '') =>
  get(funeralSite, `fees.prices[${graveType}].selling_price`, 0) * germanTax;

export const getEstimatedGravePrice = (funeralSite: FuneralSite, graveType: string) => {
  const prices = funeralSite.fees.prices[graveType];

  if (prices?.selling_price) {
    return formatPrice(prices?.selling_price, { gross: true });
  }

  return '';
};

export const getRootDomain = () => {
  if (window?.location.hostname === 'localhost' || window?.location.hostname === '127.0.0.1') {
    return window?.location.hostname;
  }

  return `.${window?.location.hostname.split('.').slice(-2).join('.')}`;
};

export const findBasicService = (services: { [id: string]: Service }) =>
  find(services, ({ identifier }) => endsWith(identifier, 'service-basic'));

export const findAnonymousBasicService = (services: { [id: string]: Service }) =>
  find(services, ({ identifier }) => endsWith(identifier, 'service-basic-anonymous'));

export const getOptionalDefaultProductId = (products: ProductsState, id: string) => {
  // We rely on that optional and default products are stored in the same order,
  // what is guaranteed by sorting them based on categories
  const productIndex = products.optional.indexOf(id);

  if (productIndex === -1) {
    return undefined;
  }

  return products.defaults.optional[productIndex];
};

// if createdAt is older than 6 months return true
export const isOfferOutOfDate = (createdAt: Date) => {
  const current = moment().startOf('day');
  const given = moment(createdAt, 'YYYY-MM-DD');
  //Difference in number of months
  return moment.duration(current.diff(given)).asMonths() > 6;
};

export const refreshTranslation = {
  title: refreshOffer.result.title,
  submit: refreshOffer.result.submit,
  responseFeedback: ({
    products: { removedProducts, replacedProducts, updatedProducts },
    updatedServices,
  }: RefreshProps) => {
    let textList = '';

    if (removedProducts.length > 0) {
      const articleNumbers = removedProducts.map(
        ({ articleNumber }, index) => `\n\n  ${index + 1}- Artikelnummer ${articleNumber}`,
      );

      textList += sprintf(refreshOffer.result.removedProducts, {
        articleNumbers: articleNumbers.join(' '),
      });
    }

    if (updatedProducts.length > 0) {
      const shortnames = updatedProducts.map(
        ({ translations }, index) => `\n\n  ${index + 1}- ${translations.shortname}`,
      );
      textList += sprintf(refreshOffer.result.updatedProducts, {
        shortnames: shortnames.join(' '),
      });
    }

    if (replacedProducts.length > 0) {
      const shortnames = replacedProducts.map(
        ({ translations }, index) => `\n\n  ${index + 1}- ${translations.shortname}`,
      );
      textList += sprintf(refreshOffer.result.replacedProducts, {
        shortnames: shortnames.join(' '),
      });
    }

    if (updatedServices.length > 0) {
      const shortnames = updatedServices.map(
        ({ translations }, index) => `\n\n  ${index + 1}- ${translations.shortname}`,
      );
      textList += sprintf(refreshOffer.result.updatedServices, {
        shortnames: shortnames.join(' '),
      });
    }

    return textList;
  },
};

export const getConsentFromCookies = (
  cookies: {
    [name: string]: any;
  },
  cookieSiteSlug: string,
) => {
  let consent: { [key: string]: boolean } = {};

  if (cookies) {
    for (const [key, value] of Object.entries(cookies)) {
      if (key.startsWith(cookieSiteSlug)) {
        consent[key.replace(cookieSiteSlug, '')] = value === 'true';
      }
    }
  }

  return consent;
};
