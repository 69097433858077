import { trackLocation } from 'actions/tracking';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'hooks';
import { DL_INIT, GTMLoad, GTM_JS, initializeDataLayer } from 'actions/tracking';
import { getConsentFromCookies } from 'helpers';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import config from 'config';

const { cookieSiteSlug } = config;

interface Callback<T> {
  (props: T): string;
}

export default <R extends {}>(
  name: string | Callback<R>,
  group: string | Callback<R> = 'provision-portal',
) => <P extends R>(WrappedComponent: FunctionComponent<P>) => {
  const WithLocationTrack: FunctionComponent<P> = props => {
    const [isGTMLoaded, setGTMLoaded] = useState(false);
    const [cookies] = useCookies();
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const nameValue = typeof name === 'string' ? name : name(props);
    const groupValue = typeof group === 'string' ? group : group(props);

    useEffect(() => {
      const handleGtmLoadState = () => {
        const consent = getConsentFromCookies(cookies, cookieSiteSlug);

        const [dlInitIndex, hasGTMLoad] = [DL_INIT, GTM_JS].map(event =>
          window.dataLayer?.findIndex(data => data?.event === event),
        );

        if (dlInitIndex === -1) {
          dispatch(initializeDataLayer(consent));
        }

        if (hasGTMLoad === -1) {
          dispatch(GTMLoad());
        }

        setGTMLoaded(true);
      };

      if (!!window.google_tag_manager) {
        handleGtmLoadState();
      } else {
        window.addEventListener('gtm_loaded', handleGtmLoadState, { once: true });
      }
      return () => document.removeEventListener('gtm_loaded', handleGtmLoadState);
    }, [cookies, dispatch]);

    useEffect(() => {
      if (isGTMLoaded) {
        dispatch(
          trackLocation({
            name: nameValue,
            path: pathname,
            contentGroup1: groupValue,
          }),
        );
      }
    }, [dispatch, pathname, nameValue, groupValue, isGTMLoaded]);

    return <WrappedComponent {...props} />;
  };

  return WithLocationTrack;
};
