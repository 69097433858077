import { fetchProvision } from 'actions/provision';
import { fetchGraveTypes } from 'actions/graveTypes';
import { fetchSalesforceUserAccountsData } from 'actions/salesforce';
import { Head, Page, PrivateRoute, PublicRoute } from 'components';
import {
  ActivateAccount,
  DocumentsPage,
  ForgotPassword,
  ForgotPasswordVerify,
  NotFoundPage,
  OfferPage,
  OverviewPage,
  PartnerPage,
  PricingPage,
  ProfilePage,
  ProvisionLoader,
  SignIn,
  SignUp,
  TrustedPersonPage,
  WishesPage,
  DigitalHeritage,
  FinancePage,
  ProvisionCreator,
  SalesforcePage,
} from 'containers';
import { useAuth, useDispatch, useLocalStorage, useSelector } from 'hooks';
import { Loader } from 'mymoria-ui/components';
import { useCMS, useToast } from 'mymoria-ui/hooks';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PortalSection } from 'types';
import LettersPage from './Letters';

const App = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const userEmail = useSelector(({ provision }) => provision.userEmail);
  const [isLoading, setIsLoading] = useState(false);
  const { isAuthenticated, isAdmin, user } = useAuth();
  const [provisionId] = useLocalStorage('provisionId', 'me');
  const [{ data: portalSections = [], loading: portalSectionsLoading }] = useCMS<PortalSection[]>(
    `/PortalSections?simple=1${
      process.env.REACT_APP_ENVIRONMENT === 'production' ? `&filter[Status]=live` : ''
    }`,
  );

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoading(true);
      const id = isAdmin ? provisionId : 'me';
      Promise.all([dispatch(fetchProvision(id)), dispatch(fetchGraveTypes())])
        .catch(({ message }) => addToast(message))
        .finally(() => setIsLoading(false));
    }
  }, [isAuthenticated, isAdmin, provisionId, dispatch, addToast]);

  useEffect(() => {
    if (userEmail && user) {
      // get customer email when customer care admin is viewing their provision
      const email = isAdmin && user.username !== userEmail ? userEmail : user.username;

      // fetch user salesfroce data
      dispatch(fetchSalesforceUserAccountsData(email));
    }
  }, [dispatch, isAdmin, userEmail, user]);

  // todo: this component is re-rendering 5 times on mount - have to be fix
  return (
    <>
      <Head />
      {isLoading || portalSectionsLoading ? (
        <Page withNavigation={false} footer="none">
          <Loader />
        </Page>
      ) : (
        <Switch>
          <Redirect exact from="/" to="/overview" />
          <PrivateRoute path="/overview/offer" component={OfferPage} />
          <PrivateRoute path="/overview/patientenverfuegung" component={PartnerPage} />
          <PrivateRoute path="/overview/wishes" component={WishesPage} />
          <PrivateRoute path="/overview/trusted-person" component={TrustedPersonPage} />
          <PrivateRoute path="/overview/finance" component={FinancePage} />
          <PrivateRoute path="/overview/pricing" component={PricingPage} />
          <PrivateRoute path="/overview/letters" component={LettersPage} />
          <PrivateRoute path="/overview/digitaler-nachlass" component={DigitalHeritage} />
          <PrivateRoute
            path="/overview"
            tiles={portalSections}
            render={props => <OverviewPage tiles={portalSections} {...props} />}
          />
          <PrivateRoute path="/documents" component={DocumentsPage} />
          <PrivateRoute path="/profile" component={ProfilePage} />
          <PrivateRoute path="/salesforce" component={SalesforcePage} />
          <PrivateRoute path="/provisions/:provisionId" component={ProvisionLoader} />
          <PublicRoute path="/login" component={SignIn} />
          <PublicRoute path="/forgot-password/verify" component={ForgotPasswordVerify} />
          <PublicRoute path="/forgot-password" component={ForgotPassword} />
          <PublicRoute path="/register/prepare" component={ProvisionCreator} />
          <PublicRoute path="/register" component={SignUp} />
          <Route path="/activate-account" component={ActivateAccount} />
          <Route component={NotFoundPage} />
        </Switch>
      )}
    </>
  );
};
export default App;
