import { Markdown, WizardCard } from 'mymoria-ui/components';
import { financial, global } from 'data';
import React, { FunctionComponent } from 'react';
import { WizardComponentProps } from 'react-albus';
import { useHistory } from 'mymoria-ui/hooks';

const SummaryStep: FunctionComponent<WizardComponentProps> = ({ wizard: { step, steps } }) => {
  const { handlePush } = useHistory();

  return (
    <WizardCard
      title={financial.wundw.summary.title}
      labelPrev={global.labels.back}
      labelNext={global.labels.continue}
      onClickPrev={handlePush('/overview/finance')}
      onClickNext={handlePush('/overview/finance')}
      steps={steps.length - 1}
      step={steps.indexOf(step)}
    >
      <Markdown>{financial.wundw.summary.description}</Markdown>
    </WizardCard>
  );
};

export default SummaryStep;
