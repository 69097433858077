import React, { useEffect } from 'react';
import { Section } from 'components';
import { usePush } from 'mymoria-ui/hooks';
import { Redirect, useLocation } from 'react-router-dom';
import { useSessionStorage } from 'hooks';
import { letter as t } from 'data';
import ContentStep from './ContentStep';
import RecipientStep from './RecipientStep';
import ShippingDateStep from './ShippingDateStep';
import ConfirmationStep from './ConfirmationStep';
import { Col, Row, Grid } from 'mymoria-ui/components';

const steps = [ContentStep, RecipientStep, ShippingDateStep, ConfirmationStep];

const LetterCreatePage = () => {
  const { handlePush } = usePush();
  const { state: letterId } = useLocation<string>();
  const [step, setStep] = useSessionStorage<number>(`letter-step`, 1);
  const [storedLetterId, storeLetterId] = useSessionStorage<string | null>(`letter-id`, null);
  const Step = steps[step - 1];

  useEffect(() => {
    if (letterId && letterId !== storedLetterId) {
      setStep(1);
      storeLetterId(letterId);
    }
  }, [letterId, storedLetterId, storeLetterId, setStep]);

  return letterId ? (
    <Section
      title={t.create.section.title}
      onGoBack={handlePush('/overview/letters')}
      goBackLabel={t.create.section.back}
    >
      <Grid>
        <Row justifyContent="center">
          <Col width={[1, 10 / 12, 2 / 3]} marginBottom="7rem">
            <Step
              step={step}
              steps={steps.length}
              onClickPrev={() => setStep((step: number) => Math.max(step - 1, 1))}
              onClickNext={() => setStep((step: number) => Math.min(step + 1, steps.length))}
              letterId={letterId}
            />
          </Col>
        </Row>
      </Grid>
    </Section>
  ) : (
    <Redirect to="/overview/letters" />
  );
};

export default LetterCreatePage;
