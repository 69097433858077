import React from 'react';
import { Card, DataPreview } from 'mymoria-ui/components';
import * as t from 'data';
import { useSelector } from 'hooks';
import { useHistory } from 'mymoria-ui/hooks';
import { useRouteMatch } from 'react-router-dom';
import { isOfferEditable } from 'reducers/provision';

const BasicInformationCard = () => {
  const { url } = useRouteMatch();
  const { handlePush } = useHistory();
  const isEditable = useSelector(isOfferEditable());
  const { concernType, relationship, funeralType, graveTypeNote } = useSelector(
    ({ provision }) => provision,
  );

  return (
    <Card title={t.offer.title}>
      <DataPreview
        data={[
          {
            label: t.offer.basicInfo.concernType,
            value: t.offer.concernType[concernType],
          },
          {
            label: t.offer.basicInfo.relationship,
            value: t.offer.relationship[relationship],
          },
          {
            label: t.offer.basicInfo.funeralType,
            value: t.offer.funeralType[funeralType],
          },
        ]}
        editButtonHandler={isEditable ? handlePush(`${url}/basic-information`) : undefined}
      />
      <hr />
      <DataPreview
        data={[{ label: t.global.graveTypeNote.header, value: graveTypeNote }]}
        placeholder={t.global.graveTypeNote.notAdded}
        editButtonLabel={graveTypeNote ? t.global.graveTypeNote.edit : t.global.graveTypeNote.add}
        editButtonHandler={handlePush(`${url}/gravetypenote`)}
      />
    </Card>
  );
};

export default BasicInformationCard;
