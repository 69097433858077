import React, { FunctionComponent } from 'react';
import { styled } from 'mymoria-ui';
import { global as t } from 'data';
import { Text } from 'mymoria-ui/components';

export interface PriceProps {
  className?: string;
  currency?: string;
  decimal?: boolean;
  period?: 'YEAR' | 'MONTH';
  value: number;
}

const StyledText = styled(Text)`
  margin: 0;
  white-space: pre-wrap;
`;

const Price: FunctionComponent<PriceProps> = ({ currency, decimal, period, value, ...props }) => (
  <StyledText {...props}>
    {`${decimal ? value.toFixed(2).replace('.', ',') : value} ${currency} `}
    {period && <span {...props}>{`/ ${t.periods[period.toLowerCase()]}`}</span>}
  </StyledText>
);

Price.defaultProps = {
  className: '',
  currency: t.currency.euro,
  decimal: true,
  period: 'YEAR',
};

export default Price;
