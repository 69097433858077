import { FieldConfig, useField } from 'formik';
import Select, { SelectProps, Option } from 'mymoria-ui/components/Form/Select';
import React, { FunctionComponent } from 'react';

const SelectField: FunctionComponent<Omit<FieldConfig, 'validate'> & SelectProps> = ({
  name,
  options,
  ...props
}) => {
  const [field, , helpers] = useField(name);

  return (
    <Select
      options={options}
      name={field.name}
      value={options ? options.find((option: Option) => option.value === field.value) : ''}
      onChange={(option: Option): void => helpers.setValue(option.value)}
      onBlur={field.onBlur}
      isSearchable={false}
      {...props}
    />
  );
};

export default SelectField;
