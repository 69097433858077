import { Dispatch } from 'redux';
import { createAction } from 'typesafe-actions';
import { FuneralSite } from 'mymoria-types';
import { funeralSiteAPI } from 'api';

const fetchFuneralSiteAction = createAction('FETCH_FUNERAL_SITE')<FuneralSite>();

export default {
  fetchFuneralSite: fetchFuneralSiteAction,
};

export const fetchFuneralSite = (id: string, graveType?: string) => (dispatch: Dispatch) =>
  funeralSiteAPI
    .fetch(id, graveType)
    .then(funeralSite => dispatch(fetchFuneralSiteAction(funeralSite)))
    .then(({ payload }) => payload);
