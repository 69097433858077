import React, { FunctionComponent } from 'react';
import { css, styled } from 'mymoria-ui';
import { LetterStyles } from 'mymoria-types';

interface LetterInputProps {
  value: string;
  placeholder?: string;
  onChange: (v: string) => void;
  styles: LetterStyles;
}

const StyledTextarea = styled.textarea<Pick<LetterStyles, 'styles'>>(
  ({ styles: { color, fontFamily, fontSize }, theme: { colors } }) => css`
    box-sizing: border-box;
    color: ${color};
    font-family: ${fontFamily};
    font-size: ${fontSize}px;
    width: 100%;
    border: 1px dashed ${colors.primary};
    min-height: 400px;
    border-radius: 5px;
    padding: 1rem;
    line-height: 1.5;
  `,
);

const LetterInput: FunctionComponent<LetterInputProps> = ({ onChange, ...props }) => (
  <StyledTextarea onChange={e => onChange(e.target.value)} {...props} />
);

export default LetterInput;
