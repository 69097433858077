import { AuthContext, FunnelContext } from 'context';
import { useContext } from 'react';
import {
  TypedUseSelectorHook,
  useDispatch as useDispatchBase,
  useSelector as useSelectorBase,
} from 'react-redux';
import { RootAction } from 'reducers/types';
import { ThunkDispatch } from 'redux-thunk';
import { ApplicationState } from 'types';
import useStorage from './useStorage';

export const useAuth = () => useContext(AuthContext);
export const useFunnel = () => useContext(FunnelContext);

export const useDispatch = () =>
  useDispatchBase<ThunkDispatch<ApplicationState, any, RootAction>>();

export const useSelector: TypedUseSelectorHook<ApplicationState> = useSelectorBase;
export const useLocalStorage = useStorage('localStorage');
export const useSessionStorage = useStorage('sessionStorage');

export { default as useStepper } from './useStepper';
export { default as useBasicServices } from './useBasicServices';
export { default as useErrorMessage } from './useErrorMessage';
