import React, { useCallback } from 'react';
import { styled, css } from 'mymoria-ui/utils';
import { Button, Caption, Card, Icon, Message, PriceRow, Col } from 'mymoria-ui/components';
import { formatPrice, getFuneralSitePrice, isOfferOutOfDate } from 'helpers';
import { useSelector } from 'hooks';
import { isWundw } from 'reducers/insurance';
import { getFuneralSiteById } from 'reducers/entities';
import { sprintf } from 'utils';
import { upperCase } from 'lodash';
import { useHistory, useModal } from 'mymoria-ui/hooks';
import config from 'config';
import { Wuerttembergische } from 'components/Icons';
import { RefreshOfferModal } from 'components/Modals';
import * as t from 'data';

const Sticky = styled.div`
  top: 4rem;
  position: sticky;

  button {
    margin: 1rem 0;
  }
`;

const RefreshIcon = styled(Icon.Refresh)`
  vertical-align: text-bottom;
  margin-right: 0.5rem;
  height: 26px;
  width: 26px;
`;

const RefreshButton = styled(Button)(
  ({ theme: { colors } }) => css`
    &:hover {
      path {
        fill: ${colors.primary};
        stroke: ${colors.primary};
      }
    }
  `,
);

const SummaryCard = () => {
  const { handlePush } = useHistory();
  const isWundW = useSelector(isWundw);
  const price = useSelector(({ provision }) => provision.price);
  const number = useSelector(({ provision }) => provision.number);
  const funeralSiteId = useSelector(({ provision }) => provision.funeralSite);
  const isActivated = useSelector(({ provision }) => !!provision.paymentAcceptedAt);
  const paymentPriceGuarantee = useSelector(({ provision }) => provision.paymentPriceGuarantee);
  const requestedDocuments = useSelector(({ provision }) => provision.requestedDocuments);
  const id = useSelector(({ provision }) => provision.id);
  const graveType = useSelector(({ provision }) => provision.graveType);
  const funeralSite = useSelector(getFuneralSiteById(funeralSiteId));
  const funeralSitePrice = getFuneralSitePrice(funeralSite, graveType);
  const outOfDateOfferItems = useSelector(({ provision }) => provision.outOfDateOfferItems);
  const createdAt = useSelector(({ provision }) => provision.createdAt);
  const state = useSelector(({ provision }) => provision.state);
  const isOutOfDate = isOfferOutOfDate(createdAt);
  const showRefreshButton =
    state !== 'accepted' && !paymentPriceGuarantee && (outOfDateOfferItems || isOutOfDate);

  const {
    showModal: showRefreshOfferModal,
    hideModal: hideRefreshOfferModal,
    Modal: RefreshOfferModalHook,
  } = useModal();

  const handlePDFDownload = useCallback(() => {
    const url = new URL(`/pdf/provision/${id}`, config.api.endpoint);
    window.open(url.toString());
  }, [id]);

  return (
    <>
      <Sticky>
        <Card variant="medium" title={sprintf(t.offer.summaryCard.title, { number })}>
          {funeralSite ? (
            <>
              {/* todo: funeral site price should be calculated based on grave type */}
              <PriceRow
                label={sprintf(t.offer.summaryCard.servicesText, { number })}
                value={formatPrice(price - funeralSitePrice)}
              />
              {funeralSitePrice > 0 && (
                <PriceRow
                  label={t.offer.summaryCard.servicesPlaceOfDelivery}
                  value={formatPrice(funeralSitePrice)}
                />
              )}
              <hr />
              <PriceRow
                label={t.offer.summaryCard.totalPrice}
                value={formatPrice(price)}
                caption={t.offer.summaryCard.includeTax}
              />
            </>
          ) : (
            <PriceRow
              label={t.offer.summaryCard.totalPrice}
              value={formatPrice(price)}
              caption={`${t.offer.summaryCard.includeTax}\n\n${t.offer.summaryCard.externalCost}`}
            />
          )}
          {paymentPriceGuarantee ? (
            <Message>{sprintf(t.offer.summaryCard.priceGuarantee, { number })}</Message>
          ) : (
            <Button block variant="action" onClick={handlePush('/overview/finance')}>
              {upperCase(t.offer.summaryCard.priceGuaranteeButton)}
            </Button>
          )}
          <Button block outline onClick={handlePDFDownload}>
            <Icon.Download />
            {upperCase(t.offer.summaryCard.downloadPDFButtonTitle)}
          </Button>
          {showRefreshButton && (
            <RefreshButton block outline onClick={showRefreshOfferModal}>
              <RefreshIcon color="#111111" />
              {upperCase(t.offer.summaryCard.refreshOfferButtonTitle)}
            </RefreshButton>
          )}
          {isActivated && !requestedDocuments && (
            <Button block onClick={handlePush('/profile')}>
              {upperCase(t.offer.summaryCard.documentRequest)}
            </Button>
          )}
          <Caption light>{t.offer.summaryCard.burialFeesNote}</Caption>
        </Card>
        {isWundW && (
          <Col width={['65%', '50%', '80%']}>
            <Wuerttembergische />
          </Col>
        )}
      </Sticky>
      <RefreshOfferModalHook
        height="auto"
        width={['100%', '70%', '55%', '40%']}
        borderRadius={['0', '38px']}
      >
        <RefreshOfferModal onClose={hideRefreshOfferModal} />
      </RefreshOfferModalHook>
    </>
  );
};

export default SummaryCard;
