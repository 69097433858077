import actions from 'actions';
import { createReducer } from 'typesafe-actions';
import { LettersState } from 'types';

const { fetchLetters, createLetter } = actions.letters;

const initialState: LettersState = [];

export default createReducer(initialState)
  .handleAction(fetchLetters, (state, { payload }) => payload.result)
  .handleAction(createLetter, (state, { payload }) => [...state, payload.result]);
