import React from 'react';
import { Page } from 'components';
import { Col, Markdown, Row } from 'mymoria-ui/components';
import { global } from 'data';
import { styled } from 'mymoria-ui';

const TitleContainer = styled.div`
  padding: 1rem;
  margin: 1rem auto 2rem;
  width: fit-content;
  border-bottom: 2px solid #c9c9c9; //todo: move it to/use colors in ui lib
`;

const Title = styled.h1`
  margin: 0;
`;

const StyledRow = styled(Row)`
  padding: 4rem 2rem;
  flex-wrap: wrap;
`;

const StyledMarkdown = styled(Markdown)`
  p {
    text-align: center;
  }
`;

// todo: should be adjusted to the new design
const NotFoundPage = () => (
  <Page footer="none" withScrollRestoration={false}>
    <StyledRow justifyContent="center">
      <Col width={1}>
        <TitleContainer>
          <Title>{global.notFound}</Title>
        </TitleContainer>
      </Col>
      <Col width={[1, 2 / 3, 1 / 3]}>
        <StyledMarkdown source={global.notFoundMessage} />
      </Col>
    </StyledRow>
  </Page>
);

export default NotFoundPage;
