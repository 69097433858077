import React, { FunctionComponent } from 'react';
import { RouteComponentProps } from 'react-router';
import { useSelector } from 'hooks';
import { isWundw } from 'reducers/insurance';
import HDHWizard from './HDHWizard';
import WundWWizard from './WundWWizard';

const PartnerPage: FunctionComponent<RouteComponentProps> = props => {
  const isWundW = useSelector(isWundw);

  return isWundW ? <WundWWizard {...props} /> : <HDHWizard {...props} />;
};

export default PartnerPage;
