import arrowUp from 'assets/arrow-up.svg';
import { debounce } from 'lodash';
import { styled, css } from 'mymoria-ui';
import { Flex, Box } from 'mymoria-ui/components';
import React, {
  useState,
  FunctionComponent,
  useRef,
  useLayoutEffect,
  useCallback,
  ReactNode,
} from 'react';

interface CollapseProps {
  isInitiallyCollapsed?: boolean;
  onCollapse?: () => void;
  onExpand?: () => void;
  className?: string;
  trigger?: ReactNode;
}

const Line = styled.div(
  ({ theme: { colors } }) => `
    border-top: 2px solid ${colors.primary};
    width: 100%;
  `,
);

const TriggerBox = styled.div`
  cursor: pointer;
`;

const Arrow = styled.img<{ isExpanded: boolean }>(
  ({ isExpanded, theme: { colors, space } }) => css`
    position: absolute;
    cursor: pointer;
    transform: translateY(-50%) ${isExpanded ? '' : 'rotateZ(180deg)'};
    padding: 0px 1.5rem;
    background: ${colors.white};
  `,
);

const StyledBox = styled(Box)`
  transition: 0.4s max-height;
`;

const Collapse: FunctionComponent<CollapseProps> = ({
  isInitiallyCollapsed = false,
  onCollapse,
  onExpand,
  children,
  className,
  trigger,
}) => {
  const [isExpanded, setIsExpanded] = useState(!isInitiallyCollapsed);
  const expandableContainerRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const hideContent = useCallback(
    debounce(() => {
      if (expandableContainerRef.current) {
        expandableContainerRef.current.style.display = 'none';
      }

      if (onCollapse) {
        onCollapse();
      }
    }, 450),
    [],
  );

  useLayoutEffect(() => {
    if (isExpanded) {
      hideContent.cancel();

      if (onExpand) {
        onExpand();
      }

      if (expandableContainerRef.current) {
        expandableContainerRef.current.style.display = 'block';
        if (parentRef.current) {
          // additional 16px because margins of hr element are not included in overall height of child element
          parentRef.current.style.maxHeight =
            expandableContainerRef.current.offsetHeight + 16 + 'px';
        }
      }
    } else {
      if (parentRef.current) {
        parentRef.current.style.maxHeight = '0px';
      }

      hideContent();
    }
  }, [hideContent, isExpanded, onExpand]);

  return (
    <Flex className={className} flexDirection="column" mb="2rem">
      <StyledBox px={0} ref={parentRef} overflow="hidden">
        <Box className="hidden" ref={expandableContainerRef} px={0}>
          {children}
        </Box>
      </StyledBox>
      {trigger ? (
        <TriggerBox onClick={() => setIsExpanded(prev => !prev)}>{trigger}</TriggerBox>
      ) : (
        <Flex justifyContent="center" mt="2rem">
          <Line />
          <Arrow
            onClick={() => setIsExpanded(prev => !prev)}
            isExpanded={isExpanded}
            src={arrowUp}
            alt="arrow-icon"
          />
        </Flex>
      )}
    </Flex>
  );
};

export default Collapse;
