import { FieldConfig, useField } from 'formik';
import RadioGroup, { RadioGroupProps } from 'mymoria-ui/components/Form/RadioGroup';
import React, { FunctionComponent, ChangeEvent, useCallback } from 'react';

const RadioGroupField: FunctionComponent<
  Omit<RadioGroupProps, 'validate'> & FieldConfig
> = props => {
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error ? meta.error : undefined;

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      field.onChange(e);
      props.onChange && props.onChange(e);
    },
    [field, props],
  );

  return <RadioGroup {...field} {...props} onChange={onChange} error={error} />;
};

export default RadioGroupField;
