import { css, styled, mq } from 'mymoria-ui';
import { Grid, Text, TextLink, Icon, Partners } from 'mymoria-ui/components';
import { useCMS } from 'mymoria-ui/hooks';
import { head, isEmpty } from 'lodash';
import React, { FunctionComponent } from 'react';
import config from 'config';
import { useSelector } from 'hooks';
import { Rating } from 'types';
import { isWundw } from 'reducers/insurance';

interface PublicPageProps {
  footerTitle?: string;
  footerLink?: string;
  footerLinkLabel?: string;
}

type WithWundW = {
  isWundW: boolean;
};

const Content = styled.div<WithWundW>(
  ({ isWundW, theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${colors.backgroundPrimary};
    flex-grow: 1;
    background-image: ${isWundW ? `url('/img/wundw-background-ocean.jpeg')` : 'none'};
    background-size: ${isWundW ? 'cover' : 'unset'};

    ${mq({
      padding: ['4rem 1rem 1rem;', '4rem 1rem 1rem;', 0],
    })};
  `,
);

const Footer = styled.div<WithWundW>(
  ({ isWundW, theme: { colors } }) => css`
    margin-top: 6rem;
    margin-bottom: 1.5rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
    }

    a {
      text-align: center;
      text-transform: uppercase;
    }

    svg {
      path {
        fill: ${colors.primary};
      }
    }

    border-top: ${isWundW ? 'none' : `1px solid ${colors.darkGray}`};
    background-color: ${isWundW ? 'rgb(255 255 255 / 80%)' : 'none'};
    border-radius: ${isWundW ? '5px' : 'none'};
  `,
);

const Arrow = styled(Icon.Arrow)`
  vertical-align: text-top;
  margin-left: 0.5rem;
`;

const StyledPartners = styled(Partners)`
  margin: 1.5rem 0 0.5rem;
  padding: 0 1rem;

  ${mq({
    justifyContent: ['center', 'space-between'],
  })}
`;

const StyledFooter = styled.footer<WithWundW>(({ isWundW }) =>
  mq({
    width: '100%',
    zIndex: 1,
    paddingBottom: ['56px', 0],
    backgroundColor: isWundW ? 'rgb(255 255 255 / 80%)' : 'none',
    flexGrow: 1,
  }),
);

const PublicPage: FunctionComponent<PublicPageProps> = ({
  children,
  footerTitle,
  footerLink,
  footerLinkLabel,
}) => {
  const isWundW = useSelector(isWundw);
  const [{ data: rating }] = useCMS<Rating[]>(`/Ratings?filter[Status]=publish&simple=1`);

  const partners = config.ratedBusinessPartners.map(partner => {
    if (!isEmpty(rating)) {
      const ratingData = head(rating) || ({} as Rating);
      if (partner.name === 'Google') {
        partner.rating = +ratingData.GoogleRating;
        partner.caption = ratingData.GoogleText;
      }
      if (partner.name === 'Trusted Shops') {
        partner.rating = +ratingData.TrustedShopRating;
        partner.caption = ratingData.TrustedShopText;
      }
    }
    return partner;
  });

  return (
    <Content isWundW={isWundW}>
      <Grid>{children}</Grid>
      {footerTitle && (
        <Footer isWundW={isWundW}>
          <Text>{footerTitle}</Text>
          <TextLink href={footerLink}>
            {footerLinkLabel}
            <Arrow />
          </TextLink>
        </Footer>
      )}
      <StyledFooter isWundW={isWundW}>
        <StyledPartners partners={isWundW ? [...partners, config.wundwPartner] : partners} />
      </StyledFooter>
    </Content>
  );
};

export default PublicPage;
