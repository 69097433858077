import { css, styled } from 'mymoria-ui';
import { LinkButton, Markdown, Icon } from 'mymoria-ui/components';
import { useCMS } from 'mymoria-ui/hooks';
import { isEmpty, head } from 'lodash';
import config from 'config';
import { InfoBar as InfoBarProps } from 'mymoria-types';
import React, { useState } from 'react';

const { cms } = config;

interface BannerProps {
  bgColor: string;
  fontColor: string;
}

const StyledClose = styled(Icon.Close)`
  position: absolute;
  cursor: pointer;
  width: 1rem;
  height: 2rem;
  opacity: 0.3;
  top: 10px;
  right: 8px;
  margin: 0;
  z-index: 9;

  &:hover {
    opacity: 0.6;
  }
`;

const StyledBanner = styled.div<BannerProps>(
  ({ theme: { colors }, bgColor, fontColor }) => css`
    position: relative;
    margin-top: 5rem;
    padding: 1rem;
    text-align: center;
    background-color: ${bgColor};
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: all;

    div {
      width: 90%;
      display: initial;
    }

    p {
      display: inline;
      color: ${fontColor};
    }

    a {
      padding: 0 0 0 0.5rem;
      line-height: 0;
      display: inline;
      color: ${colors.white};
      text-decoration: underline;
    }
  `,
);

const InfoBar = () => {
  const [hideBar, setHideBar] = useState(localStorage.getItem('infoBarVisited'));
  const [{ data }] = useCMS<InfoBarProps[]>(
    `/InfoBar?simple=1&filter[Status]=publish&filter[Origins]=${cms.origin}`,
  );
  const info: InfoBarProps = head(data) || ({} as InfoBarProps);

  const hideBarHandler = () => {
    localStorage.setItem('infoBarVisited', 'true');
    setHideBar('true');
  };

  if (isEmpty(info)) {
    return null;
  }

  return !hideBar ? (
    <StyledBanner bgColor={info.BackgroundColor} fontColor={info.CopyColor}>
      <StyledClose onClick={() => hideBarHandler()} fill="#000" />
      <div>
        <Markdown source={info.Copy} />
        <LinkButton
          ghost
          className="buttonLink"
          target={info.TargetBlank ? '_blank' : ''}
          onClick={() => hideBarHandler()}
          href={info.Target}
        >
          {info.TargetText}
        </LinkButton>
      </div>
    </StyledBanner>
  ) : null;
};

export default InfoBar;
