import { trackUserInteraction } from 'actions/tracking';
import { useDispatch, useSelector } from 'hooks';
import { formatPrice } from 'helpers';
import { CustomOfferItemPreview } from 'mymoria-ui/components';
import { useHistory } from 'mymoria-ui/hooks';
import { useRouteMatch } from 'react-router-dom';
import React, { FunctionComponent } from 'react';
import * as t from 'data';
import { isOfferEditable } from 'reducers/provision';

interface BasicProductPreviewProps {
  id: string;
}

const BasicProductPreview: FunctionComponent<BasicProductPreviewProps> = ({ id }) => {
  const dispatch = useDispatch();
  const { url } = useRouteMatch();
  const { push } = useHistory();
  const isEditable = useSelector(isOfferEditable());
  const product = useSelector(({ entities }) => entities.products[id]);
  const funeralType = useSelector(({ provision }) => provision.funeralType);

  const handleEdit = () => {
    dispatch(trackUserInteraction('Edit Product', product.translations.shortname));
    push(`${url}/products/${product.category}`, { restoreScrollPosition: false });
  };

  if (!product) {
    return null;
  }

  return (
    <CustomOfferItemPreview
      selected
      funeralType={funeralType}
      name={product.translations.shortname}
      price={formatPrice(product.price, { gross: true })}
      image={product.pictures[0]?.url}
      onClick={isEditable ? handleEdit : undefined}
      outdated={!!product.deletedAt}
      description={product.translations.description}
      shortDescription={product.translations.name}
      buttonLabel={t.offer.editProduct[product.category]}
    />
  );
};

export default BasicProductPreview;
