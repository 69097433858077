import React, { FunctionComponent } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import TrustedPersonEditPage from './TrustedPersonEditPage';
import TrustedPersonViewPage from './TrustedPersonViewPage';

const TrustedPerson: FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/edit`} component={TrustedPersonEditPage} />
    <Route path={match.path} component={TrustedPersonViewPage} />
  </Switch>
);

export default TrustedPerson;
