import config from 'config';
import { global as t } from 'data';
import { get } from 'lodash';
import { useSelector } from 'hooks';
import { Loader } from 'mymoria-ui/components';
import { useFormikContext } from 'formik';
import { useToast } from 'mymoria-ui/hooks';
import { PayPalButton as Button } from 'react-paypal-button-v2';
import { getDiscount } from 'reducers/provision';
import React, { useState } from 'react';
import { PricingValues } from './types';

const {
  paypal: { options, style, plans, defaultPlanId },
} = config;

const PayPalButton = () => {
  // in general `any` is used because of react-paypal-button-v2 interface is using it

  const discount = useSelector(state => getDiscount(state));
  const [loading, setLoading] = useState(true);
  const { setFieldValue, submitForm } = useFormikContext<PricingValues>();
  const { addToast } = useToast();

  return (
    <>
      {loading && <Loader />}
      <Button
        onApprove={({ orderID, subscriptionID }: any) => {
          setFieldValue('paypalOrderID', orderID);
          setFieldValue('paypalSubscriptionID', subscriptionID);
          submitForm();
        }}
        onButtonReady={() => setLoading(false)}
        onError={() => addToast(t.payment.paypalError)}
        createSubscription={(data: any, actions: any) =>
          actions.subscription.create({
            plan_id: get(plans, discount, defaultPlanId),
          })
        }
        style={style}
        options={options}
      />
    </>
  );
};

export default PayPalButton;
