import { InfoModal } from 'components/Modals';
import { global } from 'data';
import { upperCase } from 'lodash';
import { css, styled } from 'mymoria-ui';
import { Button, Card, Heading, StatusTag, Text } from 'mymoria-ui/components';
import { useModal } from 'mymoria-ui/hooks';
import React, { FunctionComponent, ReactNode } from 'react';
import { StatusTagState } from 'mymoria-ui/components/StatusTag';

const {
  labels: { show = '', set = '', learnMore = '', priceGuarantee = '' },
  status: statusLabel,
  comingSoon: { bodyText },
} = global;

interface Props {
  /**
   * Callback function triggered on button click.
   */
  onClick: () => void;

  /**
   * Title of the Tile.
   */
  title?: string;

  /**
   * Tile Main Button Text.
   */
  buttonText?: string;

  /**
   * Section Name in CMS.
   */
  sectionName?: string;

  /**
   * Description of the Tile.
   */
  description?: string;

  /**
   * Modal description of the Tile.
   */
  modalDescription?: string;

  /**
   * Tile icon below the status.
   * */
  icon?: ReactNode | React.SVGAttributes<HTMLOrSVGElement>;

  status: StatusTagState;
}

const StyledTile = styled(Card)`
  min-height: unset;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Description = styled(Text)`
  margin-top: 2rem;
  line-height: 1.7rem;
`;

const TopContainer = styled.div`
  margin-bottom: 2rem;

  img,
  svg {
    display: block;
    margin: 2rem 0;
    width: 45px;
    height: 45px;
  }

  svg {
    width: 65%;
  }
`;

const Ghost = styled(Button)`
  display: inherit;
`;

const BodyText = styled(Text)`
  color: #777777;
  margin-top: 1rem;
`;

const Heading3 = styled(Heading.Heading3)(
  ({ theme: { font } }) => css`
    font-family: ${font.family.glosa};
    color: #777777;
  `,
);

const HeadingGlosa = styled(Heading.Heading3)(
  ({ theme: { font } }) => css`
    font-family: ${font.family.glosa};
  `,
);

const CommingSoonDescription = styled(Text)`
  color: #777777;
`;

const TileOverview: FunctionComponent<Props> = ({
  onClick,
  description,
  modalDescription,
  title,
  status,
  sectionName,
  icon: Icon,
}) => {
  const { showModal, hideModal, Modal: HookModal } = useModal();

  const statusText = sectionName === 'price_guarantee' ? priceGuarantee : statusLabel[status];

  return (
    <StyledTile variant="small">
      <TopContainer>
        <StatusTag status={status}>{statusText}</StatusTag>
        {Icon}
        {status === 'comingSoon' ? (
          <>
            <Heading3>{title}</Heading3>
            <CommingSoonDescription>{description}</CommingSoonDescription>
            <BodyText>
              <em>{bodyText}</em>
            </BodyText>
          </>
        ) : (
          <>
            <HeadingGlosa>{title}</HeadingGlosa>
            <Description>{description}</Description>
          </>
        )}
      </TopContainer>
      {status === 'comingSoon' ? (
        <Ghost ghost onClick={showModal}>
          {upperCase(learnMore)}
        </Ghost>
      ) : (
        <div>
          {status === 'completed' ? (
            <Button outline onClick={onClick}>
              {upperCase(show)}
            </Button>
          ) : (
            <>
              <Button ghost onClick={showModal}>
                {upperCase(learnMore)}
              </Button>
              <Button onClick={onClick}>{upperCase(set)}</Button>
            </>
          )}
        </div>
      )}
      <HookModal onClose={hideModal} width={['100%', '60%']} height={['100%', 'auto']}>
        <InfoModal
          title={title}
          description={modalDescription}
          label={global.infoModal.buttonLabel}
          status={status}
          statusText={statusText}
          onSubmit={onClick}
        />
      </HookModal>
    </StyledTile>
  );
};

TileOverview.defaultProps = {
  description: '',
  title: '',
  icon: null,
};

export default TileOverview;
