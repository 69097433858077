import { Page, Section } from 'components';
import { global as t } from 'data';
import { withLocationTrack } from 'hocs';
import { useSelector } from 'hooks';
import { transparentize } from 'polished';
import { mq, styled, css } from 'mymoria-ui';
import { Card, Col, Row, Text, Grid, Heading } from 'mymoria-ui/components';
import { useHistory } from 'mymoria-ui/hooks';
import React, { FunctionComponent, Fragment } from 'react';

const Container = styled.div`
  p {
    margin: 0;
  }

  ${mq({
    padding: ['2.5rem 0', '4rem 0', '1rem 0'],
  })};
`;

const StyledRow = styled(Row)`
  margin-bottom: 1rem;
  word-break: break-all;

  ${mq({
    flexDirection: ['column', 'row'],
  })};
`;

const StyledLine = styled.hr(
  ({ theme: { colors } }) => css`
    border-top: 1px solid;
    border-color: ${transparentize(0.9, colors.black)};
    width: 100%;
    margin: 3rem 0;
  `,
);

const SalesforcePage: FunctionComponent = () => {
  const salesforce = useSelector(({ salesforce }) => salesforce.accounts);
  const { handlePush } = useHistory();

  return (
    <Page>
      <Section
        title={t.salesforce.pageTitle}
        onGoBack={handlePush('/overview', { restoreScrollPosition: true })}
        goBackLabel={t.overview.name}
      >
        <Grid>
          <Row justifyContent="center">
            <Col width={[1, 10 / 12, 2 / 3]}>
              {salesforce.map(({ name, contact, address, opportunities }, index) => (
                <Fragment key={`Account ${index + 1}`}>
                  <Card title={`Account ${index + 1}`}>
                    <Container>
                      <StyledRow>
                        <Col width={[1, 1 / 2]}>{t.profile.name}</Col>
                        <Col width={[1, 1 / 2]}>
                          <Text>{name}</Text>
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col width={[1, 1 / 2]}>{t.profile.address}</Col>
                        <Col>
                          <Text>{address.street}</Text>
                          <Text>{`${address.code} ${address.city}`}</Text>
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col width={[1, 1 / 2]}>{t.profile.email}</Col>
                        <Col width={[1, 1 / 2]}>
                          <Text>{contact.email}</Text>
                        </Col>
                      </StyledRow>
                      <StyledRow>
                        <Col width={[1, 1 / 2]}>{t.profile.phoneNumber}</Col>
                        <Col width={[1, 1 / 2]}>
                          <Text>{contact.phone}</Text>
                        </Col>
                      </StyledRow>
                      <StyledLine />
                      <Heading.Heading2 mb="2rem">
                        {t.salesforce.opportunity.title}
                      </Heading.Heading2>
                      {opportunities.map(({ emailStatus, status }, index) => (
                        <Fragment key={`Opportunity ${index + 1}`}>
                          <StyledLine />
                          <StyledRow>
                            <Col width={[1, 1 / 2]}>{t.salesforce.opportunity.emailStatus}</Col>
                            <Col width={[1, 1 / 2]}>
                              <Text color={emailStatus !== 'CONFIRMED' ? 'red' : '#111'}>
                                {emailStatus}
                              </Text>
                            </Col>
                          </StyledRow>
                          <StyledRow>
                            <Col width={[1, 1 / 2]}>{t.salesforce.opportunity.status}</Col>
                            <Col width={[1, 1 / 2]}>
                              <Text color={status !== 'Open' ? 'red' : '#111'}>{status}</Text>
                            </Col>
                          </StyledRow>
                        </Fragment>
                      ))}
                    </Container>
                  </Card>
                </Fragment>
              ))}
            </Col>
          </Row>
        </Grid>
      </Section>
    </Page>
  );
};

export default withLocationTrack(t.salesforce.pageTitle)(SalesforcePage);
