import { global } from 'data';
import { Form, FormikProps } from 'formik';
import { PasswordField, EmailField } from 'forms/fields';
import { Button, Loader } from 'mymoria-ui/components';
import React, { FunctionComponent } from 'react';
import { usePush } from 'mymoria-ui/hooks';
import { styled } from 'mymoria-ui/utils';

export interface SignInFormValues {
  username: string;
  password: string;
}

const GhostButton = styled(Button)`
  margin-top: 2rem;
`;

const StyledButton = styled(Button)`
  position: relative;
`;

const SignInForm: FunctionComponent<FormikProps<SignInFormValues>> = ({ isSubmitting }) => {
  const { push } = usePush();

  return (
    <Form noValidate={true}>
      <EmailField type="email" name="username" label="E-mail" />
      <PasswordField type="password" name="password" label="Passwort" validation={false} />

      <StyledButton block type="submit" disabled={isSubmitting}>
        {isSubmitting ? <Loader tiny overlay /> : global.labels.signIn}
      </StyledButton>
      <GhostButton block ghost onClick={() => push('/forgot-password')}>
        {global.signIn.forgotPassword}
      </GhostButton>
    </Form>
  );
};

export default SignInForm;
