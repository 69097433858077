import React, { useEffect, useState } from 'react';
import { Page, Section } from 'components';
import {
  Col,
  FormFooter,
  DataRowProps,
  Grid,
  ProductDetails,
  Row,
  Loader,
  Icon,
} from 'mymoria-ui/components';
import { ProductCategory } from 'mymoria-types';
import { useHistory, useToast } from 'mymoria-ui/hooks';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'hooks';
import { NotFoundPage } from 'containers';
import * as t from 'data';
import { formatPrice } from 'helpers';
import { fetchProduct } from 'actions/products';
import { getSelectedProductByCategory } from 'reducers/provision';
import { changeProduct } from 'actions/provision';

export type ProductProperty = 'color' | 'size' | 'weight';

interface URLParams {
  id: string;
  category: ProductCategory;
}

type SupportedProperties = {
  name: ProductProperty;
  label: string;
};

const supportedProperties: SupportedProperties[] = [
  { name: 'color', label: t.offer.productDetails.color },
  { name: 'size', label: t.offer.productDetails.size },
  { name: 'weight', label: t.offer.productDetails.weight },
];

const ProductPage = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { handlePush } = useHistory();
  const { id, category } = useParams<URLParams>();
  const product = useSelector(({ entities }) => entities.products[id]);
  const selectedProduct = useSelector(getSelectedProductByCategory(category));
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!product) {
      setIsLoading(true);
      dispatch(fetchProduct(id))
        .catch(error => {
          addToast(error.message);
          setError(true);
        })
        .finally(() => setIsLoading(false));
    }
  }, [dispatch, setIsLoading, category, product, addToast, id]);

  const handleSubmit = () => {
    if (selectedProduct) {
      setIsSubmitting(true);
      dispatch(changeProduct(id, selectedProduct.id))
        .then(handlePush('/overview/offer', { restoreScrollPosition: true }))
        .catch(() => addToast(t.global.apiErrors.general))
        .finally(() => setIsSubmitting(false));
    }
  };

  if (error) {
    return <NotFoundPage />;
  }

  const details: DataRowProps[] = [];

  supportedProperties.forEach(({ name, label }) => {
    if (product?.translations.properties[name]) {
      details.push({ label, value: product.translations.properties[name] });
    }
  });

  if (product?.articleNumber) {
    details.push({ label: t.offer.productDetails.articleNumber, value: product.articleNumber });
  }

  return (
    <Page
      withNavigation={false}
      footer="none"
      isLoading={isLoading}
      headerTitle={t.offer.headerTitle}
    >
      {product && (
        <>
          <Section
            onGoBack={handlePush(`/overview/offer/products/${category}`, {
              restoreScrollPosition: true,
            })}
            goBackLabel={t.offer.productDetails.backToList[category]}
          >
            <Grid>
              <Row flexDirection="row" justifyContent={['center']} flexWrap="wrap">
                <Col mb="7rem" width={[1, 5 / 6, 2 / 3]}>
                  {isSubmitting && <Loader overlay />}
                  <ProductDetails
                    icon={<Icon.Zoom />}
                    name={product.translations.shortname}
                    price={formatPrice(product.price, { gross: true })}
                    description={product.translations.description}
                    pictures={product.pictures}
                    statusTagState={selectedProduct?.id === id ? 'completed' : undefined}
                    statusTagLabel={t.offer.productDetails.selectedStatusTag}
                    details={details}
                    detailsLabel={t.offer.productDetails.title}
                    caption={category !== 'flower' ? t.offer.productDetails.caption : undefined}
                  />
                </Col>
              </Row>
            </Grid>
          </Section>
          <FormFooter
            onCancel={handlePush(`/overview/offer/products/${category}`)}
            onSubmit={handleSubmit}
            labelCancel={t.global.labels.cancel}
            labelSubmit={t.global.labels.submit}
            disabledSubmit={!selectedProduct || selectedProduct.id === id}
          />
        </>
      )}
    </Page>
  );
};

export default ProductPage;
