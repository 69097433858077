import React from 'react';
import config from 'config';
import { offer } from 'data';
import { useSelector } from 'hooks';
import moment from 'moment';
import { sprintf } from 'utils';
import { Page } from 'components';
import TotalPriceStickyBanner from './TotalPriceStickyBanner';
import { Col, Grid, Message, Row, Section } from 'mymoria-ui/components';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import SummaryCard from './SummaryCard';
import BasicInformationCard from './BasicInformationCard';
import BasicItemsCard from './BasicItemsCard';
import FuneralSiteCard from './FuneralSiteCard';
import GraveTypeNotePage from './GraveTypeNotePage';
import SelectedOptionalItemsCard from './SelectedOptionalItemsCard';

const {
  dateFormat: { view: dateFormatView },
} = config;

const OfferCustomizedPage = () => {
  const createdAt = useSelector(({ provision }) => provision.createdAt);
  const createDate = createdAt ? moment(createdAt).format(dateFormatView) : '';

  return (
    <Page>
      <TotalPriceStickyBanner />
      <Section
        title={offer.title}
        description={sprintf(offer.description, { createDate })}
        partners={config.businessPartners}
      >
        <Grid>
          <Row flexDirection="row" justifyContent={['center']} flexWrap="wrap">
            <Col width={1}>
              <Message status="error">{offer.customized.message}</Message>
            </Col>
            <Col width={[1, 1, 2 / 3]}>
              <BasicInformationCard />
              <BasicItemsCard />
              <FuneralSiteCard />
              <SelectedOptionalItemsCard />
            </Col>
            <Col width={[1, 1, 1 / 3]}>
              <SummaryCard />
            </Col>
          </Row>
        </Grid>
      </Section>
    </Page>
  );
};

const OfferCustomizedPageRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.path} component={OfferCustomizedPage} />
      <Route path={`${match.path}/gravetypenote`} component={GraveTypeNotePage} />
      <Redirect to={match.path} />
    </Switch>
  );
};

export default OfferCustomizedPageRouter;
