import React from 'react';
import { global, letter as t } from 'data';
import { Caption, Card, Col, Loader, Row, Text } from 'mymoria-ui/components';
import { InputField, RadioGroupField, EmailField } from 'forms/fields';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import { postalCodeValidator } from 'utils';
import config from 'config';

const {
  regex: { noLatinChar },
} = config;

export const recipientSchema = yup.object().shape({
  salutation: yup.string().required(global.validators.required),
  firstname: yup.string().required(global.validators.required),
  lastname: yup.string().required(global.validators.required),
  street: yup.string().required(global.validators.required),
  postalcode: yup
    .string()
    .test('isPostalCode', global.validators.postalCodeInvalid, postalCodeValidator)
    .required(global.validators.required)
    .typeError(global.validators.wrong),
  city: yup.string().required(global.validators.required),
  email: yup
    .string()
    .email(global.validators.email)
    .matches(noLatinChar, global.validators.noLatinChar),
});

const RecipientForm = () => {
  const { isSubmitting } = useFormikContext();

  return (
    <Card title={t.create.steps.recipient.title}>
      {isSubmitting && <Loader overlay />}
      <Row flexWrap="wrap">
        <Col width={1}>
          <RadioGroupField
            name="salutation"
            values={[
              { value: 'sir', label: global.forms.salutation.sir },
              { value: 'madame', label: global.forms.salutation.madame },
            ]}
          />
        </Col>
        <Col width={[1, 1 / 2]}>
          <InputField name="firstname" label={global.forms.firstname} />
        </Col>
        <Col width={[1, 1 / 2]}>
          <InputField name="lastname" label={global.forms.lastname} />
        </Col>
        <Col width={1}>
          <InputField name="street" label={global.forms.street} />
        </Col>
        <Col width={[1, 1 / 2]}>
          <InputField name="postalcode" label={global.forms.postalCode} />
        </Col>
        <Col width={[1, 1 / 2]}>
          <InputField name="city" label={global.forms.city} />
        </Col>
        <Col width={1}>
          <Text>{t.create.steps.recipient.emailDescription}</Text>
          <EmailField name="email" label={global.forms.email} />
        </Col>
        <Col width={1}>
          <Caption light fontStyle="italic">
            {t.create.steps.recipient.remark}
          </Caption>
        </Col>
      </Row>
    </Card>
  );
};

export default RecipientForm;
