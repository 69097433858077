import { api } from 'utils';
import { FuneralPlan, FuneralSite, FuneralType } from 'mymoria-types';
import { FuneralSiteMarker } from '../types';
import config from 'config';
import { filter, includes, keys } from 'lodash';

const fetch = (id: string, graveType?: string) =>
  api
    .get<FuneralSite>(`/funeralsites/${id}`, { params: { graveType } })
    .then(({ data }) => data);

const fetchDefault = (plan: FuneralPlan, type: FuneralType) =>
  api
    .get<{ funeralSite: FuneralSite; graveType: string }>('/funeralsites/default', {
      params: { plan, type },
    })
    .then(({ data }) => data);

const fetchMarkers = (
  funeralType: FuneralType,
  funeralPlan: FuneralPlan,
  bounds: google.maps.LatLngBounds,
) =>
  api
    .get<FuneralSiteMarker[]>('/funeralsites/markers', {
      params: {
        types: funeralType,
        prices: funeralType === 'sea',
        activeOnly: true,
        fitBounds: true,
        ...bounds.toJSON(),
      },
    })
    .then(({ data: markers }) =>
      funeralType === 'sea'
        ? filter(markers, ({ fees }) =>
            includes(keys(fees.prices), config.defaultGraveTypes[funeralType][funeralPlan]),
          )
        : markers,
    );

export default {
  fetch,
  fetchDefault,
  fetchMarkers,
};
