import React, { FunctionComponent, useEffect, useCallback } from 'react';
import { CreateFunnel } from 'components';
import config from 'config';
import { shallowEqual } from 'react-redux';
import { useDispatch, useFunnel, useSelector } from 'hooks';
import { pick } from 'lodash';
import { isTileCompleted } from 'helpers';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { global } from 'data';
import { usePush } from 'mymoria-ui/hooks';
import { updateProvision } from 'actions/provision';
import { Provision } from 'types';
import { WishesStepIntro, WishesStepMusic, WishesStepNotification, WishesStepQuote } from './Steps';

const {
  tilesOptions: { wishes },
} = config;

const t = global.wishes.createMode;

const WishesCreate: FunctionComponent<RouteComponentProps> = ({ match: { path } }) => {
  const { push } = usePush();
  const dispatch = useDispatch();
  const { openCreateFunnel, closeFunnel } = useFunnel();
  const wishesData = useSelector(({ provision }) => pick(provision, wishes), shallowEqual);
  const handleSubmit = useCallback(
    (values: Partial<Provision>) =>
      dispatch(updateProvision(values)).then(() => push('/overview/wishes')),
    [dispatch, push],
  );

  useEffect(() => {
    openCreateFunnel('WISHES', pick(t, 'headerTitle'));

    return () => closeFunnel();
  }, [closeFunnel, openCreateFunnel]);

  return isTileCompleted('wishes', wishesData) ? (
    <Redirect to="/overview/wishes" />
  ) : (
    <CreateFunnel
      name="wishes-create-mode"
      basepath={path}
      headerTitle={global.wishes.createMode.headerTitle}
      initialValues={{
        music: '',
        quote: '',
        personalNotification: '',
      }}
      steps={[
        {
          title: t.steps.intro.sectionTitle,
          path: '/intro',
          component: WishesStepIntro,
        },
        {
          title: t.steps.music.sectionTitle,
          path: '/music',
          component: WishesStepMusic,
        },
        {
          title: t.steps.quote.sectionTitle,
          path: '/quote',
          component: WishesStepQuote,
        },
        {
          title: t.steps.notification.sectionTitle,
          path: '/notification',
          component: WishesStepNotification,
        },
      ]}
      onSubmit={handleSubmit}
    />
  );
};

export default WishesCreate;
