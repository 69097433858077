import { createAction } from 'typesafe-actions';
import { normalize, schema } from 'normalizr';
import { Dispatch } from 'redux';
import { EntitiesState } from 'types';
import { ProductCategory } from 'mymoria-types';
import { GetState } from 'types';
import { productAPI } from 'api';

interface FetchProductAction {
  entities: Pick<EntitiesState, 'products'>;
  result: {
    [key in ProductCategory]: string;
  };
}

interface FetchProductsAction {
  entities: Pick<EntitiesState, 'products'>;
  result: string[];
}

const product = new schema.Entity('products');

const fetchProductAction = createAction('FETCH_PRODUCT')<FetchProductAction>();
const fetchAlternativeProductsAction = createAction(
  'FETCH_ALTERNATIVE_PRODUCTS',
)<FetchProductsAction>();

export default {
  fetchProduct: fetchProductAction,
  fetchAlternativeProducts: fetchAlternativeProductsAction,
};

export const fetchProduct = (id: string) => (dispatch: Dispatch) =>
  productAPI
    .fetch(id)
    .then(data =>
      dispatch(
        fetchProductAction(normalize({ [data.category]: data }, { [data.category]: product })),
      ),
    );

export const fetchAlternativeProducts = (category: ProductCategory) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  const { code, funeralType: type } = getState().provision;

  return productAPI
    .fetchAll({
      category,
      code,
      type,
    })
    .then(products =>
      dispatch(
        fetchAlternativeProductsAction(
          normalize({ [category]: products }, { [category]: [product] }),
        ),
      ),
    );
};
