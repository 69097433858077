import { createAction } from 'typesafe-actions';
import { SignupValues } from 'types';

const registrationAction = createAction('SET_REGISTRATION')<Partial<SignupValues>>();
const removeRegistrationAction = createAction('REMOVE_REGISTRATION_DATA')<Partial<SignupValues>>();

export const setRegistration = (params: Partial<SignupValues>) => registrationAction(params);
export const removeRegistrationData = () => removeRegistrationAction({});

export default {
  setRegistration: registrationAction,
  removeRegistrationData: removeRegistrationAction,
};
