import { trackSignupSubmit } from 'actions/tracking';
import actions from 'actions/provision';
import { ResendConfirmationEmail, Page } from 'components';
import { global } from 'data';
import { parseQueryParams } from 'helpers';
import { withLocationTrack } from 'hocs';
import { useAuth, useDispatch } from 'hooks';
import { css, mq, styled } from 'mymoria-ui';
import { Button, Heading, Loader, Text } from 'mymoria-ui/components';
import { usePush } from 'mymoria-ui/hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { api } from 'utils';

interface BgProps {
  padding?: string[];
  justifyContent?: string[];
  height?: string[];
}

export const StyledCard = styled.div<BgProps>(
  ({ padding = ['1rem'], justifyContent = ['center'], height = ['500px', '700px', '900px'] }) =>
    css`
      display: flex;
      flex-direction: column;
      overflow: auto;
      align-items: center;

      ${mq({
        height: height,
        padding: padding,
        justifyContent: justifyContent,
      })};
    `,
);

const ActivateAccount: FunctionComponent<RouteComponentProps> = ({
  location: { search },
  history,
}) => {
  const { handlePush } = usePush();
  const dispatch = useDispatch();
  const { code, username } = parseQueryParams(search, ['code', 'username']);
  const [pending, setPending] = useState(username !== '' && code !== '');
  const [error, setError] = useState(!username || !code ? global.apiErrors.default : '');
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    api
      .get(`/provisions/users/confirm/${code}`)
      .then(({ data }) => {
        setError('');
        dispatch(trackSignupSubmit(global.tracking.emailConfirmed, username));
        dispatch(actions.update(data));
        setPending(false);
        isAuthenticated && history.replace('/overview');
      })
      .catch(() => {
        setPending(false);
        setError(global.apiErrors.accountConfirmation);
      });
  }, [dispatch, code, username, setPending, setError, history, isAuthenticated]);

  return (
    <Page footer="none">
      <StyledCard>
        {pending ? (
          <Loader overlay />
        ) : error ? (
          <ResendConfirmationEmail
            message={global.activateAccount.invalidLink}
            username={username}
            isUserNameEncrypted={!!code}
          />
        ) : (
          <>
            <Heading.Heading1>{global.activateAccount.hello}</Heading.Heading1>
            <Text bold m="3rem 0 5rem 0" textAlign="center">
              {global.activateAccount.succeed}
            </Text>
            <Button disabled={pending} onClick={handlePush('/')}>
              {global.activateAccount.goToLoginPage}
            </Button>
          </>
        )}
      </StyledCard>
    </Page>
  );
};

export default withLocationTrack(global.tracking.activateAccount)(ActivateAccount);
