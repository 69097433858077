import React from 'react';
import config from 'config';
import * as t from 'data';
import { AdminEditingMessage, AlertMessage, Page, Section } from 'components';
import { Card, Col, Grid, IconsBanner, Row } from 'mymoria-ui/components';
import { useHistory } from 'mymoria-ui/hooks';
import TotalPriceStickyBanner from './TotalPriceStickyBanner';
import BasicInformationCard from './BasicInformationCard';
import BasicItemsCard from './BasicItemsCard';
import OptionalItemsCard from './OptionalItemsCard';
import SummaryCard from './SummaryCard';
import FuneralSiteCard from './FuneralSiteCard';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import BasicInformationPage from './BasicInformationPage';
import FuneralSitesPage from './FuneralSitesPage';
import ProductPage from './ProductPage';
import ProductsPage from './ProductsPage';
import GraveTypeNotePage from './GraveTypeNotePage';
import { useSelector } from 'hooks';
import { isWundw } from 'reducers/insurance';
import { sprintf } from 'utils';

const OfferEditPage = () => {
  const { handlePush } = useHistory();
  const status = useSelector(({ provision }) => provision.status);
  const userEmail = useSelector(({ provision }) => provision.userEmail);
  const isWundW = useSelector(isWundw);

  return (
    <Page>
      <TotalPriceStickyBanner />
      <Section
        title={t.offer.title}
        description={t.offer.description}
        onGoBack={handlePush('/overview', { restoreScrollPosition: true })}
        goBackLabel={t.global.labels.back}
        partners={config.businessPartners}
      >
        <Grid>
          <Row flexWrap="wrap">
            <Col width={1}>
              {status !== 'CONFIRMED' && (
                <AlertMessage
                  message={sprintf(t.global.activateAccount.ActivateAccount, { email: userEmail })}
                  status="warning"
                />
              )}
              <AdminEditingMessage />
            </Col>
            <Col width={[1, 1, 2 / 3]}>
              <BasicInformationCard />
              <BasicItemsCard />
              <FuneralSiteCard />
              <OptionalItemsCard />
            </Col>
            <Col width={[1, 1, 1 / 3]}>
              <SummaryCard />
            </Col>
            <Col width={[1, 1, 1]}>
              {isWundW ? (
                <Card py="2rem" pr="1.5rem">
                  <IconsBanner
                    businessPartners={config.businessPartners}
                    businessPartnersTitle={t.global.iconSection.partnerTitle}
                    paymentPartners={config.paymentPartners}
                    paymentPartnersTitle={t.global.iconSection.paymentTitle}
                  />
                </Card>
              ) : (
                <IconsBanner
                  businessPartners={config.businessPartners}
                  businessPartnersTitle={t.global.iconSection.partnerTitle}
                  paymentPartners={config.paymentPartners}
                  paymentPartnersTitle={t.global.iconSection.paymentTitle}
                />
              )}
            </Col>
          </Row>
        </Grid>
      </Section>
    </Page>
  );
};

const OfferEditPageRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={match.path} component={OfferEditPage} />
      <Route path={`${match.path}/basic-information`} component={BasicInformationPage} />
      <Route path={`${match.path}/gravetypenote`} component={GraveTypeNotePage} />
      <Route path={`${match.path}/funeral-site`} component={FuneralSitesPage} />
      <Route path={`${match.path}/products/:category/:id`} component={ProductPage} />
      <Route path={`${match.path}/products/:category`} component={ProductsPage} />
      <Redirect to={match.path} />
    </Switch>
  );
};

export default OfferEditPageRouter;
