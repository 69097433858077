import React, { FunctionComponent } from 'react';
import { Page, Section } from 'components';
import { financial as t } from 'data';
import { RouteComponentProps } from 'react-router';
import { Step, Steps, Wizard } from 'react-albus';
import { WizardGrid } from 'mymoria-ui/components';
import IntroStep from './IntroStep';
import InsuranceTypeStep from './InsuranceTypeStep';
import InsuranceDetailsStep from './InsuranceDetailsStep';

const InsuranceWizard: FunctionComponent<RouteComponentProps> = ({ history, match }) => (
  <Page footer="none">
    <Wizard
      history={history}
      basename={match.url}
      render={({ step }) => (
        <Section
          title={t.wizard[step.id]?.title}
          onGoBack={step.id === 'intro' ? () => history.push('/overview') : undefined}
        >
          <WizardGrid>
            <Steps>
              <Step id="intro" render={wizard => <IntroStep wizard={wizard} />} />
              <Step id="type" render={wizard => <InsuranceTypeStep wizard={wizard} />} />
              <Step id="details" render={wizard => <InsuranceDetailsStep wizard={wizard} />} />
            </Steps>
          </WizardGrid>
        </Section>
      )}
    />
  </Page>
);

export default InsuranceWizard;
