import React, { FunctionComponent, useCallback } from 'react';
import { letter as t } from 'data';
import { LetterStyles } from 'mymoria-types';
import { styled, mq } from 'mymoria-ui';
import { Heading, Col, Row } from 'mymoria-ui/components';
import ToolBarContainer from './ToolBarContainer';
import Selector from './Selector';
import ColorPicker from './ColorPicker';
import { Icon } from 'mymoria-ui/components';
import config from 'config';

const {
  letter: { fonts, fontSizes, colors },
} = config;

interface ToolBarStylesProps {
  styles: LetterStyles;
  onChange: (styles: LetterStyles) => void;
  onClose: () => void;
}

const StyledHeaderCol = styled(Col)`
  position: relative;
  margin-bottom: 2rem;

  h3 {
    text-transform: uppercase;
    color: white;
    text-align: center;
  }

  svg {
    cursor: pointer;
    width: 17px;
    height: auto;
    position: absolute;
    right: 8px;
    top: 6px;
  }
`;

const Container = styled(ToolBarContainer)`
  min-height: 250px;

  hr {
    margin: 2rem 0;
    height: 1px;
    background: white;
    border-top-width: 0;
    display: block;
  }

  ${mq({
    width: ['100%', '450px'],
  })};
`;

const ToolBarStyles: FunctionComponent<ToolBarStylesProps> = ({ styles, onChange, onClose }) => {
  const handleFontChange = useCallback(fontFamily => onChange({ ...styles, fontFamily }), [
    onChange,
    styles,
  ]);
  const handleFontSizeChange = useCallback(fontSize => onChange({ ...styles, fontSize }), [
    onChange,
    styles,
  ]);
  const handleColorChange = useCallback(color => onChange({ ...styles, color }), [
    onChange,
    styles,
  ]);

  return (
    <Container>
      <Row flexWrap="wrap">
        <StyledHeaderCol width={1}>
          <Heading.Heading3>{t.editor.edit}</Heading.Heading3>
          <Icon.Close color="#fff" onClick={onClose} />
        </StyledHeaderCol>
        <Col width={3 / 4}>
          <Selector
            items={fonts}
            value={styles.fontFamily}
            fontFamily={styles.fontFamily}
            onChange={handleFontChange}
          />
        </Col>
        <Col width={1 / 4}>
          <Selector items={fontSizes} value={styles.fontSize} onChange={handleFontSizeChange} />
        </Col>
        <Col width={1}>
          <hr />
          <ColorPicker color={styles.color} colors={colors} onChange={handleColorChange} />
        </Col>
      </Row>
    </Container>
  );
};

export default ToolBarStyles;
