import React, { FunctionComponent, useContext, useState } from 'react';
import { useSelector } from 'hooks';
import { calculateTotalFuturePrice } from 'helpers';
import { Provision } from 'types';

export type HDHContactDetails = Pick<
  Provision,
  'salutation' | 'firstname' | 'lastname' | 'street' | 'postalCode' | 'city' | 'email' | 'phone'
> & { houseNumber?: string };

export type HDHPaymentType = 'onetime' | 'monthly' | 'treuhand';

export interface HDHState {
  age: number;
  totalFuturePrice: number;
  contactDetails: HDHContactDetails;
  paymentType?: HDHPaymentType;
}

type HDHContextData = [HDHState, (data: Partial<HDHState>) => void];

const HDHContext = React.createContext<HDHContextData>({} as HDHContextData);

const HDHStateProvider: FunctionComponent = ({ children }) => {
  const provision = useSelector(({ provision }) => provision);

  const [state, setState] = useState<HDHState>(() => {
    const { totalFuturePrice, age } = calculateTotalFuturePrice(provision);
    return {
      age,
      totalFuturePrice,
      contactDetails: {
        salutation: provision.salutation,
        firstname: provision.firstname,
        lastname: provision.lastname,
        email: provision.email,
        phone: provision.phone,
        city: provision.city,
        street: provision.street || '',
        postalCode: provision.postalCode || '',
        houseNumber: '',
      },
    };
  });

  const handleUpdate = (data: Partial<HDHState>) =>
    setState((prevState: HDHState) => ({ ...prevState, ...data }));

  return <HDHContext.Provider value={[state, handleUpdate]}>{children}</HDHContext.Provider>;
};

export const useHDHState = () => useContext(HDHContext);

export default HDHStateProvider;
