import React from 'react';
import { MobileStickyBanner } from 'mymoria-ui/components';
import { formatPrice } from 'helpers';
import { useSelector } from 'hooks';
import * as t from 'data';

const TotalPriceStickyBanner = () => {
  const price = useSelector(({ provision }) => provision.price);

  return <MobileStickyBanner label={t.offer.summaryCard.totalPrice} value={formatPrice(price)} />;
};

export default TotalPriceStickyBanner;
