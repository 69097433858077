import { FieldConfig, useField } from 'formik';
import EmailInput, { EmailInputProps } from 'mymoria-ui/components/Form/EmailInput';
import React, { FunctionComponent, useCallback, useRef } from 'react';

const EmailField: FunctionComponent<Omit<FieldConfig, 'validate'> & EmailInputProps> = ({
  onChange,
  validation = true,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(props);
  const { current: setFieldValue } = useRef(setValue);

  const handleChange = useCallback((value: string) => setFieldValue(value), [setFieldValue]);

  // if input is prefilled and has no error allow validation other wise check error state
  const prefilled = (field.value && !meta.error) || !!meta.error;

  return (
    <EmailInput
      {...field}
      {...props}
      onChange={handleChange}
      validation={validation && meta.touched && prefilled}
      error={meta.touched ? meta.error : ''}
    />
  );
};

export default EmailField;
