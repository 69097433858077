import React, { FunctionComponent, useContext, useState } from 'react';
import { useSelector } from 'hooks';
import { calculateTotalFuturePrice, getInsuranceStartDate } from 'helpers';
import { useCallback } from 'react';

export type WundWPaymentType = 'oneTime' | 'monthly' | 'consulting';

export interface WundWTariffResponse {
  insuranceAge: number;
  sum: number;
  sumStatus: string;
  oneTime: number;
  monthly: number;
  paymentTypeStatus: string;
  startDate: number;
}

export interface WundWState {
  age: number;
  birthDate: string;
  tariff?: WundWTariffResponse;
  startDate: number;
  totalFuturePrice: number;
  paymentType?: WundWPaymentType;
}

type WundWContextData = [WundWState, (data: Partial<WundWState>) => void];

const WundWContext = React.createContext<WundWContextData>({} as WundWContextData);

const WundWStateProvider: FunctionComponent = ({ children }) => {
  const provision = useSelector(({ provision }) => provision);
  const [state, setState] = useState<WundWState>(() => {
    const { totalFuturePrice, age } = calculateTotalFuturePrice(provision);

    return {
      age,
      birthDate: provision.provisionBirth,
      totalFuturePrice,
      startDate: getInsuranceStartDate(),
    };
  });

  const handleUpdate = useCallback(
    (data: Partial<WundWState>) => setState((prevState: WundWState) => ({ ...prevState, ...data })),
    [],
  );

  return <WundWContext.Provider value={[state, handleUpdate]}>{children}</WundWContext.Provider>;
};

export const useWundWState = () => useContext(WundWContext);

export default WundWStateProvider;
