import React, { FunctionComponent } from 'react';
import { Page } from 'components';
import { global as t } from 'data';
import { Helmet } from 'react-helmet';
import { RouteComponentProps } from 'react-router';
import { Step, Steps, Wizard } from 'react-albus';
import { WizardGrid } from 'mymoria-ui/components';
import FuneralTypeStep from './signUPWizard/FuneralTypeStep';
import PublicPage from './PublicPage';
import FuneralPlanStep from './signUPWizard/FuneralPlanStep';
import PlaceStep from './signUPWizard/PlaceStep';
import BirthDateStep from './signUPWizard/BirthDateStep';
import ContactStep from './signUPWizard/ContactStep';

const SignUp: FunctionComponent<RouteComponentProps> = ({ history, match }) => (
  <Page footer="none">
    <Helmet meta={[{ name: 'robots', content: 'noindex, nofollow' }]} />
    <Wizard
      history={history}
      basename={match.url}
      render={() => (
        <PublicPage
          footerTitle={t.signup.loginQuestion}
          footerLink="/login"
          footerLinkLabel={t.labels.signIn.toUpperCase()}
        >
          <WizardGrid>
            <Steps>
              <Step id="type" render={wizard => <FuneralTypeStep wizard={wizard} />} />
              <Step id="character" render={wizard => <FuneralPlanStep wizard={wizard} />} />
              <Step id="place" render={wizard => <PlaceStep wizard={wizard} />} />
              <Step id="birthdate" render={wizard => <BirthDateStep wizard={wizard} />} />
              <Step id="data" render={wizard => <ContactStep wizard={wizard} />} />
            </Steps>
          </WizardGrid>
        </PublicPage>
      )}
    />
  </Page>
);

export default SignUp;
