import { css, styled } from 'mymoria-ui';
import { Icon } from 'mymoria-ui/components';
import React, { FunctionComponent, useState } from 'react';
import { lighten } from 'polished';
import useOuterClick from 'mymoria-ui/hooks/useOutSideClick';

export type SelectorItem = {
  label: string;
  value: any;
};

interface SelectorProps {
  items: SelectorItem[];
  onChange: (v: any) => void;
  fontFamily?: string;
  value: any;
}

const Arrow = styled(Icon.Arrow)`
  vertical-align: text-top;
  transform: rotate(90deg);
  margin-left: 0.5rem;
  transition-duration: 0.5s;
  transition-property: transform;

  &.open {
    transform: rotate(-90deg);
  }
`;

const StyledSelector = styled.div(
  ({ theme: { colors } }) => css`
    position: relative;
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 5px;
    background: #181818;
    color: ${colors.white};
    border: 1px solid #aaaaaa;
    cursor: pointer;

    .visible {
      visibility: visible;
    }

    .hidden {
      visibility: hidden;
    }

    &:hover {
      background-color: ${lighten(0.1, '#181818')};
    }
  `,
);

const Header = styled.div(
  ({ theme: { font } }) => css`
    display: flex;
    width: 100%;
    font-weight: ${font.weight.bold};
    text-align: right;
    text-decoration: none;
  `,
);

const List = styled.div(
  ({ theme: { colors } }) => css`
    position: absolute;
    top: 50px;
    left: -1px;
    right: -1px;
    background: #181818;
    color: ${colors.white};
    border-radius: 0 0 5px 5px;
    border: 1px solid #aaaaaa;
    z-index: 1;
  `,
);

interface FontProps {
  fontFamily?: string;
}

const Item = styled.div<FontProps>(
  ({ fontFamily, theme: { colors, font } }) => css`
    font-weight: ${font.weight.normal};
    font-family: ${fontFamily};
    padding: 0.8rem 1rem;
    display: block;
    background: transparent;
    border-radius: 5px;
    cursor: pointer;

    &.active {
      background: ${colors.backgroundPrimary};
    }
    &:hover {
      background-color: ${lighten(0.1, '#181818')};
    }
  `,
);

const Teaser = styled.span<FontProps>(
  ({ fontFamily }) => css`
    width: 100%;
    font-family: ${fontFamily ? fontFamily : 'inherit'};
    text-align: left;
  `,
);

const Selector: FunctionComponent<SelectorProps> = ({ value, items, onChange, fontFamily }) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOuterClick(() => setIsOpen(false));

  return (
    <div ref={ref}>
      <StyledSelector onClick={() => setIsOpen(isOpen => !isOpen)}>
        <Header>
          <Teaser fontFamily={fontFamily}>{items.find(item => item.value === value)?.label}</Teaser>
          <Arrow color="white" className={isOpen ? 'open' : 'close'} />
        </Header>
        <List className={isOpen ? 'visible' : 'hidden'}>
          {items.map(item => (
            <Item
              fontFamily={fontFamily ? item.value : 'inherit'}
              // selected={item.value === value} // TODO implement this
              key={item.label}
              onClick={() => {
                setIsOpen(false);
                onChange(item.value);
              }}
            >
              {item.label}
            </Item>
          ))}
        </List>
      </StyledSelector>
    </div>
  );
};

export default Selector;
