import React from 'react';
import { sprintf } from 'utils';
import { useRouteMatch } from 'react-router-dom';
import * as t from 'data';
import { Button, Card, EditButton, Markdown, Row, Text } from 'mymoria-ui/components';
import { useHistory } from 'mymoria-ui/hooks';
import { styled } from 'mymoria-ui/utils';
import { useSelector } from 'hooks';

const StyledMarkdown = styled(Markdown)`
  padding-bottom: 1rem;
  p {
    white-space: pre-wrap;
  }
`;

const StyledButton = styled(Button)`
  margin: 0 auto;
`;

const InsuranceCard = () => {
  const { url } = useRouteMatch();
  const { handlePush } = useHistory();
  const financialProvisionType = useSelector(({ provision }) => provision.financialProvisionType);
  const financialProvisionNote = useSelector(({ provision }) => provision.financialProvisionNote);
  const existingInsuranceType = useSelector(({ provision }) => provision.existingInsuranceType);

  const existingInsuranceCompany = useSelector(
    ({ provision }) => provision.existingInsuranceCompany,
  );
  const existingInsuranceContractNumber = useSelector(
    ({ provision }) => provision.existingInsuranceContractNumber,
  );

  const isCompleted =
    financialProvisionType ||
    financialProvisionNote ||
    existingInsuranceType ||
    existingInsuranceCompany ||
    existingInsuranceContractNumber;

  return (
    <Card
      title={t.financial.view.insurance.title}
      caption={t.financial.view.insurance.subTitle}
      status={isCompleted ? 'completed' : 'incompleted'}
      statusLabel={isCompleted ? t.global.status.completed : t.global.status.incompleted}
    >
      {financialProvisionType ? (
        <Row flexDirection="column">
          {financialProvisionType && (
            <Text mb={2} fontWeight="bold">
              {t.financial.wizard.insuranceType.status[financialProvisionType]}
            </Text>
          )}
          {financialProvisionNote && <Text my={3}>{financialProvisionNote}</Text>}
          {existingInsuranceType && (
            <StyledMarkdown
              source={sprintf(t.financial.view.insurance.existingInsuranceType, {
                existingInsuranceType,
              })}
            />
          )}
          {existingInsuranceCompany && (
            <StyledMarkdown
              source={sprintf(t.financial.view.insurance.existingInsuranceCompany, {
                existingInsuranceCompany,
              })}
            />
          )}
          {existingInsuranceContractNumber && (
            <StyledMarkdown
              source={sprintf(t.financial.view.insurance.existingInsuranceContractNumber, {
                existingInsuranceContractNumber,
              })}
            />
          )}
          <EditButton onClick={handlePush(`${url}/insurance`)} />
        </Row>
      ) : (
        <Row flexDirection="column" justifyContent="center">
          <Text textAlign="center" mb={3} fontWeight="bold">
            {t.financial.view.insurance.noData}
          </Text>
          <StyledButton onClick={handlePush(`${url}/insurance/wizard}`)}>
            {t.financial.view.insurance.editButton}
          </StyledButton>
        </Row>
      )}
    </Card>
  );
};

export default InsuranceCard;
