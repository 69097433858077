import config from 'config';
import * as yup from 'yup';
import * as t from 'data';
import { every, isEmpty, memoize } from 'lodash';
import { Place } from 'types';
import { AxiosResponse } from 'axios';
import moment from 'moment';

const { validators } = t.global;

const {
  dateFormat,
  phonePattern,
  regex: { noLatinChar, nameRule, ccEmailRule, mymoriaDomain, vorsorgeEmailRule },
} = config;

//vorsorgeEmailRule check if email start with vorsorge+
const validateVorsorgeEmail = (value: string) => vorsorgeEmailRule.test(value);
//vorsorgeEmailRule check if email ends with +cc before @domain
const validateCCEmail = (value: string) => ccEmailRule.test(value);
const validateMymoriaDomain = (value: string) => mymoriaDomain.test(value);

const checkEmail = memoize(
  (value: string, checkUser: (username: string) => Promise<AxiosResponse>) =>
    yup
      .string()
      .required(validators.required)
      .email()
      .isValid(value)
      .then(
        isValid =>
          isValid &&
          checkUser(value)
            .then(() => false)
            .catch(({ response }) => response.status === 404),
      ),
);

const validateEmail = memoize((value: string) =>
  yup
    .string()
    .required(validators.required)
    .email()
    .isValid(value)
    .then(
      isValid =>
        isValid &&
        (validateMymoriaDomain(value)
          ? validateVorsorgeEmail(value) || validateCCEmail(value)
          : !!value),
    ),
);

export const contactDetailsSchema = (checkUser: (username: string) => Promise<AxiosResponse>) =>
  yup.object().shape({
    salutation: yup.string().required(validators.required),
    firstname: yup.string().required(validators.required).matches(nameRule, validators.wrongName),
    lastname: yup.string().required(validators.required).matches(nameRule, validators.wrongName),
    email: yup
      .string()
      .required(validators.required)
      .email(validators.email)
      .matches(noLatinChar, validators.noLatinChar)
      .test('is-vorsorge-cc-email', validators.invalidCCEmail, validateEmail)
      .test('checkUser', validators.emailExist, value => checkEmail(value, checkUser)),
    password: yup
      .string()
      .required(validators.required)
      .min(6, validators.length)
      .matches(/^[\S]+.*[\S]+$/, validators.trimPassword),
    phone: yup
      .string()
      .required(validators.required)
      .test('is-phone', validators.phone, phone => phonePattern.test(phone)),
  });

export const profilePageSchema = (checkUser: (username: string) => Promise<AxiosResponse>) =>
  yup.object().shape({
    firstname: yup.string().required(validators.required).matches(nameRule, validators.wrongName),
    lastname: yup.string().required(validators.required).matches(nameRule, validators.wrongName),
    street: yup.string(),
    postalCode: yup.number(),
    city: yup.string(),
    phone: yup.number(),
    email: yup
      .string()
      .email(validators.email)
      .required(validators.required)
      .matches(noLatinChar, validators.noLatinChar)
      .test('is-vorsorge-cc-email', validators.invalidCCEmail, validateEmail)
      .test('checkUser', validators.emailExist, value => checkEmail(value, checkUser)),
    provisionBirth: yup
      .date()
      .transform(function (_, originalValue) {
        const newValue = moment(originalValue, dateFormat.view);
        return newValue.isValid() ? newValue.toDate() : new Date('');
      })
      .typeError(validators.date)
      .required(validators.required),
  });

export const placeSchema = yup
  .mixed()
  .test(
    'place',
    validators.required,
    (place: Partial<Place>) =>
      !isEmpty(place) && every(place, value => typeof value !== 'undefined'),
  );
