import { Dispatch } from 'redux';
import { createAction } from 'typesafe-actions';
import { api } from 'utils';
import { PlansState } from 'types';

const fetchPlansAction = createAction('PLANS_FETCH')<PlansState>();

export default {
  fetch: fetchPlansAction,
};

export const fetchPlans = () => (dispatch: Dispatch) =>
  api.get('/provisions/plans/').then(({ data }) => {
    dispatch(fetchPlansAction(data));
    return data;
  });
