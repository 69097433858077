import React, { useEffect, useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'hooks';
import { fetchLetters } from 'actions/letters';
import { Button, Col, Loader, Row, Grid } from 'mymoria-ui/components';
import { isEmpty, head } from 'lodash';
import { useCMS, usePush } from 'mymoria-ui/hooks';
import { LetterTile, Section, Page } from 'components';
import { letter as t } from 'data';
import { styled } from 'mymoria-ui';
import { PortalSection } from 'types';
import CreatePage from './CreatePage';
import LetterIntro from './LetterIntro';
import LetterEdit from './EditPage';

const NewItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  margin: 10px 0;
  border: 2px dashed #ccc;
  border-radius: 5px;
  white-space: nowrap;

  button {
    margin: 1.5rem;
  }

  & > div {
    color: #aaa;
    margin: auto;
    font-size: 150px;
  }
`;

const Letters = () => {
  const dispatch = useDispatch();
  const { path, url } = useRouteMatch();
  const [lettersLoading, setLettersLoading] = useState(true);
  const { handlePush, push } = usePush();
  const letters = useSelector(({ letters }) => letters);
  const hasLetters = isEmpty(letters);
  const [{ data: introPageTranslations, loading: cmsLoading }] = useCMS<PortalSection[]>(
    '/PortalSections?simple=1&filter[Tile]=letter-service',
  );

  useEffect(() => {
    if (hasLetters) {
      dispatch(fetchLetters()).then(({ payload }) => {
        setLettersLoading(false);

        if (isEmpty(payload.result)) {
          push(`${url}/intro`);
        }
      });
    } else {
      setLettersLoading(false);
    }
  }, [dispatch, hasLetters, push, url]);

  return (
    <Page footer="none" headerTitle={t.headerTitle}>
      {lettersLoading || cmsLoading ? (
        <Loader overlay />
      ) : (
        <Switch>
          <Route
            path={`${path}/intro`}
            render={props => <LetterIntro {...props} translations={head(introPageTranslations)} />}
          />
          <Route path={`${path}/create`} component={CreatePage} />
          <Route path={`${path}/edit`} component={LetterEdit} />
          <Route
            path={`${path}/`}
            render={() => (
              <Section
                {...t.list.section}
                onGoBack={handlePush('/overview', { restoreScrollPosition: true })}
              >
                <Grid>
                  <Row flexWrap="wrap">
                    {letters.map(id => (
                      <Col display="flex" key={id} width={[1, 1 / 2, 1 / 3]}>
                        <LetterTile id={id} />
                      </Col>
                    ))}
                    <Col display="flex" width={[1, 1 / 2, 1 / 3]}>
                      <NewItem>
                        <div>+</div>
                        <Button outline onClick={handlePush(`${url}/intro`)}>
                          {t.list.newLetter}
                        </Button>
                      </NewItem>
                    </Col>
                  </Row>
                </Grid>
              </Section>
            )}
          />
        </Switch>
      )}
    </Page>
  );
};

export default Letters;
