import { EditButton, Page, Section } from 'components';
import { global as t } from 'data';
import { withLocationTrack } from 'hocs';
import { useSelector } from 'hooks';
import { Card, Col, Grid, Row, Text } from 'mymoria-ui/components';
import { getTrustedPersonPhone } from 'reducers/provision';
import { useHistory } from 'mymoria-ui/hooks';
import React, { FunctionComponent } from 'react';

const TrustedPersonViewPage: FunctionComponent = () => {
  const { handlePush } = useHistory();
  const {
    firstname = '',
    lastname = '',
    street = '',
    postalCode = '',
    city = '',
    relationship = '',
    email = '',
    note = '',
    permissionGrantedAt,
  } = useSelector(({ provision: { trustedPerson } }) => trustedPerson) || {};
  const phone = useSelector(getTrustedPersonPhone);

  return (
    <Page>
      <Section
        title={t.trustedPerson.sectionTitle}
        onGoBack={handlePush('/overview', { restoreScrollPosition: true })}
        goBackLabel={t.overview.name}
      >
        <Grid>
          <Row justifyContent="center">
            <Col width={[1, 10 / 12, 2 / 3]}>
              <Card
                title={t.trustedPerson.personalInfo.title}
                caption={t.trustedPerson.personalInfo.caption}
                status={permissionGrantedAt ? 'active' : 'inActive'}
                statusLabel={
                  permissionGrantedAt
                    ? t.trustedPerson.permission.grantedStatusTag
                    : t.trustedPerson.permission.declinedStatusTag
                }
              >
                <Col px={0} my={3}>
                  <Text>{`${firstname} ${lastname} ${relationship && `(${relationship})`}`}</Text>
                  <Text>{street}</Text>
                  <Text>{`${postalCode} ${city}`}</Text>
                  <Col px={0} mt={3}>
                    <Text>{phone}</Text>
                    <Text>{email}</Text>
                    <Text>{note}</Text>
                  </Col>
                </Col>
                <EditButton onClick={handlePush('trusted-person/edit')} />
              </Card>
            </Col>
          </Row>
        </Grid>
      </Section>
    </Page>
  );
};

export default withLocationTrack(t.trustedPerson.sectionTitle)(TrustedPersonViewPage);
