import React, { FunctionComponent } from 'react';
import { Header } from 'mymoria-ui/components/Header';
import { Footer, SimpleFooter } from 'mymoria-ui/components/Footer';
import { Icon, Loader, Main } from 'mymoria-ui/components';
import { useScrollRestoration, useToast } from 'mymoria-ui/hooks';
import * as t from 'data';
import { useAuth } from 'hooks';
import { styled } from 'mymoria-ui';

interface PageProps {
  className?: string;
  isLoading?: boolean;
  headerTitle?: string;
  footer?: 'default' | 'simple' | 'none';
  withHeader?: boolean;
  withNavigation?: boolean;
  withScrollRestoration?: boolean;
}

const StyledFooter = styled(Footer)`
  z-index: 1;
`;

const StyledSimpleFooter = styled(SimpleFooter)`
  z-index: 1;
`;

const Page: FunctionComponent<PageProps> = ({
  children,
  className,
  isLoading = false,
  headerTitle,
  footer = 'default',
  withHeader = true,
  withNavigation = true,
  withScrollRestoration = true,
}) => {
  useScrollRestoration(!isLoading && withScrollRestoration);
  const { addToast } = useToast();
  const { signOut, user, isAuthenticated, isAdmin } = useAuth();
  const basicSubNaviItems = [
    {
      to: '/profile',
      label: 'Mein Profil',
    },
    {
      to: '#',
      label: 'abmelden', // we have the value "Abmelden" for "signout" and "signOut" in the header.yml and in the globla.yml, respectively, but we don't use it anywhere. can I clean this up?
      onClick: () => signOut().catch(({ message }) => addToast(message)),
    },
  ];

  const navigation = [
    {
      to: '/overview',
      label: 'meine vorsorge',
    },
    {
      to: '/documents',
      label: 'dokumente',
    },
    {
      to: '#',
      label: user?.attributes?.name || user?.attributes?.email,
      subNavigation: isAdmin
        ? [
            {
              to: '/salesforce',
              label: 'Mein Salesforce',
            },
            ...basicSubNaviItems,
          ]
        : basicSubNaviItems,
    },
  ];

  return (
    <>
      {withHeader &&
        (withNavigation && isAuthenticated ? (
          <Header logo={<Icon.ProvisionPortalLogo />} title={headerTitle} navigation={navigation} />
        ) : (
          <Header logo={<Icon.ProvisionPortalLogo />} title={headerTitle} logoAlign="right" />
        ))}
      <Main className={className} paddingTop={[60, 60, 76]}>
        {isLoading ? <Loader /> : children}
      </Main>
      {footer === 'default' && (
        <StyledFooter
          copyright={t.footer.copyright}
          primaryLinks={t.footer.primaryLinks}
          secondaryLinks={t.footer.secondaryLinks}
          contactLinks={t.footer.contactLinks}
          socialMediaLinks={t.footer.socialMediaLinks}
        />
      )}
      {footer === 'simple' && (
        <StyledSimpleFooter links={t.footer.secondaryLinks} copyright={t.footer.copyright} />
      )}
    </>
  );
};

export default Page;
