import React, { FunctionComponent } from 'react';
import { FuneralSite, FuneralType } from 'mymoria-types';
import { Button, Col, EditButton, Icon, Row, Text } from 'mymoria-ui/components';
import { useSelector } from 'hooks';
import * as t from 'data';
import { getEstimatedGravePrice } from 'helpers';

interface FuneralSiteDetailsProps {
  funeralSite: FuneralSite;
  funeralType: FuneralType;
  onEdit: () => void;
  onDelete: () => void;
  graveType?: string;
}

const FuneralSiteDetails: FunctionComponent<FuneralSiteDetailsProps> = ({
  funeralSite,
  funeralType,
  onEdit,
  onDelete,
  graveType = '',
}) => {
  const grave = useSelector(({ entities }) => entities.graveTypes[graveType]);

  return (
    <Row my={5} flexWrap="wrap">
      <Col width={1}>
        {grave ? (
          <>
            <Text bold>{`${grave.translations.name} ${getEstimatedGravePrice(
              funeralSite,
              graveType,
            )}`}</Text>
            <Text>{grave.translations.description}</Text>
          </>
        ) : (
          <Text bold>{t.offer.funeralSites.details.noGrave}</Text>
        )}
      </Col>
      <Col width={[1, 1 / 3]}>
        <EditButton onClick={onEdit}>{t.offer.funeralSites.details.editGraveType}</EditButton>
      </Col>
      {funeralType !== 'sea' && (
        <Col width={[1, 2 / 3]}>
          <Button ghost onClick={onDelete}>
            <Icon.Delete /> {t.offer.funeralSites.details.deleteFuneralsite}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default FuneralSiteDetails;
