import { global as t } from 'data';
import { Form, FormikHelpers, useFormikContext } from 'formik';
import { InputField } from 'forms/fields';
import { withLocationTrack } from 'hocs';
import { memoize, isEmpty } from 'lodash';
import { Caption, Card, Col, Row } from 'mymoria-ui/components';
import React, { ChangeEvent, useCallback } from 'react';
import config from 'config';
import { PricingValues } from '../types';

const matches = (regex: RegExp, message: string) => (value: string) => {
  if (isEmpty(value)) {
    return t.validators.required;
  } else if (!regex.test(value)) {
    return message;
  }
};

const Account = () => {
  const { setFieldValue } = useFormikContext<PricingValues>();

  const handleIbanChange = useCallback(
    memoize(
      (setFieldValue: FormikHelpers<PricingValues>['setFieldValue']) => (
        event: ChangeEvent<HTMLInputElement>,
      ) => {
        setFieldValue(
          'iban',
          event.target.value
            ? event.target.value
                .replace(/[^\dA-Z]/g, '')
                .replace(/(.{4})/g, '$1 ')
                .trim()
            : '',
        );
      },
    ),
    [],
  );

  return (
    <Card variant="large" title={t.pricing.account.formTitle}>
      <Form noValidate>
        <Row flexWrap="wrap">
          <Col width={[1, 1, 1 / 2]}>
            <InputField name="firstname" label="Vorname" />
          </Col>
          <Col width={[1, 1, 1 / 2]}>
            <InputField name="lastname" label="Nachname" />
          </Col>
          <Col width={1}>
            <InputField
              name="iban"
              label="IBAN"
              onChange={handleIbanChange(setFieldValue)}
              validate={matches(config.regex.iban, t.validators.iban)}
            />
          </Col>
          <Col width={1}>
            <InputField
              name="bank"
              label="BANK"
              validate={matches(config.regex.alphanumericGerman, t.validators.bank)}
            />
          </Col>
          <Col width={[1, 1, 1 / 2]}>
            <InputField
              name="bic"
              label="BIC"
              validate={matches(config.regex.bic, t.validators.bic)}
            />
          </Col>
          <Col width={1}>
            <Caption>{t.pricing.account.formRemark}</Caption>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default withLocationTrack(t.pricing.account.sectionTitle)(Account);
