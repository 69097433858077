import { css, mq, styled } from 'mymoria-ui';
import { Button, Col, Grid, Heading, Icon, Markdown, Row, Text } from 'mymoria-ui/components';
import React, { FunctionComponent } from 'react';
import { usePush } from 'mymoria-ui/hooks';

interface PublicPageProps {
  title?: string;
  description?: string;
  footerTitle?: string;
  footerLink?: string;
  footerLinkLabel?: string;
  page?: string;
}

const StyledPage = styled.section(
  ({ theme: { colors } }) => css`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    background-color: ${colors.backgroundPrimary};
    background-position: top 0 right -2px;
    height: 100%;
    box-sizing: border-box;

    ${mq({
      padding: ['0 0 2rem', '4rem 0'],
      backgroundSize: ['75% auto', '50% auto'],
    })};
  `,
);

const FlexGrid = styled(Grid)`
  display: flex;
  max-width: 700px;
`;

const FlexCol = styled(Col)`
  display: flex !important;
  flex-direction: column !important;
`;

const ContentCol = styled(FlexCol)`
  justify-content: space-between;
`;

const Arrow = styled(Icon.Arrow)`
  vertical-align: text-top;
  margin-left: 0.5rem;
`;

const HeaderRow = styled(Row)`
  text-align: center;
  margin-bottom: 2rem;

  h1 {
    margin: 2rem 0;
  }

  p {
    text-align: center;
  }
`;

const Footer = styled.div(
  ({ theme: { colors } }) => css`
    border-top: 1px solid ${colors.darkGray};
    margin-top: 2rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
    }

    button {
      margin: 0;
    }
  `,
);

const SectionPublic: FunctionComponent<PublicPageProps> = ({
  children,
  title,
  description,
  footerTitle,
  footerLink,
  footerLinkLabel,
}) => {
  const { push } = usePush();

  return (
    <StyledPage>
      <FlexGrid>
        <Row justifyContent="center" flex={1}>
          <FlexCol width={[1, 5 / 6]}>
            <HeaderRow justifyContent="center">
              <Col width={[5 / 6, 1]}>
                <Heading.Heading1>{title}</Heading.Heading1>
                <Markdown>{description}</Markdown>
              </Col>
            </HeaderRow>
            <Row justifyContent="center" flex={1}>
              <ContentCol width={[1, 3 / 4]}>
                {children}
                {footerLink && (
                  <Footer>
                    <Text>{footerTitle}</Text>
                    <Button block ghost onClick={() => push(footerLink)}>
                      {footerLinkLabel}
                      {footerLinkLabel && <Arrow color="#00c1b1" />}
                    </Button>
                  </Footer>
                )}
              </ContentCol>
            </Row>
          </FlexCol>
        </Row>
      </FlexGrid>
    </StyledPage>
  );
};

export default SectionPublic;
