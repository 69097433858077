import { useAuth, useDispatch, useSelector } from 'hooks';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { useToast } from 'mymoria-ui/hooks';
import { Button, Heading, Row, Text } from 'mymoria-ui/components';
import * as t from 'data';
import { mq, styled } from 'mymoria-ui/utils';
import config from 'config';
import { SignupValues } from 'types';
import { isAxiosError, parseDataLayerError } from 'utils';
import { trackCreationError, trackSignupSubmit } from 'actions/tracking';
import { createProvision, createProposal } from 'actions/provision';
import anime from 'animejs';
import { useCallback } from 'react';
import { removeRegistrationData } from 'actions/registration';
import { withLocationTrack } from 'hocs';
import moment from 'moment';

const {
  animation: { duration, opacity, delay },
} = config;

const { provisionNotes } = t.global.animation;

const Container = styled(Row)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -300px;

  ${mq({
    backgroundSize: ['80%', '80%', '80%', '50%'],
    backgroundPositionX: ['center', 'center', '90%'],
  })};
`;

const Animation = styled.div`
  position: relative;
  height: 50px;
  width: 80%;

  & > p {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
`;

const ProvisionCreator: FunctionComponent<RouteComponentProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const [error, setError] = useState(false);
  const provisionTL = anime.timeline({ autoplay: true, loop: false });
  const registration = useSelector(({ registration }) => registration);
  const { signUp, sendConfirmationEmail, signIn } = useAuth();

  const referrer = useSelector(({ params }) => params.referrer);
  const couponcode = useSelector(({ params }) => params.couponcode);
  const financePartner = useSelector(({ params }) => params.fp);
  const salesPersonId = useSelector(({ params }) => params.vermittler);

  const save = useCallback(
    async ({ password, email, firstname, lastname, place, ...contactData }: SignupValues) => {
      try {
        const {
          data: { id: proposalId, email: userEmail, lastVersionCreatedAt: lastProposalCreatedAt },
        } = await dispatch(
          createProposal({
            email,
            firstname,
            lastname,
            place,
            ...contactData,
          }),
        );
        validateCreationDate(lastProposalCreatedAt);

        const { lastVersionCreatedAt: lastProvisionCreatedAt } = await dispatch(
          createProvision({
            proposalId,
            userEmail,
            referrer,
            couponCode: couponcode,
            financePartner,
            salesPersonId,
          }),
        );
        validateCreationDate(lastProvisionCreatedAt);

        try {
          await signUp({
            password,
            name: `${firstname} ${lastname}`,
            username: email,
          });
        } catch (error) {
          if ((error as Error).name !== 'UsernameExistsException') {
            throw error;
          }
        }

        await sendConfirmationEmail(email);
        await dispatch(trackSignupSubmit(t.global.tracking.register, t.global.labels.signup));
        await signIn({ password, username: email, skipPostAuthentication: true });
        await dispatch(removeRegistrationData());

        history.replace(`/overview`);
      } catch (error) {
        if (isAxiosError(error)) {
          dispatch(trackCreationError(parseDataLayerError(error)));
        }
        setError(true);
        addToast(error.message);
      }
    },
    [
      signUp,
      sendConfirmationEmail,
      signIn,
      history,
      setError,
      addToast,
      couponcode,
      dispatch,
      financePartner,
      referrer,
      salesPersonId,
    ],
  );

  useEffect(() => {
    if (registration.password && !error) {
      save(registration as SignupValues);
    }
  }, [dispatch, save, registration, error]);

  useEffect(() => {
    provisionNotes.forEach((_: string, index: number) => {
      provisionTL.add({
        targets: `.provision-${index + 1}`,
        opacity: opacity,
        duration: duration,
        easing: 'easeInOutQuad',
        delay: delay,
      });
      if (index < provisionNotes.length - 1) {
        provisionTL.add({
          targets: `.provision-${index + 1}`,
          opacity: 0,
          duration: duration,
          easing: 'easeOutExpo',
          delay: delay,
        });
      }
    });
  }, [provisionTL]);

  return (
    <Container>
      {error ? (
        <>
          <Text>{t.global.apiErrors.general}</Text>
          <Button onClick={() => window.location.reload()} ghost>
            {t.global.labels.tryAgain}
          </Button>
        </>
      ) : (
        <>
          <Heading.Heading2>{t.global.animation.title}</Heading.Heading2>
          <Animation>
            {provisionNotes.map((item: string, index: number) => (
              // @ts-ignore
              <Text key={item} className={`provision-${index + 1}`} textAlign="center">
                {item}
              </Text>
            ))}
          </Animation>
        </>
      )}
    </Container>
  );
};

export default withLocationTrack(t.global.animation.title)(ProvisionCreator);

const validateCreationDate = (value?: number) => {
  if (!value) return;

  const date = moment(value);
  const start = date.startOf('day').valueOf();
  const end = date.endOf('day').valueOf();

  if (value >= end || value <= start) {
    throw new Error('User already exists');
  }
};
