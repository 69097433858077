import React from 'react';
import { isEmpty } from 'lodash';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { usePush } from 'mymoria-ui/hooks';
import { useSelector } from 'hooks';
import { OverviewCard, Section } from 'components';
import { global, letter as t } from 'data';
import { styled } from 'mymoria-ui/utils';
import { Col, Row, Text, Grid } from 'mymoria-ui/components';
import EditRecipient from './EditRecipient';
import EditShippingDate from './EditShippingDate';
import EditContent from './EditContent';
import LetterPaper from '../LetterEditor/LetterPaper';

const FormattedText = styled.pre`
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const LetterEditPage = () => {
  const { path } = useRouteMatch();
  const { state: letterId } = useLocation<string>();
  const { handlePush } = usePush();
  const { url } = useRouteMatch();
  const { content, recipient, shippingDate } = useSelector(
    ({ entities }) => entities.letters[letterId],
  );

  return (
    <Switch>
      {isEmpty(letterId) && <Redirect to="/overview/letters" />}
      <Route path={`${path}/content`} component={EditContent} />
      <Route path={`${path}/recipient`} component={EditRecipient} />
      <Route path={`${path}/shipping-date`} component={EditShippingDate} />
      <Route
        path={`${path}/`}
        render={() => (
          <Section
            title={
              recipient
                ? `${t.edit.section.title} ${recipient.firstname} ${recipient.lastname}`
                : t.list.tile.inProgress
            }
            onGoBack={handlePush('/overview/letters')}
            goBackLabel={t.edit.section.back}
          >
            <Grid>
              <Row flexWrap="wrap" justifyContent="center">
                <Col width={[1, 10 / 12, 2 / 3]} px={[0, 1]}>
                  <LetterPaper
                    btnLabel={recipient ? global.labels.edit : global.labels.specifyNow}
                    btnOnClick={handlePush(`${url}/content`, letterId)}
                  >
                    {content && (
                      <FormattedText>
                        <Text {...content.styles}>{content.text}</Text>
                      </FormattedText>
                    )}
                  </LetterPaper>
                  <OverviewCard
                    title={t.edit.recipient.title}
                    status={recipient ? 'completed' : 'incompleted'}
                    btnLabel={recipient ? global.labels.edit : global.labels.specifyNow}
                    btnOnClick={handlePush(`${url}/recipient`, letterId)}
                  >
                    {recipient && (
                      <Text>
                        <strong>
                          {`${global.forms.salutation[recipient.salutation]} ${
                            recipient.firstname
                          } ${recipient.lastname}`}
                        </strong>
                        <br />
                        {recipient.street}
                        <br /> {`${recipient.postalcode} ${recipient.city}`}
                      </Text>
                    )}
                  </OverviewCard>
                  <OverviewCard
                    title={t.edit.shippingDate.title}
                    status={shippingDate ? 'completed' : 'incompleted'}
                    btnLabel={recipient ? global.labels.edit : global.labels.specifyNow}
                    btnOnClick={handlePush(`${url}/shipping-date`, letterId)}
                  >
                    {shippingDate && (
                      <Text>
                        <strong>
                          {shippingDate.generic === 'EXACT_DATE' && shippingDate.exact
                            ? `${shippingDate.exact.day}${
                                shippingDate.exact.year === 'EXACT_DATE'
                                  ? shippingDate.exact.exactYear
                                  : ` ${t.list.dates[shippingDate.exact.year]}`
                              }`
                            : t.list.dates[shippingDate.generic]}
                        </strong>
                      </Text>
                    )}
                  </OverviewCard>
                </Col>
              </Row>
            </Grid>
          </Section>
        )}
      />
    </Switch>
  );
};

export default LetterEditPage;
