import { Page, Section } from 'components';
import * as t from 'data';
import { Card, Col, FormFooter, Grid, Loader, PriceRow, Row, Text } from 'mymoria-ui/components';
import { useHistory, useToast } from 'mymoria-ui/hooks';
import { Formik } from 'formik';
import * as yup from 'yup';
import React, { FunctionComponent } from 'react';
import { InputField } from 'forms/fields';
import { useDispatch, useSelector } from 'hooks';
import { sprintf } from 'utils';
import { calculateCostEstimation, formatPrice } from 'helpers';
import { css, styled } from 'mymoria-ui';
import { Provision } from 'mymoria-types';
import { updateProvision } from 'actions/provision';
import moment from 'moment';
import OfferTotalPriceSummary from './OfferTotalPriceSummary';

type FormValues = Pick<Provision, 'funeralSiteEstimatedPrice'>;

const StyledLine = styled.hr(
  ({ theme: { colors } }) => css`
    width: 100%;
    border-top: 2px solid ${colors.primary};
    margin: 1rem 0;
  `,
);

const CostsEditPage: FunctionComponent = () => {
  const { handlePush, push } = useHistory();

  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { price: basePrice, funeralSiteEstimatedPrice, provisionBirth } = useSelector(
    ({ provision }) => provision,
  );

  const birthYear = moment(provisionBirth).year();
  const { timeToLive, interestRate, ageAtDeath } = calculateCostEstimation(provisionBirth);

  const handleSubmit = async ({ funeralSiteEstimatedPrice }: FormValues) => {
    try {
      await dispatch(updateProvision({ funeralSiteEstimatedPrice }));
      push('/overview/finance');
    } catch (error) {
      addToast(t.global.apiErrors.general);
    }
  };

  return (
    <Page footer="none">
      <Formik
        initialValues={{ funeralSiteEstimatedPrice }}
        validationSchema={yup.object().shape({
          funeralSiteEstimatedPrice: yup
            .string()
            .matches(/^(0|[1-9][0-9]+)$/, t.financial.calculator.priceValidateMsg),
        })}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isSubmitting, values: { funeralSiteEstimatedPrice } }) => {
          const price = basePrice + parseInt(funeralSiteEstimatedPrice || '0');
          const priceIncrease = timeToLive * price * interestRate;
          const totalFuturePrice = price + priceIncrease;

          return (
            <>
              <Section
                title={t.financial.wizard.futureEstimation.title}
                onGoBack={handlePush('/overview/finance')}
              >
                <Grid>
                  <Row justifyContent="center" pb="5rem">
                    <Col width={[1, 10 / 12, 2 / 3]} px={0}>
                      {isSubmitting && <Loader overlay />}
                      <Card>
                        <OfferTotalPriceSummary />
                        <Row mt={3}>
                          <Text my={0} mr={2}>
                            {t.financial.wizard.costCalculator.funeralSiteEstimatedPrice}
                          </Text>
                          <InputField name="funeralSiteEstimatedPrice" />
                        </Row>
                        <Text>
                          {sprintf(t.financial.wizard.futureEstimation.description, {
                            birthYear,
                            timeToLive,
                            ageAtDeath,
                            interestRate: interestRate * 100,
                          })}
                        </Text>
                        <StyledLine />
                        <PriceRow
                          label={t.financial.wizard.futureEstimation.todayCost}
                          value={formatPrice(
                            basePrice + parseInt(funeralSiteEstimatedPrice || '0'),
                          )}
                        />
                        <PriceRow
                          label={sprintf(t.financial.wizard.futureEstimation.futureCost, {
                            timeToLive,
                          })}
                          value={formatPrice(priceIncrease)}
                        />
                        <StyledLine />
                        <PriceRow
                          label={sprintf(t.financial.wizard.futureEstimation.totalFutureCost, {
                            timeToLive,
                          })}
                          value={formatPrice(totalFuturePrice)}
                        />
                        <StyledLine />
                        <Text>
                          {sprintf(t.financial.wizard.futureEstimation.footNote, {
                            totalFuturePrice: formatPrice(totalFuturePrice),
                          })}
                        </Text>
                      </Card>
                    </Col>
                  </Row>
                </Grid>
              </Section>
              <FormFooter
                labelCancel={t.global.labels.cancel}
                labelSubmit={t.global.labels.submit}
                onSubmit={submitForm}
                onCancel={handlePush('/overview/finance')}
                disabledSubmit={isSubmitting}
              />
            </>
          );
        }}
      </Formik>
    </Page>
  );
};

export default CostsEditPage;
