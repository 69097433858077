import actions from 'actions';
import { EntitiesState } from 'types';
import { ApplicationState } from 'types';
import { createReducer } from 'typesafe-actions';

const { fetchGraveTypes } = actions.graveTypes;
const { fetchLetters, createLetter, updateLetter } = actions.letters;
const { fetch: fetchProvision, changeBasicInformation } = actions.provision;
const { fetchAlternativeProducts, fetchProduct } = actions.products;
const { fetchFuneralSite } = actions.funeralSites;

const initialState: EntitiesState = {
  letters: {},
  products: {},
  services: {},
  graveTypes: {},
  funeralSites: {},
};

export default createReducer(initialState)
  .handleAction(fetchLetters, (state, { payload: { entities } }) => ({
    ...state,
    ...entities,
  }))
  .handleAction([createLetter, updateLetter], (state, { payload: { entities } }) => ({
    ...state,
    letters: { ...state.letters, ...entities.letters },
  }))
  .handleAction([fetchAlternativeProducts, fetchProduct], (state, { payload: { entities } }) => ({
    ...state,
    products: { ...state.products, ...entities.products },
  }))
  .handleAction([fetchProvision, changeBasicInformation], (state, { payload: { entities } }) => ({
    ...state,
    products: { ...state.products, ...entities.products },
    services: { ...state.services, ...entities.services },
    funeralSites: entities.funeralSites
      ? { ...state.funeralSites, ...entities.funeralSites }
      : state.funeralSites,
  }))
  .handleAction(fetchGraveTypes, (state, { payload: { entities } }) => ({
    ...state,
    graveTypes: { ...state.graveTypes, ...entities.graveTypes },
  }))
  .handleAction(fetchFuneralSite, (state, { payload: funeralSite }) => ({
    ...state,
    funeralSites: { ...state.funeralSites, [funeralSite.id]: funeralSite },
  }));

export const getFuneralSiteById = (id = '') => ({ entities }: ApplicationState) =>
  entities.funeralSites[id];
