import actions from 'actions';
import { createReducer } from 'typesafe-actions';
import { merge } from 'lodash';
import { ServicesState } from 'types';

const { fetch, changeBasicInformation } = actions.provision;

const initialState: ServicesState = { basic: [], optional: [] };

export default createReducer(initialState).handleAction(
  [fetch, changeBasicInformation],
  (state, { payload }) => merge({}, state, payload.result.services),
);
