import actions from 'actions/flags';
import { FlagsState } from 'types';
import { createReducer } from 'typesafe-actions';

const { set } = actions;
const initialState: FlagsState = {} as FlagsState;

export default createReducer(initialState).handleAction(set, (state, { payload }) => ({
  ...state,
  ...payload,
}));
