import React, { FunctionComponent } from 'react';
import { css, mq, styled } from 'mymoria-ui';
import { useSelector } from 'hooks';
import { formatPrice } from 'helpers';
import { transparentize } from 'polished';
import { getDiscount } from 'reducers/provision';
import { Button, Caption, Card, Heading, Markdown } from 'mymoria-ui/components';
import { ProvisionPlan as Plan } from 'mymoria-types';
import { sprintf } from 'utils';
import { global as t } from 'data';

const Header = styled.div(
  ({ theme: { colors } }) => css`
    position: relative;
    overflow: hidden;
    background-color: ${colors.planHeader};
    display: flex;
    justify-content: space-between;
    border-radius: 5px 5px 0 0;

    ${mq({
      padding: ['2.5rem 1rem', '4rem', '4rem', '4rem 6rem'],
      flexDirection: ['column', 'column', 'row'],
      alignItems: ['unset', 'unset', 'center'],
      borderRadius: ['unset', '5px 5px 0 0'],
    })};
  `,
);

const Heading3 = styled(Heading.Heading3)`
  color: white;

  ${mq({
    width: ['100%', '100%', '50%'],
    marginTop: ['2.5rem', 0],
  })};
`;

const PriceText = styled(Heading3)`
  display: inline;
  margin-left: 1.5rem;
`;

const Price = styled.div(
  ({ theme: { font, colors } }) => css`
    color: white;
    font-weight: ${font.weight.bold};

    del {
      display: block;
      font-family: ${font.family.proximaNova};
      font-size: ${font.size.large};
      font-weight: ${font.weight.semiBold};
      color: ${colors.white};
      opacity: 0.5;
      letter-spacing: 0.65px;

      ${mq({
        textAlign: ['left', 'left', 'right'],
      })};
    }

    ${mq({
      marginTop: ['0.5rem', '0.5rem', '0'],
      fontSize: ['2.125rem', '2.5rem', '2.5rem', '3.5rem'],
    })};
  `,
);

const StyledMarkdown = styled(Markdown)`
  margin-bottom: 1.5rem;

  ul {
    margin: 0;
  }

  li {
    display: flex;
    list-style-type: none;
    padding: 1rem 0;
  }

  li:before {
    content: '';
    display: inline-block;
    padding-right: 2.5rem;
    height: 1.4rem;
    background: url('/img/ic_check.svg') no-repeat left bottom;
  }
`;

const Content = styled.div(
  mq({
    padding: ['2.5rem 1rem', '2rem 4rem', '2rem 4rem', '2rem 6rem'],
  }),
);

const Footer = styled.div<{ withCoupon: boolean; planIsActivated: boolean }>(
  ({ withCoupon, planIsActivated }) => css`
    display: flex;
    justify-content: ${withCoupon || planIsActivated ? 'flex-end' : 'space-between'};
    align-items: center;

    & div:first-of-type {
      ${mq({
        textAlign: ['center', 'center', 'unset'],
        width: ['100%', '100%', '55%'],
        marginTop: ['1rem', '1rem', '0'],
      })};
    }

    button {
      margin: 0;

      ${mq({
        width: ['100%', '100%', 'auto'],
      })};
    }

    ${mq({
      flexDirection: ['column-reverse', 'column-reverse', 'row'],
    })};
  `,
);

const StyledCard = styled(Card)(
  mq({
    padding: ['0 !important'],
    minHeight: ['auto', '450px', '450px', '480px'],
  }),
);

const StyledLine = styled.hr(
  ({ theme: { colors } }) => css`
    border-top: 1px solid;
    border-color: ${transparentize(0.9, colors.black)};
    width: 100%;
    margin: 3rem 0;
  `,
);

const Discount = styled.div(
  ({ theme: { colors, font } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.white};
    width: 206px;
    height: 44px;
    position: absolute;
    top: 10px;
    left: -60px;
    transform: rotate(-30deg);
    background-color: ${colors.yellow};

    h2 {
      color: ${colors.white};
      font-weight: ${font.weight.semiBold};
      font-family: ${font.family.proximaNova};
    }
  `,
);

interface ProvisionPlanProps {
  plan?: Plan;
  onClick: () => void;
  isActive: boolean;
}

const ProvisionPlan: FunctionComponent<ProvisionPlanProps> = ({ plan, onClick, isActive }) => {
  const discount = useSelector(state => getDiscount(state));
  const number = useSelector(({ provision }) => provision.number);

  if (!plan) {
    return null;
  }

  const {
    price: { value },
  } = plan;

  return (
    <StyledCard>
      <Header>
        {discount && (
          <Discount>
            <Heading.Heading2>
              {sprintf(t.priceGuarantee.discount, { value: discount })}
            </Heading.Heading2>
          </Discount>
        )}
        <Heading3>{plan.name}</Heading3>
        <Price>
          {formatPrice(discount ? value - (value * discount) / 100 : value)}
          <PriceText>{`/ ${t.periods[plan.price.period.toLowerCase()]}`}</PriceText>
          {discount && (
            <del>
              {formatPrice(plan.price.value)}
              {`/ ${t.periods[plan.price.period.toLowerCase()]}`}
            </del>
          )}
        </Price>
      </Header>
      <Content>
        <StyledMarkdown
          source={sprintf(plan.description, {
            proposalNumber: number,
          })}
        />
        <StyledLine />
        <Footer withCoupon={!!discount} planIsActivated={isActive}>
          {!discount && !isActive && <Caption light>{plan.caption}</Caption>}
          {!isActive && (
            <Button variant="action" onClick={onClick}>
              {t.priceGuarantee.button}
            </Button>
          )}
        </Footer>
      </Content>
    </StyledCard>
  );
};

export default ProvisionPlan;
