import { useCallback, useEffect, useState } from 'react';
import useToast, { ToastOptions } from 'mymoria-ui/hooks/useToast';
import { merge } from 'lodash';
import { AxiosError } from 'axios';

export interface ErrorMessages {
  [key: string]: string;
  network: string;
  request: string;
  general: string;
}

interface useErrorConfig {
  toastOptions?: ToastOptions;
  messages: ErrorMessages;
}

const defaultToastOptions = {
  autoDismiss: false,
};
export type Error = AxiosError | string;

export default ({ toastOptions, messages }: useErrorConfig) => {
  const [error, setError] = useState<Error | null>(null);
  const { addToast, removeAllToasts } = useToast();

  useEffect(() => {
    if (error) {
      let message;
      if (typeof error === 'string') {
        // get custom error message
        message = error;
      } else if (error.isAxiosError) {
        // handle network errors
        if (error.response) {
          message = messages[error.response.status];
        } else if (error.request) {
          message = messages.request;
        } else {
          message = messages.network;
        }
      } else {
        // get general error message
        message = messages.general;
      }
      addToast(message, 'error', merge(defaultToastOptions, toastOptions));
    } else {
      removeAllToasts();
    }
  }, [addToast, removeAllToasts, toastOptions, messages, error]);

  return { error, setError: useCallback(setError, [setError]) };
};
