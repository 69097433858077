import actions from 'actions';
import { ParamsState } from 'types';
import { createReducer } from 'typesafe-actions';

const initialState: ParamsState = {
  firstname: '',
  lastname: '',
  email: '',
  referrer: '',
  couponcode: '',
  fp: '',
  vermittler: '',
};

const { setAppParams } = actions.params;

export default createReducer(initialState).handleAction(setAppParams, (state, { payload }) => ({
  ...state,
  ...payload,
}));
