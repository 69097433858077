import React from 'react';
import * as t from 'data';
import { formatPrice, getFuneralSitePrice } from 'helpers';
import { isEmpty } from 'lodash';
import { PriceRow } from 'mymoria-ui/components';
import { useSelector } from 'hooks';
import OfferTotalPriceDetails from './OfferTotalPriceDetails';

const OfferTotalPriceSummary = () => {
  const {
    price,
    basicProducts,
    optionalProducts,
    basicServices,
    optionalServices,
    extras,
    graveType,
    funeralSite: fsId = '',
  } = useSelector(({ provision }) => provision);

  const allProducts = useSelector(({ entities }) => entities.products);
  const allServices = useSelector(({ entities }) => entities.services);
  const funeralSite = useSelector(({ entities }) => entities.funeralSites[fsId]);

  const products = [...basicProducts, ...optionalProducts].map(id => allProducts[id]);
  const services = [...basicServices, ...optionalServices].map(id => allServices[id]);
  const offerItems = [...products, ...services].map(({ translations, price }) => ({
    label: translations.shortname || translations.name,
    value: formatPrice(price, { gross: true }),
  }));

  if (funeralSite) {
    offerItems.push({
      label: funeralSite.title,
      value: formatPrice(getFuneralSitePrice(funeralSite, graveType), { gross: false }),
    });
  }

  if (extras) {
    offerItems.concat(
      extras.map(item => ({ label: item.name, value: formatPrice(item.price, { gross: true }) })),
    );
  }

  return (
    <>
      <PriceRow
        margin="0"
        label={t.financial.wizard.futureEstimation.todayCost}
        value={formatPrice(price)}
      />
      {!isEmpty(offerItems) && <OfferTotalPriceDetails offerItems={offerItems} />}
    </>
  );
};

export default OfferTotalPriceSummary;
