import { createAction, TypeConstant } from 'typesafe-actions';
import { TrackingCookies, TrackingParams, Provision } from 'mymoria-types';

type GTMEventConstant =
  | 'genericEvent'
  | 'virtualPageView'
  | 'buttonClick'
  | 'dl_init'
  | 'optimize.activate'
  | 'provisionPortalData'
  | 'gtm.js'
  | 'creationError';

interface GTMGenericEvent {
  eCat: string;
  eAct: string;
  eLab: string;
}

interface GTMMeta {
  track: boolean;
}

export interface GTMProvisionPortalData {
  provisionPortalData: Partial<Provision>;
}

interface GTMEvent {
  event: GTMEventConstant;
}

interface GTMLoad {
  'gtm.start': number;
}

interface GTMErrorData {
  errorData: { endpoint: string; params: string; response: string };
}

export const GTM_JS = 'gtm.js';
export const GTM_INIT_DATA_LAYER = 'GTM_INIT_DATA_LAYER';
export const DL_INIT = 'dl_init';
export const CONSENT_UPDATE = 'consent_update';

interface GTMTrackLocation {
  name: string;
  path: string;
  contentGroup1: string;
}

interface GTMTrackCtaClick extends GTMGenericEvent {}

export const trackGenericEvent = createAction<
  TypeConstant,
  GTMEvent & GTMGenericEvent,
  GTMMeta,
  [GTMGenericEvent]
>(
  'GTM_TRACK_GENERIC_EVENT',
  payload => ({
    event: 'genericEvent',
    track: true,
    ...payload,
  }),
  () => ({ track: true }),
)();

export const GTMLoad = createAction<TypeConstant, GTMEvent & GTMLoad, GTMMeta>(
  'GTM_LOAD',
  () => ({
    event: GTM_JS,
    'gtm.start': new Date().getTime(),
  }),
  () => ({ track: true }),
)();

export const initializeDataLayer = createAction<TypeConstant, GTMEvent, GTMMeta>(
  GTM_INIT_DATA_LAYER,
  payload => ({
    event: DL_INIT,
    consent: payload,
  }),
  () => ({ track: true }),
)();

const trackCtaClickAction = createAction<
  TypeConstant,
  GTMEvent & GTMTrackCtaClick,
  GTMMeta,
  [GTMTrackCtaClick]
>(
  'GTM_TRACK_CTA_CLICK',
  payload => ({
    event: 'buttonClick',
    ...payload,
  }),
  () => ({ track: true }),
)();

export const trackLocation = createAction<
  TypeConstant,
  GTMEvent & GTMTrackLocation,
  GTMMeta,
  [GTMTrackLocation]
>(
  'GTM_TRACK_LOCATION',
  payload => ({
    event: 'virtualPageView',
    ...payload,
  }),
  () => ({ track: true }),
)();

export const trackProvisionPortalData = createAction<
  TypeConstant,
  GTMEvent,
  GTMMeta,
  [Partial<Provision>]
>(
  'GTM_TRACK_PROVISION_PORTAL_DATA',
  payload => ({ event: 'provisionPortalData', provisionPortalData: payload }),
  () => ({ track: true }),
)();

export const trackCreationError = createAction<
  TypeConstant,
  GTMEvent & GTMErrorData,
  GTMMeta,
  [{ endpoint: string; params: string; response: string }]
>(
  'GTM_TRACK_CREATION_ERROR',
  payload => ({ event: 'creationError', errorData: payload }),
  () => ({ track: true }),
)();

export const setTrackingParams = createAction('SET_TRACKING_PARAMS')<Partial<TrackingParams>>();
export const setTrackingCookies = createAction('SET_TRACKING_COOKIES')<Partial<TrackingCookies>>();

export default {
  trackLocation,
  setTrackingParams,
  setTrackingCookies,
  initializeDataLayer,
  trackCtaClick: trackCtaClickAction,
  trackProvisionPortalData,
  trackCreationError,
};

export const trackSigninProcess = (eAct: string, eLab: string) =>
  trackCtaClickAction({ eCat: 'Login', eAct, eLab });

export const trackUserInteraction = (eAct: string, eLab: string) =>
  trackCtaClickAction({ eCat: 'User Interaction', eAct, eLab });

export const trackSignupSubmit = (eAct: string, eLab: string) =>
  trackCtaClickAction({ eCat: 'Registration', eAct, eLab });

export const trackActivationSuccess = (eAct: string, eLab: string) =>
  trackGenericEvent({ eCat: 'Activation', eAct, eLab });

export const trackPaymentProcess = (eAct: string, eLab: string) =>
  trackCtaClickAction({ eCat: 'Payment', eAct, eLab });

export const trackOfferRefresh = (eAct: string, eLab: string) =>
  trackCtaClickAction({ eCat: 'Offer Refresh', eAct, eLab });
