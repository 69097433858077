import { Product, Proposal, Service } from 'mymoria-types';
import { api } from 'utils';
import { BasicInformation } from 'types';

interface Response {
  proposal: Proposal;
  services: Service[];
  products: Product[];
}

const changeBasicInformation = (id: string, data: BasicInformation) =>
  api.post<Response>(`/proposals/${id}/basic-information`, data).then(({ data }) => data);

const refreshOffer = (id: string) => api.get(`/proposals/${id}/refresh`);

export default {
  changeBasicInformation,
  refreshOffer,
};
