import React, { FunctionComponent } from 'react';
import * as t from 'data';
import { Col, Row, Text, WizardCard } from 'mymoria-ui/components';
import { funeralPlans, seaFuneralPlans } from 'forms/options';
import { WizardComponentProps } from 'react-albus';
import { FuneralPlan } from 'mymoria-types';
import FunnelButton from './FunnelButton';
import { withLocationTrack } from 'hocs';
import { useDispatch } from 'hooks';
import { trackProvisionPortalData } from 'actions/tracking';
import { useSelector } from 'hooks';
import { setRegistration } from 'actions/registration';
import { useStepValidation } from './utils';

const FuneralPlanStep: FunctionComponent<WizardComponentProps> = ({
  wizard: { next, previous, steps, step, replace },
}) => {
  useStepValidation(steps, step, replace);
  const funeralType = useSelector(({ registration }) => registration.funeralType || '');
  const funeralPlan = useSelector(({ registration }) => registration.funeralPlan);
  const dispatch = useDispatch();

  const handleSubmit = (funeralPlan: FuneralPlan) => {
    dispatch(setRegistration({ funeralPlan }));
    dispatch(trackProvisionPortalData({ funeralPlan }));
    next();
  };

  const funeralPlanOptions = funeralType === 'sea' ? seaFuneralPlans : funeralPlans;

  const title =
    funeralType === 'sea'
      ? t.signUp.wizards.funeralPlan.seaTitle
      : t.signUp.wizards.funeralPlan.title;

  return (
    <WizardCard
      title={title}
      onClickPrev={previous}
      onClickNext={next}
      labelPrev={t.global.labels.back}
      labelNext={t.global.labels.continue}
      disabledNext={!funeralPlan}
      icon="/img/icons/funeralPlan.svg"
      hint={t.signUp.wizards.funeralPlan.hint}
      steps={steps.length - 1}
      step={steps.indexOf(step)}
    >
      <Text mb="2rem">{t.signUp.wizards.funeralPlan.description[funeralType]}</Text>
      <Row justifyContent="center" flexWrap="wrap" mx={-1}>
        {funeralPlanOptions.map(({ value, label }) => (
          <Col key={value} width={[1, 3 / 4, 1 / 2]} my={1}>
            <FunnelButton
              selected={funeralPlan === value}
              onClick={() => handleSubmit(value as FuneralPlan)}
            >
              {label}
            </FunnelButton>
          </Col>
        ))}
      </Row>
    </WizardCard>
  );
};

export default withLocationTrack(t.signUp.wizards.funeralPlan.title)(FuneralPlanStep);
