import actions from 'actions';
import { TrackingState } from 'types';
import { createReducer } from 'typesafe-actions';

const initialState: TrackingState = {
  utmSource: '',
  utmMedium: '',
  utmCampaign: '',
  sourceCookie: '',
  clientidGclid: '',
  sessionID: '',
  userID: '',
  clientId: '',
};

const { setTrackingParams, setTrackingCookies } = actions.tracking;

export default createReducer(initialState).handleAction(
  [setTrackingParams, setTrackingCookies],
  (state, { payload }) => ({
    ...state,
    ...payload,
  }),
);
