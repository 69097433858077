import React from 'react';
import { global } from 'data';
import { Button, Icon, Text, Card } from 'mymoria-ui/components';
import { css, mq, styled } from 'mymoria-ui';
import { usePush } from 'mymoria-ui/hooks';

const t = global.wishes.createMode.steps.intro;

const EstimatedTime = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;

const StyledFormCard = styled(Card)(
  ({ theme: { colors } }) => css`
    background-color: ${colors.white};
    background-position: top 0px right -2px;
    background-size: 308px 168px;
    background-image: url('/img/background.svg');
    background-repeat: no-repeat;

    button {
      ${mq({
        display: ['block', 'inline-block'],
        width: ['100%', 'auto'],
        margin: ['2rem auto', '1rem 2rem 1rem 0'],
      })};
    }
  `,
);

const WishesStepIntro = () => {
  const { push } = usePush();

  return (
    <StyledFormCard variant="large">
      <Text>{t.formDescription}</Text>
      <Button type="submit">{t.setNow}</Button>
      <Button ghost onClick={() => push('/overview/wishes')}>
        {t.setLater}
      </Button>
      <EstimatedTime>
        <Icon.Duration />
        <Text>{t.estimatedTime}</Text>
      </EstimatedTime>
    </StyledFormCard>
  );
};

export default WishesStepIntro;
