import { formatPrice } from 'helpers';
import { CustomOfferItemPreview } from 'mymoria-ui/components';
import React, { FunctionComponent } from 'react';
import { useSelector } from 'hooks';

const BasicServicePreview: FunctionComponent<{ id: string }> = ({ id }) => {
  const service = useSelector(({ entities }) => entities.services[id]);
  const funeralType = useSelector(({ provision }) => provision.funeralType);

  if (!service) {
    return null;
  }

  return (
    <CustomOfferItemPreview
      outdated={!!service.deletedAt}
      funeralType={funeralType}
      selected
      name={service.translations.shortname}
      price={formatPrice(service.price, { gross: true })}
      description={service.translations.description}
      shortDescription={service.translations.shortDescription}
    />
  );
};

export default BasicServicePreview;
