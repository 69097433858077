import { removeProvision, updateProvision } from 'actions/provision';
import { Page, Section, EditFunnel } from 'components';
import { ConfirmModal } from 'components/Modals';
import config from 'config';
import { global as t } from 'data';
import { DateInputField, InputField, RadioGroupField, EmailField } from 'forms/fields';
import { withLocationTrack } from 'hocs';
import { useAuth, useDispatch, useFunnel, useSelector } from 'hooks';
import { getCustomerPhone } from 'reducers/provision';
import moment from 'moment';
import { mq, styled, css, getValidPhoneFormat } from 'mymoria-ui';
import { Button, Card, Col, Icon, Row, Text, Caption, Heading, Grid } from 'mymoria-ui/components';
import { RadioGroupOptions } from 'mymoria-ui/components/Form/RadioGroup';
import { useHistory, useModal, useToast } from 'mymoria-ui/hooks';
import React, { FunctionComponent, useCallback } from 'react';
import { Provision } from 'types';
import letter1 from 'assets/letter1.jpg';
import letter2 from 'assets/letter2.jpg';
import letter3 from 'assets/letter3.jpg';
import { profilePageSchema } from 'helpers';

const Container = styled.div`
  p {
    margin: 0;
  }

  ${mq({
    padding: ['2.5rem 0', '4rem 0', '1rem 0'],
  })};
`;

const CustomButton = styled(Button)`
  display: flex;
  margin-bottom: 0;
  svg {
    margin-right: 0.5rem;
  }
`;

const StyledRow = styled(Row)`
  margin-bottom: 1rem;

  ${mq({
    flexDirection: ['column', 'row'],
  })};
`;

const CustomCard = styled(Card)`
  margin-top: 1.5rem;
`;

const DocumentsCard = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #dfe4e4;
  margin-top: 5rem;

  img {
    position: absolute;
    ${mq({
      top: ['-6%', '-8%'],
      width: ['110px', '130px', '150px'],
    })};
  }

  h1 {
    margin-top: 3rem;
  }

  p,
  h1 {
    text-align: center;
  }
`;

const StyledCard = styled(Card)(
  mq({
    minHeight: ['auto', '450px', '450px', '480px'],
  }),
);

const CustomText = styled(Text)`
  margin: 2rem 0 3rem;
`;

const SubHeadLine = styled(CustomText)`
  margin: -1rem 0 3rem;
`;

const StyledCol = styled(Col)`
  padding-bottom: 1rem;
`;

const Thumbnail = styled.div<{ background: string }>(
  ({ background }) => css`
    background: url(${background});
    background-size: cover;
    border-radius: 50%;
    border: 1px solid #ccc;

    ${mq({
      minWidth: ['80px', '100px', '120px'],
      minHeight: ['80px', '100px', '120px'],
      width: ['80px', '100px', '120px'],
      height: ['80px', '100px', '120px'],
      marginRight: ['0.5rem', '0.5rem', '1rem'],
    })}
  `,
);

const ProfilePage: FunctionComponent = () => {
  const dispatch = useDispatch();
  const { addToast } = useToast();
  const { handlePush } = useHistory();

  const firstname = useSelector(({ provision }) => provision.firstname);
  const lastname = useSelector(({ provision }) => provision.lastname);
  const street = useSelector(({ provision }) => provision.street) || '';
  const postalCode = useSelector(({ provision }) => provision.postalCode) || '';
  const city = useSelector(({ provision }) => provision.city);
  const formattedPhone = useSelector(getCustomerPhone);
  const phone = useSelector(({ provision }) => provision.phone);
  const email = useSelector(({ provision }) => provision.email);
  const pBirth = useSelector(({ provision }) => provision.provisionBirth);
  const salutation = useSelector(({ provision }) => provision.salutation);
  const localDocumentFlag = useSelector(({ provision }) => provision.requestedDocumentsAt);
  const isActivated = useSelector(({ provision }) => !!provision.paymentAcceptedAt);
  const provisionBirth = moment(pBirth).format(config.dateFormat.view);

  const initialValues = {
    firstname,
    lastname,
    street,
    postalCode,
    city,
    phone,
    email,
    provisionBirth,
    salutation,
  };

  const { showModal, hideModal, Modal: HookModal } = useModal();
  const { section, openEditFunnel } = useFunnel();
  const { checkUser, signOut } = useAuth();

  const handleSubmit = useCallback(
    ({ provisionBirth, phone, ...profileDetails }: Provision) =>
      dispatch(
        updateProvision({
          ...profileDetails,
          phone: getValidPhoneFormat({ phone, formatType: 'e164' }),
          provisionBirth: moment(provisionBirth, config.dateFormat.view)
            .set({ hour: 12 })
            .toISOString(),
        }),
      )
        .then(({ requestedDocumentsAt: serverDocumentFlag }) => {
          if (serverDocumentFlag && !localDocumentFlag) {
            addToast(t.profile.documentRequest.toast, 'success');
          }
        })
        .catch(({ message }) => addToast(message)),
    [dispatch, addToast, localDocumentFlag],
  );

  const openEditSection = useCallback(
    () => openEditFunnel('PROFILE', { headerTitle: t.profile.sectionTitle }),
    [openEditFunnel],
  );

  const removeProvisionFunc = useCallback(
    () =>
      dispatch(removeProvision())
        .then(() => signOut())
        .catch(({ message }) => addToast(message)),
    [dispatch, signOut, addToast],
  );

  const saluteTypes: RadioGroupOptions[] = [
    { value: 'sir', label: t.forms.salutation.sir },
    { value: 'madame', label: t.forms.salutation.madame },
  ];

  return section ? (
    <EditFunnel
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={profilePageSchema(checkUser)}
      title={t.profile.editMode.sectionTitle}
    >
      <StyledCard title={t.profile.title} caption={t.profile.caption}>
        <Row flexWrap="wrap">
          <StyledCol width={1}>
            <RadioGroupField name="salutation" values={saluteTypes} />
          </StyledCol>
          <Col width={[1, 1, 1 / 2]}>
            <InputField name="firstname" label={t.forms.firstname} />
          </Col>
          <Col width={[1, 1, 1 / 2]}>
            <InputField name="lastname" label={t.forms.lastname} />
          </Col>
          <Col width={1}>
            <InputField name="street" label={t.forms.street} />
          </Col>
          <Col width={[1, 1, 1 / 2]}>
            <InputField name="postalCode" label={t.forms.postalCode} />
          </Col>
          <Col width={[1, 1, 1 / 2]}>
            <InputField name="city" label={t.forms.city} />
          </Col>
          <Col width={[1, 1, 1 / 2]}>
            <EmailField name="email" label={t.forms.email} />
          </Col>
          <Col width={[1, 1, 1 / 2]}>
            <InputField name="phone" label={t.forms.phone} />
          </Col>
          <Col width={[1, 1, 1 / 2]}>
            <DateInputField name="provisionBirth" label={t.forms.birthDate} />
          </Col>
        </Row>
      </StyledCard>
    </EditFunnel>
  ) : (
    <Page>
      <Section
        title={t.profile.pageTitle}
        onGoBack={handlePush('/overview', { restoreScrollPosition: true })}
        goBackLabel={t.overview.name}
      >
        <Grid>
          <Row justifyContent="center">
            <Col width={[1, 10 / 12, 2 / 3]}>
              <Card title={t.profile.title}>
                <Container>
                  <StyledRow>
                    <Col width={[1, 1 / 2]}>{t.profile.name}</Col>
                    <Col width={[1, 1 / 2]}>
                      <Text>{`${firstname} ${lastname}`}</Text>
                    </Col>
                  </StyledRow>
                  <StyledRow>
                    <Col width={[1, 1 / 2]}>{t.profile.address}</Col>
                    <Col>
                      <Text>{`${street || ''}\n ${city} ${postalCode || ''}`}</Text>
                    </Col>
                  </StyledRow>
                  <StyledRow>
                    <Col width={[1, 1 / 2]}>{t.profile.email}</Col>
                    <Col width={[1, 1 / 2]}>
                      <Text>{email}</Text>
                    </Col>
                  </StyledRow>
                  <StyledRow>
                    <Col width={[1, 1 / 2]}>{t.profile.birthdate}</Col>
                    <Col width={[1, 1 / 2]}>
                      <Text>{provisionBirth}</Text>
                    </Col>
                  </StyledRow>
                  <StyledRow>
                    <Col width={[1, 1 / 2]}>{t.profile.phoneNumber}</Col>
                    <Col width={[1, 1 / 2]}>
                      <Text>{formattedPhone}</Text>
                    </Col>
                  </StyledRow>
                </Container>
                <CustomButton ghost onClick={openEditSection}>
                  <Icon.Edit color="#00c1b1" />
                  {t.profile.buttonTitle}
                </CustomButton>
              </Card>

              {!localDocumentFlag && isActivated && (
                <DocumentsCard>
                  <img src="/img/tipp.svg" alt="tipp" />
                  <Heading.Heading1>{t.profile.documentRequest.title}</Heading.Heading1>
                  <CustomText>{t.profile.documentRequest.description}</CustomText>
                  <SubHeadLine>{t.profile.documentRequest.descriptionSubHeadline}</SubHeadLine>
                  <Col display="flex" pb="3rem" px="0">
                    {[letter1, letter3, letter2].map((letter, index) => (
                      <Thumbnail key={`letter${index}`} background={letter} />
                    ))}
                  </Col>
                  <Button onClick={openEditSection}>{t.profile.documentRequest.buttonTitle}</Button>
                  <Caption>{t.profile.documentRequest.buttonCaption}</Caption>
                </DocumentsCard>
              )}

              <CustomCard title={t.profile.removeAccount.title}>
                <CustomText>{t.profile.removeAccount.description}</CustomText>
                <HookModal onClose={hideModal} width={['100%', '60%']} height={['100%', 'auto']}>
                  <ConfirmModal
                    title={t.profile.modal.title}
                    text={t.profile.modal.description}
                    submitModal={removeProvisionFunc}
                    hideModal={hideModal}
                  />
                </HookModal>
                <Button onClick={showModal}>{t.profile.removeAccount.buttonTitle}</Button>
              </CustomCard>
            </Col>
          </Row>
        </Grid>
      </Section>
    </Page>
  );
};

export default withLocationTrack(t.profile.pageTitle)(ProfilePage);
