import React, { useEffect, useState } from 'react';
import config from 'config';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'hooks';
import { TrackingCookies } from 'mymoria-types';
import { camelCase, head, isEmpty, map, mapKeys, kebabCase } from 'lodash';
import { useLocation } from 'react-router-dom';
import { CONSENT_UPDATE, setTrackingCookies, setTrackingParams } from 'actions/tracking';
import { setAppParams } from 'actions/params';
import { global as t } from 'data';
import { useCMS } from 'mymoria-ui/hooks';
import { CookiesConsent } from 'mymoria-ui/components';
import { CMSCookieConsent, Cookie } from 'types';
import { getRootDomain } from 'helpers';

const { cookieSiteSlug } = config;

const parseQueryParams = (query: string, params: string[]) => {
  const queryParams = new URLSearchParams(query);
  const parsedParams: { [key: string]: string } = {};
  params.forEach(param =>
    queryParams.get(param) ? (parsedParams[param] = queryParams.get(param) || '') : {},
  );

  return parsedParams;
};

const Head = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [{ data }] = useCMS<CMSCookieConsent[]>(
    `/CookiePopup?filter[Status]=publish&filter[Origins]=${config.cms.cookieOrigin}&simple=1`,
  );
  const [cookies, setCookie] = useCookies();
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [optionalCookiesItems, setOptionalCookiesItems] = useState<Cookie[]>([]);

  const cookiesConsentData = head(data);

  useEffect(() => {
    if (cookiesConsentData) {
      const optionalCookiesObj = cookiesConsentData.OptionalCookies.map(({ value }) => {
        const cookieName = kebabCase(value.Name);
        const checkedValue = cookies[`${cookieSiteSlug}${cookieName}`];
        return {
          ...value,
          checked: checkedValue === 'true',
        };
      });

      setOptionalCookiesItems(optionalCookiesObj);
    }
  }, [cookiesConsentData, cookies]);

  const { gatsbyConsentMandatory } = cookies;
  const cookiesAccepted = gatsbyConsentMandatory === 'true';

  useEffect(() => {
    if (!cookiesAccepted) {
      setShowCookieConsent(true);
    }
  }, [cookiesAccepted]);

  useEffect(() => {
    const appParams = parseQueryParams(search, config.appParams);

    if (!isEmpty(appParams)) {
      dispatch(setAppParams(appParams));
    }

    const trackingParams = mapKeys(parseQueryParams(search, config.trackingParams), (v, k) =>
      camelCase(k),
    );

    if (!isEmpty(trackingParams)) {
      dispatch(setTrackingParams(trackingParams));
    }
  }, [dispatch, search]);

  useEffect(() => {
    const trackingCookies = {} as TrackingCookies;

    config.trackingCookies.forEach(({ cookieName, fieldName }) => {
      trackingCookies[fieldName] = cookies[cookieName];
    });

    dispatch(setTrackingCookies(trackingCookies));
  }, [dispatch, cookies]);

  useEffect(() => {
    // ga_clientId get notified once ga clientId is created at GA/GTM serverside
    const handleGaClientId = (event: CustomEventInit) =>
      dispatch(setTrackingCookies({ clientId: event?.detail?.clientId }));

    window.addEventListener('ga_clientId', handleGaClientId, { once: true });

    return () => document.removeEventListener('ga_clientId', handleGaClientId);
  }, [dispatch]);

  const handleCookieAccept = (cookieValue: boolean) => {
    let consent: { [key: string]: boolean | string } = {};

    const options = {
      expires: moment().add(1, 'year').toDate(),
      domain: getRootDomain(),
      path: '/',
      sameSite: true,
    };

    setCookie('gatsbyConsentMandatory', true, options);

    optionalCookiesItems.map(cookieItem => {
      let customCookieValue = cookieValue !== undefined ? cookieValue : cookieItem.checked;

      consent[kebabCase(cookieItem.Name)] = customCookieValue;

      return setCookie(
        `${cookieSiteSlug}${kebabCase(cookieItem.Name)}`,
        customCookieValue,
        options,
      );
    });

    if (window?.dataLayer) {
      window.dataLayer.push({ event: CONSENT_UPDATE, consent });
    }

    setShowCookieConsent(false);
  };

  return (
    <>
      <Helmet {...config.app.head}>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/animejs/2.0.2/anime.min.js" />
      </Helmet>
      {showCookieConsent && cookiesConsentData && (
        <CookiesConsent
          title={cookiesConsentData.Headline}
          description={cookiesConsentData.MoreInfo}
          shortDescription={cookiesConsentData.Copy}
          submitAllLabel={t.cookiesConsentModal.acceptAll}
          submitSelectedLabel={t.cookiesConsentModal.acceptSelected}
          moreInfoLabel={t.cookiesConsentModal.moreInfoLabel}
          lessInfoLabel={t.cookiesConsentModal.lessInfoLabel}
          settingsText={t.cookiesConsentModal.settingsText}
          settingsButton={t.cookiesConsentModal.settingsButton}
          settingsTextEnd={t.cookiesConsentModal.settingsTextEnd}
          essentialCookiesTitle={t.cookiesConsentModal.essentialCookiesTitle}
          mandatoryCookiesTitle={t.cookiesConsentModal.mandatoryCookiesTitle}
          mandatoryCookiesDescription={t.cookiesConsentModal.mandatoryCookiesDescription}
          mandatoryCookies={map(cookiesConsentData.MandatoryCookies, ({ value }) => value)}
          optionalCookiesTitle={t.cookiesConsentModal.optionalCookiesTitle}
          optionalCookiesDescription={t.cookiesConsentModal.optionalCookiesDescription}
          optionalCookies={optionalCookiesItems}
          setOptionalCookies={setOptionalCookiesItems}
          detailPageInfo={cookiesConsentData.DetailPageInfo}
          links={t.cookiesConsentModal.links}
          showDisableLabel={t.cookiesConsentModal.disableCookiesLabel}
          onSubmit={handleCookieAccept}
        />
      )}
    </>
  );
};

export default Head;
