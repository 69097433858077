import { Button, Markdown, WizardCard, Row, Text } from 'mymoria-ui/components';
import { financial, global } from 'data';
import React, { FunctionComponent } from 'react';
import { WizardComponentProps } from 'react-albus';
import config from 'config';
import { useHistory } from 'mymoria-ui/hooks';
import { useSelector } from 'hooks';
import { setUpFuneralTrusts } from 'api/provisions';

const TreuhandStep: FunctionComponent<WizardComponentProps> = ({
  wizard: { push, step, steps },
}) => {
  const { handlePush } = useHistory();
  const id = useSelector(({ provision }) => provision.id);

  return (
    <WizardCard
      title={financial.wizard.treuhand.title}
      labelPrev={global.labels.back}
      labelNext={global.labels.continue}
      onClickPrev={() => push('paymentType')}
      steps={3}
      step={3}
    >
      <Text fontWeight="bold">{financial.wizard.treuhand.subTitle}</Text>
      <Markdown>{financial.wizard.treuhand.description}</Markdown>
      <Row justifyContent="center" mt={6}>
        <Button
          onClick={() => {
            setUpFuneralTrusts(id);
            return window.open(config.treuhand.pdfUrl, '_blank');
          }}
          mx={2}
        >
          {financial.wizard.treuhand.buttonText}
        </Button>
        <Button ghost onClick={handlePush('/overview/finance')} mx={2}>
          {financial.hdh.download.fundingButton}
        </Button>
      </Row>
    </WizardCard>
  );
};

export default TreuhandStep;
