import { offer } from 'data';
import { useAuth, useSelector } from 'hooks';
import { Markdown, Message, TextLink } from 'mymoria-ui/components';
import React from 'react';
import { getProvision } from 'reducers/provision';
import { sprintf } from 'utils';

const AdminEditingMessage = () => {
  const { isAdmin, user, signOut } = useAuth();
  const provision = useSelector(getProvision);

  return isAdmin && user.username !== provision.userEmail ? (
    <Message status="error">
      <Markdown source={sprintf(offer.customerCareEditing, provision)} />
      <TextLink href="#" onClick={() => signOut()}>
        {offer.customerCareEditingDisconnect}
      </TextLink>
    </Message>
  ) : null;
};

export default AdminEditingMessage;
