import React, { FunctionComponent, useEffect, useState } from 'react';
import { global as t } from 'data';
import { Form, Formik, FormikHelpers } from 'formik';
import { PasswordField } from 'forms/fields';
import { Button, Loader, Text } from 'mymoria-ui/components';
import { ConfirmationBox, Page, SectionPublic } from 'components';
import { styled } from 'mymoria-ui';
import { api, sprintf } from 'utils';
import { parseQueryParams } from 'helpers';
import { RouteComponentProps } from 'react-router-dom';
import * as yup from 'yup';
import { usePush } from 'mymoria-ui/hooks';

interface FormikValues {
  password: string;
}

const StyledLoader = styled(Loader)`
  padding: 0 1rem;
`;

const ForgotPasswordVerify: FunctionComponent<RouteComponentProps> = ({ location: { search } }) => {
  const [token, setToken] = useState('');
  const [username, setUsername] = useState('');
  const [verificationError, setVerificationError] = useState(null);
  const [verificationPending, setVerificationPending] = useState(true);
  const { push } = usePush();

  const handleSubmit = ({ password }: FormikValues, { setStatus }: FormikHelpers<FormikValues>) => {
    setStatus({ error: '' });

    api
      .post('/provisions/users/forgot-password/password', { password, token })
      .then(() => {
        setStatus({ passwordSet: true });
      })
      .catch(() => {
        setStatus({ error: 'something went wrong', passwordSet: false });
      });
  };

  useEffect(() => {
    const { token } = parseQueryParams(search, ['token']);

    api
      .get(`/provisions/users/forgot-password/verify/${token}`)
      .then(({ data: { username } }) => {
        setToken(token);
        setUsername(username);
        setVerificationPending(false);
      })
      .catch(() => {
        setVerificationPending(false);
        setVerificationError(t.apiErrors.accountConfirmation);
      });
  }, [search]);

  if (verificationPending) {
    return <Loader />;
  }

  return (
    <Page footer="none">
      <SectionPublic
        title={t.forgotPassword.email.title}
        description={username && sprintf(t.forgotPassword.password.description, { username })}
        footerLink="/login"
        footerLinkLabel={t.labels.signIn}
        footerTitle={t.forgotPassword.email.footerTitle}
      >
        {verificationError ? (
          <ConfirmationBox>
            <Text align="center">{t.forgotPassword.verify.error}</Text>
            <Button type="button" onClick={() => push('/forgot-password')}>
              {t.forgotPassword.verify.submit}
            </Button>
          </ConfirmationBox>
        ) : (
          <Formik
            validationSchema={yup.object().shape({
              password: yup
                .string()
                .required(t.validators.required)
                .min(6, t.validators.length)
                .matches(/^[\S]+.*[\S]+$/, t.validators.trimPassword),
            })}
            initialValues={{ password: '' }}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, status = {} }) =>
              status.passwordSet ? (
                <ConfirmationBox>
                  <img src="/img/fancy-check-mark.svg" alt="success" />
                  <Text align="center">{t.forgotPassword.password.summary}</Text>
                </ConfirmationBox>
              ) : (
                <Form noValidate>
                  <PasswordField type="password" name="password" label={t.labels.password} />
                  <Button type="submit" block disabled={isSubmitting}>
                    {t.labels.setNewPassword}
                    {isSubmitting && <StyledLoader tiny color="#ffffff" />}
                  </Button>
                  {status.error && <Text>{status.error}</Text>}
                </Form>
              )
            }
          </Formik>
        )}
      </SectionPublic>
    </Page>
  );
};

export default ForgotPasswordVerify;
