import insuranceActions from 'actions/insurance';
import { isEmpty } from 'lodash';
import { InsuranceState, InsuranceTariff } from 'types';
import { ApplicationState } from 'types';
import { createReducer } from 'typesafe-actions';

const initialState: InsuranceState = {
  insurance: {} as InsuranceTariff,
};

export default createReducer(initialState).handleAction(
  insuranceActions.fetch,
  (state, { payload }) => ({
    ...state,
    insurance: payload[0],
  }),
);

const getTariff = (state: ApplicationState) => state.insurance.insurance;

export const getMonthlyTariffPrice = (state: ApplicationState) => {
  const Tariff = getTariff(state);
  if (Tariff && !isEmpty(Tariff.LISTE)) return Tariff.LISTE && Tariff.LISTE[0].Beitrag;
  return 0;
};

export const getExpectedPayoutPrice = (state: ApplicationState) => {
  const Tariff = getTariff(state);
  if (Tariff && !isEmpty(Tariff.LISTE)) return Tariff.LISTE && Tariff.LISTE[0].effVsSumme;
  return 0;
};

export const isWundw = (state: ApplicationState) =>
  state.provision.financePartner === 'ww' || state.params.fp === 'ww';
