import { Page, Section } from 'components';
import config from 'config';
import { global as t } from 'data';
import { withLocationTrack } from 'hocs';
import { useAuth, useSelector } from 'hooks';
import { Button, Caption, Card, Col, Markdown, Row, Grid } from 'mymoria-ui/components';
import { usePush } from 'mymoria-ui/hooks';
import { mq, styled } from 'mymoria-ui/utils';
import React, { FunctionComponent } from 'react';
import { getProvision } from 'reducers/provision';

const {
  dipat: { url: dipatUrl },
} = config;

const FlexCol = styled(Col)`
  display: flex !important;
  flex-direction: column;
  align-items: center;

  img {
    position: absolute;
    top: -70px;
    width: 202px;
    height: 154px;
    transform: rotate(180deg);
  }
`;

const StyledCard = styled(Card)`
  position: relative;
  margin-top: 4rem;
`;

const ButtonCol = styled(Col)`
  display: flex !important;
  align-items: center;
  margin: 4rem 0;

  button {
    ${mq({
      width: ['100%', 'auto'],
    })};
  }

  ${mq({
    flexWrap: ['wrap', 'nowrap'],
    flexDirection: ['column', 'row'],
  })};
`;

const StyledCaption = styled(Caption)`
  margin-top: 2rem;
`;

const StyledGhost = styled(Button)(
  mq({
    marginLeft: ['0', '2rem'],
  }),
);

const StyledMarkdown = styled(Markdown)`
  margin-top: 4rem;
  margin-bottom: 1rem;
`;

const PartnerPage: FunctionComponent = () => {
  const { user } = useAuth();
  const { handlePush } = usePush();

  const provision = useSelector(getProvision);
  const username = user && user.getUsername();
  const firstName = (provision && provision.firstname) || '';
  const lastName = (provision && provision.lastname) || '';

  return (
    <Page headerTitle={t.partnerPage.headerTitle}>
      <Section
        title={t.partnerPage.title}
        description={t.partnerPage.description}
        onGoBack={handlePush('/overview', { restoreScrollPosition: true })}
        goBackLabel={t.overview.name}
      >
        <Grid>
          <Row justifyContent="center">
            <Col width={[1, 10 / 12, 6 / 9]}>
              <StyledCard variant="small">
                <Row flexDirection="column" alignItems="center">
                  <FlexCol width={[1, 1, 7 / 10]}>
                    <img src="/img/flower_04.png" alt="flower" />
                    <StyledMarkdown>{t.partnerPage.cardDescriptionOne}</StyledMarkdown>
                    <Markdown>{t.partnerPage.cardDescriptionTwo}</Markdown>
                    <StyledCaption light>{t.partnerPage.cardCaption}</StyledCaption>
                  </FlexCol>
                  <ButtonCol width={[1, 1, 7 / 10]}>
                    <Button onClick={() => window.open(dipatUrl(username, firstName, lastName))}>
                      {t.partnerPage.cardConfirmButton}
                    </Button>
                    <StyledGhost ghost onClick={handlePush('/overview')}>
                      {t.partnerPage.cardCancelButton}
                    </StyledGhost>
                  </ButtonCol>
                </Row>
              </StyledCard>
            </Col>
          </Row>
        </Grid>
      </Section>
    </Page>
  );
};

export default withLocationTrack(t.partnerPage.title)(PartnerPage);
