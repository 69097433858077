import React, { FunctionComponent } from 'react';

interface Props {
  fill: string;
}

const Offer: FunctionComponent<Props> = ({ fill, ...props }) => (
  <svg {...props} viewBox="0 0 74 74">
    <path
      fill={fill}
      d="M37,72C17.701,72,2,56.299,2,37S17.701,2,37,2s35,15.701,35,35S56.299,72,37,72z M37,4 C18.804,4,4,18.804,4,37s14.804,33,33,33s33-14.804,33-33S55.196,4,37,4z"
    />
    <g>
      <path
        fill={fill}
        d="M58,48h-9V19c0-0.553-0.448-1-1-1H23c-0.552,0-1,0.447-1,1v30c0,3.309,2.691,6,6,6h25c3.309,0,6-2.691,6-6 C59,48.447,58.552,48,58,48z M24,49V20h23v28H33c-0.552,0-1,0.447-1,1c0,2.206-1.794,4-4,4S24,51.206,24,49z M53,53H32.468 c0.029-0.032,0.052-0.07,0.08-0.103c0.066-0.077,0.125-0.16,0.188-0.241c0.134-0.173,0.259-0.351,0.374-0.538 c0.059-0.095,0.114-0.19,0.167-0.288c0.106-0.196,0.198-0.399,0.282-0.608c0.037-0.093,0.079-0.183,0.111-0.277 c0.105-0.306,0.193-0.619,0.248-0.944H48h8.874C56.428,51.724,54.86,53,53,53z"
      />
      <circle fill={fill} cx="28" cy="24" r="2" />
      <circle fill={fill} cx="35.5" cy="24" r="2" />
      <circle fill={fill} cx="43" cy="24" r="2" />
      <path
        fill={fill}
        d="M27,30h12c0.552,0,1-0.447,1-1s-0.448-1-1-1H27c-0.552,0-1,0.447-1,1S26.448,30,27,30z"
      />
      <path
        fill={fill}
        d="M27,34h17c0.552,0,1-0.447,1-1s-0.448-1-1-1H27c-0.552,0-1,0.447-1,1S26.448,34,27,34z"
      />
      <path
        fill={fill}
        d="M27,38h15c0.552,0,1-0.447,1-1s-0.448-1-1-1H27c-0.552,0-1,0.447-1,1S26.448,38,27,38z"
      />
      <path
        fill={fill}
        d="M27,42h12c0.552,0,1-0.447,1-1s-0.448-1-1-1H27c-0.552,0-1,0.447-1,1S26.448,42,27,42z"
      />
      <path
        fill={fill}
        d="M36,45c0-0.553-0.448-1-1-1h-8c-0.552,0-1,0.447-1,1s0.448,1,1,1h8C35.552,46,36,45.553,36,45z"
      />
      <path
        fill={fill}
        d="M44,44h-5c-0.552,0-1,0.447-1,1s0.448,1,1,1h5c0.552,0,1-0.447,1-1S44.552,44,44,44z"
      />
    </g>
  </svg>
);

export default Offer;
