import { FieldConfig, useField } from 'formik';
import SelectLight, { SelectProps } from 'mymoria-ui/components/Form/SelectLight';
import React, { FunctionComponent } from 'react';

const SelectLightField: FunctionComponent<
  FieldConfig & Omit<SelectProps, 'value' | 'onChange'>
> = ({ name, ...props }) => {
  const [field, meta, { setValue }] = useField(name);
  const error = meta.touched && meta.error ? meta.error : undefined;

  return <SelectLight {...field} {...props} error={error} onChange={value => setValue(value)} />;
};

export default SelectLightField;
