import React, { FunctionComponent, useState } from 'react';
import * as t from 'data';
import { SelectButton, Text, WizardCard } from 'mymoria-ui/components';
import { useDispatch, useSelector } from 'hooks';
import { WizardComponentProps } from 'react-albus';
import { useHistory, useToast } from 'mymoria-ui/hooks';
import { updateProvision } from 'actions/provision';
import { ProvisionType } from 'mymoria-types';

type Button<T> = {
  value: T;
  label: string;
};

const provisionTypeButtons: Button<ProvisionType>[] = [
  { value: 'ALREADY_PAID', label: t.financial.wizard.insuranceType.status.ALREADY_PAID },
  { value: 'PAY_BY_MYSELF', label: t.financial.wizard.insuranceType.status.PAY_BY_MYSELF },
  { value: 'PAY_BY_FAMILY', label: t.financial.wizard.insuranceType.status.PAY_BY_FAMILY },
];

const InsuranceTypeStep: FunctionComponent<WizardComponentProps> = ({ wizard }) => {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { push } = useHistory();
  const { addToast } = useToast();
  const financialProvisionType = useSelector(({ provision }) => provision.financialProvisionType);

  const goNext = (financialProvisionType?: ProvisionType) => {
    if (financialProvisionType === 'ALREADY_PAID') {
      wizard.push('details');
    } else if (financialProvisionType === 'PAY_BY_MYSELF') {
      push('/overview/finance/costs/wizard');
    } else if (financialProvisionType === 'PAY_BY_FAMILY') {
      push('/overview/finance');
    }
  };

  const handleSubmit = async (financialProvisionType: ProvisionType) => {
    try {
      setIsSubmitting(true);
      await dispatch(updateProvision({ financialProvisionType }));
      setIsSubmitting(false);
      goNext(financialProvisionType);
    } catch (err) {
      setIsSubmitting(false);
      addToast(t.global.apiErrors.general);
    }
  };

  return (
    <WizardCard
      title={t.financial.wizard.insuranceType.title}
      onClickPrev={() => wizard.push('intro')}
      onClickNext={() => goNext(financialProvisionType)}
      isLoading={isSubmitting}
      labelPrev={t.global.labels.back}
      labelNext={t.global.labels.continue}
      disabledNext={!financialProvisionType}
    >
      <Text>{t.financial.wizard.insuranceType.description}</Text>
      {provisionTypeButtons.map(({ value, label }) => (
        <SelectButton
          key={value}
          selected={value === financialProvisionType}
          onClick={() => handleSubmit(value)}
        >
          {label}
        </SelectButton>
      ))}
      <Text>{t.financial.wizard.insuranceType.additionalInfo}</Text>
    </WizardCard>
  );
};

export default InsuranceTypeStep;
