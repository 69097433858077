import plansActions from 'actions/plans';
import { PlansState } from 'types';
import { createReducer } from 'typesafe-actions';

const initialState: PlansState = [] as PlansState;

export default createReducer(initialState).handleAction(
  // @ts-ignore
  plansActions.fetch,
  // @ts-ignore
  (state, { payload: { plans } }) => plans,
);
