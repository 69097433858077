/* global dataLayer */

import { Middleware } from 'redux';
import { isEmpty, pick } from 'lodash';
import { REHYDRATE } from 'redux-persist';
import { GTM_INIT_DATA_LAYER, trackProvisionPortalData } from 'actions/tracking';

const funnelFields = [
  'funeralType',
  'funeralPlan',
  'salutation',
  'firstname',
  'lastname',
  'email',
  'password',
  'phone',
  'provisionBirth',
  'terms',
  'place',
];

const addGTMEvent = ((cache: any[]) => {
  const interval = setInterval(() => {
    if (typeof dataLayer !== 'undefined') {
      cache.forEach(event => dataLayer.push(event));
      cache.splice(0);
      clearInterval(interval);
    }
  }, 500);

  return (event: any) => {
    if (typeof dataLayer !== 'undefined' && isEmpty(cache)) {
      dataLayer.push(event);
    } else {
      cache.push(event);
    }
  };
})([]);

let data = {};
const googleTagMiddleware: Middleware = () => next => action => {
  if (action.meta?.track) {
    addGTMEvent(action.payload);
  }

  if (action.type === REHYDRATE) {
    data = pick(action.payload?.registration, funnelFields);
  }

  if (!isEmpty(data) && action.type === GTM_INIT_DATA_LAYER) {
    addGTMEvent(trackProvisionPortalData(data).payload);
    data = {};
  }

  return next(action);
};

export default googleTagMiddleware;
