import React, { useState, FunctionComponent } from 'react';
import * as t from 'data';
import { useHistory } from 'mymoria-ui/hooks';
import Collapse from 'components/Collapse';
import { TextLink, Text, Flex } from 'mymoria-ui/components';
import { styled, css } from 'mymoria-ui';

interface Props {
  offerItems: { label: string; value: string }[];
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Label = styled(Text)(
  ({ theme: { font } }) => css`
    font-weight: ${font.weight.semiBold};
  `,
);

const Price = styled(Label)`
  flex: 1;
  margin: 0 0 0 1rem;
  white-space: nowrap;
  text-align: right;
`;

const OfferTotalPriceDetails: FunctionComponent<Props> = ({ offerItems }) => {
  const { handlePush } = useHistory();
  const [, setIsCollapsed] = useState(false);

  return (
    <>
      <Flex>
        (
        <TextLink m={0} lineHeight="unset" onClick={handlePush('/overview/offer')}>
          {t.financial.wizard.futureEstimation.editOfferLabel}
        </TextLink>
        )
      </Flex>
      <Collapse
        isInitiallyCollapsed
        onCollapse={() => setIsCollapsed(true)}
        onExpand={() => setIsCollapsed(false)}
      >
        {offerItems.map((item, index) => (
          <StyledWrapper key={item.label}>
            <Label m={index === 0 ? '0.5rem 0 0' : '0'}>{item.label}</Label>
            <Price>{item.value}</Price>
          </StyledWrapper>
        ))}
      </Collapse>
    </>
  );
};

export default OfferTotalPriceDetails;
