import actions from 'actions';
import { createReducer } from 'typesafe-actions';
import { ProductsState } from 'types';
import { map } from 'lodash';

const {
  fetch: fetchProvision,
  changeBasicInformation,
  changeProduct,
  removeProduct,
} = actions.provision;
const { fetchAlternativeProducts } = actions.products;

const initialState: ProductsState = {
  basic: [],
  optional: [],
  defaults: {
    basic: [],
    optional: [],
  },
  alternatives: {
    card: [],
    coffin: [],
    flower: [],
    notice: [],
    urn: [],
    grave_goods: [],
  },
};

const updateCollection = (collection: string[], removedId: string, addedId: string) =>
  map(collection, id => (id === removedId ? addedId : id));

export default createReducer(initialState)
  .handleAction([fetchProvision, changeBasicInformation], (state, { payload }) => ({
    ...state,
    ...payload.result.products,
  }))
  .handleAction(fetchAlternativeProducts, (state, { payload: { result } }) => ({
    ...state,
    alternatives: { ...state.alternatives, ...result },
  }))
  .handleAction(changeProduct, (state, { payload: { addedId, removedId } }) => ({
    ...state,
    basic: updateCollection(state.basic, removedId, addedId),
    optional: updateCollection(state.optional, removedId, addedId),
  }))
  .handleAction(removeProduct, (state, { payload }) => ({
    ...state,
    ...(payload.defaultProductId && {
      optional: updateCollection(state.optional, payload.id, payload.defaultProductId),
    }),
  }));
