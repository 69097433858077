import { FieldConfig, useField } from 'formik';
import Checkbox, { CheckboxProps } from 'mymoria-ui/components/Form/Checkbox';
import React, { FunctionComponent } from 'react';

const CheckboxField: FunctionComponent<Omit<CheckboxProps, 'validate'> & FieldConfig> = props => {
  const [field, meta] = useField({ ...props, type: 'checkbox' });

  return <Checkbox {...field} {...props} error={meta.touched && meta.error} />;
};

export default CheckboxField;
