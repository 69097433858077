import React, { FunctionComponent } from 'react';
import { Page, Section } from 'components';
import { financial as t } from 'data';
import { RouteComponentProps } from 'react-router';
import { Step, Steps, Wizard } from 'react-albus';
import { WizardGrid } from 'mymoria-ui/components';
import CostCalculatorStep from './CostsCalculatorStep';
import FutureEstimationStep from './FutureEstimationStep';

const CostsWizard: FunctionComponent<RouteComponentProps> = ({ history, match }) => (
  <Page footer="none">
    <Wizard
      history={history}
      basename={match.url}
      render={({ step }) => (
        <Section title={t.wizard[step.id]?.title}>
          <WizardGrid>
            <Steps>
              <Step id="costCalculator" render={wizard => <CostCalculatorStep wizard={wizard} />} />
              <Step
                id="futureEstimationStep"
                render={wizard => <FutureEstimationStep wizard={wizard} />}
              />
            </Steps>
          </WizardGrid>
        </Section>
      )}
    />
  </Page>
);

export default CostsWizard;
