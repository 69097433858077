import { discountProvision } from 'actions/provision';
import { global as t } from 'data';
import { Form, Formik, FormikHelpers } from 'formik';
import { InputField } from 'forms/fields';
import { useDispatch } from 'hooks';
import { styled } from 'mymoria-ui';
import { Button, Text } from 'mymoria-ui/components';
import React, { FunctionComponent } from 'react';
import { Discount } from 'mymoria-types';
import * as yup from 'yup';

const Container = styled.div`
  form {
    display: flex;

    & > div:first-of-type {
      width: 100%;
    }
  }

  button {
    margin-left: 0.5rem;
  }
`;

const CouponCode: FunctionComponent = () => {
  const dispatch = useDispatch();

  const handleSubmit = (
    values: Pick<Discount, 'couponCode'>,
    { setStatus, setSubmitting }: FormikHelpers<Pick<Discount, 'couponCode'>>,
  ) =>
    dispatch(discountProvision(values))
      .catch(({ response }) => {
        setStatus({
          msg:
            response && response.status === 404 ? t.validators.couponInvalid : t.apiErrors.general,
        });
      })
      .finally(() => setSubmitting(false));

  return (
    <Container>
      <Formik
        initialValues={{
          couponCode: '',
        }}
        validationSchema={yup.object({
          couponCode: yup.string(),
        })}
        onSubmit={handleSubmit}
        render={({ isSubmitting, status, dirty }) => (
          <>
            <Form noValidate>
              <InputField name="couponCode" label="Coupon Code" />
              <Button type="submit" disabled={isSubmitting || !dirty}>
                {t.coupon.buttonText}
              </Button>
            </Form>
            {status && status.msg && <Text>{status.msg}</Text>}
          </>
        )}
      />
    </Container>
  );
};

export default CouponCode;
