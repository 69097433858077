import { Page, Section } from 'components';
import { css, mq, styled } from 'mymoria-ui';
import { Button, Col, Grid, Heading, Icon, Loader, Row, Text } from 'mymoria-ui/components';
import { darken } from 'polished';
import { global as t } from 'data';
import React, { FunctionComponent } from 'react';
import { useStepper } from 'hooks';
import { StepperConfig } from 'hooks/useStepper';
import { Form, Formik } from 'formik';
import { Redirect, Route, Switch } from 'react-router';
import { pick } from 'lodash';
import { usePush } from 'mymoria-ui/hooks';

/* todo: move common styled components to separate file */
const Footer = styled.div(({ theme: { colors } }) =>
  mq({
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: 0,
    height: ['60px', '100px'],
    display: 'flex',
    justifyContent: ['space-around', 'space-between'],
    alignItems: 'center',
    padding: [0, '0 2.5rem', '0 4rem'],
    background: colors.primary,
  }),
);

const StyledButton = styled(Button)(
  ({ theme: { colors } }) => css`
    margin: 0 1rem;
    background: transparent;
    color: ${colors.white};
    border-color: ${colors.white};

    svg {
      path {
        stroke: ${colors.white};
        fill: ${colors.white};
      }

      vertical-align: text-top;
    }

    &[disabled] {
      color: ${colors.white};
      opacity: 0.4;
    }

    &:active,
    &:focus {
      color: ${colors.white} !important;
    }

    &:not([disabled]) {
      &:hover {
        color: ${darken(0.1, colors.white)} !important;
        border-color: ${darken(0.1, colors.white)} !important;
        background: transparent !important;

        svg {
          path {
            stroke: ${darken(0.1, colors.white)};
            fill: ${darken(0.1, colors.white)};
          }
        }
      }
    }
  `,
);

const PrevButton = styled(StyledButton)`
  svg {
    margin-right: 0.5rem;
    transform: rotate(180deg);
  }
`;

const NextButton = styled(StyledButton)`
  svg {
    margin-left: 0.5rem;
  }
`;

const Steps = styled(Heading.Heading3)(
  ({ theme: { colors } }) => css`
    color: ${colors.white};

    span {
      opacity: 0.7;
    }
  `,
);

const Error = styled(Text)(
  ({ theme: { colors } }) => css`
    color: ${colors.red};
    text-align: center;
    margin: 2rem 0;
  `,
);

const ContentCol = styled(Col)`
  margin-bottom: 7rem;
`;

const CreateFunnel: FunctionComponent<StepperConfig> = ({
  basepath,
  steps,
  onGoBack = '/overview',
  headerTitle,
  ...props
}) => {
  const { handlePush } = usePush();
  const { currentStep, formikProps, goToPrevStep } = useStepper({ basepath, steps, ...props });

  return (
    <Page withNavigation={false} footer="none" headerTitle={headerTitle}>
      <Formik enableReinitialize {...formikProps}>
        {({ isSubmitting, status }) => (
          <Form>
            <Section
              onGoBack={handlePush(onGoBack)}
              goBackLabel={t.overview.name}
              {...pick(steps[currentStep], ['title', 'description'])}
            >
              <Grid>
                <Row justifyContent="center">
                  {isSubmitting && <Loader overlay />}
                  <ContentCol width={[1, 10 / 12, 2 / 3]}>
                    <Switch>
                      {steps.map(({ path, component }) => (
                        <Route key={path} path={`${basepath}${path}`} component={component} />
                      ))}
                      <Redirect to={`${basepath}${steps[0].path}`} />
                    </Switch>
                  </ContentCol>
                </Row>
                {status?.error && <Error>{t.validators.error}</Error>}
              </Grid>
            </Section>
            <Footer>
              <PrevButton
                type="button"
                ghost
                disabled={currentStep === 0 || isSubmitting}
                onClick={goToPrevStep}
                className="back"
              >
                <Icon.Arrow />
                {t.labels.back}
              </PrevButton>
              <Steps>
                {currentStep + 1}
                <span>{` / ${steps.length}`}</span>
              </Steps>
              <NextButton type="submit" ghost disabled={isSubmitting} className="next">
                {t.labels.continue}
                <Icon.Arrow />
              </NextButton>
            </Footer>
          </Form>
        )}
      </Formik>
    </Page>
  );
};

export default CreateFunnel;
