import React, { FunctionComponent } from 'react';
import * as t from 'data';
import { PriceRow, Text, WizardCard } from 'mymoria-ui/components';
import { useSelector } from 'hooks';
import { WizardComponentProps } from 'react-albus';
import { sprintf } from 'utils';
import { formatPrice } from 'helpers';
import { styled, css } from 'mymoria-ui';
import { calculateCostEstimation } from 'helpers/index';
import moment from 'moment';
import { useHistory } from 'mymoria-ui/hooks';

const StyledLine = styled.hr(
  ({ theme: { colors } }) => css`
    width: 100%;
    border-top: 2px solid ${colors.primary};
    margin: 1rem 0;
  `,
);

const FutureEstimationStep: FunctionComponent<WizardComponentProps> = ({ wizard }) => {
  const { price: basePrice, funeralSiteEstimatedPrice, provisionBirth } = useSelector(
    ({ provision }) => provision,
  );
  const { handlePush } = useHistory();

  const price = basePrice + parseInt(funeralSiteEstimatedPrice || '0');
  const birthYear = moment(provisionBirth).year();
  const { timeToLive, ageAtDeath, interestRate } = calculateCostEstimation(provisionBirth);
  const priceIncrease = timeToLive * price * interestRate;
  const totalFuturePrice = price + priceIncrease;

  return (
    <WizardCard
      title={t.financial.wizard.futureEstimation.title}
      onClickPrev={() => wizard.push('costCalculator')}
      onClickNext={handlePush('/overview/finance/partner')}
      labelPrev={t.global.labels.back}
      labelNext={t.global.labels.continue}
    >
      <Text>
        {sprintf(t.financial.wizard.futureEstimation.description, {
          birthYear,
          timeToLive,
          ageAtDeath,
          interestRate: interestRate * 100,
        })}
      </Text>
      <StyledLine />
      <PriceRow label={t.financial.wizard.futureEstimation.todayCost} value={formatPrice(price)} />
      <PriceRow
        label={sprintf(t.financial.wizard.futureEstimation.futureCost, {
          timeToLive,
        })}
        value={formatPrice(priceIncrease)}
      />
      <StyledLine />
      <PriceRow
        label={sprintf(t.financial.wizard.futureEstimation.totalFutureCost, {
          timeToLive,
        })}
        value={formatPrice(totalFuturePrice)}
      />
      <StyledLine />
      <Text>
        {sprintf(t.financial.wizard.futureEstimation.footNote, {
          totalFuturePrice: formatPrice(totalFuturePrice),
        })}
      </Text>
    </WizardCard>
  );
};

export default FutureEstimationStep;
