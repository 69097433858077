import React, { FunctionComponent, useLayoutEffect } from 'react';
import { WizardComponentProps } from 'react-albus';
import { Text, WizardCard, Markdown, Caption } from 'mymoria-ui/components';
import { financial, global } from 'data';
import { formatPrice, calculateCostEstimation } from 'helpers';
import { css, styled } from 'mymoria-ui/utils';
import { useWundWState } from './WundWState';
import moment from 'moment';
import config from 'config';
import { sprintf } from 'utils';

const { dateFormat } = config;

const Widget = styled.div(
  ({ theme: { colors } }) => css`
    background-color: ${colors.primary};
    min-height: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;

    p {
      color: white;
    }
  `,
);

const Price = styled.span(
  ({
    theme: {
      components: { headings },
    },
  }) => css`
    ${headings.h1};
    color: white;
    display: inline-block;
    margin-right: 0.5rem;
  `,
);

const TariffStep: FunctionComponent<WizardComponentProps> = ({
  wizard: { next, push, steps, step },
}) => {
  const [{ paymentType, startDate, totalFuturePrice, birthDate, tariff }] = useWundWState();
  const { age, futureYears, ageAtDeath } = calculateCostEstimation(birthDate);

  useLayoutEffect(() => {
    if (!paymentType) {
      push('paymentType');
    }
  }, [paymentType, push]);

  // just for ts compiler
  if (!tariff || !paymentType) {
    return null;
  }

  return (
    <WizardCard
      title={financial.wundw.tariff.title}
      onClickPrev={() => push('paymentType')}
      onClickNext={next}
      labelPrev={global.labels.back}
      labelNext={global.labels.continue}
      steps={steps.length - 1}
      step={steps.indexOf(step)}
    >
      <Markdown>
        {sprintf(financial.wundw.tariff.paymentType[paymentType].description, {
          totalFuturePrice: formatPrice(totalFuturePrice),
          birthDate: moment(birthDate).format(dateFormat.view),
          startDate: moment(startDate).format(dateFormat.view),
          startAge: age,
          paymentType: financial.wundw.tariff.paymentType[paymentType].title,
          sum: formatPrice(tariff.sum),
          sumStatus:
            tariff.sumStatus === 'normal' ? '' : financial.wundw.tariff.sumStatus[tariff.sumStatus],
        })}
      </Markdown>
      <Widget>
        <Text>
          {sprintf(financial.wundw.tariff.paymentType[paymentType].priceHeader, {
            deathYear: futureYears,
            deathAge: ageAtDeath,
          })}
        </Text>
        <Text>
          <Price>
            {formatPrice(paymentType === 'consulting' ? 0 : tariff[paymentType], {
              gross: false,
            })}
          </Price>
        </Text>
      </Widget>
      <Caption light>{financial.wundw.tariff.paymentType[paymentType].priceHint}</Caption>
    </WizardCard>
  );
};

export default TariffStep;
