import React, { FunctionComponent } from 'react';
import * as t from 'data';
import { Text, WizardCard } from 'mymoria-ui/components';
import { PlaceSearchField } from 'forms/fields';
import { Formik } from 'formik';
import { WizardComponentProps } from 'react-albus';
import { SignupValues, Place } from 'types';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { placeSchema } from 'helpers';
import { withLocationTrack } from 'hocs';
import { useDispatch } from 'hooks';
import { trackProvisionPortalData } from 'actions/tracking';
import { useSelector } from 'hooks';
import { setRegistration } from 'actions/registration';
import { useStepValidation } from './utils';
import { isMobile } from 'react-device-detect';

type FormValues = Pick<SignupValues, 'place'>;

const PlaceStep: FunctionComponent<WizardComponentProps> = ({
  wizard: { next, previous, steps, step, replace },
}) => {
  useStepValidation(steps, step, replace);
  const place = useSelector(({ registration }) => registration.place || ({} as Place));
  const dispatch = useDispatch();

  const handleSubmit = ({ place }: FormValues) => {
    dispatch(setRegistration({ place }));
    dispatch(trackProvisionPortalData({ ...place }));
    next();
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={{ place }}
      validationSchema={yup.object({ place: placeSchema })}
    >
      {({ values: { place }, dirty, isSubmitting }) => (
        <WizardCard
          title={t.signUp.wizards.place.title}
          onClickPrev={previous}
          onClickNext={() => handleSubmit({ place })}
          isLoading={isSubmitting}
          labelPrev={t.global.labels.back}
          labelNext={t.global.labels.continue}
          disabledNext={!dirty && isEmpty(place)}
          icon="/img/icons/place.svg"
          hint={t.signUp.wizards.place.hint}
          steps={steps.length - 1}
          step={steps.indexOf(step)}
        >
          <Text mb="2rem">{t.signUp.wizards.place.description}</Text>
          <PlaceSearchField
            name="place"
            label={t.global.forms.place}
            onChange={
              isMobile
                ? place => {
                    place.city && place.code && handleSubmit({ place });
                  }
                : undefined
            }
          />
        </WizardCard>
      )}
    </Formik>
  );
};

export default withLocationTrack(t.signUp.wizards.place.title)(PlaceStep);
