import React, { FunctionComponent } from 'react';
import * as t from 'data';
import { Col, Markdown, Row, Text, WizardCard, Button } from 'mymoria-ui/components';
import { RadioGroupField, InputField, PasswordField, EmailField } from 'forms/fields';
import { Formik } from 'formik';
import { salutationOptions } from 'forms/options';
import { useAuth, useDispatch } from 'hooks';
import { WizardComponentProps } from 'react-albus';
import { usePush } from 'mymoria-ui/hooks';
import { pick } from 'lodash';
import { SignupValues } from 'types';
import { styled, getValidPhoneFormat } from 'mymoria-ui';
import { withLocationTrack } from 'hocs';
import { trackProvisionPortalData } from 'actions/tracking';
import { useSelector } from 'hooks';
import { setRegistration } from 'actions/registration';
import { useStepValidation } from './utils';
import { contactDetailsSchema } from 'helpers';

const { forms, labels, signIn } = t.global;

const StyledMarkdown = styled(Markdown)`
  ul {
    margin: 0;
    list-style: none;

    li {
      margin: 1rem 0;
      padding: 0 0 0 44px;
      background-size: 30px 31px;
      background-image: url('/img/ic_check.svg');
      background-repeat: no-repeat;
    }

    p {
      font-size: 18px;
      line-height: 30px;
      letter-spacing: 1.69px;
    }
  }
`;

const ContactStep: FunctionComponent<WizardComponentProps> = ({
  wizard: { previous, steps, step, replace },
}) => {
  useStepValidation(steps, step, replace);
  const { push: historyPush } = usePush();
  const registration = useSelector(({ registration }) => registration);
  const dispatch = useDispatch();
  const { checkUser } = useAuth();

  const handleSubmit = (signUpValues: SignupValues) => {
    const data = {
      ...signUpValues,
      terms: true,
      phone: getValidPhoneFormat({ phone: signUpValues.phone, formatType: 'e164' }),
    };
    dispatch(setRegistration(data));
    dispatch(trackProvisionPortalData(pick(data, ['firstname', 'lastname', 'email', 'phone'])));
    historyPush('/register/prepare');
  };

  return (
    <Formik
      validationSchema={contactDetailsSchema(checkUser)}
      onSubmit={handleSubmit}
      autoComplete="off"
      initialValues={
        {
          password: '',
          phone: '',
          terms: false,
          salutation: '',
          ...registration,
        } as SignupValues
      }
    >
      {({ submitForm, isSubmitting }) => (
        <WizardCard
          title={t.signUp.wizards.contact.title}
          onClickNext={undefined}
          onClickPrev={previous}
          labelPrev={labels.back}
          icon="/img/icons/contact.svg"
          hint={t.signUp.wizards.contact.hint}
          steps={steps.length - 1}
          step={steps.indexOf(step)}
        >
          <Text mb="2rem">{t.signUp.wizards.contact.description}</Text>
          <Row flexWrap="wrap" mx={-1}>
            <Col width={1}>
              <RadioGroupField name="salutation" values={salutationOptions} />
            </Col>
            <Col width={[1, 1 / 2]}>
              <InputField name="firstname" label={forms.firstname} />
            </Col>
            <Col width={[1, 1 / 2]}>
              <InputField name="lastname" label={forms.lastname} />
            </Col>
            <Col width={1}>
              <InputField name="phone" label={forms.phone} />
            </Col>
            <Col width={1}>
              <EmailField name="email" label={forms.email} type="email" hint={forms.usernameHint} />
            </Col>
            <Col width={1}>
              <PasswordField name="password" label={forms.password} type="password" />
            </Col>
            <Col width={1}>
              <Markdown children={forms.terms} />
            </Col>
            <Col width={1} my={3} display="flex" justifyContent="center">
              <Button onClick={submitForm} disabled={isSubmitting}>
                {signIn.register}
              </Button>
            </Col>
            <Col width={1}>
              <StyledMarkdown>{t.signUp.wizards.contact.list}</StyledMarkdown>
            </Col>
          </Row>
        </WizardCard>
      )}
    </Formik>
  );
};

export default withLocationTrack(t.signUp.wizards.contact.title)(ContactStep);
