import { useFunnel } from 'hooks';
import React, { FunctionComponent } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import WishesView from './WishesView';
import WishesEdit from './WishesEdit';
import WishesCreate from './WishesCreate';

const WishesPage: FunctionComponent<RouteComponentProps> = ({ match: { path } }) => {
  const { mode, section } = useFunnel();

  if (mode === 'edit') {
    return <WishesEdit section={section} />;
  }

  return (
    <Switch>
      <Route path={`${path}/create`} component={WishesCreate} />
      <Route path={path} component={WishesView} />
    </Switch>
  );
};

export default WishesPage;
