import * as t from 'data';
import React, { ChangeEvent, FunctionComponent } from 'react';
import { FuneralPlan, FuneralSite, FuneralType } from 'mymoria-types';
import { Col, RadioHint, Row, Text } from 'mymoria-ui/components';
import RadioGroup from 'mymoria-ui/components/Form/RadioGroup';
import { useSelector } from 'hooks';
import { getEstimatedGravePrice } from 'helpers';
import config from 'config';
import { head } from 'lodash';

const {
  defaultGraveTypes: {
    sea: { basic, high },
  },
} = config;

interface FuneralSiteGravesProps {
  funeralSite: FuneralSite;
  funeralType: FuneralType;
  funeralPlan: FuneralPlan;
  onChange: (value: string) => void;
  graveType?: string;
}

const FuneralSiteGraves: FunctionComponent<FuneralSiteGravesProps> = ({
  funeralSite,
  funeralType,
  funeralPlan,
  onChange,
  graveType,
}) => {
  const graves = useSelector(({ entities }) => entities.graveTypes);
  const values = [];
  let exactPriceGrave = false;

  for (const graveType in funeralSite?.fees.prices) {
    const grave = graves[graveType];
    const price = getEstimatedGravePrice(funeralSite, graveType);
    const gravePricesInfo = funeralSite.fees.prices[graveType];

    // check if there is at least 1 grave with exact price
    if (grave && gravePricesInfo.selling_price) {
      exactPriceGrave = true;
    }

    if (grave && grave.funeralType === funeralType) {
      values.push({
        value: grave.id,
        label: grave.translations.name,
        suffix: <RadioHint>{grave.translations.description}</RadioHint>,
        extra: price,
      });
    }
  }

  if (funeralType !== 'sea' && !exactPriceGrave) {
    values.push({
      label: t.offer.funeralSites.graves.notSure,
      value: 'not-sure',
    });
  }

  let graveTypeValue = graveType;
  if (funeralType === 'sea') {
    if (!graveTypeValue) {
      if (funeralPlan === 'high' || funeralPlan === 'premium') {
        graveTypeValue = high;
      } else {
        graveTypeValue = basic;
      }
    }

    if (!values.map(({ value }) => value).includes(graveTypeValue)) {
      graveTypeValue = head(values)?.value;
    }

    if (graveTypeValue && graveTypeValue !== graveType) {
      onChange(graveTypeValue);
    }
  }

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    onChange(target.value);
  };

  return (
    <Row flexWrap="wrap">
      <Col width={1}>
        <Text>{t.offer.funeralSites.graves.selection[funeralType]}</Text>
        <RadioGroup
          name="gravesType"
          layout="vertical"
          value={graveTypeValue}
          values={values}
          onChange={handleChange}
        />
      </Col>
    </Row>
  );
};

export default FuneralSiteGraves;
